import { defineMessages } from 'react-intl';

export default defineMessages({
    details: {
        id: 'rooms.details',
        defaultMessage: 'Details',
    },
    units: {
        id: 'rooms.units',
        defaultMessage: 'Units',
    },
    iCalManagement: {
        id: 'rooms.iCalManagement',
        defaultMessage: 'iCal channel management',
    },
    editRoom: {
        id: 'rooms.editRoom',
        defaultMessage: 'Edit room - {roomName}',
    },
    newRoom: {
        id: 'rooms.newRoom',
        defaultMessage: 'New room',
    },
    name: {
        id: 'rooms.name',
        defaultMessage: 'Name',
    },
    maximumGuests: {
        id: 'rooms.maximumGuests',
        defaultMessage: 'Maximum guests',
    },
    maximumAdults: {
        id: 'rooms.maximumAdults',
        defaultMessage: 'Maximum adults',
    },
    maximumAdultsValidation: {
        id: 'rooms.maximumAdultsValidation',
        defaultMessage: 'Room cannot accept more adult guests than its maximum capacity',
    },
    minimumPricePerDay: {
        id: 'rooms.minimumPricePerDay',
        defaultMessage: 'Minimum price per day',
    },
    daysToBlockAfterCheckout: {
        id: 'rooms.daysToBlockAfterCheckout',
        defaultMessage: 'Number of days to block after check out',
    },
    iCalExportUrl: {
        id: 'rooms.iCalExportUrl',
        defaultMessage: 'ICal export URL',
    },
    iCalUrls: {
        id: 'rooms.iCalUrls',
        defaultMessage: 'ICal URLs',
    },
    addImportLink: {
        id: 'rooms.addImportLink',
        defaultMessage: 'Add import link',
    },
    deleteImportLink: {
        id: 'rooms.deleteImportLink',
        defaultMessage: 'delete',
    },
    channelName: {
        id: 'rooms.channelName',
        defaultMessage: 'Channel name',
    },
    importUrls: {
        id: 'rooms.importUrls',
        defaultMessage: 'Import URLs',
    },
    importUrl: {
        id: 'rooms.importUrl',
        defaultMessage: 'Import URL',
    },
    unitsCount: {
        id: 'rooms.unitsCount',
        defaultMessage: 'Number of units',
    },
    minimumPrice: {
        id: 'rooms.minimumPrice',
        defaultMessage: 'Minimum price',
    },
    roomHasBeenDeleted: {
        id: 'rooms.roomHasBeenDeleted',
        defaultMessage: 'Room {roomName} has been deleted',
    },
    edit: {
        id: 'rooms.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'rooms.delete',
        defaultMessage: 'Delete',
    },
    deleteRoomConfirmation: {
        id: 'rooms.deleteRoomConfirmation',
        defaultMessage: 'Are you sure you want to delete this room?',
    },
    addRoom: {
        id: 'rooms.addRoom',
        defaultMessage: 'Add room',
    },
    addUnit: {
        id: 'rooms.addUnit',
        defaultMessage: 'Add a unit',
    },
    deleteUnitConfirmation: {
        id: 'rooms.deleteUnitConfirmation',
        defaultMessage: 'Unit might have reservations. Are you sure you want to delete it?',
    },
    unitName: {
        id: 'rooms.unitName',
        defaultMessage: 'Unit name',
    },
    defaultUnitName: {
        id: 'rooms.defaultUnitName',
        defaultMessage: 'Unit {number}',
    },
    unit: {
        id: 'rooms.unit',
        defaultMessage: 'Unit',
    },
    nameTooltip: {
        id: 'rooms.nameTooltip',
        defaultMessage: 'Choose a name for this room (e.g. "Single Room", or "Studio With a Balcony").',
    },
    maximumGuestsTooltip: {
        id: 'rooms.maximumGuestsTooltip',
        defaultMessage: 'This is the total number of people this room can accommodate. A booking cannot be made for this room for more than this number of people.',
    },
    maximumAdultsTooltip: {
        id: 'rooms.maximumAdultsTooltip',
        defaultMessage: 'This is the maximum number of adults this room can accommodate.',
    },
    minimumStayInDaysTooltip: {
        id: 'rooms.minimumStayInDaysTooltip',
        defaultMessage: 'This sets the minimum length of stay for this room.',
    },
    maximumStayInDaysTooltip: {
        id: 'rooms.maximumStayInDaysTooltip',
        defaultMessage: 'This sets the maximum number of nights a booking will be accepted for.',
    },
    minimumPriceTooltip: {
        id: 'rooms.minimumPriceTooltip',
        defaultMessage: 'The minimum price per night this room is allowed to sell for.',
    },
    unitNameTooltip: {
        id: 'rooms.unitNameTooltip',
        defaultMessage: ' If you have more than one unit of this room type give each unit an individual name for internal use (e.g. "Room 1", "Room 2", etc.)',
    },
    editUnit: {
        id: 'rooms.editUnit',
        defaultMessage: 'Edit unit - {unitName}',
    },
    newUnit: {
        id: 'rooms.newUnit',
        defaultMessage: 'New unit',
    },
    cannotDeleteRoom: {
        id: 'rooms.cannotDeleteRoom',
        defaultMessage: 'It is not possible to delete a room that has reservations or closings, has rates associated to it or is connected to an OTA.',
    },
    atLeastOneRoom: {
        id: 'rooms.atLeastOneRoom',
        defaultMessage: 'Property must have at least one room.',
    },
    atLeastOneUnit: {
        id: 'rooms.atLeastOneUnit',
        defaultMessage: 'Room must have at least one unit.',
    },
    cannotDeleteUnit: {
        id: 'rooms.cannotDeleteUnit',
        defaultMessage: 'It is not possible to delete a unit that has reservations or closings.',
    },
})
