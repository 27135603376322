import React, { Component } from 'react';

import { composeClassName } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import {
    handleRadioChange,
} from '../Form';

export default class Radio extends Component {
    render() {
        const {
            label,
            formatters = [],
            options,
            error,
            value,
            name,
            disabled,
            submitting,
        } = this.props;

        const radioOptions = typeof options === 'function'
            ? options(this.props)
            : options;

        return (
            <div className={composeClassName('input-container-radio', ...formatters)}>
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}

                <div className={composeClassName('radio-container', ...formatters)}>
                    {radioOptions.map(radio =>
                        <div
                            className={composeClassName('single-radio', ...formatters)}
                            key={radio.key}
                        >
                            <input
                                type="radio"
                                name={name}
                                id={radio.value}
                                value={radio.value}
                                defaultChecked={radio.value === value}
                                onChange={handleRadioChange.bind(this, this, radio.value)}
                                className={composeClassName('input-radio', ...formatters)}
                                disabled={disabled || submitting}
                            />
                            <label
                                className={composeClassName('radio-label', ...formatters)}
                            >
                                {radio.text}
                            </label>
                        </div>
                    )}
                </div>

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        );
    }
}
