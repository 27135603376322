import {
    TABS_SET_ACTIVE_TAB,
    TABS_REMOVE_TAB_GROUP,
    SET_PENDING_TAB,
} from './constants';

export const removeTabGroup = groupName => ({
    type: TABS_REMOVE_TAB_GROUP,
    groupName,
});

export const setActiveTab = (groupName, tabId, onChange)  => ({
    type: TABS_SET_ACTIVE_TAB,
    groupName,
    tabId,
});

export const setPendingTab = (groupName, tabId) => ({
    type: SET_PENDING_TAB,
    groupName,
    tabId,
});
