import { List, fromJS } from 'immutable';

import { reduceWithDefault } from 'utils';
import {
    UNAVAILABILITY_PROPS,
    CAL_SET_EDITED_UNAVAILABILITY,
    CAL_GET_UNAVAILABILITIES_SUCCESS,
    CAL_POST_UNAVAILABILITY_SUCCESS,
    CAL_PUT_UNAVAILABILITY_SUCCESS,
    CAL_DELETE_UNAVAILABILITY_SUCCESS,
} from './constants';
import {mapUnavailabilitiesValues, mapUnavailabilityValues} from "./utils";

const initialState = fromJS({
    editedUnavailability: {},
    unavailabilities: [],
});

const reducers = {
    [CAL_GET_UNAVAILABILITIES_SUCCESS]: (state, { data }) =>
        state.set('unavailabilities', mapUnavailabilitiesValues(fromJS(data || []))),

    [CAL_POST_UNAVAILABILITY_SUCCESS]: (state, { data }) =>
        state.update('unavailabilities', unavailabilities =>
            updateUnavailabilities(unavailabilities || List(), data)
        ),

    [CAL_PUT_UNAVAILABILITY_SUCCESS]: (state, { data }) =>
        state.update('unavailabilities', unavailabilities =>
            updateUnavailabilities(unavailabilities || List(), data)
        ),

    [CAL_DELETE_UNAVAILABILITY_SUCCESS]: (state, { data }) =>
        state.update('unavailabilities', unavailabilities =>
            unavailabilities.filterNot(unavailability =>
                unavailability.get(UNAVAILABILITY_PROPS.ID) === data[UNAVAILABILITY_PROPS.ID]
            )
        ),

    [CAL_SET_EDITED_UNAVAILABILITY]: (state, { editedUnavailability }) =>
        state.set('editedUnavailability', editedUnavailability),
};

const updateUnavailabilities = (unavailabilities, data) => {
    if (data.Delete && data.Delete.length) {
        const toDelete = data.Delete.map(unavailability => unavailability[UNAVAILABILITY_PROPS.ID])

        unavailabilities = unavailabilities.filter(unavailability =>
            toDelete.indexOf(unavailability.get(UNAVAILABILITY_PROPS.ID)) === -1
        )
    }

    if (data.Add) {
        unavailabilities = unavailabilities
            .push(mapUnavailabilityValues(fromJS(data.Add)))
    }

    if (data.Update) {
        unavailabilities = unavailabilities.map(unavailability =>
            unavailability.get(UNAVAILABILITY_PROPS.ID) === data.Update[UNAVAILABILITY_PROPS.ID]
                ? mapUnavailabilityValues(fromJS(data.Update))
                : unavailability
        )
    }

    return unavailabilities
}

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers)
