import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    injectIntl,
} from "react-intl";

import Page from 'eksenia-lib/src/Page';
import Form from 'eksenia-lib/src/Form';
import GuestList from './GuestList';
import guestsFilteringForm from "./guestsFilteringForm";
import {PAGE_FORMATTERS} from "eksenia-lib/src/Page/Page";

export class Guests extends Component {

    renderHeader() {
        return (
            <Form
                blueprint={guestsFilteringForm(this.props.intl)}
            />
        );
    }

    render() {
        return (
            <Page
                header={this.renderHeader()}
                formatters={[PAGE_FORMATTERS.WITH_HEADER]}
            >
                <GuestList />
            </Page>
        );
    }
}

const mapDispatchToProps = {
};

export default injectIntl(connect(null, mapDispatchToProps)(Guests))
