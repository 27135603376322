import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import localeMessages from './messages';
import {
    GUESTS_FILTERING_PROPS,
    FILTER_GUESTS_FORM_NAME,
} from './constants';

export default intl => ({
    formName: FILTER_GUESTS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.HORIZONTAL],
    noSubmitButton: true,
    fields: [
        {
            type: 'input',
            name: GUESTS_FILTERING_PROPS.TEXT,
            initialValue: '',
            placeholder: intl.formatMessage(localeMessages.searchGuestPlaceholder),
        },
    ],
})

