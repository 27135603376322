import { Map, List } from 'immutable';
import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    PROPERTY_PROPS,
} from 'Properties';
import {
    BOOKING_PAGE_SETTINGS_FORM_NAME,
    BOOKING_PAGE_PROPERTY_PROPS,
    AVAILABILITY_RULE_OPTIONS,
} from './constants';
import localeMessages from './messages';
import {FILE_INPUT_PROPS} from "eksenia-lib/src/File/constants";

export default (
    initialValues = Map(),
    intl,
    countryOptions
) => ({
    formName: BOOKING_PAGE_SETTINGS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: PROPERTY_PROPS.BOOKING_PAGE_ACTIVE,
                    initialValue: initialValues.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE),
                    label: <FormattedMessage {...localeMessages.activate} />,
                    help: <FormattedMessage {...localeMessages.activateTooltip} />,
                },
                {
                    type: 'input',
                    name: BOOKING_PAGE_PROPERTY_PROPS.DISPLAY_NAME,
                    initialValue: initialValues.getIn([PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.DISPLAY_NAME]),
                    label: <FormattedMessage {...localeMessages.displayName} />,
                    validations: ['required'],
                    validateIf: values => values.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE),
                    help: <FormattedMessage {...localeMessages.displayNameTooltip} />,
                },
                {
                    type: 'select',
                    name: PROPERTY_PROPS.COUNTRY,
                    initialValue: initialValues.get(PROPERTY_PROPS.COUNTRY),
                    label: <FormattedMessage {...localeMessages.country} />,
                    validations: ['required'],
                    validateIf: values => values.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE),
                    options: countryOptions,
                },
                {
                    type: 'input',
                    name: PROPERTY_PROPS.CITY,
                    initialValue: initialValues.get(PROPERTY_PROPS.CITY),
                    label: <FormattedMessage {...localeMessages.city} />,
                    validations: ['required'],
                    validateIf: values => values.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE),
                },
                {
                    type: 'input',
                    name: BOOKING_PAGE_PROPERTY_PROPS.ADDRESS,
                    initialValue: initialValues.getIn([PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.ADDRESS]),
                    label: <FormattedMessage {...localeMessages.address} />,
                    validations: ['required'],
                    validateIf: values => values.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE),
                },
                {
                    type: 'input',
                    name: BOOKING_PAGE_PROPERTY_PROPS.EMAIL,
                    initialValue: initialValues.getIn([PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.EMAIL]),
                    label: <FormattedMessage {...localeMessages.email} />,
                    help: <FormattedMessage {...localeMessages.emailTooltip} />,
                },
                {
                    type: 'input',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PHONE_NUMBER,
                    initialValue: initialValues.getIn([PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.PHONE_NUMBER]),
                    label: <FormattedMessage {...localeMessages.phone} />,
                    help: <FormattedMessage {...localeMessages.phoneTooltip} />,
                },
                {
                    type: 'input',
                    name: BOOKING_PAGE_PROPERTY_PROPS.WEBSITE,
                    initialValue: initialValues.getIn([PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.WEBSITE]),
                    label: <FormattedMessage {...localeMessages.website} />,
                    help: <FormattedMessage {...localeMessages.websiteTooltip} />,
                },
                {
                    type: 'file',
                    name: BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_MAIN_IMAGE,
                    initialValue: Map({
                        [FILE_INPUT_PROPS.IDS]: List([initialValues.getIn(
                            [PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_MAIN_IMAGE]
                        )].filter( val => val)),
                        [FILE_INPUT_PROPS.FILES]: List(),
                    }),
                    label: <FormattedMessage {...localeMessages.coverImage} />,
                    accept: 'image/png, image/jpeg',
                    showPreview: true,
                    validateWith: values => {
                        if (!values.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE)) {
                            return ['maxFileSize']
                        } else {
                            return ['requiredFile', 'maxFileSize']
                        }
                    },
                    help: <FormattedMessage {...localeMessages.coverImageTooltip} />,
                    previewTitle: <FormattedMessage {...localeMessages.coverImagePreviewTitle} />,
                },
                {
                    type: 'file',
                    name: BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_IMAGES,
                    initialValue: Map({
                        [FILE_INPUT_PROPS.IDS]: initialValues.getIn(
                            [PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_IMAGES]
                        ) || List(),
                        [FILE_INPUT_PROPS.FILES]: List(),
                    }),
                    label: <FormattedMessage {...localeMessages.galleryImages} />,
                    accept: 'image/png, image/jpeg',
                    multiple: true,
                    showPreview: true,
                    validateWith: values => {
                        if (!values.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE)) {
                            return ['maxFileSize']
                        } else {
                            return [
                                'maxFileSize',
                                {validator: 'minimumFiles', args: [2]},
                                {validator: 'maximumFiles', args: [20]},
                            ]
                        }
                    },
                    help: <FormattedMessage {...localeMessages.galleryImagesTooltip} />,
                    previewTitle: <FormattedMessage {...localeMessages.galleryImagesPreviewTitle} />,
                },
                {
                    type: 'select',
                    name: BOOKING_PAGE_PROPERTY_PROPS.AVAILABILITY_RULE,
                    initialValue: initialValues.getIn([PROPERTY_PROPS.AMENITIES, BOOKING_PAGE_PROPERTY_PROPS.AVAILABILITY_RULE])
                        || AVAILABILITY_RULE_OPTIONS.ANY_UNIT_AVAILABLE,
                    label: <FormattedMessage {...localeMessages.availabilityRule} />,
                    options: [
                        {
                            key: AVAILABILITY_RULE_OPTIONS.SUM_OF_ALL_BOOKINGS,
                            value: AVAILABILITY_RULE_OPTIONS.SUM_OF_ALL_BOOKINGS,
                            text: intl.formatMessage(localeMessages.sumOfAllBookings),
                        },
                        {
                            key: AVAILABILITY_RULE_OPTIONS.ANY_UNIT_AVAILABLE,
                            value: AVAILABILITY_RULE_OPTIONS.ANY_UNIT_AVAILABLE,
                            text: intl.formatMessage(localeMessages.anyUnitAvailable),
                        },
                    ],
                    help: <div>
                        <div>
                            <FormattedMessage {...localeMessages.sumOfAllBookings} tagName="strong" />
                            <FormattedMessage {...localeMessages.sumOfAllBookingsTooltip} />
                        </div>
                        <div>
                            <FormattedMessage {...localeMessages.anyUnitAvailable} tagName="strong" />
                            <FormattedMessage {...localeMessages.anyUnitAvailableTooltip} />
                        </div>
                    </div>,
                },
            ],
        },
    ],
})
