import React, { Component } from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import {
    FormattedMessage,
} from 'react-intl';

import Page from 'eksenia-lib/src/Page';
import Button from 'eksenia-lib/src/Button';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import RateList from './RateList';
import EditRate from './EditRate';
import {
    EDIT_RATE_MODAL_NAME,
} from './constants';
import localeMessages from './messages';
import {PAGE_FORMATTERS} from "eksenia-lib/src/Page/Page";

export class RatePage extends Component {

    renderHeader() {
        const {
            showModal,
        } = this.props;

        return (
            <div>
                <Button
                    onClick={showModal.bind(this, EDIT_RATE_MODAL_NAME)}
                    formatters={[Button.TYPE.PRIMARY]}
                >
                    <FormattedMessage {...localeMessages.addRate} />
                </Button>
            </div>
        );
    }

    render() {
        return (
            <Page
                header={this.renderHeader()}
                formatters={[PAGE_FORMATTERS.WITH_HEADER]}
            >
                <RateList />

                <EditRate />
            </Page>
        );
    }
}

const mapStateToProps = (store) => {
    const ratesReducer = store.ratesReducer;

    return {
        editedRate: ratesReducer.get('editedRate', Map()),
    }
};

const mapDispatchToProps = {
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RatePage);
