import { Map } from 'immutable';
import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    FOOD_AND_DRINK_AMENITIES_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: FOOD_AND_DRINK_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.DINING_AREA,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.DINING_AREA),
                    label: <FormattedMessage {...localeMessages.diningArea} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.DINING_TABLE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.DINING_TABLE),
                    label: <FormattedMessage {...localeMessages.diningTable} />,
                },
                {
                    type: 'checkbox',
                    integer: true,
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BOTTLE_OF_WATER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BOTTLE_OF_WATER),
                    label: <FormattedMessage {...localeMessages.bottleOfWater} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.COOKIES,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.COOKIES),
                    label: <FormattedMessage {...localeMessages.chocolate} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FRUIT,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FRUIT),
                    label: <FormattedMessage {...localeMessages.fruit} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.WINE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.WINE),
                    label: <FormattedMessage {...localeMessages.wine} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BARBECUE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BARBECUE),
                    label: <FormattedMessage {...localeMessages.barbecue} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.OVEN,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.OVEN),
                    label: <FormattedMessage {...localeMessages.oven} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.STOVE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.STOVE),
                    label: <FormattedMessage {...localeMessages.stove} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TOASTER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TOASTER),
                    label: <FormattedMessage {...localeMessages.toaster} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.DISHWASHER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.DISHWASHER),
                    label: <FormattedMessage {...localeMessages.dishwasher} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.ELECTRIC_KETTLE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.ELECTRIC_KETTLE),
                    label: <FormattedMessage {...localeMessages.electricKettle} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.OUTDOOR_DINING_AREA,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.OUTDOOR_DINING_AREA),
                    label: <FormattedMessage {...localeMessages.outdoorDiningArea} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.OUTDOOR_FURNITURE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.OUTDOOR_FURNITURE),
                    label: <FormattedMessage {...localeMessages.outdoorFurniture} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.MICROWAVE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.MICROWAVE),
                    label: <FormattedMessage {...localeMessages.microwave} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.REFRIGERATOR,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.REFRIGERATOR),
                    label: <FormattedMessage {...localeMessages.refrigerator} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TEA_COFFEE_MAKER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TEA_COFFEE_MAKER),
                    label: <FormattedMessage {...localeMessages.coffeeMaker} />,
                },
            ],
        },
    ],
})
