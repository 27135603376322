import React, {Component} from 'react';
import { Redirect, withRouter } from 'react-router';
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from 'Header';
import Skeleton from "eksenia-lib/src/Skeleton";
import VerticalNavigation from "../VerticalNavigation";
import Onboarding from "../Onboarding";
import ReactResizeDetector from "react-resize-detector";

class AuthCheck extends Component {

    render() {
        const {
            component: Component,
            location,
            user,
            loadingProperties,
            rooms,
            finishedLoadingRooms,
        } = this.props;

        if (!user) {
            return null
        }
        if (!user.toJS().Email) {
            return (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: location }
                }}/>
            )
        }
        if (!finishedLoadingRooms || loadingProperties) {
            return (
                <Skeleton />
            )
        }

        const showOnboarding = finishedLoadingRooms && rooms.size === 0;
        if (showOnboarding && location.pathname !== '/rooms') {
            return (
                <Redirect to={{
                    pathname: '/rooms',
                }}/>
            )
        }

        return (
            <ReactResizeDetector handleWidth>
                {({width}) =>
                    <div className="private-layout">
                        <VerticalNavigation location={location} width={width}/>

                        <div className="private-container">
                            <Header/>
                            <Component/>
                        </div>

                        {showOnboarding && <Onboarding/>}
                    </div>
                }
            </ReactResizeDetector>
        );
    }
}

const mapStateToProps = store => ({
    user: store.userReducer.get('user'),
    loadingProperties: store.propertiesReducer.get('loadingProperties'),
    rooms: store.roomsReducer.get('rooms'),
    finishedLoadingRooms: store.roomsReducer.get('finishedLoadingRooms'),
});

const AuthCheckRedux = withRouter(connect(mapStateToProps)(AuthCheck));

const PrivateLayout = ({ component: Component, ...rest }) => {
    return <Route { ...rest } render={ () => <AuthCheckRedux component={ Component } /> } />;
};

export default PrivateLayout;