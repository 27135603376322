import {endpoints} from "api";
import {
    USR_GET_USER_ERROR,
    USR_GET_USER_REQUEST,
    USR_GET_USER_SUCCESS,
    USR_POST_LOG_IN_ERROR,
    USR_POST_LOG_IN_REQUEST,
    USR_POST_LOG_IN_SUCCESS,
    USR_POST_LOG_OUT_ERROR,
    USR_POST_LOG_OUT_REQUEST,
    USR_POST_LOG_OUT_SUCCESS,
    USR_POST_COMPLETE_PASSWORD_RESET_ERROR,
    USR_POST_COMPLETE_PASSWORD_RESET_REQUEST,
    USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS,
    USR_POST_LANGUAGE_REQUEST,
    USR_POST_LANGUAGE_SUCCESS,
    USR_POST_LANGUAGE_ERROR,
    USR_POST_REQUEST_PASSWORD_RESET_REQUEST,
    USR_POST_REQUEST_PASSWORD_RESET_SUCCESS,
    USR_POST_REQUEST_PASSWORD_RESET_ERROR,
    USR_ALLOW_NEW_PASSWORD_REQUEST,
} from "./constants";

export const getUser = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getUser,
    actions: {
        request: USR_GET_USER_REQUEST,
        success: USR_GET_USER_SUCCESS,
        error: USR_GET_USER_ERROR,
    },
});

export const logIn = (email, pwd) => ({
    type: 'API_CALL',
    endpoint: endpoints.logIn,
    actions: {
        request: USR_POST_LOG_IN_REQUEST,
        success: USR_POST_LOG_IN_SUCCESS,
        error: USR_POST_LOG_IN_ERROR,
    },
    body: {
        Email: email,
        Pwd: pwd,
    },
});

export const logOut = () => ({
    type: 'API_CALL',
    endpoint: endpoints.logOut,
    actions: {
        request: USR_POST_LOG_OUT_REQUEST,
        success: USR_POST_LOG_OUT_SUCCESS,
        error: USR_POST_LOG_OUT_ERROR,
    },
});

export const completePasswordReset = (token, pwd) => ({
    type: 'API_CALL',
    endpoint: endpoints.completePasswordReset,
    actions: {
        request: USR_POST_COMPLETE_PASSWORD_RESET_REQUEST,
        success: USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS,
        error:  USR_POST_COMPLETE_PASSWORD_RESET_ERROR,
    },
    body: {
        Pwd: pwd,
        Token: token,
    },
});

export const setLanguage = (language, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postLanguage,
    actions: {
        request: USR_POST_LANGUAGE_REQUEST,
        success: USR_POST_LANGUAGE_SUCCESS,
        error:  USR_POST_LANGUAGE_ERROR,
        successCallback,
    },
    body: {
        Language: language,
    },
});

export const impersonateWithToken = (token) => ({
    type: 'API_CALL',
    endpoint: endpoints.impersonateWithToken,
    actions: {
        request: USR_GET_USER_REQUEST,
        success: USR_GET_USER_SUCCESS,
        error: USR_GET_USER_ERROR,
    },
    body: {
        Token: token,
    },
});

export const requestPasswordReset = (email, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.requestPasswordReset,
    actions: {
        request: USR_POST_REQUEST_PASSWORD_RESET_REQUEST,
        success: USR_POST_REQUEST_PASSWORD_RESET_SUCCESS,
        error:  USR_POST_REQUEST_PASSWORD_RESET_ERROR,
        successCallback,
    },
    body: {
        Email: email,
        NewUser: false,
    },
});

export const allowNewPasswordRequest = () => ({
    type: USR_ALLOW_NEW_PASSWORD_REQUEST,
});