import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Iterable, List, Map } from 'immutable';
import {
    FormattedDate, injectIntl,
} from 'react-intl';

import { getFormValues } from 'eksenia-lib/src/Form';
import Table from 'eksenia-lib/src/Table';
import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import {
    getUnitName,
} from 'Rooms';
import {
    openEditReservationModal,
    getReservations,
    setReservationListEditedReservation,
} from './actions';
import {
    RESERVATION_PROPS,
    BKG_FILTER_RESERVATIONS_FORM_NAME,
    BKG_RESERVATION_FILTERING_PROPS,
    BKG_RESERVATION_FILTERING_ALL_VALUE,
    BKG_RESERVATION_STATUS_VALUES,
} from './constants';
import {
    getReservationGuestName,
} from './utils';
import localeMessages from './messages';
import {ReservationStatus} from "./ReservationStatus";
import {EditReservation} from "./index";

const tableHeader = [
    {
        content: localeMessages.status,
        key: 'status',
        width: '150px',
        sort: {
            ascending: (a, b) => (b === BKG_RESERVATION_STATUS_VALUES.CONFIRMED && a !== BKG_RESERVATION_STATUS_VALUES.CONFIRMED)
                || (b === BKG_RESERVATION_STATUS_VALUES.NOT_CONFIRMED && a === BKG_RESERVATION_STATUS_VALUES.CANCELED)
                    ? 1
                    : -1,
            descending: (a, b) => (a === BKG_RESERVATION_STATUS_VALUES.CONFIRMED && b !== BKG_RESERVATION_STATUS_VALUES.CONFIRMED)
            || (a === BKG_RESERVATION_STATUS_VALUES.NOT_CONFIRMED && b === BKG_RESERVATION_STATUS_VALUES.CANCELED)
                ? 1
                : -1
        }
    },
    {
        content: localeMessages.unit,
        key: 'unit',
        width: '25%',
        sort: true,
    },
    {
        content: localeMessages.guestName,
        key: 'guestName',
        width: '25%',
        sort: true,
    },
    {
        content: localeMessages.checkInDate,
        key: 'checkIn',
        width: '15%',
        sort: true,
    },
    {
        content: localeMessages.checkOutDate,
        key: 'checkOut',
        width: '15%',
        sort: true,
    },
    {
        content: localeMessages.bookingDate,
        key: 'bookingDate',
        width: '20%',
        sort: true,
    },
];

export class ReservationList extends Component {

    constructor() {
        super(...arguments);

        this.handleEditReservation = this.handleEditReservation.bind(this);
    }

    componentDidMount() {
        this.props.getReservations();
    }

    handleEditReservation(reservation) {
        const {
            showModal,
            openEditReservationModal,
            setReservationListEditedReservation,
        } = this.props;

        const reservationId = reservation.get(RESERVATION_PROPS.ID);

        setReservationListEditedReservation(reservationId);

        openEditReservationModal(reservation);
        showModal(reservationId)
    }

    prepareTableRows() {
        const {
            rooms,
            reservations,
            filtersForm,
            units,
            intl,
        } = this.props;

        const filterValues = getFormValues(filtersForm);
        const roomFilterValue = filterValues[BKG_RESERVATION_FILTERING_PROPS.ROOMS];
        const periodFilterValue = filterValues[BKG_RESERVATION_FILTERING_PROPS.PERIOD];

        if (!Iterable.isIterable(rooms) || !periodFilterValue) {
            return false;
        }

        return reservations
            .filter(reservation =>
                (roomFilterValue === BKG_RESERVATION_FILTERING_ALL_VALUE
                || roomFilterValue === String(reservation.get(RESERVATION_PROPS.ROOM_ID)))
                && periodFilterValue.isBefore(reservation.get(RESERVATION_PROPS.CHECK_OUT), 'day')
            )
            .map(reservation => {
                const id = reservation.get(RESERVATION_PROPS.ID);
                const unitId = reservation.get(RESERVATION_PROPS.UNIT_ID);
                const unitName = getUnitName(rooms, units, unitId);

                const guestName = getReservationGuestName(reservation, intl);

                const status = reservation.get(RESERVATION_PROPS.STATUS);

                const bookingDate = (
                    <React.Fragment>
                        <FormattedDate dateStyle="medium" timeStyle="medium" value={reservation.get(RESERVATION_PROPS.RESERVED_AT)} />
                    </React.Fragment>);

                return {
                    key: id,
                    onClick: this.handleEditReservation.bind(null, reservation),
                    cells: [
                        {
                            content: <ReservationStatus status={status} />,
                            value: status,
                            key: 'status',
                            className: 'reservation-list-status-value',
                        },
                        {
                            content: unitName,
                            value: unitName,
                            key: 'unit',
                            className: 'reservation-list-unit-value',
                        },
                        {
                            content: guestName,
                            value: guestName,
                            key: 'guestName',
                            className: 'reservation-list-guest-name-value',
                        },
                        {
                            content: <FormattedDate value={reservation.get(RESERVATION_PROPS.CHECK_IN)} />,
                            value: reservation.get(RESERVATION_PROPS.CHECK_IN),
                            key: 'checkIn',
                            className: 'reservation-list-check-in-value',
                        },
                        {
                            content: <FormattedDate value={reservation.get(RESERVATION_PROPS.CHECK_OUT)} />,
                            value: reservation.get(RESERVATION_PROPS.CHECK_OUT),
                            key: 'checkOut',
                            className: 'reservation-list-check-out-value',
                        },
                        {
                            content: bookingDate,
                            value: reservation.get(RESERVATION_PROPS.RESERVED_AT),
                            key: 'bookingDate',
                        }
                    ],
                }
            }).toJS();
    }

    render() {
        const {
            reservationListEditedReservation,
            setReservationListEditedReservation,
        } = this.props;

        const rows = this.prepareTableRows();

        if (!rows) {
            return false;
        }

        return (
            <>
                <Table header={tableHeader} rows={rows} />
                {reservationListEditedReservation &&
                    <EditReservation
                        id={reservationListEditedReservation}
                        onClose={setReservationListEditedReservation.bind(this, null)}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (store) => {
    const roomsReducer = store.roomsReducer;
    const reservationsReducer = store.reservationsReducer;
    const formReducer = store.formReducer;

    return {
        rooms: roomsReducer.get('rooms'),
        units: roomsReducer.get('units', List()),
        filtersForm: formReducer.get(BKG_FILTER_RESERVATIONS_FORM_NAME) || Map(),
        reservations: reservationsReducer.get('reservations', List()),
        reservationListEditedReservation: reservationsReducer.get('reservationListEditedReservation'),
    }
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    openEditReservationModal,
    getReservations,
    addNotification,
    setReservationListEditedReservation,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReservationList))
