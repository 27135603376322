import { fromJS } from 'immutable';


import { reduceWithDefault } from '../utils';
import {
    GET_PUBLIC_FILE_URL_PREFIX_SUCCESS,
    SET_UPLOAD_PROGRESS,
    SET_UPLOAD_FINISHED,
} from './constants';

const initialState = fromJS({
    publicFileUrlPrefix: '',
    uploadProgress: {},
});

const reducers = {
    [GET_PUBLIC_FILE_URL_PREFIX_SUCCESS]: (state, { data }) =>
        state.set('publicFileUrlPrefix', data.Prefix),

    [SET_UPLOAD_PROGRESS]: (state, { name, total }) =>
        state.mergeIn(['uploadProgress', name], {
            total,
        }),

    [SET_UPLOAD_FINISHED]: (state, { name }) =>
        state.deleteIn(['uploadProgress', name]),
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
