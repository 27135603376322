import React, { Component } from 'react';

import { composeClassName } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import Tooltip from "../Tooltip";

export default class NumberInput extends Component {

    constructor() {
        super(...arguments);

        this.handleNumberChange = this.handleNumberChange.bind(this);
    }

    handleNumberChange(event) {
        const {
            onChangeFromForm,
            onChange,
            name,
            integer,
        } = this.props;

        var newValue = event.currentTarget.value;

        if (typeof newValue === 'string' && newValue !== '') {
            if (integer) {
                newValue = newValue.replace(/\D/g, '').substring(0, 14);

            } else {
                newValue = newValue.split('').reduce((acc, char) => {
                    const isAllowed = char.match(/\d|\./);

                    // if character is not a digit or a dot
                    // or if it is a dot but there's already a dot in the accumulator
                    if (!isAllowed || (char === '.' && acc.indexOf('.') !== -1)) {
                        return acc;
                    }

                    return acc.concat(char);
                }, '').substring(0, 14);
            }
        }

        if (typeof onChangeFromForm === 'function') {
            onChangeFromForm(name, newValue);
        }

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            formatters = [],
            error,
            validations,
            integer,
            suffix,
            parentValue,
            help,
            disabled,
            submitting,
            ...props
        } = this.props;

        return (
            <div
                className={composeClassName('input-number-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}

                <input
                    type="text"
                    className={composeClassName('input-number', error && 'error', ...formatters)}
                    onChange={this.handleNumberChange}
                    maxLength={12}
                    { ...props }
                    disabled={disabled || submitting}
                />

                {suffix &&
                <span className="suffix">
                        {suffix}
                    </span>
                }

                {help && <Tooltip help={help} />}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
