import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Iterable, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import ValidationError from '../ValidationError'
import { composeClassName } from '../utils';
import {
    addForm,
    removeForm,
    updateInputField,
    setValidationError,
} from './actions';
import methods, {getBlueprintFields} from './methods';
import localeMessages from './messages';
import {uploadFiles,} from '../File';

class Form extends Component {

    constructor() {
        super(...arguments);

        this.handleInputChange = methods.handleInputChange.bind(this);
        this.handleSubmit = methods.handleSubmit.bind(this);
        this.renderInputField = methods.renderInputField.bind(this);
        this.shouldShowField = methods.shouldShowField.bind(this);
        this.fieldBlueprintToStore = methods.fieldBlueprintToStore.bind(this);
        this.updateConnectedInputs = methods.updateConnectedInputs.bind(this);
        this.validateForm = methods.validateForm.bind(this);
        this.validateField = methods.validateField.bind(this);
        this.isFieldDirty = methods.isFieldDirty.bind(this);
        this.renderSection = methods.renderSection.bind(this);
        this.handleUploadFiles = methods.handleUploadFiles.bind(this);
        this.submit = methods.submit.bind(this);

        this.state = {
            success: false,
            valid: true,
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        const {
            addForm,
            blueprint,
        } = this.props;

        if (typeof blueprint.onMount === 'function') {
            blueprint.onMount();
        }

        const blueprintFields = getBlueprintFields(blueprint);
        const storeFields = this.fieldBlueprintToStore(blueprintFields);
        addForm(blueprint.formName, storeFields);
    }


    componentDidUpdate(prevProps) {
        const {
            form = Map(),
        } = this.props;
        const {
            form: prevForm = Map(),
        } = prevProps;

        if (!form.get('valid') && prevForm.get('valid')) {
            this.setState({valid: false});

            this.validTimeout = window.setTimeout(this.setState.bind(this, {valid: true}), 5000);

        } else if (!form.get('submitting') && prevForm.get('submitting') && form.get('error') === undefined && form.get('valid')) {
            this.setState({success: true});

            this.submittingTimeout = window.setTimeout(this.setState.bind(this, {success: false}), 5000);
        }

    }

    componentWillUnmount() {
        const {
            removeForm,
            blueprint,
        } = this.props;

        removeForm(blueprint.formName);
        if (this.validTimeout) {
            window.clearTimeout(this.validTimeout);
        }
        if (this.submittingTimeout) {
            window.clearTimeout(this.submittingTimeout);
        }
        if (this.errorScrollTimeout) {
            window.clearTimeout(this.errorScrollTimeout);
        }
    }

    render() {
        const {
            form,
            blueprint = {},
            children,
        } = this.props;

        const {
            formatters = [],
            fields = [],
            className,
            submitButtonText,
            noSubmitButton,
            columns,
        } = blueprint;

        if (!Iterable.isIterable(form)) {
            return false;
        }

        const {
            valid,
            success,
        } = this.state;

        const error = form.get('error');
        const submitting = form.get('submitting');

        let buttonContent = submitButtonText || <FormattedMessage {...localeMessages.save} />;
        if (submitting) {
            buttonContent = (
                <>
                    <span className="submitting-icon"/>
                    <FormattedMessage {...localeMessages.saving} />
                </>
            )
        } else if (valid && success) {
            buttonContent = (
                <>
                    <span className="success-icon"/>
                    <FormattedMessage {...localeMessages.saved} />
                </>
            )
        }

        return (
            <form
                onSubmit={this.handleSubmit}
                className={composeClassName('form', className, ...formatters)}
                ref={this.formRef}
            >
                <div className={composeClassName('form-input-fields', columns && 'columns')}>
                    {fields.map(field => methods.renderInputField.call(this, field))}
                </div>

                {children}

                {!noSubmitButton &&
                <div className="form-controls-container">
                    <div className="form-submit-button-container">
                        <Button
                            type="submit"
                            name="submit"
                            submitting={submitting}
                            error={!valid}
                            success={valid && success}
                            formatters={[Button.TYPE.SAVE]}
                        >
                            {buttonContent}
                        </Button>
                    </div>

                    <div className="form-error-container">
                        <ValidationError
                            error={error}
                            formatters={formatters.concat([ValidationError.FORMATTER.BASE_COLOR])}
                            withIcon
                        />
                    </div>
                </div>
                }

            </form>
        );
    }
}

const mapStateToProps = (store, ownProps) => {
    const formReducer = store.formReducer;
    const {
        blueprint,
    } = ownProps;

    return {
        form: formReducer.get(blueprint.formName),
    }
};

const mapDispatchToProps = {
    addForm,
    removeForm,
    updateInputField,
    setValidationError,
    uploadFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form)
