import { fromJS, Iterable } from 'immutable';

import { reduceWithDefault } from 'utils';
import {
    GET_PROPERTIES_SUCCESS, GET_TIMEZONES_SUCCESS,
    UPDATE_PROPERTY_SUCCESS,
} from './constants';

const initialState = fromJS({
    properties: [],
    currentProperty: false,
    loadingProperties: true,
    timezones: [],
});

const reducers = {
    [GET_PROPERTIES_SUCCESS]: (state, { data }) => {
        const properties = fromJS(data);
        return state.merge({
            properties,
            loadingProperties: false,
            currentProperty: Iterable.isIterable(properties) && properties.size
                ? properties.get(0)
                : false,
        })
    },

    [UPDATE_PROPERTY_SUCCESS]: (state, { data }) =>
        state.setIn(['properties', '0'], fromJS(data))
            .set('currentProperty', fromJS(data)),

    [GET_TIMEZONES_SUCCESS]: (state, { data }) =>
        state.set("timezones", fromJS(data)),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
