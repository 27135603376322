import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fromJS, Iterable, List, Map} from 'immutable';
import moment from 'moment';
import {FormattedMessage, injectIntl,} from 'react-intl';

import {addNotification,} from 'eksenia-lib/src/Notifications';
import {Form, updateInputField} from 'eksenia-lib/src/Form';
import {getRoomUnits, RMS_ROOM_PROPS, UNIT_PROPS,} from 'Rooms';
import Modal, {hideAllModals, hideModal, showModal,} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';
import Tabs from 'eksenia-lib/src/Tabs';
import Payments from 'Payments';
import InvoicePage from './InvoicePage';
import {EDIT_GUEST_MODAL_NAME, GUEST_PROPS,} from 'Guests';
import { clearAllEditedGuests, addEditedGuest } from 'Guests/actions';

import {
    BKG_DELETE_RESERVATION_CONFIRMATION_NAME,
    BKG_EDIT_RESERVATION_FORM_NAME,
    BKG_EDIT_RESERVATION_MODAL_NAME,
    GUEST_LIST_PROPS, inputsInfluencingPrice,
    RESERVATION_NOTES_FORM_NAME,
    RESERVATION_PROPS,
} from './constants';
import {
    closeAllEditedReservations,
    closeEditReservationModal, deleteNewReservationId,
    deleteReservation,
    getLatestReservations,
    getProvisionalPrice,
    getReservations,
    postReservation,
    putReservation, saveNewReservationId,
} from './actions';
import {
    prepareDataForApi,
    prepareReservationGuests,
    reservationGuestModalId,
    reservationGuestsFormName,
} from './utils';
import editReservationForm from './editReservationForm';
import reservationNotesForm from './reservationNotesForm';
import reservationGuestsForm from "./reservationGuestsForm";
import localeMessages from './messages';
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";
import Confirm from "eksenia-lib/src/Confirm";
import {generateKey} from "../utils";

export const defaultReservationData = (rooms, roomUnits) => ({
    [RESERVATION_PROPS.ROOM_ID]: rooms.getIn([0, RMS_ROOM_PROPS.ID]),
    [RESERVATION_PROPS.CHECK_IN]: moment(),
    [RESERVATION_PROPS.CHECK_OUT]: moment().add(1, 'days'),
    [RESERVATION_PROPS.ADULTS]: 1,
    [RESERVATION_PROPS.CHILDREN]: 0,
    [RESERVATION_PROPS.INFANTS]: 0,
    [RESERVATION_PROPS.PRICE]: '',
    [RESERVATION_PROPS.NOTES]: '',
    [RESERVATION_PROPS.GUEST_LIST]: [],
    [RESERVATION_PROPS.CHARGE_LIST]: [],
    [RESERVATION_PROPS.UNIT_ID]: ((roomUnits.get(rooms.getIn([0, RMS_ROOM_PROPS.ID])) || List()).find(unit =>
        unit.get(UNIT_PROPS.RANK) === 0
    ) || Map()).get(UNIT_PROPS.ID),
});

const formNames = (id, modalId) => [
    BKG_EDIT_RESERVATION_FORM_NAME,
    RESERVATION_NOTES_FORM_NAME,
    reservationGuestsFormName(modalId || id),
];

export class EditReservation extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleSaveGuests = this.handleSaveGuests.bind(this);
        this.saveSuccessCallback = this.saveSuccessCallback.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleGuestClick = this.handleGuestClick.bind(this);
        this.handleAddNewGuestClick = this.handleAddNewGuestClick.bind(this);
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
        this.handleFormValueChange = this.handleFormValueChange.bind(this);
        this.handleFormMount = this.handleFormMount.bind(this);
        this.createSuccessCallback = this.createSuccessCallback.bind(this);

        this.delayedGetProvisionalPrice = undefined
        this.state = {}
    }

    handleFormMount(initialValues) {
        this.props.getProvisionalPrice(prepareDataForApi(
            fromJS(initialValues)
                .filter((val, key) =>
                    inputsInfluencingPrice.indexOf(key) !== -1
                    || (key === RESERVATION_PROPS.PRICE && val !== 0 && val !== '')
                )
        ));
    }

    handleFormValueChange(inputName, value, values) {
        const {
            getProvisionalPrice,
        } = this.props;

        if (this.delayedGetProvisionalPrice) {
            window.clearTimeout(this.delayedGetProvisionalPrice);
        }

        if (inputsInfluencingPrice.indexOf(inputName) !== -1) {
            values = values
                .filter((val, key) =>
                    inputsInfluencingPrice.indexOf(key) !== -1
                )
                .set(inputName, inputName === RESERVATION_PROPS.ADULTS ? parseInt(value) : value);
            if (values.get(RESERVATION_PROPS.CHECK_OUT).isAfter(values.get(RESERVATION_PROPS.CHECK_IN))) {
                getProvisionalPrice(prepareDataForApi(values));
            }
        }

        if (inputName === RESERVATION_PROPS.PRICE) {
            this.delayedGetProvisionalPrice = window.setTimeout(() => {
                values = values.set(inputName, value);
                getProvisionalPrice(prepareDataForApi(values));
            }, 1000);
        }
    }

    handleConfirmationClose() {
        this.props.hideModal();
    }

    handleConfirmDelete() {
        const {
            deleteReservation,
            editedReservation,
        } = this.props;

        if (Iterable.isIterable(editedReservation)) {
            deleteReservation(editedReservation.get(RESERVATION_PROPS.ID), this.deleteSuccessCallback);
        }
    }

    handleSaveGuests(newValue, formName) {
        const {
            putReservation,
            editedReservation,
        } = this.props;

        const reservation = {
            ...prepareDataForApi(editedReservation || Map()).toJS(),
            [RESERVATION_PROPS.GUEST_LIST]: newValue.map(value => prepareReservationGuests(value)).toJS(),
        };

        putReservation(
            editedReservation.get(RESERVATION_PROPS.ID),
            reservation,
            formName,
            this.saveSuccessCallback
        );
    }

    handleSaveClick(formValues, formName) {
        const {
            postReservation,
            putReservation,
            editedReservation,
        } = this.props;

        const isExistingReservation = Iterable.isIterable(editedReservation)
            && !!(editedReservation.get(RESERVATION_PROPS.ID));

        const reservation = {
            ...prepareDataForApi(editedReservation || Map()).toJS(),
            ...prepareDataForApi(formValues).toJS(),
        };

        if (isExistingReservation) {
            putReservation(
                editedReservation.get(RESERVATION_PROPS.ID),
                reservation,
                formName,
                this.saveSuccessCallback
            );

        } else {
            postReservation(reservation, formName, this.createSuccessCallback);
        }
    }

    handleCloseModal() {
        const {
            hideModal,
            closeEditReservationModal,
            editedReservation,
            onClose,
            deleteNewReservationId,
            newReservationId,
        } = this.props;

        if (newReservationId) {
            deleteNewReservationId();
            closeEditReservationModal(newReservationId);

        } else if (Iterable.isIterable(editedReservation)) {
            closeEditReservationModal(editedReservation.get(RESERVATION_PROPS.ID));
        }

        hideModal();

        if (typeof onClose === 'function') {
            onClose();
        }
    }

    handleGuestClick(guestId, guest) {
        const {
            showModal,
            addEditedGuest,
        } = this.props;

        const modalId = reservationGuestModalId(guestId, generateKey());
        this.setState({
            [guestId]: modalId,
        })

        const updatedGuest = guest.merge({
            [GUEST_PROPS.ID]: guest.get(GUEST_LIST_PROPS.GUEST_ID),
        });

        addEditedGuest(fromJS(updatedGuest));
        showModal(modalId);
    }

    handleAddNewGuestClick() {
        const {
            showModal,
        } = this.props;

        showModal(EDIT_GUEST_MODAL_NAME);
    }

    deleteSuccessCallback() {
        const {
            addNotification,
            getReservations,
            getLatestReservations,
            onDelete,
            hideAllModals,
            closeAllEditedReservations,
            clearAllEditedGuests,
        } = this.props;

        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage {...localeMessages.reservationRemoved} />,
        });
        getReservations();
        getLatestReservations();
        this.handleConfirmationClose();
        this.handleCloseModal();
        hideAllModals();
        closeAllEditedReservations();
        clearAllEditedGuests();
        if (typeof onDelete === 'function') {
            onDelete();
        }
    }

    saveSuccessCallback() {
        const {
            getReservations,
            getLatestReservations,
            onSuccess,
        } = this.props;

        getReservations();
        getLatestReservations();
        if (typeof onSuccess === 'function') {
            onSuccess()
        }
    }

    createSuccessCallback(reservation) {
        const {
            saveNewReservationId,
        } = this.props;

        this.saveSuccessCallback();
        saveNewReservationId(reservation[RESERVATION_PROPS.ID]);
    }

    render() {
        const {
            user,
            editedReservation,
            rooms,
            calculatedPrice,
            roomUnits,
            showModal,
            intl,
            id,
            countryNamesMap,
            modalId,
            updateInputField,
        } = this.props;

        const hasEditedReservation = Iterable.isIterable(editedReservation);
        const isExistingReservation = hasEditedReservation && !!(editedReservation.get(RESERVATION_PROPS.ID));

        let paymentsComponent = null;
        let invoiceComponent = null;
        let isIcalReservation = false;
        if (isExistingReservation) {
            paymentsComponent = <Payments idReservation={id} />;
            invoiceComponent = <InvoicePage reservationId={id} />;
            isIcalReservation = editedReservation.get(RESERVATION_PROPS.IS_ICAL_EVENT) || false;
        }

        let initialValues = hasEditedReservation
            ? editedReservation
            : fromJS(defaultReservationData(rooms, roomUnits));

        const tabs = [
            {
                defaultActive: true,
                id: 'details',
                key: 'details',
                label: <FormattedMessage {...localeMessages.details} />,
                content: (
                    <Form
                        key="reservation-details"
                        blueprint={editReservationForm(
                            user,
                            rooms,
                            roomUnits,
                            initialValues,
                            isExistingReservation,
                            isIcalReservation,
                            calculatedPrice,
                            intl,
                            this.handleFormValueChange,
                            this.handleFormMount.bind(this, initialValues),
                            updateInputField,
                        )}
                        onSubmit={this.handleSaveClick}
                    />
                ),
                withDelete: isExistingReservation,
            },
            {
                id: 'guests',
                key: 'guests',
                label: <FormattedMessage {...localeMessages.guests} />,
                content: (
                    <Form
                        key="reservation-guests"
                        blueprint={reservationGuestsForm(
                            initialValues,
                            this.handleSaveGuests,
                            this.handleGuestClick,
                            this.handleAddNewGuestClick,
                            rooms,
                            countryNamesMap,
                            this.state,
                            modalId || id,
                        )}
                        onSubmit={() => {}}
                    />
                ),
                enabled: isExistingReservation,
            },
            {
                id: 'payments',
                key: 'payments',
                label: <FormattedMessage {...localeMessages.payments} />,
                content: paymentsComponent,
                enabled: isExistingReservation,
            },
            {
                id: 'notes',
                key: 'notes',
                label: <FormattedMessage {...localeMessages.notes} />,
                content: (
                    <Form
                        key="reservation-notes"
                        blueprint={reservationNotesForm(initialValues)}
                        onSubmit={this.handleSaveClick}
                    />
                ),
                enabled: isExistingReservation,
                withDelete: true,
            },
            {
                id: 'invoice',
                key: 'invoice',
                label: <FormattedMessage {...localeMessages.invoice} />,
                content: invoiceComponent,
                enabled: isExistingReservation,
            },
            // {
            //     id: 'logs',
            //     key: 'logs',
            //     label: 'Logs',
            //     content: 'Logs',
            //     enabled: isExistingReservation,
            // },
        ];

        return (
            <ModalWithForm
                id={modalId || id || BKG_EDIT_RESERVATION_MODAL_NAME}
                key={modalId || id || BKG_EDIT_RESERVATION_MODAL_NAME}
                headerText={<FormattedMessage {...localeMessages.reservation} />}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={formNames(id, modalId)}
            >
                <Tabs
                    groupName={modalId ? `${modalId}_edit-reservation` : 'edit-reservation'}
                    tabs={tabs}
                    activeForms={formNames(id, modalId)}
                    onDelete={() => showModal(BKG_DELETE_RESERVATION_CONFIRMATION_NAME)}
                />

                <Confirm
                    id={BKG_DELETE_RESERVATION_CONFIRMATION_NAME}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleConfirmDelete}
                >
                    <FormattedMessage {...localeMessages.deleteReservationConfirmation} />
                </Confirm>
            </ModalWithForm>
        );
    }
}

const mapStateToProps = (store, { id, newReservationId }) => {
    const reservationsReducer = store.reservationsReducer;
    const roomsReducer = store.roomsReducer;
    const userReducer = store.userReducer;
    const localeReducer = store.localeReducer;

    const editedReservation = reservationsReducer.getIn(['editedReservations', id || newReservationId, 'editedReservation']);

    return {
        editedReservation,
        user: userReducer.get('user'),
        calculatedPrice: reservationsReducer.get('calculatedPrice') || Map(),
        rooms: roomsReducer.get('rooms', List()),
        roomUnits: getRoomUnits(roomsReducer.get('units', List())),
        countryNamesMap: localeReducer.get('countryNamesMap') || Map(),
    };
};

const mapDispatchToProps = {
    getReservations,
    postReservation,
    putReservation,
    hideModal,
    showModal,
    closeEditReservationModal,
    getProvisionalPrice,
    getLatestReservations,
    addEditedGuest,
    deleteReservation,
    addNotification,
    hideAllModals,
    closeAllEditedReservations,
    clearAllEditedGuests,
    saveNewReservationId,
    deleteNewReservationId,
    updateInputField,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditReservation))
