import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import {List, Map} from 'immutable';
import Message, { getMessages } from 'Messages';
import Dropdown from "eksenia-lib/src/Dropdown";
import {
    getLocale,
} from 'locale';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import {FormattedMessage} from "react-intl";
import localeMessages from './messages';
import {composeClassName} from "../utils";
import Icon from "eksenia-lib/src/Icon";
import Button from "eksenia-lib/src/Button";
import {
    BKG_EDIT_RESERVATION_MODAL_NAME,
    EditReservation
} from "../Reservations";
import SettingsMenu from "./SettingsMenu";
import {MESSAGE_PROPS, MESSAGE_RESERVATION_PROPS, MESSAGE_ROOM_PROPS} from "../Messages/constants";

export class Header extends Component {

    componentDidMount() {
        const {
            user,
            getMessages,
        } = this.props;

        if (user && user.size) {
            getMessages();

            // load messages every 5 minutes until we implement websockets
            this.messagesTimeInterval = window.setInterval(getMessages, 300000);
        }
    }

    componentWillUnmount() {
        if (this.messagesTimeInterval) {
            window.clearInterval(this.messagesTimeInterval);
        }
    }

    handleChooseLanguage(language) {
        const {
            getLocale,
            setLanguage,
        } = this.props;

        setLanguage(language);
        getLocale(language);
    }

    getLocationMessageName() {
        const {
            location,
        } = this.props;
        let messageName = location.pathname.replace('/', '');
        if (messageName === '') {
            messageName = 'home';
        }

        return <FormattedMessage {...localeMessages[messageName]} />
    }

    notificationsItems() {
        const {
            messages,
        } = this.props;

        if (messages.size === 0) {
            return [
                {
                    id: 'noNotifications',
                    onClick: null,
                    content: () => (
                        <div className="no-notifications">
                            <FormattedMessage {...localeMessages.noNotifications} />
                        </div>
                    )
                }
            ]

        } else {
            return messages.map(message => {
                const roomID = message.getIn([MESSAGE_PROPS.ROOM, MESSAGE_ROOM_PROPS.ID]);
                const reservationID = message.getIn([MESSAGE_PROPS.RESERVATION, MESSAGE_RESERVATION_PROPS.ID]);
                const newReservationID = message.getIn([MESSAGE_PROPS.OVERBOOKING, MESSAGE_RESERVATION_PROPS.ID]);
                const key = `${roomID}_${reservationID}_${newReservationID}`;

                return {
                    id: key,
                    onClick: null,
                    content: () => <Message message={message} key={key} />,
                }
            });
        }
    }

    renderMessageCount() {
        const {
            messages,
        } = this.props;

        if (!messages.size) {
            return null;
        }

        return (
            <span className="message-count">
                {messages.size}
            </span>
        )
    }

    render() {
        const {
            showModal,
            newReservationId,
        } = this.props;

        return (
            <header className="header">
                <div className="breadcrumbs">
                    <span>
                        {/*{`${currentProperty.get(PROPERTY_PROPS.NAME)} > `}*/}
                        {this.getLocationMessageName()}
                    </span>
                </div>

                <div className="menus">
                    <Button
                        className="add-reservation"
                        onClick={showModal.bind(this, BKG_EDIT_RESERVATION_MODAL_NAME)}
                        formatters={[Button.TYPE.PRIMARY]}
                    >
                        <FormattedMessage {...localeMessages.addReservation} />
                    </Button>

                    <div className="header-menu">
                        <Dropdown
                            title={(() => (
                                <button
                                    className={composeClassName('header-menu-item')}
                                    aria-labelledby="header-notifications"
                                    type="button"
                                >
                                    <Icon
                                        name="headerBell"
                                        type="header-menu"
                                    />
                                    <div role="tooltip" id="header-notifications">
                                        <FormattedMessage {...localeMessages.notifications} />
                                    </div>
                                    {this.renderMessageCount()}
                                </button>
                            ))}
                            items={this.notificationsItems()}
                        />

                        {/*
                        <button
                            className={composeClassName('header-menu-item')}
                            aria-labelledby="header-search"
                            type="button"
                        >
                            <Icon
                                name="headerSearch"
                                type="header-menu"
                            />
                            <div role="tooltip" id="header-search">
                                <FormattedMessage {...localeMessages.search} />
                            </div>
                        </button>

                        <button
                            className={composeClassName('header-menu-item')}
                            aria-labelledby="header-help"
                            type="button"
                        >
                            <Icon
                                name="headerHelp"
                                type="header-menu"
                            />
                            <div role="tooltip" id="header-help">
                                <FormattedMessage {...localeMessages.help} />
                            </div>
                        </button>
                        */}

                        <SettingsMenu />
                    </div>
                </div>

                <EditReservation newReservationId={newReservationId} />
            </header>
        );        
    }
}

const mapStateToProps = store => {
    const userReducer = store.userReducer;
    const messagesReducer = store.messagesReducer;
    const propertiesReducer = store.propertiesReducer;
    const reservationsReducer = store.reservationsReducer;

    return {
        user: userReducer.get('user'),
        messages: messagesReducer.get('messages') || List(),
        currentProperty: propertiesReducer.get('currentProperty') || Map(),
        loadingProperties: propertiesReducer.get('loadingProperties'),
        newReservationId: reservationsReducer.get('newReservationId'),
    }
};

const mapDispatchToProps = {
    getMessages,
    getLocale,
    showModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
