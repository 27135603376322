import { Map } from 'immutable';

import { reduceWithDefault } from 'utils';

import {
    TABS_SET_ACTIVE_TAB,
    TABS_REMOVE_TAB_GROUP,
    SET_PENDING_TAB,
} from './constants'

const initialState = Map();

const reducers = {
    [TABS_SET_ACTIVE_TAB]: (state, { groupName, tabId }) =>
        state.setIn([groupName, 'activeTab'], tabId),

    [TABS_REMOVE_TAB_GROUP]: (state, { groupName }) =>
        state.delete(groupName),

    [SET_PENDING_TAB]: (state, { groupName, tabId }) =>
        state.setIn([groupName, 'pendingTab'], tabId),
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
