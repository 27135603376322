import React, { Component } from 'react';

import {
    inputTypes,
} from '../Form';
import InputLabel from '../InputLabel'
import Button from '../Button'
import ValidationError from '../ValidationError'
import { composeClassName } from '../utils';

export default class InputList extends Component {

    constructor() {
        super(...arguments);

        this.handleAddInput = this.handleAddInput.bind(this);
    }

    handleAddInput() {
        const {
            value,
            createEmptyInputValue,
        } = this.props;

        const newValue = [...value];
        newValue.push(createEmptyInputValue());

        this.updateValue(newValue);
    }

    handleDeleteInput(id) {
        const {
            value,
            name,
            inputs,
        } = this.props;

        const {
            name: getFieldName,
        } = inputs;

        const newValue = value.filter((field, i) =>
            id !== getFieldName(name, i, field)
        );

        this.updateValue(newValue);
    }

    handleChange(id, value) {
        const {
            value: oldValue,
        } = this.props;

        const newValue = oldValue.map(item => 
            item.id === id
                ? { id, value }
                : item
        );

        this.updateValue(newValue);
    }

    updateValue(newValue) {
        const {
            onChangeFromForm,
            onChange,
            name,
        } = this.props;

        if (typeof onChangeFromForm === 'function') {
            onChangeFromForm(name, newValue);
        }

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    render() {
        const {
            label,
            defaultValue,
            value,
            inputs,
            name,
            addInputText,
            addInputName,
            deleteInputText,
            deleteInputName,
            formatters = [],
            error,
        } = this.props;

        if (!Array.isArray(value)) {
            return false
        }

        const {
            type,
            name: getFieldName,
            label: getFieldLabel,
            ...inputProps
        } = inputs;

        const Field = inputTypes[type];

        return (
            <div
                className={composeClassName('input-list-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}

                <div
                    className={composeClassName('input-list', ...formatters)}
                >
                    {(value || defaultValue).map((item, i) => {
                        const itemId = getFieldName(name, i, item);
                        const itemLabel = getFieldLabel
                            ? getFieldLabel(name, i)
                            : undefined;

                        return (
                            <div
                                key={itemId}
                                className={composeClassName('input-list-item', ...formatters)}
                            >
                                <Field
                                    onChange={this.handleChange.bind(this, itemId)}
                                    value={item.value}
                                    label={itemLabel}
                                    name={item.name}
                                    inputId={itemId}
                                    deleteInput={this.handleDeleteInput.bind(this, itemId)}
                                    {...inputProps}
                                />
                                <Button
                                    onClick={this.handleDeleteInput.bind(this, itemId)}
                                    formatters={[Button.TYPE.LINK]}
                                    name={deleteInputName}
                                >
                                    {deleteInputText}
                                </Button>
                            </div>
                    )})}
                </div>

                <div
                    className={composeClassName('input-list-add-button', ...formatters)}
                >
                    <Button
                        onClick={this.handleAddInput}
                        name={addInputName}
                    >
                        {addInputText}
                    </Button>
                </div>

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
