import {endpoints} from 'api';
import {
    GET_LOCALE_REQUEST,
    GET_LOCALE_SUCCESS,
    GET_LOCALE_ERROR,
    GET_COUNTY_NAMES_REQUEST,
    GET_COUNTY_NAMES_SUCCESS,
    GET_COUNTY_NAMES_ERROR,
} from './constants';

export const getLocale = language => ({
    type: 'API_CALL',
    endpoint: endpoints.getLocale,
    actions: {
        request: GET_LOCALE_REQUEST,
        success: GET_LOCALE_SUCCESS,
        error: GET_LOCALE_ERROR,
    },
    params: {
        file: `${language}.json`,
    }
});

export const getCountryNames = language => ({
    type: 'API_CALL',
    endpoint: endpoints.getCountryNamesLocalisation,
    actions: {
        request: GET_COUNTY_NAMES_REQUEST,
        success: GET_COUNTY_NAMES_SUCCESS,
        error: GET_COUNTY_NAMES_ERROR,
    },
    params: {
        language,
    }
});
