import { endpoints } from 'api';

import {
    PMT_GET_PAYMENTS_REQUEST,
    PMT_GET_PAYMENTS_SUCCESS,
    PMT_GET_PAYMENTS_ERROR,
    PMT_POST_PAYMENT_REQUEST,
    PMT_POST_PAYMENT_SUCCESS,
    PMT_POST_PAYMENT_ERROR,
    PMT_UPDATE_PAYMENT_REQUEST,
    PMT_UPDATE_PAYMENT_SUCCESS,
    PMT_UPDATE_PAYMENT_ERROR,
    PMT_OPEN_EDIT_PAYMENT_MODAL,
    PMT_CLOSE_EDIT_PAYMENT_MODAL,
    PMT_DELETE_PAYMENT_REQUEST,
    PMT_DELETE_PAYMENT_SUCCESS,
    PMT_DELETE_PAYMENT_ERROR,
} from './constants'

export const getPayments = idReservation => ({
    type: 'API_CALL',
    endpoint: endpoints.getPayments,
    actions: {
        request: PMT_GET_PAYMENTS_REQUEST,
        success: PMT_GET_PAYMENTS_SUCCESS,
        error: PMT_GET_PAYMENTS_ERROR,
    },
    params: {
        idReservation,
    },
});

export const postPayment = (payment, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postPayment,
    actions: {
        request: PMT_POST_PAYMENT_REQUEST,
        success: PMT_POST_PAYMENT_SUCCESS,
        error: PMT_POST_PAYMENT_ERROR,
        successCallback,
    },
    body: payment,
    formName,
});

export const updatePayment = (idPayment, payment, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putPayment,
    actions: {
        request: PMT_UPDATE_PAYMENT_REQUEST,
        success: PMT_UPDATE_PAYMENT_SUCCESS,
        error: PMT_UPDATE_PAYMENT_ERROR,
        successCallback,
    },
    body: payment,
    params: {
        idPayment,
    },
    formName,
});

export const deletePayment = (idPayment) => ({
    type: 'API_CALL',
    endpoint: endpoints.deletePayment,
    actions: {
        request: PMT_DELETE_PAYMENT_REQUEST,
        success: PMT_DELETE_PAYMENT_SUCCESS,
        error: PMT_DELETE_PAYMENT_ERROR,
    },
    params: {
        idPayment,
    },
});

export const openEditPaymentModal = editedPayment => ({
    type: PMT_OPEN_EDIT_PAYMENT_MODAL,
    editedPayment,
});

export const closeEditPaymentModal = () => ({
    type: PMT_CLOSE_EDIT_PAYMENT_MODAL,
});
