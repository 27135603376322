import React, {Component} from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Iterable } from 'immutable';
import {FormattedMessage, injectIntl,} from 'react-intl';

import {allowNewPasswordRequest, requestPasswordReset} from 'User';
import Input from 'eksenia-lib/src/Input';
import Button from 'eksenia-lib/src/Button';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import localeMessages from './messages';
import ValidationError from "eksenia-lib/src/ValidationError";
import {supportEmail} from "./SupportEmail";
import Icon from "eksenia-lib/src/Icon";
import {LANGUAGE_NAME_TEXT, LANGUAGE_NAMES} from "../locale";

class RequestPasswordReset extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            email: '',
            emailEmptyError: false,
            requestFailed: false,
            submitDisabled: false,
            timeToReset: 15,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.successCallback = this.successCallback.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            requestPasswordResetInProgress,
            requestPasswordResetSuccess,
        } = this.props;

        if (prevProps.requestPasswordResetInProgress && !requestPasswordResetInProgress) {
            this.setState({
                requestFailed: !requestPasswordResetSuccess,
                submitDisabled: requestPasswordResetSuccess,
                redirectToDashboard: requestPasswordResetSuccess,
            });
        }
    }

    handleEmailChange(value) {
        this.setState({
            email: value,
            emailEmptyError: false,
            requestFailed: false,
        });
    };

    handleSubmit(evt) {
        const {
            requestPasswordReset,
        } = this.props;
        const {
            email,
        } = this.state;

        evt.preventDefault();

        if (email.length === 0) {
            this.setState({
                emailEmptyError: true,
            });
        } else {
            this.setState({
                submitDisabled: true,
            });

            requestPasswordReset(email, this.successCallback);
        }
    }

    handleChangeLanguage(event) {
        localStorage.setItem('language', event.currentTarget.value);
        window.location.reload();
    }

    successCallback() {
        const {
            timeToReset,
        } = this.state;

        setTimeout(() => {
            this.setState({ timeToReset: timeToReset -1 })
            if (timeToReset > 1) {
                this.successCallback()
            }
        }, 1000)
    }

    renderSuccess() {
        const {
            intl,
            allowNewPasswordRequest,
        } = this.props;

        const {
            timeToReset,
        } = this.state;

        return (
            <div className="login request-password">
                <div className="login-content">
                    <div className="login-form-header">
                        <img
                            src="../eksenia-logo.svg"
                            alt={intl.formatMessage(localeMessages.ekseniaLogo)}
                        />
                    </div>

                    <div className="success-container">
                        <FormattedMessage {...localeMessages.requestSuccess} tagName="div" />
                        <FormattedMessage
                            {...localeMessages.forHelp}
                            values={{
                                email: supportEmail(),
                            }}
                            tagName="div"
                        />
                        {timeToReset > 0
                            ? <FormattedMessage
                                {...localeMessages.tryAgainTimer}
                                values={{
                                    time: timeToReset,
                                }}
                                tagName="div"
                            />
                            : <FormattedMessage {...localeMessages.tryAgainNow} tagName="div" />
                        }
                        <Button
                            disabled={timeToReset > 0}
                            formatters={[Button.TYPE.SECONDARY]}
                            onClick={ () => {
                                this.setState({
                                    timeToReset: 15,
                                    submitDisabled: false,
                                });
                                allowNewPasswordRequest();
                            }}
                        >
                            <FormattedMessage {...localeMessages.tryAgain} />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            user,
            requestPasswordResetSuccess,
            intl,
        } = this.props;

        const {
            email,
            submitDisabled,
            emailEmptyError,
            requestFailed,
        } = this.state;

        if (Iterable.isIterable(user) && user.toJS().Email) {
            return (
                <Redirect to="/" />
            );
        }

        if (requestPasswordResetSuccess) {
            return this.renderSuccess();
        }

        let error;
        if (emailEmptyError) {
            error = <FormattedMessage {...localeMessages.emailCannotBeEmpty} tagName="div" />
        }
        if (requestFailed) {
            // TODO wrong credentials vs internal error
            error = <FormattedMessage
                {...localeMessages.requestFailed}
                tagName="div"
                values={{
                    email: supportEmail()
                }}
            />
        }

        return (
            <div className="login request-password">
                <form className="login-content" onSubmit={this.handleSubmit}>
                    <div className="login-form-header">
                        <img
                            src="eksenia-logo.svg"
                            alt={intl.formatMessage(localeMessages.ekseniaLogo)}
                        />
                    </div>
                    <Input
                        label={<FormattedMessage {...localeMessages.email} />}
                        value={email}
                        onChange={this.handleEmailChange}
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />

                    <Button
                        type="submit"
                        disabled={submitDisabled}
                        formatters={[Button.TYPE.PRIMARY]}
                    >
                        <FormattedMessage {...localeMessages.resetPassword} />
                    </Button>

                    <ValidationError
                        error={error}
                        formatters={[INPUT_FORMATTERS.VERTICAL, ValidationError.FORMATTER.BASE_COLOR]}
                        withIcon
                    />
                </form>

                <div className="login-extra-controls">
                    <div className="login-language">
                        <Icon name="globe" type="globe" />
                        <select
                            className="language-select"
                            onChange={this.handleChangeLanguage}
                            defaultValue={intl.locale}
                        >
                            <option
                                key={LANGUAGE_NAMES.ENG}
                                value={LANGUAGE_NAMES.ENG}
                            >
                                {LANGUAGE_NAME_TEXT.ENG}
                            </option>
                            <option
                                key={LANGUAGE_NAMES.SER}
                                value={LANGUAGE_NAMES.SER}
                            >
                                {LANGUAGE_NAME_TEXT.SER}
                            </option>
                            <option
                                key={LANGUAGE_NAMES.MNE}
                                value={LANGUAGE_NAMES.MNE}
                            >
                                {LANGUAGE_NAME_TEXT.MNE}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = store => {
    const userReducer = store.userReducer;
    return {
        requestPasswordResetSuccess: userReducer.get('requestPasswordResetSuccess'),
        requestPasswordResetInProgress: userReducer.get('requestPasswordResetInProgress'),
        user: userReducer.get('user'),
    }
};

const mapDispatchToProps = {
    requestPasswordReset,
    allowNewPasswordRequest,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset))
