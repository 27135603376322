import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from 'rc-calendar';
import RCDatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import {
    injectIntl,
} from 'react-intl';
import en from 'rc-calendar/lib/locale/en_GB';
import sr from 'rc-calendar/lib/locale/sr_RS';
import moment from "moment";

import {
    handleDateChange,
} from '../Form'
import Input from '../Input'
import {USR_PROPS} from "../User";
import {getLocaleString} from "../utils";
import Icon from "../Icon";

export class DatePicker extends Component {

    prepareDisplayValue(value) {
        return value
            ? this.props.intl.formatDate(value.toDate())
            : '';
    }

    render() {
        const {
            label,
            onChange,
            formatters = [],
            error,
            language,
            help,
            disabled,
            submitting,
            ...props
        } = this.props;

        const locale = language === 'sr' || language === 'cnr'
            ? sr
            : en;

        if (moment.isMoment(props.value)) {
            props.value.locale(getLocaleString(language));
        }

        const calendar = (
            <Calendar
                format={locale === sr ? 'DD.MM.YYYY.' : 'D/M/YYYY'}
                locale={locale}
            />
        );

        let horizontalOffset = 0;
        if (formatters.indexOf('horizontal') !== -1) {
            horizontalOffset = help ? -10 : 6;

        } else if (formatters.indexOf('vertical') !== -1) {
            horizontalOffset = -34;
        }

        return (
            <RCDatePicker
                onChange={handleDateChange.bind(this, this)}
                calendar={ calendar }
                placement={'right'}
                align={{
                    points: ['tr', 'br'],
                    offset: [`${horizontalOffset}px`, '-36px'],
                    targetOffset: ['0%','0%'],
                    overflow: { adjustX: true, adjustY: true },
                }}
                { ...props }
            >
                {({value}) => (
                    <Input
                        value={ this.prepareDisplayValue(value) }
                        formatters={['date-picker', ...formatters]}
                        label={label}
                        error={error}
                        help={help}
                        icon={
                            <Icon
                                name={"inputArrowDown"}
                                type={"input-dropdown-arrow collapsed"}
                            />
                        }
                        disabled={disabled || submitting}
                    />
                )}
            </RCDatePicker>
        )
    }
}

const mapStateToProps = (store) => {
    const {
        userReducer,
    } = store;

    const user = userReducer.get('user');
    return {
        language: user && user.get(USR_PROPS.LANGUAGE),
    }
};

export default injectIntl(connect(mapStateToProps, undefined)(DatePicker))
