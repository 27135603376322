import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'confirmation.cancel',
        defaultMessage: 'Go back',
    },
    delete: {
        id: 'confirmation.delete',
        defaultMessage: 'Delete',
    },
})
