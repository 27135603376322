export const GET_MULTI_RESERVATION_PRICE_BREAKDOWN_SUCCESS = 'GET_MULTI_RESERVATION_PRICE_BREAKDOWN_SUCCESS';

export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';

export const BKG_POST_RESERVATIONS_REQUEST = 'BKG_POST_RESERVATIONS_REQUEST';
export const BKG_POST_RESERVATIONS_SUCCESS = 'BKG_POST_RESERVATIONS_SUCCESS';
export const BKG_POST_RESERVATIONS_ERROR = 'BKG_POST_RESERVATIONS_ERROR';

export const BKG_UPDATE_RESERVATIONS_REQUEST = 'BKG_UPDATE_RESERVATIONS_REQUEST';
export const BKG_UPDATE_RESERVATIONS_SUCCESS = 'BKG_UPDATE_RESERVATIONS_SUCCESS';
export const BKG_UPDATE_RESERVATIONS_ERROR = 'BKG_UPDATE_RESERVATIONS_ERROR';

export const BKG_DELETE_RESERVATIONS_REQUEST = 'BKG_DELETE_RESERVATIONS_REQUEST';
export const BKG_DELETE_RESERVATIONS_SUCCESS = 'BKG_DELETE_RESERVATIONS_SUCCESS';
export const BKG_DELETE_RESERVATIONS_ERROR = 'BKG_DELETE_RESERVATIONS_ERROR';

export const GET_PROVISIONAL_PRICE_REQUEST = 'GET_PROVISIONAL_PRICE_REQUEST';
export const GET_PROVISIONAL_PRICE_SUCCESS = 'GET_PROVISIONAL_PRICE_SUCCESS';
export const GET_PROVISIONAL_PRICE_ERROR = 'GET_PROVISIONAL_PRICE_ERROR';

export const GET_LATEST_RESERVATIONS_SUCCESS = 'GET_LATEST_RESERVATIONS_SUCCESS';

export const PATCH_RESERVATIONS_SUCCESS = 'PATCH_RESERVATIONS_SUCCESS';

export const ADD_RESERVATION_GUEST_SUCCESS = 'ADD_RESERVATION_GUEST_SUCCESS';

export const BKG_OPEN_EDIT_RESERVATION_MODAL = 'BKG_OPEN_EDIT_RESERVATION_MODAL';
export const BKG_CLOSE_EDIT_RESERVATION_MODAL = 'BKG_CLOSE_EDIT_RESERVATION_MODAL';
export const BKG_CLEAR_ALL_EDITED_RESERVATIONS = 'BKG_CLEAR_ALL_EDITED_RESERVATIONS';
export const SAVE_NEW_RESERVATION_ID = 'SAVE_NEW_RESERVATION_ID';
export const DELETE_NEW_RESERVATION_ID = 'DELETE_NEW_RESERVATION_ID';

export const SET_RESERVATION_LIST_EDITED_RESERVATION = 'SET_RESERVATION_LIST_EDITED_RESERVATION';

export const BKG_EDIT_RESERVATION_FORM_NAME = 'BKG_EDIT_RESERVATION_FORM_NAME';
export const BKG_FILTER_RESERVATIONS_FORM_NAME = 'BKG_FILTER_RESERVATIONS_FORM_NAME';
export const RESERVATION_NOTES_FORM_NAME = 'RESERVATION_NOTES_FORM_NAME';
export const RESERVATION_GUESTS_FORM_NAME = 'RESERVATION_GUESTS_FORM_NAME';
export const BKG_DELETE_RESERVATION_CONFIRMATION_NAME = 'BKG_DELETE_RESERVATION_CONFIRMATION_NAME';
export const BKG_EDIT_RESERVATION_MODAL_NAME = 'BKG_EDIT_RESERVATION_MODAL_NAME';

export const BKG_RESERVATION_FILTERING_ALL_VALUE = 'ALL';

export const BKG_RESERVATION_FILTERING_PROPS = {
    PERIOD: 'period',
    ROOMS: 'rooms',
};

export const RESERVATION_PROPS = {
    ID: 'ID',
    ROOM_ID: 'RoomID',
    CHECK_IN: 'CheckInTime',
    CHECK_OUT: 'CheckOutTime',
    NAME: 'Name',
    STATUS: 'Status',
    RESERVED_AT: 'ReservedAt',
    ROOM_NAME: 'roomName',
    ADULTS: 'Adults',
    CHILDREN: 'Children',
    INFANTS: 'Infants',
    PRICE: 'Price',
    PRICE_WITHOUT_INCLUSIVE_CHARGES: 'PriceWithoutInclusiveCharges',
    CALCULATED_PRICE: 'CalculatedPrice',
    PRICE_WITH_All_CHARGES: 'PriceWithAllCharges',
    PRICE_BREAKDOWN: 'priceBreakdown',
    GUEST_ID: 'GuestID',
    GUEST: 'Guest',
    NOTES: 'Notes',
    CHANNEL: 'Channel',
    CHECKED_IN: 'CheckedIn',
    CHECKED_OUT: 'CheckedOut',
    IS_ICAL_EVENT: 'IsICalEvent',
    GUEST_LIST: 'GuestList',
    UNIT_ID: 'UnitID',
    UNIT_NAME: 'unitName',
    MULTI_RESERVATION_ID: 'MultiReservationID',
    CHARGE_LIST: 'ChargeList',
};

export const GUEST_LIST_PROPS = {
    GUEST_RANK: 'GuestRank',
    ID: 'ID',
    GUEST_ID: 'GuestID',
    RESERVATION_ID: 'ReservationID',
    ENTITY_ID: 'entityID',
    DELETED: 'Deleted',
};

export const RESERVATION_CHARGE_PROPS = {
    IS_INCLUSIVE: 'IsInclusive',
    TOTAL_PRICE: 'TotalPrice',
    NAME_FROM_OTA: 'NameFromOTA',
    TYPE: 'Type',
    ID: 'id',
}

export const PRICE_BREAKDOWN_PROPS = {
    TOTAL_ROOM_PRICE: 'TotalRoomPrice',
    CANCELLATION_FEE: 'CancellationFee',
    TOTAL_PRICE: 'TotalPrice',
    TOTAL_ROOM_PRICE_WITH_ALL_CHARGES: 'TotalRoomPriceWithAllCharges',
    TOTAL_ROOM_PRICE_WITHOUT_INCLUSIVE_CHARGES: 'TotalRoomPriceWithoutInclusiveCharges',
}

export const BKG_RESERVATION_STATUS_VALUES = {
    NOT_CONFIRMED: 'NOT_CONFIRMED',
    CONFIRMED: 'CONFIRMED',
    CANCELED: 'CANCELED',
};

export const RESERVATION_PRICE_PROPS = {
    ROOM_ID: 'RoomID',
    PRICE_WITHOUT_INCLUSIVE_CHARGES: 'PriceWithoutInclusiveCharges',
    PRICE: 'Price',
    PRICE_WITH_ALL_CHARGES: 'PriceWithAllCharges',
    CHARGE_LIST: 'ChargeList',
}

export const inputsInfluencingPrice = [
    RESERVATION_PROPS.CHECK_IN,
    RESERVATION_PROPS.CHECK_OUT,
    RESERVATION_PROPS.ROOM_ID,
    RESERVATION_PROPS.ADULTS,
];
