import React, {Component} from "react";
import Icon from "../Icon";
import ReactDOM from "react-dom";

export default class Tooltip extends Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.closeTooltip = this.closeTooltip.bind(this);
        this.tooltipRef = React.createRef();
        this.containerRef = React.createRef();
        this.state = {
            open: false,
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.closeTooltip);
        // this is here so that tooltips in modal get closed when clicking outside of them
        const modalContainer = this.containerRef.current.closest('.modal-container');
        if (modalContainer) {
            modalContainer.addEventListener('click', this.closeTooltip);
        }
        window.addEventListener('touchstart', this.closeTooltip);
        window.addEventListener('wheel', this.closeTooltip);
        window.addEventListener('touchmove', this.closeTooltip);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.closeTooltip);
        const modalContainer = this.containerRef.current.closest('.modal-container');
        if (modalContainer) {
            this.containerRef.current.closest('.modal-container').addEventListener('click', this.closeTooltip);
        }
        window.removeEventListener('touchstart', this.closeTooltip);
        window.removeEventListener('wheel', this.closeTooltip);
        window.removeEventListener('touchmove', this.closeTooltip);
    }

    handleClick(event) {
        event.stopPropagation();
        this.setState({
            open: !this.state.open,
        });
    }

    closeTooltip(event) {
        if (this.state.open
            && (event && this.tooltipRef.current
                && event.target !== this.tooltipRef.current
                && !this.tooltipRef.current.contains(event.target))
        ) {
            this.setState({
                open: false,
            })
        }
    }

    renderTooltip() {
        const {
            help,
        } = this.props;

        const containerPosition = this.containerRef.current.getBoundingClientRect();
        const left = containerPosition.x + 30;
        const top = containerPosition.y - 8;

        return (
            ReactDOM.createPortal((
                <div
                    className="tooltip"
                    ref={this.tooltipRef}
                    style={{
                        left: `${left}px`,
                        top: `${top}px`,
                    }}
                >
                    {help}
                </div>
            ), document.getElementById('tooltips'))
        )
    }

    render() {
        const {
            open,
        } = this.state;
        const {
            small,
        } = this.props;

        return (
            <div className="tooltip-container" ref={this.containerRef}>
                <Icon
                    type={small ? 'help-small' : 'help'}
                    name="help"
                    onClick={this.handleClick}
                />

                {open && this.renderTooltip()}
            </div>
        )
    }
}