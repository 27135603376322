import React, {Component} from "react";
import { connect } from 'react-redux';
import {List, Map} from 'immutable';
import {
    injectIntl,
} from 'react-intl';

import {
    PROPERTY_PROPS,
} from './constants';
import propertySettingsForm from "./propertySettingsForm";
import {
    patchProperty,
    getTimezones,
} from './actions';

import {
    Form,
} from 'eksenia-lib/src/Form';
import Page from 'eksenia-lib/src/Page';
import {
    countryNamesToOptions,
    currenciesToOptions,
    timezonesToOptions,
} from "../locale/utils";
import {mapPropertyForPatch} from "./utils";

class PropertyPage extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveSettings = this.handleSaveSettings.bind(this);
    }

    componentDidMount() {
        this.props.getTimezones()
    }

    handleSaveSettings(formValues, formName) {
        const {
            patchProperty,
            currentProperty,
        } = this.props;

        patchProperty(
            currentProperty.get(PROPERTY_PROPS.ID),
            mapPropertyForPatch(formValues),
            formName
        )
    }

    render() {
        const {
            currentProperty,
            intl,
            countryNamesList,
            timezones,
        } = this.props;

        const countryOptions = countryNamesToOptions(countryNamesList);
        const currencyOptions = currenciesToOptions(intl);
        const timezoneOptions = timezonesToOptions(timezones.toJS());

        return (
            <Page>
                <Form
                    key="property-settings"
                    blueprint={propertySettingsForm(
                        currentProperty,
                        intl,
                        countryOptions,
                        currencyOptions,
                        timezoneOptions
                    )}
                    onSubmit={this.handleSaveSettings}
                />
            </Page>
        );
    }
}

const mapStateToProps = store => {
    const propertiesReducer = store.propertiesReducer;
    const localeReducer = store.localeReducer;

    return {
        currentProperty: propertiesReducer.get('currentProperty') || Map(),
        timezones: propertiesReducer.get('timezones') || List(),
        countryNamesList: localeReducer.get('countryNamesList') || List(),
    }
};

const mapDispatchToProps = {
    patchProperty,
    getTimezones,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PropertyPage))
