import Input from '../Input';
import InputList from '../InputList';
import NumberInput from '../NumberInput';
import Select from '../Select';
import RangeDatePicker from '../RangeDatePicker';
import DatePicker from '../DatePicker';
import InputGroup from '../InputGroup';
import CustomFormElement from '../CustomFormElement';
import Checkbox from '../Checkbox';
import MoneyInput from '../MoneyInput';
import InputMatrix from '../InputMatrix';
import TextArea from '../TextArea';
import FileUpload from '../File';
import Radio from '../Radio';
import SearchInput from "../SearchInput";

export const inputTypes = {
    input: Input,
    inputList: InputList,
    select: Select,
    dateRange: RangeDatePicker,
    date: DatePicker,
    number: NumberInput,
    inputGroup: InputGroup,
    custom: CustomFormElement,
    checkbox: Checkbox,
    money: MoneyInput,
    inputMatrix: InputMatrix,
    textArea: TextArea,
    file: FileUpload,
    radio: Radio,
    searchInput: SearchInput,
};

export const numberInputs = [
    'number',
];

export const FORM_ADD_FORM = 'FORM_ADD_FORM';
export const FORM_REMOVE_FORM = 'FORM_REMOVE_FORM';
export const FORM_UPDATE_FIELD = 'FORM_UPDATE_FIELD';
export const FORM_SET_INITIAL_VALUES_TO_CURRENT = 'FORM_SET_INITIAL_VALUES_TO_CURRENT';
export const LEAVE_FORM = 'LEAVE_FORM';
export const FORM_SET_VALIDATION_ERROR = 'FORM_SET_VALIDATION_ERROR';
export const FORM_SET_SUBMITTING = 'FORM_SET_SUBMITTING';
export const FORM_SET_NOT_SUBMITTING = 'FORM_SET_NOT_SUBMITTING';

export const CANCEL_FORM_CONFIRMATION_NAME = 'CANCEL_FORM_CONFIRMATION_NAME';
