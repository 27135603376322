import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal, {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    Form,
    updateInputField,
} from 'eksenia-lib/src/Form';
import {
    DELETE_UNIT_CONFIRMATION_NAME,
    EDIT_UNIT_FORM_NAME, UNIT_PROPS,
} from "./constants";
import ModalWithForm from "eksenia-lib/src/ModalWithForm";
import Confirm from "eksenia-lib/src/Confirm";
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";
import editUnitForm from "./editUnitForm";
import {
    updateUnit,
    postUnit,
    deleteUnit,
} from "./actions";
import {fromJS} from "immutable";

export class ReservationGuest extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveUnit = this.handleSaveUnit.bind(this);
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.postSuccessCallback = this.postSuccessCallback.bind(this);
        this.updateSuccessCallback = this.updateSuccessCallback.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
    }

    handleCloseModal() {
       this.props.hideModal();
    }

    handleConfirmationClose() {
        this.props.hideModal();
    }

    handleConfirmDelete() {
        const {
            deleteUnit,
            unitData,
            roomId,
        } = this.props;

        deleteUnit(unitData.get(UNIT_PROPS.ID), roomId, this.deleteSuccessCallback);
    }

    handleSaveUnit(formData, unitFormName) {
        const {
            unitData,
            updateUnit,
            postUnit,
            inputValue,
            roomId,
        } = this.props;

        const unitId = unitData.get(UNIT_PROPS.ID);
        const isExistingUnit = !!unitId;

        if (isExistingUnit) {
            updateUnit(
                formData.toJS(),
                unitId,
                roomId,
                unitFormName,
                this.updateSuccessCallback
            );

        } else {
            postUnit(
                formData.merge({
                    [UNIT_PROPS.ROOM_ID]: roomId,
                    [UNIT_PROPS.RANK]: inputValue.size
                }).toJS(),
                roomId,
                unitFormName,
                this.postSuccessCallback
            );
        }
    }

    postSuccessCallback(data) {
        const {
            updateInputField,
            formName,
            inputName,
            inputValue,
            hideModal,
        } = this.props;

        const updatedInputValue = inputValue.push(fromJS(data));

        updateInputField(formName, inputName, {
            value: updatedInputValue,
        });

        hideModal();
    }

    updateSuccessCallback(data) {
        const {
            updateInputField,
            formName,
            inputName,
            inputValue,
            hideModal,
        } = this.props;

        const updatedUnit = fromJS(data);
        const updatedUnitId = updatedUnit.get(UNIT_PROPS.ID);

        const updatedInputValue = inputValue.map(unit => {
            if (unit.get(UNIT_PROPS.ID) === updatedUnitId) {
                return updatedUnit;
            } else {
                return unit;
            }
        });

        updateInputField(formName, inputName, {
            value: updatedInputValue,
        });

        hideModal();
    }

    deleteSuccessCallback(data) {
        const {
            updateInputField,
            formName,
            inputName,
            inputValue,
        } = this.props;

        const deletedUnitId = data[UNIT_PROPS.ID];

        const updatedInputValue = inputValue.filter(unit =>
            unit.get(UNIT_PROPS.ID) !== deletedUnitId
        );

        updateInputField(formName, inputName, {
            value: updatedInputValue,
        });
    }

    render() {
        const {
            inputId,
            unitData,
            showModal,
            inputValue,
        } = this.props;

        const isExistingUnit = unitData.get(UNIT_PROPS.ID);
        const headerText = isExistingUnit
            ? <FormattedMessage {...localeMessages.editUnit} values={{unitName: unitData.get(UNIT_PROPS.NAME)}} />
            : <FormattedMessage {...localeMessages.newUnit} />;
        const canDelete = unitData.get(UNIT_PROPS.CAN_DELETE);
        const disableDelete = !canDelete || inputValue.size === 1;
        const deleteDisabledTooltipMessage = disableDelete
            ? !canDelete
                ? <FormattedMessage {...localeMessages.cannotDeleteUnit} />
                : <FormattedMessage {...localeMessages.atLeastOneUnit} />
            : false;

        return (
            <ModalWithForm
                id={inputId}
                headerText={headerText}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={[EDIT_UNIT_FORM_NAME]}
                withDelete={isExistingUnit}
                disableDelete={disableDelete}
                deleteDisabledTooltip={deleteDisabledTooltipMessage}
                onDelete={() => {showModal(DELETE_UNIT_CONFIRMATION_NAME)}}
            >
                <Form
                    key="unit-details"
                    blueprint={editUnitForm(unitData)}
                    onSubmit={this.handleSaveUnit}
                />

                <Confirm
                    id={DELETE_UNIT_CONFIRMATION_NAME}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleConfirmDelete}
                >
                    <FormattedMessage {...localeMessages.deleteUnitConfirmation} />
                </Confirm>
            </ModalWithForm>
        );
    }
}

const mapStateToProps = (store, ownProps) => {
    const {
        formName,
        inputName,
    } = ownProps;
    const formReducer = store.formReducer;

    return {
        inputValue: formReducer.getIn([formName, 'inputFields', inputName, 'value'])
    };
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    updateInputField,
    updateUnit,
    postUnit,
    deleteUnit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationGuest);
