import { Map } from 'immutable';
import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    BATHROOM_AMENITIES_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: BATHROOM_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.PRIVATE_BATHROOM,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.PRIVATE_BATHROOM),
                    label: <FormattedMessage {...localeMessages.privateBathroom} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SHARED_BATHROOM,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SHARED_BATHROOM),
                    label: <FormattedMessage {...localeMessages.sharedBathroom} />,
                },
                {
                    type: 'checkbox',
                    integer: true,
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TOILET_PAPER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TOILET_PAPER),
                    label: <FormattedMessage {...localeMessages.toiletPaper} />,
                },

                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BATHTUB,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BATHTUB),
                    label: <FormattedMessage {...localeMessages.bathtub} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BIDET,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BIDET),
                    label: <FormattedMessage {...localeMessages.bidet} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SHOWER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SHOWER),
                    label: <FormattedMessage {...localeMessages.shower} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BATHROBE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BATHROBE),
                    label: <FormattedMessage {...localeMessages.bathrobe} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FREE_TOILETRIES,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FREE_TOILETRIES),
                    label: <FormattedMessage {...localeMessages.freeToiletries} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.HAIRDRYER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.HAIRDRYER),
                    label: <FormattedMessage {...localeMessages.hairdryer} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SAUNA,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SAUNA),
                    label: <FormattedMessage {...localeMessages.sauna} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SLIPPERS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SLIPPERS),
                    label: <FormattedMessage {...localeMessages.slippers} />,
                },
            ],
        },
    ],
})
