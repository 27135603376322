import React from 'react';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    GUEST_PROPS,
    GUEST_RESERVATIONS_FORM_NAME,
} from './constants';
import {
    RESERVATION_PROPS,
    messages as reservationMessages,
} from '../Reservations'
import {ReservationStatus} from "../Reservations/ReservationStatus";
import {FormattedDate} from "react-intl";
import Money from "eksenia-lib/src/Money";
import {getUnitName} from "../Rooms";
import GuestReservation from "./GuestReservation";

export default (
    reservations,
    handleReservationClick,
    rooms,
    units,
    reservationKeys,
    id,
) => ({
    formName: id ? `${GUEST_RESERVATIONS_FORM_NAME}_${id}` : GUEST_RESERVATIONS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.TABLE],
    noSubmitButton: true,
    fields: [
        {
            type: 'inputMatrix',
            name: GUEST_PROPS.RESERVATIONS,
            initialValue: reservations,
            tableView: true,
            headers: [
                {
                    id: RESERVATION_PROPS.STATUS,
                    text: reservationMessages.status,
                    width: '20%',
                },
                {
                    id: RESERVATION_PROPS.UNIT_NAME,
                    text: reservationMessages.unit,
                    width: '20%',
                },
                {
                    id: RESERVATION_PROPS.CHECK_IN,
                    text: reservationMessages.checkInDate,
                    width: '20%',
                },
                {
                    id: RESERVATION_PROPS.CHECK_OUT,
                    text: reservationMessages.checkOutDate,
                    width: '20%',
                },
                {
                    id: RESERVATION_PROPS.PRICE,
                    text: reservationMessages.totalPrice,
                    width: '20%',
                },
            ],
            inputs: {
                id: (listName, index, value) => value.get(RESERVATION_PROPS.ID),
                entityId: value => value.get(RESERVATION_PROPS.ID),
                onClick: handleReservationClick,
                edit: props => {
                    const {
                        inputId,
                    } = props;

                    const modalId = reservationKeys[inputId];

                    return (modalId
                        ? <GuestReservation
                            inputId={inputId}
                            formName={GUEST_RESERVATIONS_FORM_NAME}
                            inputName={GUEST_PROPS.RESERVATIONS}
                            modalId={modalId}
                        />
                        : false
                    );
                },
                fields: [
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.STATUS,
                        render: value =>
                            <ReservationStatus status={value} />
                    },
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.UNIT_ID,
                        render: value =>
                            getUnitName(rooms, units, value)

                    },
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.CHECK_IN,
                        render: value =>
                            <FormattedDate value={value} />
                    },
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.CHECK_OUT,
                        render: value =>
                            <FormattedDate value={value} />
                    },
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.PRICE,
                        render: value =>
                            <Money value={value}/>
                    },
                ],
            },
        },
    ],
})

