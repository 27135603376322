import React, { Component } from 'react';
import {
    FormattedMessage,
} from "react-intl";

import { composeClassName } from 'utils';
import localeMessages from './messages';

export default class ValidationError extends Component {

    static FORMATTER = {
        BASE_COLOR: 'base-color',
    }

    render() {
        const {
            formatters = [],
            error,
            values = {},
            withIcon,
        } = this.props;

        if (!error) {
            return false;
        }

        const updatedMessage = typeof error === 'object' && localeMessages[error.message]
            ? <FormattedMessage {...localeMessages[error.message]} values={error.values} />
            : localeMessages[error]
                ? <FormattedMessage {...localeMessages[error]} values={values} />
                : error;

        return (
            <div
                className={composeClassName('validation-error', ...formatters)}
            >
                {withIcon &&
                    <i className="error-icon">
                        !
                    </i>
                }
                {updatedMessage}
            </div>
        )
    }
}
