import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    RMS_ROOM_PROPS,
    RMS_EDIT_ROOM_FORM_NAME,
} from './constants';
import localeMessages from './messages';

export default (roomData, intl) => ({
    formName: RMS_EDIT_ROOM_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'name',
            fields: [
                {
                    type: 'input',
                    name: RMS_ROOM_PROPS.NAME,
                    label: <FormattedMessage {...localeMessages.name} />,
                    placeholder: intl.formatMessage(localeMessages.name),
                    initialValue: roomData.get(RMS_ROOM_PROPS.NAME),
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.nameTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'guests',
            fields: [
                {
                    type: 'number',
                    name: RMS_ROOM_PROPS.MAX_GUESTS,
                    label: <FormattedMessage {...localeMessages.maximumGuests} />,
                    initialValue: roomData.get(RMS_ROOM_PROPS.MAX_GUESTS),
                    validations: ['required', {validator: 'numberGreaterThan', args: [0]}],
                    integer: true,
                    help: <FormattedMessage {...localeMessages.maximumGuestsTooltip} />,
                },
                {
                    type: 'number',
                    name: RMS_ROOM_PROPS.MAX_ADAULTS,
                    label: <FormattedMessage {...localeMessages.maximumAdults} />,
                    initialValue: roomData.get(RMS_ROOM_PROPS.MAX_ADAULTS),
                    validations: [
                        'required',
                        {
                            validator: 'numberGreaterThan',
                            args: [0],
                        },
                        (value, values) =>
                            value > values.get(RMS_ROOM_PROPS.MAX_GUESTS)
                                ? <FormattedMessage {...localeMessages.maximumAdultsValidation} />
                                : false,
                    ],
                    integer: true,
                    help: <FormattedMessage {...localeMessages.maximumAdultsTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'price',
            fields: [
                {
                    type: 'money',
                    name: RMS_ROOM_PROPS.MIN_PRICE,
                    label: <FormattedMessage {...localeMessages.minimumPricePerDay} />,
                    initialValue: roomData.get(RMS_ROOM_PROPS.MIN_PRICE),
                    validations: ['required', {validator: 'numberGreaterThan', args: [0]}],
                    help: <FormattedMessage {...localeMessages.minimumPriceTooltip} />,
                },
            ],
        },
        // {
        //     type: 'number',
        //     name: RMS_ROOM_PROPS.BLOCK_DAYS_AFTER_CHECK_OUT,
        //     label: <FormattedMessage {...localeMessages.daysToBlockAfterCheckout} />,
        //     initialValue: roomData.get(RMS_ROOM_PROPS.BLOCK_DAYS_AFTER_CHECK_OUT),
        //     validations: ['required', 'numberNotNegative'],
        //     integer: true,
        // },
    ],
})

