import React, { Component } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Notifications from 'eksenia-lib/src/Notifications';
import {
    getPublicFileUrlPrefix,
} from 'eksenia-lib/src/File';
import Dashboard from 'Dashboard';
import Reservations from 'Reservations';
import {
    Rooms,
    getRooms,
    resetRoomsState,
} from 'Rooms'
import {
    getProperties,
} from "../Properties";
import Calendar from 'Calendar';
import Rates from 'Rates';
import Guests from 'Guests';
import BookingPage from 'BookingPage';
import TaxesAndFees, {getTaxesAndFees} from 'TaxesAndFees';

import PrivateLayout from "../PrivateLayout";
import PropertyPage from "../Properties/PropertyPage";
import Login, { RequestPasswordReset, ResetPassword } from "Login";

const basename = '';

export class Layout extends Component {

    componentDidMount() {
        const {
            getRooms,
            getProperties,
            getTaxesAndFees,
            user,
            getPublicFileUrlPrefix,
        } = this.props;

        if (user && user.size) {
            getRooms();
            getProperties();
            getTaxesAndFees();
            getPublicFileUrlPrefix();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            getRooms,
            getProperties,
            getTaxesAndFees,
            user,
        } = this.props;
        const {
            user: prevUser,
        } = prevProps;

        if (user && user.size && (!prevUser || !prevUser.size)) {
            getRooms();
            getProperties();
            getTaxesAndFees();
        }
    }

    componentWillUnmount() {
        this.props.resetRoomsState();
    }

    render() {
        return (
            <Router basename={basename}>
                <Switch>
                    <Route path="/login" component={ Login } />
                    <Route path="/reset_password/:token" component={ ResetPassword } />
                    <Route path="/reset_password" component={ RequestPasswordReset } />
                    <PrivateLayout path="/property-settings" component={ PropertyPage } />
                    <PrivateLayout path="/rooms" component={ Rooms } />
                    <PrivateLayout path="/taxes-and-fees" component={ TaxesAndFees } />
                    <PrivateLayout path="/reservations" component={ Reservations } />
                    <PrivateLayout path="/calendar" component={ Calendar } />
                    <PrivateLayout path="/rates" component={ Rates } />
                    <PrivateLayout path="/guests" component={ Guests } />
                    <PrivateLayout path="/booking-page" component={ BookingPage } />
                    <PrivateLayout path="/" component={ Dashboard } />
                </Switch>
                <Notifications />
            </Router>
        );
    }

}

const mapStateToProps = store => {
    const userReducer = store.userReducer;

    return {
        user: userReducer.get('user'),
    }
};

const mapDispatchToProps = {
    getRooms,
    getProperties,
    resetRoomsState,
    getPublicFileUrlPrefix,
    getTaxesAndFees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
