import React from "react";

export const supportEmail = () =>
    <a
        href="mailto:support@eksenia.com"
        target="_blank"
        rel="noopener noreferrer"
    >
        support@eksenia.com
    </a>
