import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
} from 'react-intl';

import Page from 'eksenia-lib/src/Page';
import Button from 'eksenia-lib/src/Button';
import RoomList from './RoomList';
import EditRoom from './EditRoom';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import {
    RMS_EDIT_ROOM_MODAL_NAME,
} from './constants';
import localeMessages from './messages';
import {PAGE_FORMATTERS} from "eksenia-lib/src/Page/Page";

export class RoomsPage extends Component {

    renderHeader() {
        const {
            showModal,
        } = this.props;

        return (
            <Button
                onClick={showModal.bind(this, RMS_EDIT_ROOM_MODAL_NAME)}
                name="add-room"
                formatters={[Button.TYPE.PRIMARY]}
            >
                <FormattedMessage {...localeMessages.addRoom} />
            </Button>
        )
    }

    render() {
        return (
            <Page
                header={this.renderHeader()}
                formatters={[PAGE_FORMATTERS.WITH_HEADER]}
            >
                <RoomList />
                <EditRoom />
            </Page>
        );
    }
}

const mapDispatchToProps = {
    showModal,
};

export default connect(null, mapDispatchToProps)(RoomsPage);
