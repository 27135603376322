import { defineMessages } from 'react-intl';

export default defineMessages({
    dashboard: {
        id: "verticalNavigation.dashboard",
        defaultMessage: "Dashboard",
    },
    calendar: {
        id: "verticalNavigation.calendar",
        defaultMessage: "Calendar",
    },
    reservations: {
        id: "verticalNavigation.reservations",
        defaultMessage: "Reservations",
    },
    rooms: {
        id: "verticalNavigation.rooms",
        defaultMessage: "Rooms",
    },
    guests: {
        id: "verticalNavigation.guests",
        defaultMessage: "Guests",
    },
    rates: {
        id: "verticalNavigation.rates",
        defaultMessage: "Rates",
    },
    bookingPage: {
        id: "verticalNavigation.bookingPage",
        defaultMessage: "Booking Page",
    },
    settings: {
        id: "verticalNavigation.settings",
        defaultMessage: "Settings",
    },
    property: {
        id: "verticalNavigation.property",
        defaultMessage: "Property",
    },
    collapse: {
        id: "verticalNavigation.collapse",
        defaultMessage: "Collapse",
    },
    ekseniaLogo: {
        id: "verticalNavigation.ekseniaLogo",
        defaultMessage: "Eksenia logo",
    },
    propertySubmenu: {
        id: "verticalNavigation.propertySubmenu",
        defaultMessage: "More settings",
    },
    mainMenu: {
        id: "verticalNavigation.mainMenu",
        defaultMessage: "Main menu",
    },
    taxesAndFees: {
        id: "verticalNavigation.taxesAndFees",
        defaultMessage: "Taxes and fees",
    },
})