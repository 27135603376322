import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'onboarding.title',
        defaultMessage: 'Welcome to Eksenia!',
    },
    propertyStart: {
        id: 'onboarding.propertyStart',
        defaultMessage: 'Before you start fill in some basic information about your property.'
    },
    roomStart: {
        id: 'onboarding.roomStart',
        defaultMessage: 'Add a first room to your property and you are good to go.'
    },
    aboutProperty: {
        id: 'onboarding.aboutProperty',
        defaultMessage: '1) About the property'
    },
    firstRoom: {
        id: 'onboarding.firstRoom',
        defaultMessage: '3) Add first property room'
    },
    taxesAndFees: {
        id: 'onboarding.taxesAndFees',
        defaultMessage: '2) Setup taxes and fees'
    },
    taxesAndFeesStart: {
        id: 'onboarding.taxesAndFeesStart',
        defaultMessage: 'Set up taxes and fees that your guests need to pay. You can skip this step and do it later if you want.'
    },
    done: {
        id: 'onboarding.done',
        defaultMessage: 'Done'
    },
    deleteTaxOrFee: {
        id: 'onboarding.deleteTaxOrFee',
        defaultMessage: 'Are you sure you want to delete it?',
    },
})
