import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Iterable, List, Map,
} from 'immutable';
import {
    FormattedMessage,
    injectIntl,
} from "react-intl";

import {
    Form,
} from 'eksenia-lib/src/Form';
import Modal, {
    hideModal, showModal, hideAllModals,
} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';

import {
    GUEST_PROPS,
    EDIT_GUEST_MODAL_NAME,
    EDIT_GUEST_FORM_NAME,
    ID_TYPES, DELETE_GUEST_CONFIRMATION_NAME,
} from './constants';
import {
    postGuest,
    putGuest,
    getGuests,
    clearEditedGuest,
    getGuestReservations,
    clearGuestReservations,
    deleteGuest,
    clearAllEditedGuests,
    searchGuests,
    clearSearchGuestsResult,
} from './actions';
import editGuestForm from './editGuestForm';
import localeMessages from './messages';
import {countryNamesToOptions} from "../locale/utils";
import Tabs from "eksenia-lib/src/Tabs";
import Confirm from "eksenia-lib/src/Confirm";
import guestReservationsForm from "./guestReservationsForm";
import {
    addReservationGuest,
    closeAllEditedReservations, getLatestReservations,
    getReservations, GUEST_LIST_PROPS,
    openEditReservationModal, RESERVATION_PROPS, serverGuestToInput
} from "../Reservations";
import {generateKey} from "../utils";
import {guestReservationModalId} from "./utils";
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";
import {addNotification} from "eksenia-lib/src/Notifications";

const defaultGuestData = {
    [GUEST_PROPS.FIRST_NAME]: '',
    [GUEST_PROPS.LAST_NAME]: '',
    [GUEST_PROPS.EMAIL]: '',
    [GUEST_PROPS.PHONE]: '',
    [GUEST_PROPS.COUNTRY]: '',
    [GUEST_PROPS.ADDRESS]: '',
    [GUEST_PROPS.CITY]: '',
    [GUEST_PROPS.ID_TYPE]: ID_TYPES.PASSPORT,
    [GUEST_PROPS.ID_NUMBER]: '',
    [GUEST_PROPS.NOTES]: '',
};

export class EditGuest extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleReservationClick = this.handleReservationClick.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
        this.handleChooseGuest = this.handleChooseGuest.bind(this);
        this.handleAddExistingGuestToReservationSuccess = this.handleAddExistingGuestToReservationSuccess.bind(this);

        this.state = {};
    }

    componentDidMount() {
        const {
            editedGuest,
            getGuestReservations,
        } = this.props;

        if (Iterable.isIterable(editedGuest)) {
            getGuestReservations(editedGuest.get(GUEST_PROPS.ID));
        }
    }

    componentWillUnmount() {
        this.props.clearSearchGuestsResult();
    }

    handleChooseGuest(guest) {
        const {
            addReservationGuest,
            reservation,
        } = this.props;

        const reservationId = reservation.get(RESERVATION_PROPS.ID);
        const guestId = guest.get(GUEST_PROPS.ID);
        const reservationGuest = serverGuestToInput(guest.toJS(), guestId, reservationId)
        reservationGuest[GUEST_LIST_PROPS.GUEST_RANK] = (reservation.get(RESERVATION_PROPS.GUEST_LIST) || List()).size;

        addReservationGuest(
            reservationId,
            reservationGuest,
            this.handleAddExistingGuestToReservationSuccess.bind(this, guestId)
        );
    }

    handleAddExistingGuestToReservationSuccess(guestId, reservation) {
        const {
            getReservations,
            getLatestReservations,
            onAddExistingGuestToReservation,
        } = this.props;

        const guest = reservation[RESERVATION_PROPS.GUEST_LIST]
            .find(guest => guest[GUEST_LIST_PROPS.GUEST_ID] === guestId);


        onAddExistingGuestToReservation(guest);
        getReservations();
        getLatestReservations();
        this.handleCloseModal();
    }

    handleConfirmationClose() {
        this.props.hideModal();
    }

    handleConfirmDelete() {
        const {
            editedGuest,
            deleteGuest,
        } = this.props;

        deleteGuest(editedGuest.get(GUEST_PROPS.ID), this.deleteSuccessCallback);
    }

    handleReservationClick(reservationId, reservation) {
        const {
            showModal,
            openEditReservationModal,
        } = this.props;

        const modalId = guestReservationModalId(reservationId, generateKey());
        this.setState({
            [reservationId]: modalId,
        })

        openEditReservationModal(reservation);
        showModal(modalId);
    }

    handleSaveClick(formValues, formName) {
        const {
            postGuest,
            putGuest,
            editedGuest,
            onSave,
        } = this.props;

        const guest = formValues.toJS();

        const successCallback = typeof onSave === 'function'
            ? onSave
            : this.handleCloseModal;

        if (Iterable.isIterable(editedGuest)) {
            putGuest(
                editedGuest.get(GUEST_PROPS.ID),
                {
                    ...editedGuest.toJS(),
                    ...guest,
                },
                formName,
                successCallback
            );

        } else {
            postGuest(guest, formName, onSave);
        }
    }

    handleCloseModal() {
        const {
            hideModal,
            clearEditedGuest,
            id,
        } = this.props;

        clearEditedGuest(id);
        hideModal();
    }

    deleteSuccessCallback() {
        const {
            addNotification,
            getGuests,
            onDelete,
            hideAllModals,
            getReservations,
            closeAllEditedReservations,
            clearAllEditedGuests,
        } = this.props;

        getGuests();
        getReservations();

        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage {...localeMessages.guestDeleted} />,
        });
        hideAllModals();
        closeAllEditedReservations();
        clearAllEditedGuests();

        if (typeof onDelete === 'function') {
            onDelete();
        }
    }

    render() {
        const {
            editedGuest,
            id,
            intl,
            countryNamesList,
            getGuestReservations,
            showModal,
            guestReservations,
            rooms,
            units,
            modalId,
            addGuestToReservation,
            searchGuests,
            searchResults,
            reservation,
        } = this.props;

        const isExistingGuest = Iterable.isIterable(editedGuest);

        const initialValues = isExistingGuest
            ? editedGuest.toJS()
            : defaultGuestData;

        const countryOptions = countryNamesToOptions(countryNamesList);

        const searchExcludeIds = ((reservation || Map()).get(RESERVATION_PROPS.GUEST_LIST) || List())
            .map(guest => guest.get(GUEST_LIST_PROPS.GUEST_ID)).toJS();

        const tabs = [
            {
                defaultActive: true,
                id: 'details',
                key: 'details',
                label: <FormattedMessage {...localeMessages.details} />,
                withDelete: isExistingGuest,
                content: (
                    <Form
                        key="guest-details"
                        blueprint={editGuestForm(
                            initialValues,
                            countryOptions,
                            intl,
                            addGuestToReservation,
                            searchGuests.bind(null, searchExcludeIds),
                            searchResults,
                            this.handleChooseGuest,
                        )}
                        onSubmit={this.handleSaveClick}
                    />
                ),
            }, {
                id: 'reservations',
                key: 'reservations',
                label: <FormattedMessage {...localeMessages.reservations} />,
                enabled: isExistingGuest,
                content: (
                    <Form
                        key="reservations"
                        blueprint={guestReservationsForm(
                            guestReservations,
                            this.handleReservationClick,
                            rooms,
                            units,
                            this.state,
                            modalId || id,
                        )}
                        onSubmit={() => {}}
                    />
                ),
            }
        ];

        return (
            <ModalWithForm
                id={modalId || id || EDIT_GUEST_MODAL_NAME}
                key={modalId || id || EDIT_GUEST_MODAL_NAME}
                headerText={<FormattedMessage {...localeMessages.guest} />}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={[id || EDIT_GUEST_FORM_NAME]}
                onShow={() => { if (isExistingGuest) { getGuestReservations(editedGuest.get(GUEST_PROPS.ID)) } }}
            >
                <Tabs
                    groupName={modalId ? `${modalId}_edit-guest` : 'edit-guest'}
                    tabs={tabs}
                    activeForms={[
                        EDIT_GUEST_FORM_NAME,
                    ]}
                    onDelete={() => showModal(DELETE_GUEST_CONFIRMATION_NAME)}
                />

                <Confirm
                    id={DELETE_GUEST_CONFIRMATION_NAME}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleConfirmDelete}
                >
                    <FormattedMessage {...localeMessages.deleteConfirmation} />
                </Confirm>
            </ModalWithForm>
        );
    }
}

const mapStateToProps = (store, { id }) => {
    const guestsReducer = store.guestsReducer;
    const localeReducer = store.localeReducer;
    const roomsReducer = store.roomsReducer;

    const editedGuest = guestsReducer.getIn(['editedGuests', id, 'editedGuest']) || false;
    const isExistingGuest = Iterable.isIterable(editedGuest);

    return {
        editedGuest,
        guestReservations: isExistingGuest
            ? guestsReducer.getIn(['guestReservations', editedGuest.get(GUEST_PROPS.ID)]) || List()
            : List(),
        countryNamesList: localeReducer.get('countryNamesList') || List(),
        rooms: roomsReducer.get('rooms'),
        units: roomsReducer.get('units', List()),
        searchResults: guestsReducer.get('searchGuestsResult'),
    };
};

const mapDispatchToProps = {
    postGuest,
    putGuest,
    getGuests,
    hideModal,
    clearEditedGuest,
    getGuestReservations,
    clearGuestReservations,
    showModal,
    openEditReservationModal,
    deleteGuest,
    hideAllModals,
    addNotification,
    getReservations,
    closeAllEditedReservations,
    clearAllEditedGuests,
    searchGuests,
    clearSearchGuestsResult,
    addReservationGuest,
    getLatestReservations,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditGuest))
