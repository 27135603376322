import React, {Component} from 'react';
import Table from "eksenia-lib/src/Table";
import {RESERVATION_CHARGE_PROPS, RESERVATION_PROPS} from "./constants";
import Money from "eksenia-lib/src/Money";

const strongPriceValues = [
    RESERVATION_PROPS.PRICE,
    RESERVATION_PROPS.PRICE_WITH_All_CHARGES
];

export default class PriceBreakdown extends Component {

    prepareTableRows() {
        const {
            priceBreakdown,
        } = this.props;

        return priceBreakdown.map(item => {
            const itemId = item.get(RESERVATION_CHARGE_PROPS.ID);
            const strongRow = strongPriceValues.indexOf(itemId) !== -1;
            const cellFormatters = strongRow ? ['emphasized', 'align-right'] : ['align-right'];
            const rowFormatters = strongRow ? ['custom-background', 'emphasized'] : ['custom-background'];

            return {
                key: itemId,
                formatters: rowFormatters,
                cells: [
                    {
                        content: item.get(RESERVATION_CHARGE_PROPS.TYPE),
                        key: RESERVATION_CHARGE_PROPS.TYPE,
                        formatters: cellFormatters,
                        width: '65%',
                    },
                    {
                        content: <Money value={item.get(RESERVATION_CHARGE_PROPS.TOTAL_PRICE)} />,
                        key: RESERVATION_CHARGE_PROPS.TOTAL_PRICE,
                        formatters: cellFormatters,
                        width: '35%',
                    },
                ]
            }
        });
    }

    render() {
        const rows = this.prepareTableRows();

        return (
            <Table hideHeader={true} rows={rows} />
        )
    }

}
