import { endpoints } from 'api'

import {
    GET_PROPERTIES_SUCCESS,
    UPDATE_PROPERTY_SUCCESS,
    GET_TIMEZONES_SUCCESS,
} from "./constants";

export const getProperties = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getProperties,
    actions: {
        success: GET_PROPERTIES_SUCCESS,
    },
});

export const patchProperty = (idProperty, patchObj, formName) => ({
    type: 'API_CALL',
    endpoint: endpoints.patchProperty,
    actions: {
        success: UPDATE_PROPERTY_SUCCESS,
    },
    params: { idProperty },
    body: patchObj,
    formName,
});

export const getTimezones = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getTimeZones,
    actions: {
        success: GET_TIMEZONES_SUCCESS,
    },
});

