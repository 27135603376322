import { fromJS, Iterable } from 'immutable';

import { reduceWithDefault } from 'utils';
import {
    RMS_GET_ROOMS_SUCCESS,
    RMS_GET_ROOMS_REQUEST,
    RMS_POST_ROOM_SUCCESS,
    RMS_UPDATE_ROOM_SUCCESS,
    RMS_DELETE_ROOM_SUCCESS,
    GET_UNITS_SUCCESS,
    RESET_ROOMS_STATE,
    OPEN_EDIT_ROOM,
    CLOSE_EDIT_ROOM,
    RMS_ROOM_PROPS,
    UNIT_PROPS,
    GET_UNITS_MERGE_SUCCESS,
} from './constants';

const initialState = fromJS({
    editedRoom: false,
    rooms: [],
    units: [],
    deletedUnits: [],
    finishedLoading: false,
    finishedLoadingRooms: false,
});

const reducers = {
    [RESET_ROOMS_STATE]: () =>
        initialState,

    [RMS_GET_ROOMS_REQUEST]: state =>
        state.merge({
            finishedLoading: false,
            finishedLoadingRooms: false,
        }),

    [RMS_GET_ROOMS_SUCCESS]: (state, { data }) =>
        state.merge({
            rooms: fromJS(data || []),
            finishedLoadingRooms: true,
        }),

    [GET_UNITS_SUCCESS]: (state, { data, payload = {} }) =>
        state.merge({
            units: Array.isArray(payload.rooms)
                ? state.get('units').filter(unit => payload.rooms.indexOf(unit.get(UNIT_PROPS.ROOM_ID)) === -1)
                    .concat(fromJS(data || []).filter(unit => !unit.get(UNIT_PROPS.DELETED)))
                : fromJS(data || []).filter(unit => !unit.get(UNIT_PROPS.DELETED)),
            deletedUnits: Array.isArray(payload.rooms)
                ? state.get('deletedUnits').filter(unit => payload.rooms.indexOf(unit.get(UNIT_PROPS.ROOM_ID)) === -1)
                : fromJS(data || []).filter(unit => unit.get(UNIT_PROPS.DELETED)),
            finishedLoading: true,
        }),

    [RMS_POST_ROOM_SUCCESS]: (state, { data }) =>
        state.merge({
            rooms: state.get('rooms').push(fromJS(data)),
            editedRoom: fromJS(data),
        }),

    [RMS_UPDATE_ROOM_SUCCESS]: (state, { data }) =>
        state.merge({
            rooms: state.get('rooms')
                .map(room => 
                    room.get(RMS_ROOM_PROPS.ID) === data[RMS_ROOM_PROPS.ID]
                        ? fromJS(data)
                        : room
                ),
            editedRoom: fromJS(data),
        }),

    [RMS_DELETE_ROOM_SUCCESS]: (state, { data }) =>
        state.merge({
            rooms: state.get('rooms')
                .filter(room => 
                    room.get(RMS_ROOM_PROPS.ID) !== data[RMS_ROOM_PROPS.ID]
                ),
        }),

    [OPEN_EDIT_ROOM]: (state, { editedRoom }) =>
        state.merge({
            editedRoom: Iterable.isIterable(editedRoom) && editedRoom,
        }),

    [CLOSE_EDIT_ROOM]: (state) =>
        state.merge({
            editedRoom: initialState.get('editedRoom'),
        }),

    [GET_UNITS_MERGE_SUCCESS]: (state, { data }) =>
        state.updateIn(['units'], units => units.concat(fromJS(data))),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
