import React, { Component } from 'react';

import { composeClassName } from 'utils';

export default class Button extends Component {

    static TYPE = {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        TERTIARY: 'tertiary',
        LINK: 'link',
        DELETE_LINK: 'delete-link',
        PRIMARY_LINK: 'primary-link',
        SAVE: 'save',
        CONFIRM: 'confirm',
        ACTION: 'action',
    };

    static SIZE = {
        SMALL: 'small',
    };

    static WIDTH = {
        FULL: 'full',
    }

    render() {
        const {
            formatters = [],
            type,
            className,
            submitting,
            success,
            error,
            ...props
        } = this.props;

        let statusClassName = '';

        if (submitting) {
            statusClassName = 'submitting';
        } else if (success) {
            statusClassName = 'success';
        } else if (error) {
            statusClassName = 'error';
        }

        return (
            <button
                type={type || 'button'}
                className={composeClassName('button', className, statusClassName, ...formatters)}
                disabled={submitting}
                {...props}
            />
        )
    }
}
