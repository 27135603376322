import Message from './Message.jsx';
import reducer from './reducer';
import './Messages.scss';

export * from './actions';
export {
    reducer,
    Message,
};

export default Message
