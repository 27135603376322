export const GET_TAXES_AND_FEES_REQUEST = 'GET_TAXES_AND_FEES_REQUEST';
export const GET_TAXES_AND_FEES_SUCCESS = 'GET_TAXES_AND_FEES_SUCCESS';
export const GET_TAXES_AND_FEES_ERROR = 'GET_TAXES_AND_FEES_ERROR';
export const POST_TAX_OR_FEE_REQUEST = 'POST_TAX_OR_FEE_REQUEST';
export const POST_TAX_OR_FEE_SUCCESS = 'POST_TAX_OR_FEE_SUCCESS';
export const POST_TAX_OR_FEE_ERROR = 'POST_TAX_OR_FEE_ERROR';
export const DELETE_TAX_OR_FEE_REQUEST = 'DELETE_TAX_OR_FEE_REQUEST';
export const DELETE_TAX_OR_FEE_SUCCESS = 'DELETE_TAX_OR_FEE_SUCCESS';
export const DELETE_TAX_OR_FEE_ERROR = 'DELETE_TAX_OR_FEE_ERROR';
export const UPDATE_TAX_OR_FEE_REQUEST = 'UPDATE_TAX_OR_FEE_REQUEST';
export const UPDATE_TAX_OR_FEE_SUCCESS = 'UPDATE_TAX_OR_FEE_SUCCESS';
export const UPDATE_TAX_OR_FEE_ERROR = 'UPDATE_TAX_OR_FEE_ERROR';
export const SET_EDITED_TAX_OR_FEE = 'SET_EDITED_TAX_OR_FEE';
export const CLEAR_EDITED_TAX_OR_FEE = 'CLEAR_EDITED_TAX_OR_FEE';

export const EDIT_TAXES_AND_FEES_FORM_NAME = 'EDIT_TAXES_AND_FEES_FORM_NAME';
export const EDIT_TAX_OR_FEE_MODAL_NAME = 'EDIT_TAX_OR_FEE_MODAL_NAME';
export const DELETE_CHARGE_MODAL = 'DELETE_CHARGE_MODAL';

export const TAXES_AND_FEES_PROPS = {
    ID: 'ID',
    TYPE: 'Type',
    IS_INCLUSIVE: 'IsInclusive',
    LOGIC: 'Logic',
    RATE: 'Rate',
}

export const TAX_AND_FEE_TYPES = {
    VAT: 'VAT',
    TOURIST_TAX: 'TouristTax',
    CITY_TAX: 'CityTax',
    CLEANING_FEE: 'CleaningFee',
    TRANSFER_FEE: 'TransferFee',
    OTHER_FEES: 'OtherFee',
}

export const TAX_AND_FEE_LOGIC_OPTIONS = {
    PERCENT: 'Percent',
    PER_BOOKING: 'PerBooking',
    PER_NIGHT: 'PerNight',
    PER_PERSON: 'PerPerson',
    PER_PERSON_PER_NIGHT: 'PerPersonPerNight',
}