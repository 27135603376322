import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';

import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    updateInputField,
} from 'eksenia-lib/src/Form';
import EditTaxOrFee from "../TaxesAndFees/EditTaxOrFee";
import {addEditedTaxOrFee, clearEditedTaxOrFee, TAXES_AND_FEES_PROPS} from "../TaxesAndFees";

export class OnboardingTaxOrFee extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveTaxOrFee = this.handleSaveTaxOrFee.bind(this);
    }

    handleSaveTaxOrFee(data) {
        const {
            updateInputField,
            formName,
            inputName,
            inputValue,
            hideModal,
            clearEditedTaxOrFee,
            onChange,
        } = this.props;

        if (typeof data === 'object') {
            const updatedTaxOrFeeId = data[TAXES_AND_FEES_PROPS.ID];
            // remove deleted tax or fee, updated an existing one, or add a new one if it doesn't exist
            const updatedInputValue =
                inputValue.find(taxOrFee => taxOrFee.get(TAXES_AND_FEES_PROPS.ID) === updatedTaxOrFeeId) === undefined
                    ? inputValue.push(fromJS(data))
                    : inputValue.map(taxOrFee =>
                        taxOrFee.get(TAXES_AND_FEES_PROPS.ID) === updatedTaxOrFeeId
                            ? fromJS(data)
                            : taxOrFee
                    );


            if (typeof onChange === 'function') {
                onChange(updatedInputValue);
            }

            updateInputField(formName, inputName, {
                value: updatedInputValue,
            });

            clearEditedTaxOrFee();
            hideModal();
        }
    }

    render() {
        const {
            inputId,
        } = this.props;

        return (
            <EditTaxOrFee
                onSuccess={this.handleSaveTaxOrFee}
                id={inputId}
            />
        );
    }
}

const mapStateToProps = (store, ownProps) => {
    const {
        formName,
        inputName,
    } = ownProps;
    const formReducer = store.formReducer;

    return {
        inputValue: formReducer.getIn([formName, 'inputFields', inputName, 'value'])

    };
};

const mapDispatchToProps = {
    clearEditedTaxOrFee,
    addEditedTaxOrFee,
    showModal,
    hideModal,
    updateInputField,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTaxOrFee);
