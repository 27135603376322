import { all, fork } from "redux-saga/effects";
import apiSaga from "eksenia-lib/src/api/apiSaga";
import { formSaga } from "eksenia-lib/src/Form";
import updateListSaga from "eksenia-lib/src/api/updateListSaga";
import fileUploadSaga from "eksenia-lib/src/File/fileUploadSaga";
import { watchUploadChannel } from "eksenia-lib/src/File/fileUploadSaga";

export default function* () {
  yield all([
    fork(apiSaga),
    fork(formSaga),
    fork(updateListSaga),
    fork(fileUploadSaga),
    fork(watchUploadChannel),
  ]);
}
