import Modal from './Modal';
import reducer from './reducer';
import {
    showModal,
    hideModal,
    hideAllModals,
} from './actions';
import './Modal.scss';

export {
    reducer,
    showModal,
    hideModal,
    hideAllModals,
}

export default Modal
