import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
} from 'react-intl';
import PaymentList from './PaymentList';
import Button from 'eksenia-lib/src/Button';
import EditPayment from './EditPayment';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import {
    PMT_EDIT_PAYMENT_MODAL_NAME,
} from './constants';
import localeMessages from './messages';

export class PaymentPage extends Component {

    renderHeader() {
        const {
            showModal,
        } = this.props;

        return (
            <Button
                onClick={showModal.bind(this, PMT_EDIT_PAYMENT_MODAL_NAME)}
                formatters={[Button.TYPE.PRIMARY]}
            >
                <FormattedMessage {...localeMessages.addPayment} />
            </Button>
        )
    }

    render() {
        const {
            idReservation,
        } = this.props;

        return (
            <>
                <div className="payments-header">
                    {this.renderHeader()}
                </div>
                <PaymentList idReservation={idReservation} />
                <EditPayment idReservation={idReservation} />
            </>
        )
    }
}

const mapDispatchToProps = {
    showModal,
};

export default connect(null, mapDispatchToProps)(PaymentPage);
