import { defineMessages } from 'react-intl';

export default defineMessages({
    room: {
        id: 'dashboard.room',
        defaultMessage: 'Room',
    },
    unit: {
        id: 'dashboard.unit',
        defaultMessage: 'Unit',
    },
    guestName: {
        id: 'dashboard.guestName',
        defaultMessage: 'Guest Name',
    },
    checkIn: {
        id: 'dashboard.checkIn',
        defaultMessage: 'Check-in',
    },
    checkOut: {
        id: 'dashboard.checkOut',
        defaultMessage: 'Check Out',
    },
    checkStatus: {
        id: 'dashboard.checkStatus',
        defaultMessage: 'Check Status',
    },
    arrivals: {
        id: 'dashboard.arrivals',
        defaultMessage: 'Arrivals',
    },
    departures: {
        id: 'dashboard.departures',
        defaultMessage: 'Departures',
    },
    numberOf: {
        id: 'dashboard.numberOf',
        defaultMessage: '{number}/{total}',
    },
    newBookings: {
        id: 'dashboard.newBookings',
        defaultMessage: 'New bookings in last 7 days',
    },
    dailySummary: {
        id: 'dashboard.dailySummary',
        defaultMessage: 'Daily Summary',
    },
    currentGuests: {
        id: 'dashboard.currentGuests',
        defaultMessage: 'Current Guests',
    },
    latestBookings: {
        id: 'dashboard.latestBookings',
        defaultMessage: 'Latest Bookings',
    },
    currentGuestsTooltip: {
        id: 'dashboard.currentGuestsTooltip',
        defaultMessage: 'Checked in guests who are currently staying at the property.',
    },
    latestBookingsTooltip: {
        id: 'dashboard.latestBookingsTooltip',
        defaultMessage: 'Bookings received in the last 7 days.',
    },
    arrivalsTooltip: {
        id: 'dashboard.arrivalsTooltip',
        defaultMessage: 'Guests expected to arrive today, as well as guests who should have arrived but were not checked in.',
    },
    departuresTooltip: {
        id: 'dashboard.departuresTooltip',
        defaultMessage: 'Guests expected to check out today, or who should have checked out already.',
    },
    arrivalsCheckStatusTooltip: {
        id: 'dashboard.arrivalsCheckStatusTooltip',
        defaultMessage: "Click to check the guest in. For today's arrivals you can uncheck it again.",
    },
    departureCheckStatusTooltip: {
        id: 'dashboard.departureCheckStatusTooltip',
        defaultMessage: "Click to check the guest out. For today's departures you can uncheck it again.",
    },
})
