export const RMS_GET_ROOMS_REQUEST = 'RMS_GET_ROOMS_REQUEST';
export const RMS_GET_ROOMS_SUCCESS = 'RMS_GET_ROOMS_SUCCESS';
export const RMS_GET_ROOMS_ERROR = 'RMS_GET_ROOMS_ERROR';

export const RMS_POST_ROOM_REQUEST = 'RMS_POST_ROOM_REQUEST';
export const RMS_POST_ROOM_SUCCESS = 'RMS_POST_ROOM_SUCCESS';
export const RMS_POST_ROOM_ERROR = 'RMS_POST_ROOM_ERROR';

export const RMS_DELETE_ROOM_REQUEST = 'RMS_DELETE_ROOM_REQUEST';
export const RMS_DELETE_ROOM_SUCCESS = 'RMS_DELETE_ROOM_SUCCESS';
export const RMS_DELETE_ROOM_ERROR = 'RMS_DELETE_ROOM_ERROR';

export const RMS_UPDATE_ROOM_REQUEST = 'RMS_UPDATE_ROOM_REQUEST';
export const RMS_UPDATE_ROOM_SUCCESS = 'RMS_UPDATE_ROOM_SUCCESS';
export const RMS_UPDATE_ROOM_ERROR = 'RMS_UPDATE_ROOM_ERROR';

export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_ERROR = 'GET_UNITS_ERROR';

export const UPDATE_UNIT_LIST_SUCCESS = 'UPDATE_UNIT_LIST_SUCCESS';
export const GET_UNITS_MERGE_SUCCESS = 'GET_UNITS_MERGE_SUCCESS';

export const OPEN_EDIT_ROOM = 'OPEN_EDIT_ROOM';
export const CLOSE_EDIT_ROOM = 'CLOSE_EDIT_ROOM';

export const RESET_ROOMS_STATE = 'RESET_ROOMS_STATE';

export const RMS_EDIT_ROOM_FORM_NAME = 'RMS_EDIT_ROOM_FORM_NAME';
export const RMS_EDIT_ICAL_FORM_NAME = 'RMS_EDIT_ICAL_FORM_NAME';
export const RMS_EDIT_UNITS_FORM_NAME = 'RMS_EDIT_UNITS_FORM_NAME';
export const EDIT_UNIT_FORM_NAME = 'EDIT_UNIT_FORM_NAME';

export const RMS_EDIT_ROOM_MODAL_NAME = 'RMS_EDIT_ROOM_MODAL_NAME';
export const RMS_DELETE_ROOM_CONFIRMATION_NAME = 'RMS_DELETE_ROOM_CONFIRMATION_NAME';

export const EDIT_UNIT_MODAL_NAME = 'EDIT_UNIT_MODAL_NAME';
export const DELETE_UNIT_CONFIRMATION_NAME = 'DELETE_UNIT_CONFIRMATION_NAME';

export const UPDATE_UNITS = 'UPDATE_UNITS';

export const RMS_ROOM_PROPS = {
    ID: 'ID',
    NAME: 'Name',
    MAX_GUESTS: 'MaxGuests',
    MAX_ADAULTS: 'MaxAdults',
    MIN_PRICE: 'MinimumDayPrice',
    //BLOCK_DAYS_AFTER_CHECK_OUT: 'DaysBlockedAfterCheckout',
    ICAL_IMPORT_URL_LIST: 'ICalImportURLList',
    UNIT_LIST: 'unitList',
    AMENITIES: 'Amenities',
    CAN_DELETE: 'CanDelete',
};

export const RMS_ICAL_IMPORT_PROPS = {
    ICAL_CHANNEL_NAME: 'ChannelName',
    ICAL_IMPORT_URL: 'ImportURL',
    ID: 'ID',
    TEMP_ID: 'tempId',
};

export const UNIT_PROPS = {
    ID: 'ID',
    ROOM_ID: 'RoomID',
    NAME: 'Name',
    ICAL_IMPORT_URL_LIST: 'ICalImportURLList',
    RANK: 'Rank',
    DELETED: 'Deleted',
    TEMP_ID: 'tempId',
    CAN_DELETE: 'CanDelete',
};
