import React, {Component} from 'react';

export default class Skeleton extends Component {
    render() {
        return (
            <div className="skeleton">
                <div className="skeleton-vertical-navigation" />

                <div className="skeleton-container">
                    <div className="skeleton-header" />

                    <div className="skeleton-page-content">
                        <div className="skeleton-widget-column">
                            <div className="skeleton-widget" />
                            <div className="skeleton-widget" />
                        </div>
                        <div className="skeleton-widget-column">
                            <div className="skeleton-widget-small" />
                            <div className="skeleton-widget-small" />
                            <div className="skeleton-widget" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
