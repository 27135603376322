import { Map } from 'immutable';
import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    PROPERTY_PROPS,
    PROPERTY_SETTINGS_FORM,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
    intl,
    countryOptions,
    currencyOptions,
    timezoneOptions
) => ({
    formName: PROPERTY_SETTINGS_FORM,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'name',
            fields: [
                {
                    type: 'input',
                    name: PROPERTY_PROPS.NAME,
                    initialValue: initialValues.get(PROPERTY_PROPS.NAME),
                    label: <FormattedMessage {...localeMessages.name} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.propertyNameTooltip} />,
                },
                {
                    type: 'input',
                    name: PROPERTY_PROPS.LEGAL_NAME,
                    initialValue: initialValues.get(PROPERTY_PROPS.LEGAL_NAME),
                    label: <FormattedMessage {...localeMessages.legalName} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.propertyLegalNameTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'timezone',
            fields: [
                {
                    type: 'select',
                    name: PROPERTY_PROPS.TIME_ZONE,
                    initialValue: initialValues.get(PROPERTY_PROPS.TIME_ZONE),
                    label: <FormattedMessage {...localeMessages.timeZone} />,
                    validations: ['required'],
                    options: timezoneOptions,
                    help: <FormattedMessage {...localeMessages.timezoneTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'currency',
            fields: [
                {
                    type: 'select',
                    name: PROPERTY_PROPS.CURRENCY,
                    initialValue: initialValues.get(PROPERTY_PROPS.CURRENCY),
                    label: <FormattedMessage {...localeMessages.currency} />,
                    validations: ['required'],
                    options: currencyOptions,
                    help: <FormattedMessage {...localeMessages.currencyTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'location',
            fields: [
                {
                    type: 'select',
                    name: PROPERTY_PROPS.LEGAL_COUNTRY,
                    initialValue: initialValues.get(PROPERTY_PROPS.LEGAL_COUNTRY),
                    label: <FormattedMessage {...localeMessages.legalCountry} />,
                    validations: ['required'],
                    options: countryOptions,
                    help: <FormattedMessage {...localeMessages.countryTooltip} />,
                },
                {
                    type: 'input',
                    name: PROPERTY_PROPS.LEGAL_CITY,
                    initialValue: initialValues.get(PROPERTY_PROPS.LEGAL_CITY),
                    label: <FormattedMessage {...localeMessages.legalCity} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.cityTooltip} />,
                },
                {
                    type: 'input',
                    name: PROPERTY_PROPS.LEGAL_ADDRESS,
                    initialValue: initialValues.get(PROPERTY_PROPS.LEGAL_ADDRESS),
                    label: <FormattedMessage {...localeMessages.legalAddress} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.addressTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'email',
            fields: [
                {
                    type: 'input',
                    name: PROPERTY_PROPS.BUSINESS_EMAIL,
                    initialValue: initialValues.get(PROPERTY_PROPS.BUSINESS_EMAIL),
                    label: <FormattedMessage {...localeMessages.businessEmail} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.businessEmailTooltip} />,
                },
                {
                    type: 'input',
                    name: PROPERTY_PROPS.NOTIFICATIONS_EMAIL,
                    initialValue: initialValues.get(PROPERTY_PROPS.NOTIFICATIONS_EMAIL),
                    label: <FormattedMessage {...localeMessages.notificationsEmail} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.notificationsEmailTooltip} />,
                },
            ],
        },
    ],
})
