import { fromJS } from 'immutable';

import {
    reduceWithDefault,
} from 'utils';

import {
    mapReservationsValues,
    mapReservationValues,
} from './utils';

import {
    GET_RESERVATIONS_SUCCESS,
    RESERVATION_PROPS,
    BKG_OPEN_EDIT_RESERVATION_MODAL,
    BKG_CLOSE_EDIT_RESERVATION_MODAL,
    BKG_POST_RESERVATIONS_SUCCESS,
    BKG_UPDATE_RESERVATIONS_SUCCESS,
    GET_PROVISIONAL_PRICE_REQUEST,
    GET_PROVISIONAL_PRICE_SUCCESS,
    GET_PROVISIONAL_PRICE_ERROR,
    GET_LATEST_RESERVATIONS_SUCCESS,
    GET_MULTI_RESERVATION_PRICE_BREAKDOWN_SUCCESS,
    BKG_CLEAR_ALL_EDITED_RESERVATIONS,
    SET_RESERVATION_LIST_EDITED_RESERVATION,
    SAVE_NEW_RESERVATION_ID,
    DELETE_NEW_RESERVATION_ID,
} from './constants';

const initialState = fromJS({
    editedReservations: {},
    reservations: [],
    calculatedPrice: {},
    latestReservations: '',
    multiReservationPriceBreakdown: '',
    reservationListEditedReservation: null,
    newReservationId: undefined,
});

const reducers = {
    [BKG_OPEN_EDIT_RESERVATION_MODAL]: (state, { editedReservation }) =>
        updateEditedReservation(state, editedReservation),

    [BKG_CLOSE_EDIT_RESERVATION_MODAL]: (state, { reservationId }) => {
        state = removeEditedReservation(state, reservationId);
        return state.merge({
            calculatedPrice: initialState.get('calculatedPrice'),
            multiReservationPriceBreakdown: initialState.get('multiReservationPriceBreakdown'),
        })
    },

    [BKG_CLEAR_ALL_EDITED_RESERVATIONS]: state =>
        state.merge({
            editedReservations: initialState.get('editedReservations'),
            calculatedPrice: initialState.get('calculatedPrice'),
            multiReservationPriceBreakdown: initialState.get('multiReservationPriceBreakdown'),
        }),

    [GET_RESERVATIONS_SUCCESS]: (state, { data }) =>
        state.set('reservations', mapReservationsValues(fromJS(data))),

    [BKG_POST_RESERVATIONS_SUCCESS]: (state, { data }) =>
        updateEditedReservation(state, mapReservationValues(fromJS(data))),

    [BKG_UPDATE_RESERVATIONS_SUCCESS]: (state, { data }) =>
        updateEditedReservation(state, mapReservationValues(fromJS(data)), false),

    [GET_PROVISIONAL_PRICE_REQUEST]: (state) =>
        state.set('calculatedPrice', initialState.get('calculatedPrice')),

    [GET_PROVISIONAL_PRICE_SUCCESS]: (state, { data }) =>
        state.set('calculatedPrice', fromJS(data)),

    [GET_PROVISIONAL_PRICE_ERROR]: (state) =>
        state.set('calculatedPrice', initialState.get('calculatedPrice')),

    [GET_LATEST_RESERVATIONS_SUCCESS]: (state, { data }) =>
        state.set('latestReservations', mapReservationsValues(fromJS(data))),

    [GET_MULTI_RESERVATION_PRICE_BREAKDOWN_SUCCESS]: (state, { data }) =>
        state.set('multiReservationPriceBreakdown', fromJS(data)),

    [SET_RESERVATION_LIST_EDITED_RESERVATION]: (state, { reservationId }) =>
        state.set('reservationListEditedReservation', reservationId),

    [SAVE_NEW_RESERVATION_ID]: (state, { reservationId }) =>
        state.set('newReservationId', reservationId),

    [DELETE_NEW_RESERVATION_ID]: (state) =>
        state.set('newReservationId', initialState.get('newReservationId')),

};

const updateEditedReservation = (state, editedReservation, addToCount = true) => {
    const reservationId = editedReservation.get(RESERVATION_PROPS.ID);
    const count = state.getIn(['editedReservations', reservationId, 'count'], 0);

    return state.mergeIn(['editedReservations', reservationId], {
        count: addToCount ? count + 1 : count,
        editedReservation,
    })
};

const removeEditedReservation = (state, reservationId) => {
    const count = state.getIn(['editedReservations', reservationId, 'count'], 1) - 1;
    return count === 0
        ? state.deleteIn(['editedReservations', reservationId])
        : state.setIn(['editedReservations', reservationId, 'count'], count);
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);

