import { defineMessages } from 'react-intl';
import {TAX_AND_FEE_LOGIC_OPTIONS, TAX_AND_FEE_TYPES} from "./constants";

export default defineMessages({
    type: {
        id: 'taxesAndFees.type',
        defaultMessage: 'Type',
    },
    typeTooltip: {
        id: 'taxesAndFees.typeTooltip',
        defaultMessage: 'Tax or fee type is displayed in the price breakdown when your guests are making a reservation or when you are printing an invoice so the guest knows what they are paying.',
    },
    [TAX_AND_FEE_TYPES.VAT]: {
        id: 'taxesAndFees.typesVAT',
        defaultMessage: 'VAT',
    },
    [TAX_AND_FEE_TYPES.TOURIST_TAX]: {
        id: 'taxesAndFees.typesTouristTax',
        defaultMessage: 'Tourist tax',
    },
    [TAX_AND_FEE_TYPES.CITY_TAX]: {
        id: 'taxesAndFees.typesCityTax',
        defaultMessage: 'City tax',
    },
    [TAX_AND_FEE_TYPES.CLEANING_FEE]: {
        id: 'taxesAndFees.typesCleaningFee',
        defaultMessage: 'Cleaning fee',
    },
    [TAX_AND_FEE_TYPES.TRANSFER_FEE]: {
        id: 'taxesAndFees.typesTransferFee',
        defaultMessage: 'Transfer fee',
    },
    [TAX_AND_FEE_TYPES.OTHER_FEES]: {
        id: 'taxesAndFees.typesOtherFees',
        defaultMessage: 'Other fees',
    },
    isInclusive: {
        id: 'taxesAndFees.isInclusive',
        defaultMessage: 'Is inclusive',
    },
    isInclusiveTooltip: {
        id: 'taxesAndFees.isInclusiveTooltip',
        defaultMessage: 'Is the value of the tax or fee included in the reservation price or is it added on the total price. Inclusive taxes and fees are used only for price breakdown when your guests are making a reservation or when you are printing an invoice.',
    },
    logic: {
        id: 'taxesAndFees.logic',
        defaultMessage: 'Logic',
    },
    logicTooltip: {
        id: 'taxesAndFees.logicTooltip',
        defaultMessage: 'Choose how the amount of the tax or fee is being calculated.',
    },
    [TAX_AND_FEE_LOGIC_OPTIONS.PERCENT]: {
        id: 'taxesAndFees.logicOptionsPercent',
        defaultMessage: 'Percent',
    },
    [TAX_AND_FEE_LOGIC_OPTIONS.PER_BOOKING]: {
        id: 'taxesAndFees.logicOptionsPerBooing',
        defaultMessage: 'Per booking',
    },
    [TAX_AND_FEE_LOGIC_OPTIONS.PER_NIGHT]: {
        id: 'taxesAndFees.logicOptionsPerNight',
        defaultMessage: 'Per night',
    },
    [TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON]: {
        id: 'taxesAndFees.logicOptionsPerPerson',
        defaultMessage: 'Per person',
    },
    [TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON_PER_NIGHT]: {
        id: 'taxesAndFees.logicOptionsPerPersonPerNight',
        defaultMessage: 'Per person per night',
    },
    rate: {
        id: 'taxesAndFees.rate',
        defaultMessage: 'Rate',
    },
    addTaxOrFee: {
        id: 'taxesAndFees.addTaxOrFee',
        defaultMessage: 'Add a tax or a fee',
    },
    inclusive: {
        id: 'taxesAndFees.inclusive',
        defaultMessage: 'Inclusive',
    },
    exclusive: {
        id: 'taxesAndFees.exclusive',
        defaultMessage: 'Exclusive',
    },
    taxOrFee: {
        id: 'taxesAndFees.taxOrFee',
        defaultMessage: 'Tax / fee',
    },
    confirmDelete: {
        id: 'taxesAndFees.confirmDelete',
        defaultMessage: 'Are you sure you want to delete this charge?',
    },
    chargeDeleted: {
        id: 'taxesAndFees.chargeDeleted',
        defaultMessage: 'Charged has been deleted.',
    },
})
