import {call, put, takeEvery, all, take, race} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { Map, List } from 'immutable';
import React from "react";

import {
    endpoints,
    apiCall,
} from '../api';
import {
    setValidationError,
    setFormSubmitting,
    setFormNotSubmitting,
    setInitialValuesToCurrent,
    updateInputField,
} from '../Form';
import {
    addNotification,
} from '../Notifications';
import {
    setUploadProgress,
    setUploadFinished,
} from '../File';
import {
    FILE_INPUT_ID_PREFIX,
    FILE_INPUT_PROPS,
    FILE_UPLOAD_PROPS,
} from './constants';
import {NOTIFICATION_TYPE} from "../Notifications/constants";
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";

const uploadChannel = channel()

function* fileList(action) {
    const {
        actions: {
            success,
            error,
            successCallback,
        } = {},
        formName,
        inputName,
        inputValue,
        isPublic,
        doNotSetFormNotSubmitting,
    } = action;

    if (typeof formName === 'string') {
        yield put(setFormSubmitting(formName));
    }

    const doNotUpload = inputValue.get(FILE_INPUT_PROPS.DO_NOT_UPLOAD) || List();
    const files = (inputValue.get(FILE_INPUT_PROPS.FILES) || List())
        .filter(fileMap => !doNotUpload.contains(fileMap.get(FILE_INPUT_PROPS.PATH)))
        .map(fileMap => fileMap.get(FILE_INPUT_PROPS.FILE)).toJS();
    const toYield = [];

    const uploadData = yield all(files.map(() =>
        call(apiCall, endpoints.getFileUploadUrl, {}, null, { public: !!isPublic}))
    );

    const totalProgress = [];
    let response;
    const allRequests = [];

    try {
        response = yield all(uploadData.map((it, i) =>
            call(function*() {
                const uploadPromise = new Promise(((resolve, reject) => {
                    totalProgress[i] = 0;
                    const response = () => {
                        if (request.status >= 200 && request.status < 300) {
                            resolve({
                                status: request.status,
                                ok: true,
                            });
                        } else {
                            reject({
                                status: request.status,
                                ok: false,
                            });
                            allRequests.forEach(it => it.abort());
                        }
                    }

                    const request = new XMLHttpRequest();

                    request.open('PUT', it.data[FILE_UPLOAD_PROPS.UPLOAD_URL]);
                    request.setRequestHeader('x-amz-tagging', 'unconfirmed=true');

                    request.upload.onprogress = e => {
                        totalProgress[i] = (e.loaded / e.total) * 100;
                        const newProgressValue = progressValue(totalProgress);
                        uploadChannel.put(setUploadProgress(inputName, newProgressValue));
                    };

                    request.upload.onloadend = () => {
                        totalProgress[i] = 100;
                        uploadChannel.put(setUploadProgress(inputName, progressValue(totalProgress)));
                        if (totalProgress.every(val => val === 100)) {
                            uploadChannel.put(setUploadFinished(inputName));
                        }
                    };

                    request.onerror = () => {
                        response();
                    }

                    request.onload = () => {
                        response();
                    }

                    request.send(files[i]);

                    allRequests.push(request);
                })).catch((e) => {
                    return e
                })

                const {uploadResult, timeout} = yield race ({
                    uploadResult: call(() => uploadPromise),
                    timeout: call(() => new Promise((resolve) => setTimeout(() =>
                        resolve(true), 900000))), // 15 minutes
                });

                if (timeout) {
                    allRequests.forEach(it => it.abort());
                    // slow internet connection
                    // eslint-disable-next-line
                    throw {
                        status: 0,
                        ok: false
                    }

                } else if (uploadResult.ok === false) {
                    // other errors
                    // eslint-disable-next-line
                    throw uploadResult
                }

                return uploadResult
            })
        ));

    } catch (rejectionError) {
        const {
            status,
            ok,
        } = rejectionError;

        // slow internet connection
        if (!ok && status === 0 && typeof formName === 'string') {
            if (files.length > 1) {
                toYield.push(updateInputField(formName, inputName, {error: {message: 'uploadFailed'}}));
            } else {
                toYield.push(updateInputField(formName, inputName, {error: {message: 'uploadFailedSingleFile'}}));
            }

            // server error
        } else {
            toYield.push(addNotification({
                type: NOTIFICATION_TYPE.ERROR,
                content: <FormattedMessage {...localeMessages.serverError} />,
            }));
            toYield.push({
                type: error || '',
                error,
            });
            toYield.push(setValidationError(formName, ''));
        }
    }

    if (files.length === 0 || (Array.isArray(response) && response.every(response => response.ok))) {
        toYield.push({
            type: success || '',
            data: {
                data: uploadData.map(item => item.data[FILE_UPLOAD_PROPS.FILE_KEY]),
            },
        });

        const fileIds = uploadData.map(item => item.data[FILE_UPLOAD_PROPS.FILE_KEY]);
        const doNotKeep = inputValue.get(FILE_INPUT_PROPS.DO_NOT_KEEP) || List();
        const oldValue = inputValue.get(FILE_INPUT_PROPS.IDS) || List();
        const newIdsValue = oldValue.filter(id => !doNotKeep.contains(id)).concat(List(fileIds));
        const newValue = Map({
            [FILE_INPUT_PROPS.IDS]: newIdsValue,
            [FILE_INPUT_PROPS.FILES]: List(),
        });

        successCallback(newValue);

        toYield.push(updateInputField(formName, inputName, { value: newValue }));
        toYield.push(setInitialValuesToCurrent(formName));
        document.getElementById(FILE_INPUT_ID_PREFIX + inputName).value = '';
    }

    if (typeof formName === 'string' && !doNotSetFormNotSubmitting) {
        toYield.push(setFormNotSubmitting(formName));
    }

    if (toYield.length) {
        yield all(toYield.map(act => put(act)));
    }
    uploadChannel.put(setUploadFinished(inputName));
}

const progressValue = progress =>
    Math.floor(progress.reduce((agg, cur, idx, arr) => { return (agg + cur) / arr.length }, 0));

function* fileUploadSaga() {
    yield takeEvery('FILE_UPLOAD', fileList);
}

export function* watchUploadChannel() {
    while (true) {
        const action = yield take(uploadChannel);
        yield put(action);
    }
}

export default fileUploadSaga
