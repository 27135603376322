import { Map } from 'immutable';
import React from "react";
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    PROPERTY_LOCATION_FORM_NAME,
    BOOKING_PAGE_PROPERTY_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
    intl,
) => ({
    formName: PROPERTY_LOCATION_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.SEASIDE,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.SEASIDE),
                    label: <FormattedMessage {...localeMessages.bySea} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.LAKESIDE,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.LAKESIDE),
                    label: <FormattedMessage {...localeMessages.byLake} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.RIVERSIDE,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.RIVERSIDE),
                    label: <FormattedMessage {...localeMessages.byRiver} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.CITY_CENTER,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.CITY_CENTER),
                    label: <FormattedMessage {...localeMessages.inCityCenter} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.MOUNTAIN,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.MOUNTAIN),
                    label: <FormattedMessage {...localeMessages.onMountain} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.URBAN,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.URBAN),
                    label: <FormattedMessage {...localeMessages.urban} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.RURAL,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.RURAL),
                    label: <FormattedMessage {...localeMessages.rural} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.BEACHFRONT,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.BEACHFRONT),
                    label: <FormattedMessage {...localeMessages.beachfront} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_BEACH,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_BEACH),
                    label: <FormattedMessage {...localeMessages.proximityToBeach} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_SKI_SLOPE,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_SKI_SLOPE),
                    label: <FormattedMessage {...localeMessages.proximityToSkiSlope} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_TOURIST_ATTRACTIONS,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_TOURIST_ATTRACTIONS),
                    label: <FormattedMessage {...localeMessages.proximityToTouristAttractions} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_SPORTING_GROUNDS,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_SPORTING_GROUNDS),
                    label: <FormattedMessage {...localeMessages.proximityToSportingGrounds} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_RESTAURANT,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_RESTAURANT),
                    label: <FormattedMessage {...localeMessages.proximityToRestaurant} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_SHOPPING,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_SHOPPING),
                    label: <FormattedMessage {...localeMessages.proximityToShopping} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_CITY_CENTER,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_CITY_CENTER),
                    label: <FormattedMessage {...localeMessages.proximityToCityCenter} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_PUBLIC_TRANSPORT,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_PUBLIC_TRANSPORT),
                    label: <FormattedMessage {...localeMessages.proximityToPublicTransport} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_AIRPORT,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_AIRPORT),
                    label: <FormattedMessage {...localeMessages.proximityToAirport} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
                {
                    type: 'number',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_TRAIN_STATION,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PROXIMITY_TO_TRAIN_STATION),
                    label: <FormattedMessage {...localeMessages.proximityToTrainStation} />,
                    validations: ['numberNotNegative', {validator: 'numberSmallerOrEqualTo', args: [1000]}],
                    suffix: <FormattedMessage {...localeMessages.inMeters} />,
                },
            ],
        },
    ],
})
