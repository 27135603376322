import { Map } from 'immutable';
import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    PROPERTY_DESCRIPTION_FORM_NAME,
    BOOKING_PAGE_PROPERTY_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: PROPERTY_DESCRIPTION_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'textArea',
                    name: BOOKING_PAGE_PROPERTY_PROPS.DESCRIPTION_EN,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.DESCRIPTION_EN),
                    label: <FormattedMessage {...localeMessages.english} />,
                    help: <FormattedMessage {...localeMessages.propertyDescriptionHelp} />,
                    maxLength: 560,
                    rows: 10,
                },
                {
                    type: 'textArea',
                    name: BOOKING_PAGE_PROPERTY_PROPS.DESCRIPTION_SR,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.DESCRIPTION_SR),
                    label: <FormattedMessage {...localeMessages.serbian} />,
                    help: <FormattedMessage {...localeMessages.propertyDescriptionHelp} />,
                    maxLength: 560,
                    rows: 10,
                },
                {
                    type: 'textArea',
                    name: BOOKING_PAGE_PROPERTY_PROPS.DESCRIPTION_CNR,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.DESCRIPTION_CNR),
                    label: <FormattedMessage {...localeMessages.montenegrin} />,
                    help: <FormattedMessage {...localeMessages.propertyDescriptionHelp} />,
                    maxLength: 560,
                    rows: 10,
                },
            ],
        },
    ]
})
