export default {
    getPublicFileUrlPrefix: {
        endpoint: 'file/public_url_prefix',
        method: 'GET',
    },
    getFileUploadUrl: {
        endpoint: 'file/upload_url?public',
        method: 'GET',
    },
}
