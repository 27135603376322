import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fromJS, List, Map, Iterable } from 'immutable';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';

import { generateKey } from 'utils';
import Modal, {
    hideModal, showModal,
} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';
import {
    Form,
} from 'eksenia-lib/src/Form';
import Tabs from 'eksenia-lib/src/Tabs';

import {
    RMS_ROOM_PROPS,
    RMS_EDIT_ROOM_FORM_NAME,
    RMS_ICAL_IMPORT_PROPS,
    RMS_EDIT_ROOM_MODAL_NAME,
    RMS_EDIT_ICAL_FORM_NAME,
    UNIT_PROPS, RMS_DELETE_ROOM_CONFIRMATION_NAME, EDIT_UNIT_MODAL_NAME,
} from './constants';
import {
    closeEditRoom,
    postRoom,
    updateRoom,
    deleteRoom,
} from './actions';
import editRoomForm from './editRoomForm';
// import iCalManagementForm from './iCalManagementForm';
import unitsForm from './unitsForm';
import localeMessages from './messages';
import Confirm from "eksenia-lib/src/Confirm";
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";
import {addNotification} from "eksenia-lib/src/Notifications";

const defaultUrlListId = generateKey();
export const defaultRoomData = {
    [RMS_ROOM_PROPS.NAME]: '',
    [RMS_ROOM_PROPS.UNIT_LIST]: [],
    [RMS_ROOM_PROPS.MAX_GUESTS]: 1,
    [RMS_ROOM_PROPS.MAX_ADAULTS]: 1,
    [RMS_ROOM_PROPS.MIN_PRICE]: 1,
    //[RMS_ROOM_PROPS.BLOCK_DAYS_AFTER_CHECK_OUT]: 0,
    [RMS_ROOM_PROPS.ICAL_IMPORT_URL_LIST]: [
        {
            [RMS_ICAL_IMPORT_PROPS.ICAL_CHANNEL_NAME]: '',
            [RMS_ICAL_IMPORT_PROPS.ICAL_IMPORT_URL]: '',
            id: defaultUrlListId,
        },
    ],
};

const formNames = [
    RMS_EDIT_ROOM_FORM_NAME,
    RMS_EDIT_ICAL_FORM_NAME,
];

export class EditRoom extends Component {

    constructor() {
        super(...arguments);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitICal = this.handleSubmitICal.bind(this);
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
        this.handleUnitClick = this.handleUnitClick.bind(this);
        this.handleAddNewUnit = this.handleAddNewUnit.bind(this);
    }

    handleAddNewUnit() {
        this.props.showModal(EDIT_UNIT_MODAL_NAME);
    }

    handleUnitClick(unitId) {
        this.props.showModal(unitId);
    }

    handleConfirmationClose() {
        this.props.hideModal();
    }

    handleConfirmDelete() {
        const {
            deleteRoom,
            editedRoom,
        } = this.props;

        deleteRoom(editedRoom.get(RMS_ROOM_PROPS.ID), this.deleteSuccessCallback);
    }

    deleteSuccessCallback() {
        const {
            editedRoom,
            addNotification,
        } = this.props;

        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage
                {...localeMessages.roomHasBeenDeleted}
                values={{roomName: editedRoom.get(RMS_ROOM_PROPS.NAME)}}
            />,
        });

        this.handleConfirmationClose();
        this.handleCloseModal();
    }

    handleCloseModal() {
        const {
            closeEditRoom,
            hideModal,
        } = this.props;

        closeEditRoom();
        hideModal();
    }

    handleSubmit(formValues, formName) {
        const {
            postRoom,
            editedRoom,
            updateRoom,
        } = this.props;

        let values = formValues.toJS();

        if (Iterable.isIterable(editedRoom)) {
            const idRoom = editedRoom.get(RMS_ROOM_PROPS.ID);
            const updatedValues = {
                ...editedRoom.toJS(),
                ...values,
            };

            updateRoom(idRoom, updatedValues, formName);

        } else {
            postRoom(values, formName);
        }
    }

    handleSubmitICal(formValues, formName) {
        const {
            updateUnits,
            units,
        } = this.props;
        const values = formValues.get(UNIT_PROPS.ICAL_IMPORT_URL_LIST);

        const toUpdate = values.map(value => {
            const currentUnit = units.find(unit => unit.get(UNIT_PROPS.ID) === value.get(UNIT_PROPS.ID));

            if (!Iterable.isIterable(currentUnit)) {
                return null
            }

            let iCalValues = value.get(UNIT_PROPS.ICAL_IMPORT_URL_LIST);
            if (Iterable.isIterable(iCalValues)) {
                iCalValues = iCalValues.map(entry => entry.delete('id'));
            }

            return currentUnit.merge({
                [UNIT_PROPS.ICAL_IMPORT_URL_LIST]: iCalValues,
                param: {
                    name: 'idUnit',
                    value: value.get(UNIT_PROPS.ID),
                }
            });
        }).filter(value => value).toJS();

        updateUnits([], toUpdate, [], formName);
    }

    prepareEditedRoom(editedRoom) {
        return editedRoom.updateIn([RMS_ROOM_PROPS.ICAL_IMPORT_URL_LIST],
            urlList => (urlList || List()).map(urlItem => Map({
                id: urlItem.get(RMS_ICAL_IMPORT_PROPS.ID) || urlItem.get('id'),
                value: urlItem,
            }))
        );
    }

    render() {
        const {
            editedRoom,
            units,
            intl,
            showModal,
            roomsCount,
        } = this.props;

        const isExistingRoom = Iterable.isIterable(editedRoom);

        const defaultFormData = isExistingRoom 
            ? this.prepareEditedRoom(editedRoom).set(RMS_ROOM_PROPS.UNIT_LIST, units)
            : fromJS(defaultRoomData);

        const tabs = [
            {
                defaultActive: true,
                id: 'details',
                label: <FormattedMessage {...localeMessages.details} />,
                name: 'details',
                withDelete: isExistingRoom,
                content: (
                    <Form
                        key="details"
                        blueprint={editRoomForm(defaultFormData, intl)}
                        onSubmit={this.handleSubmit}
                    />
                ),
            },
            {
                id: 'units',
                label: <FormattedMessage {...localeMessages.units} />,
                name: 'units',
                content: (
                    <Form
                        key="iCal"
                        blueprint={unitsForm(
                            defaultFormData,
                            intl,
                            this.handleUnitClick,
                            this.handleAddNewUnit
                        )}
                    />
                ),
                enabled: isExistingRoom,
            },
            // {
            //     id: 'iCal',
            //     label: <FormattedMessage {...localeMessages.iCalManagement} />,
            //     name: 'iCal',
            //     content: (
            //         <Form
            //             key="units"
            //             blueprint={iCalManagementForm(defaultFormData)}
            //             onSubmit={this.handleSubmitICal}
            //         />
            //     ),
            //     withDelete: isExistingRoom,
            //     enabled: isExistingRoom,
            // },
        ];

        const headerText = isExistingRoom
            ? <FormattedMessage {...localeMessages.editRoom} values={{roomName: editedRoom.get(RMS_ROOM_PROPS.NAME)}} />
            : <FormattedMessage {...localeMessages.newRoom} />;

        const onlyRoom = roomsCount === 1;
        const deletable = isExistingRoom && editedRoom.get(RMS_ROOM_PROPS.CAN_DELETE) && !onlyRoom;

        return (
            <ModalWithForm
                id={RMS_EDIT_ROOM_MODAL_NAME}
                headerText={headerText}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={formNames}
            >
                <Tabs
                    groupName="edit-room"
                    tabs={tabs}
                    activeForms={formNames}
                    onDelete={() => showModal(RMS_DELETE_ROOM_CONFIRMATION_NAME)}
                    disableDelete={!deletable}
                    deleteDisabledTooltip={
                        onlyRoom
                        ? <FormattedMessage {...localeMessages.atLeastOneRoom} />
                        : <FormattedMessage {...localeMessages.cannotDeleteRoom} />
                    }
                />

                <Confirm
                    id={RMS_DELETE_ROOM_CONFIRMATION_NAME}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleConfirmDelete}
                >
                    <FormattedMessage {...localeMessages.deleteRoomConfirmation} />
                </Confirm>
            </ModalWithForm>
        );
    }
}

const mapStateToProps = (store) => {
    const roomsReducer = store.roomsReducer;
    const formReducer = store.formReducer;
    const editedRoom = roomsReducer.get('editedRoom', Map());
    const units = Iterable.isIterable(editedRoom)
        ? roomsReducer.get('units', List()).filter(unit =>
            unit.get(UNIT_PROPS.ROOM_ID) === editedRoom.get(RMS_ROOM_PROPS.ID)
        )
        : List();

    return {
        editedRoom,
        units,
        roomsCount: roomsReducer.get('rooms', List()).size,
        form: formReducer.get(RMS_EDIT_ROOM_FORM_NAME),
        iCalForm: formReducer.get(RMS_EDIT_ICAL_FORM_NAME),
    }
};

const mapDispatchToProps = {
    closeEditRoom,
    postRoom,
    updateRoom,
    hideModal,
    deleteRoom,
    showModal,
    addNotification,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditRoom))
