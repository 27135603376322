import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
} from 'react-intl';

import { composeClassName } from '../utils';
import Confirm from '../Confirm';
import { hideModal } from '../Modal';
import {
    leaveForm,
    CANCEL_FORM_CONFIRMATION_NAME,
} from '../Form';
import {
    setActiveTab,
    removeTabGroup,
    setPendingTab,
} from './actions';
import localeMessages from './messages';
import Button from "../Button";
import Icon from "../Icon";

export class Tabs extends Component {

    constructor() {
        super(...arguments);

        this.handleConfirmChangeTab = this.handleConfirmChangeTab.bind(this);
    }

    componentDidMount() {
        const {
            setActiveTab,
            tabs,
            groupName,
        } = this.props;

        const defaultActiveTab = tabs.find(tab => tab.defaultActive);

        if (defaultActiveTab) {
            setActiveTab(groupName, defaultActiveTab.id)
        }
    }

    componentWillUnmount() {
        const {
            removeTabGroup,
            groupName,
        } = this.props;

        removeTabGroup(groupName)
    }

    handleTabClick(groupName, id) {
        const {
            activeForms,
            leaveForm,
            setActiveTab,
            setPendingTab,
        } = this.props;

        const setTab = setActiveTab.bind(this, groupName, id);
        const setPending = setPendingTab.bind(this, groupName, id);

        if (Array.isArray(activeForms)) {
            leaveForm(activeForms, groupName, setTab, setPending);
        } else {
            setTab();
        }
    }

    handleConfirmChangeTab() {
        const {
            setActiveTab,
            hideModal,
            pendingTab,
            groupName,
            setPendingTab,
        } = this.props;

        setActiveTab(groupName, pendingTab);
        hideModal();
        setPendingTab(groupName, undefined);
    }

    render() {
        const {
            tabs,
            activeTab,
            groupName,
            hideModal,
            onDelete,
            formatters = [],
            actions,
            withDelete,
            controlledActiveTab,
            add,
            disableDelete,
            deleteDisabledTooltip,
        } = this.props;

        const activeTabValue = tabs.find(tab => tab.id === controlledActiveTab || tab.id === activeTab);
        const activeTabContent = activeTabValue
            ? activeTabValue.content
            : false;
        const showWithDelete = activeTabValue && activeTabValue.withDelete
            ? activeTabValue.withDelete
            : withDelete || false;
        const actionsContent = activeTabValue && activeTabValue.actions !== undefined
            ? activeTabValue.actions
            : actions || false;

        return (
            <div className="tabs">
                <div className={composeClassName('tabs-menu', ...formatters)}>
                    {tabs.map(tab => {
                        const { 
                            id,
                            label,
                            enabled = true,
                            className,
                            name,
                        } = tab;

                        const classes = composeClassName(
                            'tab',
                            (id === controlledActiveTab || id === activeTab) && 'active-tab',
                            !enabled && 'disabled',
                            className,
                            ...formatters
                        );

                        return (
                            <div
                                className={classes}
                                onClick={this.handleTabClick.bind(this, groupName, id)}
                                key={id}
                                name={name}
                            >
                                <span className="tab-label">
                                    {label}
                                </span>
                            </div>
                        )
                    })}

                    {add &&
                        <div className="tabs-add">
                            {add}
                        </div>
                    }
                </div>

                <div className={composeClassName('tab-content', ...formatters)}>
                    <div className={composeClassName('tab-inner-content', ...formatters)}>
                        {showWithDelete &&
                            <div
                                className="tab-delete-container"
                            >
                                <Button
                                    aria-labelledby="tab-delete-button"
                                    onClick={onDelete}
                                    formatters={[Button.TYPE.DELETE_LINK]}
                                    disabled={disableDelete}
                                >
                                    <Icon
                                        name="bin"
                                        type="delete"
                                    />
                                    <FormattedMessage {...localeMessages.delete} />
                                </Button>

                                {disableDelete && !!deleteDisabledTooltip &&
                                    <div role="tooltip" id="tab-delete-button">
                                        {deleteDisabledTooltip}
                                    </div>
                                }
                            </div>
                        }
                        {actionsContent &&
                            <div className="tab-actions-container">
                                {actionsContent}
                            </div>
                        }
                        {activeTabContent}
                    </div>
                </div>

                <Confirm
                    id={`${groupName}_${CANCEL_FORM_CONFIRMATION_NAME}`}
                    onCancel={hideModal}
                    onCancelText={<FormattedMessage {...localeMessages.goBack} />}
                    onConfirm={this.handleConfirmChangeTab}
                    onConfirmText={<FormattedMessage {...localeMessages.continue} />}
                >
                    <FormattedMessage {...localeMessages.unsavedChanges} />
                </Confirm>
            </div>
        )
    }
}

const mapStateToProps = (state, { groupName }) => {
    const tabsReducer = state.tabsReducer;

    return {
        activeTab: tabsReducer.getIn([groupName, 'activeTab']),
        pendingTab: tabsReducer.getIn([groupName, 'pendingTab']),
    }
};

const mapDispatchToProps = {
    setActiveTab,
    removeTabGroup,
    leaveForm,
    hideModal,
    setPendingTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)
