import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Iterable, List} from 'immutable';
import {FormattedMessage, injectIntl,} from 'react-intl';

import {Form,} from 'eksenia-lib/src/Form';
import Page from 'eksenia-lib/src/Page';
import Tabs from 'eksenia-lib/src/Tabs';
import Table from 'eksenia-lib/src/Table';
import Button from 'eksenia-lib/src/Button';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';
import Modal, {hideModal, showModal,} from 'eksenia-lib/src/Modal';
import {closeEditRoom, openEditRoom, RMS_ROOM_PROPS,} from "Rooms";
import {patchProperty, PROPERTY_PROPS,} from 'Properties';
import RoomBookingSettings from './RoomBookingSettings';
import bookingPageSettingsForm from "./bookingPageSettingsForm";
import {
    BATHROOM_AMENITIES_FORM_NAME,
    BOOKING_PAGE_PROPERTY_PROPS,
    BOOKING_PAGE_ROOM_FORM_NAME,
    BOOKING_PAGE_ROOM_SETTINGS_MODAL,
    BOOKING_PAGE_SETTINGS_FORM_NAME,
    FOOD_AND_DRINK_AMENITIES_FORM_NAME,
    MEDIA_AMENITIES_FORM_NAME,
    MISCELLANEOUS_AMENITIES_FORM_NAME,
    POPULAR_AMENITIES_FORM_NAME,
    PROPERTY_AMENITIES_FORM_NAME,
    PROPERTY_LOCATION_FORM_NAME,
    SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME,
} from "./constants";
import propertyAmenitiesForm from "./propertyAmenitiesForm";
import propertyLocationForm from "./propertyLocationForm";
import localeMessages from './messages';
import {countryNamesToOptions} from "../locale/utils";
import propertyDescriptionForm from "./propertyDescriptionForm";
import {FILE_INPUT_PROPS} from "eksenia-lib/src/File/constants";
import Icon from "eksenia-lib/src/Icon";

const formNames = [
    BOOKING_PAGE_ROOM_FORM_NAME,
    POPULAR_AMENITIES_FORM_NAME,
    MISCELLANEOUS_AMENITIES_FORM_NAME,
    BATHROOM_AMENITIES_FORM_NAME,
    MEDIA_AMENITIES_FORM_NAME,
    FOOD_AND_DRINK_AMENITIES_FORM_NAME,
    SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME,
    PROPERTY_AMENITIES_FORM_NAME,
];

const roomTableHeader = [
    {content: localeMessages.name, key: 'name',},
];

export class BookingPage extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveSettings = this.handleSaveSettings.bind(this);
        this.handleSaveAmenities = this.handleSaveAmenities.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCopyBookingPageLocation = this.handleCopyBookingPageLocation.bind(this);
        this.handleOpenBookingPage = this.handleOpenBookingPage.bind(this);

        this.state = {
            copied: false,
        };
    }

    handleSaveAmenities(formValues, formName) {
        const {
            patchProperty,
            properties,
        } = this.props;

        const property = properties.get('0');

        patchProperty(
            property.get(PROPERTY_PROPS.ID),
            {
                "op_list": [{
                    op: 'replace',
                    path: `/${PROPERTY_PROPS.AMENITIES}`,
                    value: property.get(PROPERTY_PROPS.AMENITIES).merge(formValues),
                }],
            },
            formName
        )
    }

    handleSaveSettings(formValues, formName) {
        const {
            patchProperty,
            properties,
        } = this.props;

        const property = properties.get('0');

        patchProperty(
            property.get(PROPERTY_PROPS.ID),
            {
                "op_list": [
                    {
                        op: 'replace',
                        path: `/${PROPERTY_PROPS.BOOKING_PAGE_ACTIVE}`,
                        value: formValues.get(PROPERTY_PROPS.BOOKING_PAGE_ACTIVE) || false,
                    }, {
                        op: 'replace',
                        path: `/${PROPERTY_PROPS.CITY}`,
                        value: formValues.get(PROPERTY_PROPS.CITY) || "",
                    }, {
                        op: 'replace',
                        path: `/${PROPERTY_PROPS.COUNTRY}`,
                        value: formValues.get(PROPERTY_PROPS.COUNTRY),
                    }, {
                        op: 'replace',
                        path: `/${PROPERTY_PROPS.AMENITIES}`,
                        value: property.get(PROPERTY_PROPS.AMENITIES).merge({
                            [BOOKING_PAGE_PROPERTY_PROPS.DISPLAY_NAME]:
                                formValues.get(BOOKING_PAGE_PROPERTY_PROPS.DISPLAY_NAME),
                            [BOOKING_PAGE_PROPERTY_PROPS.ADDRESS]:
                                formValues.get(BOOKING_PAGE_PROPERTY_PROPS.ADDRESS),
                            [BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_MAIN_IMAGE]:
                                formValues.getIn([
                                    BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_MAIN_IMAGE,
                                    FILE_INPUT_PROPS.IDS,
                                    0
                                ]),
                            [BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_IMAGES]:
                                formValues.getIn([
                                    BOOKING_PAGE_PROPERTY_PROPS.BOOKING_PAGE_IMAGES,
                                    FILE_INPUT_PROPS.IDS
                                ]).toJS(),
                            [BOOKING_PAGE_PROPERTY_PROPS.AVAILABILITY_RULE]:
                                formValues.get(BOOKING_PAGE_PROPERTY_PROPS.AVAILABILITY_RULE),
                            [BOOKING_PAGE_PROPERTY_PROPS.EMAIL]:
                                formValues.get(BOOKING_PAGE_PROPERTY_PROPS.EMAIL),
                            [BOOKING_PAGE_PROPERTY_PROPS.PHONE_NUMBER]:
                                formValues.get(BOOKING_PAGE_PROPERTY_PROPS.PHONE_NUMBER),
                            [BOOKING_PAGE_PROPERTY_PROPS.WEBSITE]:
                                formValues.get(BOOKING_PAGE_PROPERTY_PROPS.WEBSITE),
                        }),
                    },
                ],
            },
            formName
        )
    }

    handleCloseModal() {
        const {
            hideModal,
            closeEditRoom,
        } = this.props;

        hideModal();
        closeEditRoom();
    }

    handleEditRoomSettings(room) {
        const {
            showModal,
            openEditRoom,
        } = this.props;

        openEditRoom(room);
        showModal(BOOKING_PAGE_ROOM_SETTINGS_MODAL)
    }

    handleCopyBookingPageLocation() {
        const {
            properties,
        } = this.props;
        const uri = properties.getIn(['0', PROPERTY_PROPS.BOOKING_PAGE_URI]);

        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(uri).then(() => {
                this.setCopied();
            });

        } else {
            const textArea = document.createElement('textarea');
            textArea.style.position = 'fixed';
            textArea.style.top = '-2000px';
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.value = uri;

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
                this.setCopied();
            } catch (e) {
                console.log(e)
            }

            document.body.removeChild(textArea);
        }
    }

    handleOpenBookingPage() {
        const {
            properties,
        } = this.props;
        const uri = properties.getIn(['0', PROPERTY_PROPS.BOOKING_PAGE_URI]);

        window.open(uri, '_blank');
    }

    setCopied() {
        this.setState({
            copied: true,
        });
        window.setTimeout(() => {
            this.setState({
                copied: false,
            })
        }, 3000);
    }

    render() {
        const {
            rooms,
            properties,
            editedRoom,
            intl,
            countryNamesList,
        } = this.props;

        if (properties.size === 0) {
            return null
        }

        const countryOptions = countryNamesToOptions(countryNamesList);

        const editedRoomName = Iterable.isIterable(editedRoom)
            ? editedRoom.get(RMS_ROOM_PROPS.NAME)
            : '';

        const rows = rooms.map(room => ({
            key: room.get(RMS_ROOM_PROPS.ID),
            onClick: this.handleEditRoomSettings.bind(this, room),
            cells: [{
                content: room.get(RMS_ROOM_PROPS.NAME),
                key: 'name',
            }],
        }));

        const tabs = [
            {
                defaultActive: true,
                id: 'property',
                label: <FormattedMessage {...localeMessages.propertySettings} />,
                content: (
                    <Form
                        key="booking-page-settings"
                        blueprint={bookingPageSettingsForm(properties.get('0'), intl, countryOptions)}
                        onSubmit={this.handleSaveSettings}
                    />
                ),
            },
            {
                id: 'description',
                label: <FormattedMessage {...localeMessages.propertyDescription} />,
                content: (
                    <Form
                        key="property-description"
                        blueprint={propertyDescriptionForm(properties.getIn(['0', PROPERTY_PROPS.AMENITIES]))}
                        onSubmit={this.handleSaveAmenities}
                    />
                )
            },
            {
                id: 'amenities',
                label: <FormattedMessage {...localeMessages.propertyAmenities} />,
                content: (
                    <Form
                        key="property-amenities"
                        blueprint={propertyAmenitiesForm(properties.getIn(['0', PROPERTY_PROPS.AMENITIES]), intl)}
                        onSubmit={this.handleSaveAmenities}
                    />
                ),
            },
            {
                id: 'location',
                label: <FormattedMessage {...localeMessages.propertyLocation} />,
                content: (
                    <Form
                        key="property-location"
                        blueprint={propertyLocationForm(properties.getIn(['0', PROPERTY_PROPS.AMENITIES]), intl)}
                        onSubmit={this.handleSaveAmenities}
                    />
                ),
            },
            {
                id: 'rooms',
                label: <FormattedMessage {...localeMessages.rooms} />,
                content: <Table header={roomTableHeader} rows={rows}/>,
                actions: false,
            }
        ];

        return (
            <Page>
                <Tabs
                    groupName="edit-booking-page"
                    formatters={["edit-booking-page"]}
                    tabs={tabs}
                    activeForms={[
                        BOOKING_PAGE_SETTINGS_FORM_NAME,
                        PROPERTY_AMENITIES_FORM_NAME,
                        PROPERTY_LOCATION_FORM_NAME
                    ]}
                    actions={(
                        <div>
                            <div>
                                <Button
                                    disabled={!properties.getIn(['0', PROPERTY_PROPS.BOOKING_PAGE_ACTIVE])}
                                    onClick={this.handleOpenBookingPage}
                                    formatters={[Button.TYPE.SECONDARY]}
                                >
                                    <FormattedMessage {...localeMessages.goToBookingPage} />
                                </Button>
                            </div>

                            <div className="action-button-container">
                                <Button
                                    disabled={!properties.getIn(['0', PROPERTY_PROPS.BOOKING_PAGE_ACTIVE])}
                                    onClick={this.handleCopyBookingPageLocation}
                                    formatters={[Button.TYPE.LINK, Button.SIZE.SMALL]}
                                    success={this.state.copied}
                                >
                                    <Icon type="copy" name="copy"/>
                                    <FormattedMessage {...localeMessages.copyBookingPageLocation} />
                                    {this.state.copied && <span className="success-icon"/>}
                                </Button>
                            </div>
                        </div>
                    )}
                />

                <ModalWithForm
                    id={BOOKING_PAGE_ROOM_SETTINGS_MODAL}
                    key={BOOKING_PAGE_ROOM_SETTINGS_MODAL}
                    headerText={<FormattedMessage {...localeMessages.roomSettings} values={{editedRoomName}}/>}
                    formatters={[Modal.SIZE.BIG]}
                    onClose={this.handleCloseModal}
                    activeForms={formNames}
                >
                    <RoomBookingSettings room={editedRoom}/>
                </ModalWithForm>
            </Page>
        )
    }
}

const mapStateToProps = store => {
    const roomsReducer = store.roomsReducer;
    const propertiesReducer = store.propertiesReducer;
    const localeReducer = store.localeReducer;

    return {
        rooms: roomsReducer.get('rooms') || List(),
        editedRoom: roomsReducer.get('editedRoom'),
        properties: propertiesReducer.get('properties') || List(),
        countryNamesList: localeReducer.get('countryNamesList') || List(),
    }
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    patchProperty,
    openEditRoom,
    closeEditRoom,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BookingPage))
