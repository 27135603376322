import { fromJS } from 'immutable';

import { reduceWithDefault } from 'utils';

import {
    ADD_EDITED_GUEST,
    CLEAR_EDITED_GUEST,
    GET_GUESTS_SUCCESS,
    POST_GUEST_SUCCESS,
    GET_GUEST_SUCCESS,
    GET_GUEST_RESERVATIONS_SUCCESS,
    CLEAR_GUEST_RESERVATIONS,
    GUEST_RESERVATIONS_PROPS,
    GUEST_PROPS,
    CLEAR_ALL_EDITED_GUESTS,
    SEARCH_GUESTS_SUCCESS,
    SEARCH_GUESTS_REQUEST, CLEAR_SEARCH_GUESTS_RESULT,
} from './constants';
import {mapReservationsValues} from "../Reservations";

const initialState = fromJS({
    editedGuests: {},
    guests: [],
    guestReservations: {},
    searchGuestsResult: null,
});

const reducers = {
    [GET_GUESTS_SUCCESS]: (state, { data }) =>
        state.merge({
            guests: fromJS(data || []),
        }),

    [POST_GUEST_SUCCESS]: (state, { data }) => {
        const editedGuest = fromJS(data);
        return updateEditedGuest(state, editedGuest);
    },

    [GET_GUEST_SUCCESS]: (state, { data }) => {
        const editedGuest = fromJS(data);
        return updateEditedGuest(state, editedGuest);
    },

    [ADD_EDITED_GUEST]: (state, { editedGuest }) =>
        updateEditedGuest(state, editedGuest),

    [CLEAR_EDITED_GUEST]: (state, { guestId }) => {
        const count = state.getIn(['editedGuests', guestId, 'count'], 1) - 1;
        return count === 0
            ? state.deleteIn(['editedGuests', guestId])
            : state.setIn(['editedGuests', guestId, 'count'], count);
    },

    [CLEAR_ALL_EDITED_GUESTS]: state =>
        state.merge({
            editedGuests: initialState.get('editedGuests'),
        }),

    [GET_GUEST_RESERVATIONS_SUCCESS]: (state, { data }) =>
        state.merge({
            guestReservations: state.get('guestReservations')
                .set(data[GUEST_RESERVATIONS_PROPS.GUEST_ID],
                    mapReservationsValues(fromJS(data[GUEST_RESERVATIONS_PROPS.RESERVATION_LIST]))),
        }),

    [CLEAR_GUEST_RESERVATIONS]: (state, { guestId }) =>
        state.deleteIn(['guestReservations', guestId]),

    [SEARCH_GUESTS_REQUEST]: (state) =>
        state.set("searchGuestsResult", initialState.get('searchGuestsResult')),

    [SEARCH_GUESTS_SUCCESS]: (state, { data }) =>
        state.set("searchGuestsResult", fromJS(data || [])),

    [CLEAR_SEARCH_GUESTS_RESULT]: (state) =>
        state.set("searchGuestsResult", initialState.get('searchGuestsResult')),
};

const updateEditedGuest = (state, editedGuest) => {
    const guestId = editedGuest.get(GUEST_PROPS.ID);
    return state.mergeIn(['editedGuests', guestId], {
        count: state.getIn(['editedGuests', guestId, 'count'], 0) + 1,
        editedGuest,
    })
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);

