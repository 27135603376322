import { Iterable, Map } from 'immutable';
import {
    FormattedMessage,
} from 'react-intl';

import {
    RATE_PROPS,
    RATE_ROOMS_FORM_NAME,
    RATE_ROOM_PROPS,
} from './constants';
import localeMessages from './messages';
import React from "react";
import {INPUT_FORMATTERS} from "eksenia-lib/src/formatters";

export default (initialValues) => ({
    formName: RATE_ROOMS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    className: 'rate-rooms-form',
    fields: [
        {
            type: 'section',
            name: 'rooms',
            fields: [
                {
                    type: 'inputMatrix',
                    name: RATE_PROPS.ROOMS,
                    initialValue: initialValues.get(RATE_PROPS.ROOMS),
                    formatValue: value => Iterable.isIterable(value)
                        ? value.map((item = Map()) =>
                            item.set(RATE_ROOM_PROPS.MULTIPLIER, parseFloat(item.get(RATE_ROOM_PROPS.MULTIPLIER || 1)))
                        )
                        : value,
                    headers: [
                        {
                            id: 'label',
                            text: <FormattedMessage {...localeMessages.room} />,
                        },
                        {
                            id: RATE_ROOM_PROPS.USED,
                            text: <FormattedMessage {...localeMessages.use} />,
                            help: <FormattedMessage {...localeMessages.roomsUseTooltip} />,
                        },
                        {
                            id: RATE_ROOM_PROPS.MULTIPLIER,
                            text: <FormattedMessage {...localeMessages.multiplier} />,
                            help: <FormattedMessage {...localeMessages.multiplierTooltip} />,
                        },
                    ],
                    inputs: {
                        id: (listName, index, value) =>
                            value.get(RATE_ROOM_PROPS.ID) || value.get(RATE_ROOM_PROPS.ROOM_ID),
                        fields: [
                            {
                                type: 'label',
                            },
                            {
                                type: 'checkbox',
                                name: RATE_ROOM_PROPS.USED,
                            },
                            {
                                type: 'number',
                                name: RATE_ROOM_PROPS.MULTIPLIER,
                            },
                        ],
                    },
                },
            ],
        },
    ],
})

