import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fromJS, List, Map} from 'immutable';
import {FormattedMessage, injectIntl,} from 'react-intl';
import Modal, {hideModal, showModal} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';
import {Form,} from 'eksenia-lib/src/Form';
import Tabs from 'eksenia-lib/src/Tabs';
import {postRoom, RMS_EDIT_ROOM_FORM_NAME,} from "../Rooms";
import localeMessages from "./messages";
import {ONBOARDING_MODAL_NAME} from "./constants";
import {getTimezones, patchProperty, PROPERTY_PROPS, PROPERTY_SETTINGS_FORM, } from "../Properties";
import editRoomForm from "../Rooms/editRoomForm";
import {defaultRoomData} from "../Rooms/EditRoom";
import propertySettingsForm from "../Properties/propertySettingsForm";
import {countryNamesToOptions, currenciesToOptions, timezonesToOptions} from "../locale/utils";
import {mapPropertyForPatch} from "../Properties/utils";
import SettingsMenu from "../Header/SettingsMenu";
import {addEditedTaxOrFee, EDIT_TAX_OR_FEE_MODAL_NAME, postTaxOrFee} from "../TaxesAndFees";
import onboardingTaxesAndFeesForm from "./onboardingTaxesAndFeesForm";

const formNames = [
    RMS_EDIT_ROOM_FORM_NAME,
    PROPERTY_SETTINGS_FORM,
];

class Onboarding extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveProperty = this.handleSaveProperty.bind(this);
        this.handleSaveRoom = this.handleSaveRoom.bind(this);
        this.handleSaveTaxOrFee = this.handleSaveTaxOrFee.bind(this);
        this.handleFinishTaxesAndFees = this.handleFinishTaxesAndFees.bind(this);
        this.handleTaxOrFeeClick = this.handleTaxOrFeeClick.bind(this);
        this.handleAddNewTaxOrFee = this.handleAddNewTaxOrFee.bind(this);

        this.state = {
            taxesAndFeesDone: false,
            propertyDone: false,
        };
    }

    componentDidMount() {
        const {
            showModal,
            getTimezones,
        } = this.props;

        showModal(ONBOARDING_MODAL_NAME);
        getTimezones()
    }

    handleSaveProperty(formValues, formName) {
        const {
            patchProperty,
            currentProperty,
        } = this.props;

        patchProperty(
            currentProperty.get(PROPERTY_PROPS.ID),
            mapPropertyForPatch(formValues),
            formName
        );
        this.setState({
            propertyDone: true,
        });
    }

    handleSaveTaxOrFee(formValues, formName) {
        const {
            hideModal,
            postTaxOrFee,
        } = this.props;

        postTaxOrFee(formValues, formName, hideModal);
    }

    handleFinishTaxesAndFees() {
        this.setState({
            taxesAndFeesDone: true,
        });
    }

    handleSaveRoom(formValues, formName) {
        const {
            hideModal,
            postRoom,
        } = this.props;

        let values = formValues.toJS();
        postRoom(values, formName, hideModal);
    }

    handleTaxOrFeeClick(id, taxOrFee) {
        const {
            showModal,
            addEditedTaxOrFee,
        } = this.props;

        addEditedTaxOrFee(fromJS(taxOrFee));
        showModal(id);
    }

    handleAddNewTaxOrFee() {
        const {
            showModal,
        } = this.props;

        showModal(EDIT_TAX_OR_FEE_MODAL_NAME);
    }

    render() {
        const {
            intl,
            countryNamesList,
            timezones,
            currentProperty,
            taxesAndFees,
        } = this.props;

        const {
            taxesAndFeesDone,
            propertyDone,
        } = this.state;

        const propertySetUp = !!currentProperty.get(PROPERTY_PROPS.NAME);
        const hasTaxesAndFees = taxesAndFees.size > 0;
        let activeTab = 'property';
        if (propertySetUp) {
            // show rooms if the user clicked on Done for taxes and fees
            // or if there are some taxes and fees set up but the property was not set up in this session
            activeTab = taxesAndFeesDone || (!propertyDone && hasTaxesAndFees)
                ? 'room' : 'taxesAndFees';
        }

        const countryOptions = countryNamesToOptions(countryNamesList);
        const currencyOptions = currenciesToOptions(intl);
        const timezoneOptions = timezonesToOptions(timezones.toJS());

        const tabs = [
            {
                id: 'property',
                label: <FormattedMessage {...localeMessages.aboutProperty} />,
                name: 'property',
                withDelete: false,
                enabled: activeTab === 'property',
                content: (
                    <>
                        <div className="onboarding-tab-title">
                            <FormattedMessage {...localeMessages.propertyStart} />
                        </div>
                        <Form
                            key="property-settings"
                            blueprint={propertySettingsForm(
                                currentProperty,
                                intl,
                                countryOptions,
                                currencyOptions,
                                timezoneOptions
                            )}
                            onSubmit={this.handleSaveProperty}
                        />
                    </>
                ),
            },
            {
                id: 'taxesAndFees',
                label: <FormattedMessage {...localeMessages.taxesAndFees} />,
                name: 'taxesAndFees',
                withDelete: false,
                enabled: activeTab === 'taxesAndFees',
                content: (
                    <>
                        <div className="onboarding-tab-title">
                            <FormattedMessage {...localeMessages.taxesAndFeesStart} />
                        </div>
                        <Form
                            key="edit-taxes-and-fees"
                            blueprint={onboardingTaxesAndFeesForm(this.handleTaxOrFeeClick, this.handleAddNewTaxOrFee)}
                            onSubmit={this.handleFinishTaxesAndFees}
                        />
                    </>
                ),
            },
            {
                id: 'room',
                label: <FormattedMessage {...localeMessages.firstRoom} />,
                name: 'room',
                withDelete: false,
                enabled: activeTab === 'room',
                content: (
                    <>
                        <div className="onboarding-tab-title">
                            <FormattedMessage {...localeMessages.roomStart} />
                        </div>
                        <Form
                            key="edit-room"
                            blueprint={editRoomForm(fromJS(defaultRoomData), intl)}
                            onSubmit={this.handleSaveRoom}
                        />
                    </>
                ),
            },
        ];

        return (
            <ModalWithForm
                id={ONBOARDING_MODAL_NAME}
                headerText={<FormattedMessage {...localeMessages.title} />}
                formatters={[Modal.SIZE.BIG]}
                activeForms={formNames}
                hideClose
            >
                <Tabs
                    groupName="onboarding"
                    tabs={tabs}
                    activeForms={formNames}
                    formatters={['onboarding']}
                    controlledActiveTab={activeTab}
                    add={<SettingsMenu />}
                />
            </ModalWithForm>
        )
    }
}

const mapStateToProps = (store) => {
    const propertiesReducer = store.propertiesReducer;
    const localeReducer = store.localeReducer;
    const taxesAndFeesReducer = store.taxesAndFeesReducer;

    return {
        currentProperty: propertiesReducer.get('currentProperty') || Map(),
        loadingProperties: propertiesReducer.get('loadingProperties'),
        timezones: propertiesReducer.get('timezones') || List(),
        countryNamesList: localeReducer.get('countryNamesList') || List(),
        taxesAndFees: taxesAndFeesReducer.get('taxesAndFees') || List(),
        finishedLoadingTaxesAndFees: taxesAndFeesReducer.get('finishedLoading') || List(),
    }
};

const mapDispatchToProps = {
    hideModal,
    postRoom,
    patchProperty,
    showModal,
    getTimezones,
    postTaxOrFee,
    addEditedTaxOrFee,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Onboarding))