import React, { Component } from 'react';

import { composeClassName } from '../utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import {
    handleChange,
} from '../Form';
import Tooltip from "../Tooltip";

export default class TextArea extends Component {

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            formatters = [],
            error,
            help,
            submitting,
            ...props
        } = this.props;

        return (
            <div
                className={composeClassName('input-textarea-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}
                <textarea
                    className={composeClassName('input-textarea', ...formatters)}
                    onChange={handleChange.bind(this, this)}
                    maxLength={5000}
                    { ...props }
                    disabled={props.disabled || props.submitting}
                />

                {help && <Tooltip help={help} />}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        );
    }
}
