import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    RESERVATION_PROPS,
    RESERVATION_NOTES_FORM_NAME,
} from './constants';
import localeMessages from './messages';

export default (initialValues = {}) => ({
    formName: RESERVATION_NOTES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'notes',
            fields: [
                {
                    type: 'textArea',
                    name: RESERVATION_PROPS.NOTES,
                    initialValue: initialValues.get(RESERVATION_PROPS.NOTES),
                    label: <FormattedMessage {...localeMessages.notes} />,
                    rows: 4,
                },
            ],
        },
    ],
})

