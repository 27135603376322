import { call, put, takeEvery, all } from 'redux-saga/effects';
import React from 'react';
import {
    FormattedMessage,
} from "react-intl";

import localeMessages from './messages';
import {
    setInitialValuesToCurrent,
    setValidationError,
    setFormSubmitting,
    setFormNotSubmitting,
} from '../Form';
import {
    addNotification,
} from '../Notifications';

import apiCall from './apiCall';
import {NOTIFICATION_TYPE} from "../Notifications/constants";

function* callApi(action) {
    const {
        actions: {
            request,
            success,
            successCallback,
            error,
            successActions,
        } = {},
        body,
        payload,
        params,
        endpoint,
        formName,
        entityFormNames,
        queryParams,
        noErrorNotification,
    } = action;

    if (request) {
        yield put({
            type: request || '',
            body,
            payload,
        });
    }

    if (typeof formName === 'string') {
        yield put(setFormSubmitting(formName));
    }

    try {
        const response = yield call(apiCall, endpoint, params, body, queryParams);

        const {
            data,
            ok,
            status,
        } = response;

        let toYield = [];

        // server error
        if (status >= 500) {
            toYield.push(addNotification({
                type: NOTIFICATION_TYPE.ERROR,
                content: <FormattedMessage {...localeMessages.serverError} />,
            }));
            toYield.push({
                type: error || '',
                error,
                payload,
            });
            toYield.push(setValidationError(formName, ''));

        // success
        } else if (ok) {
            if (Array.isArray(entityFormNames)) {
                for (let name of entityFormNames) {
                    toYield.push(setInitialValuesToCurrent(name));
                }
            } else if (typeof formName === 'string') {
                toYield.push(setInitialValuesToCurrent(formName));

            }

            toYield.push({
                type: success || '',
                data: data,
                payload,
            });

        // validation error
        } else {
            if (typeof data === 'string') {
                toYield.push(addNotification({
                    type: NOTIFICATION_TYPE.ERROR,
                    content: <FormattedMessage {...localeMessages.serverError} />,
                }));
                toYield.push({
                    type: error || '',
                    error,
                    payload,
                });
                toYield.push(setValidationError(formName, ''));
            } else {
                toYield.push({
                    type: error || '',
                    errors: data ? data.Errors : undefined,
                    message: data ? data.ErrorMessage : undefined,
                    status: status,
                    payload,
                });

                if (typeof formName === 'string' && data && data.ErrorMessage) {
                    toYield.push(setValidationError(formName, data.ErrorMessage));
                }
            }
        }

        if (typeof formName === 'string') {
            toYield.push(setFormNotSubmitting(formName));
        }

        if (typeof successActions === 'function') {
            toYield = toYield.concat(successActions(data, payload));
        } else if (Array.isArray(successActions)) {
            toYield = toYield.concat(successActions);
        } else if (typeof successActions === 'object') {
            toYield.push(successActions);

        }

        if (toYield.length) {
            yield all(toYield.map(act => put(act)));
        }

        if (status < 500 && ok && typeof successCallback === 'function') {
            successCallback(data, payload);
        }

    } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(error);
        }

        const toYield = [];

        if (typeof formName === 'string') {
            toYield.push(setFormNotSubmitting(formName));
            toYield.push(setValidationError(formName, ''));
        }
        if (!noErrorNotification) {
            toYield.push(addNotification({
                type: NOTIFICATION_TYPE.ERROR,
                content: <FormattedMessage {...localeMessages.serverError} />,
            }));
        }
        toYield.push({
            type: error || '',
            error,
            payload,
        });
        yield all(toYield.map(act => put(act)));
    }
}

function* apiSaga() {
    yield takeEvery('API_CALL', callApi)
}

export default apiSaga
