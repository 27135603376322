import Notifications from './Notifications';
import reducer from './reducer';
import './Notifications.scss';

export * from './actions';

export {
    reducer,
    Notifications,
}

export default Notifications
