import React, { Component } from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import Button from '../Button'
import Modal from '../Modal'
import localeMessages from './messages';

export default class Confirm extends Component {

    render() {
        const {
            children,
            onCancel,
            onCancelText,
            onConfirm,
            onConfirmText,
            ...restProps
        } = this.props;

        return (
            <Modal
                formatters={[Modal.SIZE.SMALL, Modal.TYPE.CONFIRMATION]}
                onClose={onCancel}
                {...restProps}
            >
                <div className="confirmation-text">
                    {children}
                </div>
                <div className="confirmation-buttons-container">
                    <Button
                        onClick={onCancel}
                        className="cancel-confirmation"
                        formatters={[Button.TYPE.SECONDARY]}
                    >
                        {onCancelText || <FormattedMessage {...localeMessages.cancel} />}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        formatters={[Button.TYPE.CONFIRM]}
                    >
                        {onConfirmText || <FormattedMessage {...localeMessages.delete} />}
                    </Button>
                </div>
            </Modal>
        )
    }
}
