import React from 'react';

import { composeClassName } from 'utils';

export default ({ text, formatters = [] }) => {
    return (
        <div className={composeClassName('empty-state', ...formatters)}>
            {text}
        </div>
    );
}
