import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    UNIT_PROPS,
    EDIT_UNIT_FORM_NAME,
} from './constants';
import localeMessages from './messages';

export default (unitData) => ({
    formName: EDIT_UNIT_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'name',
            fields: [
                {
                    type: 'input',
                    name: UNIT_PROPS.NAME,
                    label: <FormattedMessage {...localeMessages.name} />,
                    initialValue: unitData.get(UNIT_PROPS.NAME),
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.unitNameTooltip} />,
                },
            ],
        },
    ],
})

