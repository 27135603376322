import { List, Map } from 'immutable';
import {FormattedMessage} from "react-intl";
import React from "react";

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import { generateKey } from 'utils';
import {
    RMS_ROOM_PROPS,
    RMS_EDIT_UNITS_FORM_NAME,
    UNIT_PROPS, EDIT_UNIT_MODAL_NAME,
} from './constants';
import localeMessages from './messages';
import EditUnit from "./EditUnit";

export default (roomData, intl, handleUnitClick, handleAddNewUnit) => ({
    formName: RMS_EDIT_UNITS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.TABLE],
    noSubmitButton: true,
    fields: [
        {
            type: 'inputMatrix',
            name: RMS_ROOM_PROPS.UNIT_LIST,
            initialValue: (roomData.get(RMS_ROOM_PROPS.UNIT_LIST) || List()),
            addInputText: <FormattedMessage {...localeMessages.addUnit} />,
            atLeastOne: true,
            tableView: true,
            onAddInput: handleAddNewUnit,
            renderAddInput: (value) =>
                <EditUnit
                    inputId={EDIT_UNIT_MODAL_NAME}
                    formName={RMS_EDIT_UNITS_FORM_NAME}
                    inputName={RMS_ROOM_PROPS.UNIT_LIST}
                    unitData={Map({
                        [UNIT_PROPS.TEMP_ID]: generateKey(),
                        [UNIT_PROPS.NAME]: intl.formatMessage(localeMessages.defaultUnitName, {number: value.size + 1}),
                    })}
                    roomId={roomData.get(RMS_ROOM_PROPS.ID)}
                />
            ,
            headers: [
                {
                    id: UNIT_PROPS.NAME,
                    text: localeMessages.unitName,
                },
            ],
            inputs: {
                id: (listName, index, value) =>
                    value.get(UNIT_PROPS.ID) || value.get(UNIT_PROPS.TEMP_ID),
                entityId: value => value.get(UNIT_PROPS.ID),
                edit: props => {
                    const {
                        inputId,
                        item,
                    } = props;

                    return (<EditUnit
                        inputId={inputId}
                        formName={RMS_EDIT_UNITS_FORM_NAME}
                        inputName={RMS_ROOM_PROPS.UNIT_LIST}
                        unitData={item}
                        roomId={roomData.get(RMS_ROOM_PROPS.ID)}
                    />)
                },
                onClick: handleUnitClick,
                fields: [
                    {
                        type: 'custom',
                        name: UNIT_PROPS.NAME,
                        render: value =>
                            value
                    },
                ],
            },
        },
    ],
})

