import { defineMessages } from 'react-intl';

export default defineMessages({
    details: {
        id: 'reservations.details',
        defaultMessage: 'Details',
    },
    guests: {
        id: 'reservations.guests',
        defaultMessage: 'Guests',
    },
    payments: {
        id: 'reservations.payments',
        defaultMessage: 'Payments',
    },
    notes: {
        id: 'reservations.notes',
        defaultMessage: 'Notes',
    },
    invoice: {
        id: 'reservations.invoice',
        defaultMessage: 'Invoice',
    },
    reservation: {
        id: 'reservations.reservation',
        defaultMessage: 'Reservation',
    },
    confirmed: {
        id: 'reservations.confirmed',
        defaultMessage: 'Confirmed',
    },
    confirmReservation: {
        id: 'reservations.confirmReservation',
        defaultMessage: 'Confirm reservation',
    },
    bookingPage: {
        id: 'reservations.bookingPage',
        defaultMessage: 'Booking Page',
    },
    checkIn: {
        id: 'reservations.checkIn',
        defaultMessage: 'Check-in',
    },
    checkOut: {
        id: 'reservations.checkOut',
        defaultMessage: 'Check-out',
    },
    checkOutValidation: {
        id: 'reservations.checkOutValidation',
        defaultMessage: 'Checkout must be after checkin',
    },
    room: {
        id: 'reservations.room',
        defaultMessage: 'Room',
    },
    unit: {
        id: 'reservations.unit',
        defaultMessage: 'Unit',
    },
    unitDoesNotExist: {
        id: 'reservations.unitDoesNotExist',
        defaultMessage: 'Reservation was created for the unit that does not exist any more, please choose a new one',
    },
    adults: {
        id: 'reservations.adults',
        defaultMessage: 'Adults',
    },
    children: {
        id: 'reservations.children',
        defaultMessage: 'Children',
    },
    infants: {
        id: 'reservations.infants',
        defaultMessage: 'Infants',
    },
    calculatedPriceForPeriod: {
        id: 'reservations.calculatedPriceForPeriod',
        defaultMessage: 'Calculated price for period',
    },
    totalPrice: {
        id: 'reservations.totalPrice',
        defaultMessage: 'Total price',
    },
    guestNameFromBookingChannel: {
        id: 'reservations.guestNameFromBookingChannel',
        defaultMessage: 'Guest name from booking channel',
    },
    channel: {
        id: 'reservations.channel',
        defaultMessage: 'Channel',
    },
    updateGuestInformation: {
        id: 'reservations.updateGuestInformation',
        defaultMessage: 'Update guest information',
    },
    addGuest: {
        id: 'reservations.addGuest',
        defaultMessage: 'Add guest',
    },
    deleteGuest: {
        id: 'reservations.deleteGuest',
        defaultMessage: 'Are you sure you want to remove the guest from the reservation?',
    },
    status: {
        id: 'reservations.status',
        defaultMessage: 'Status',
    },
    guestName: {
        id: 'reservations.guestName',
        defaultMessage: 'Guest Name',
    },
    checkInDate: {
        id: 'reservations.checkInDate',
        defaultMessage: 'Check-in Date',
    },
    checkOutDate: {
        id: 'reservations.checkOutDate',
        defaultMessage: 'Check-out Date',
    },
    nights: {
        id: 'reservations.nights',
        defaultMessage: 'Nights',
    },
    bookingDate: {
        id: 'reservations.bookingDate',
        defaultMessage: 'Booking Date',
    },
    reservationRemoved: {
        id: 'reservations.reservationRemoved',
        defaultMessage: 'Reservation has been removed.',
    },
    edit: {
        id: 'reservations.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'reservations.delete',
        defaultMessage: 'Delete',
    },
    deleteReservationConfirmation: {
        id: 'reservations.deleteReservationConfirmation',
        defaultMessage: 'Are you sure you want to delete this booking?',
    },
    allRooms: {
        id: 'reservations.allRooms',
        defaultMessage: 'All rooms',
    },
    addReservation: {
        id: 'reservations.addReservation',
        defaultMessage: 'Add reservation',
    },
    pleaseCreateRooms: {
        id: 'reservations.pleaseCreateRooms',
        defaultMessage: 'Please create rooms for your property in order to start adding reservations',
    },
    CONFIRMED: {
        id: 'reservations.CONFIRMED',
        defaultMessage: 'Confirmed',
    },
    NOT_CONFIRMED: {
        id: 'reservations.NOT_CONFIRMED',
        defaultMessage: 'Not confirmed',
    },
    CANCELED: {
        id: 'reservations.CANCELED',
        defaultMessage: 'Canceled',
    },
    invoiceNumber: {
        id: 'reservations.invoiceNumber',
        defaultMessage: 'Invoice Number: {invoiceNumber}',
    },
    total: {
        id: 'reservations.total',
        defaultMessage: 'Total',
    },
    descriptionColumn: {
        id: 'reservations.descriptionColumn',
        defaultMessage: '{roomName} {startDate} - {endDate}',
    },
    greeting: {
        id: 'reservations.greeting',
        defaultMessage: 'Best regards,',
    },
    invoiceNumberLabel: {
        id: 'reservations.invoiceNumberLabel',
        defaultMessage: 'Invoice number',
    },
    print: {
        id: 'reservations.print',
        defaultMessage: 'Print',
    },
    priceWithoutInclusiveCharges: {
        id: 'reservations.priceWithoutInclusiveCharges',
        defaultMessage: 'Price Without Inclusive Charges',
    },
    price: {
        id: 'reservations.price',
        defaultMessage: 'Price',
    },
    priceWithAllCharges: {
        id: 'reservations.priceWithAllCharges',
        defaultMessage: 'Price With All Charges',
    },
    totalRoomPrice: {
        id: 'reservations.totalRoomPrice',
        defaultMessage: 'Total Room Price',
    },
    cancellationFee: {
        id: 'reservations.cancellationFee',
        defaultMessage: 'Cancellation Fee',
    },
    totalDue: {
        id: 'reservations.totalDue',
        defaultMessage: 'Total Due',
    },
    cityLine: {
        id: 'reservations.cityLine',
        defaultMessage: '{city}, {country}',
    },
    invoiceDate: {
        id: 'reservations.invoiceDate',
        defaultMessage: 'Invoice Date: {date}',
    },
    dateFilterHelp: {
        id: 'reservations.dateFilterHelp',
        defaultMessage: 'Show reservations from the selected date.',
    },
    amount: {
        id: 'reservations.amount',
        defaultMessage: 'Amount',
    },
    priceWithLabel: {
        id: 'reservations.priceWithLabel',
        defaultMessage: '{label}: {price}',
    },
    canceledUnits: {
        id: 'reservations.canceledUnits',
        defaultMessage: 'Canceled units: {count}',
    },
    deletedGuest: {
        id: 'reservations.deletedGuest',
        defaultMessage: 'This guest was deleted',
    },
    placeholderGuestName: {
        id: 'reservations.placeholderGuestName',
        defaultMessage: 'Unknown',
    },
    useCalculatedPriceAsPrice: {
        id: 'reservations.useCalculatedPriceAsPrice',
        defaultMessage: 'Use as price',
    },
})
