import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    TAXES_AND_FEES_PROPS,
    EDIT_TAXES_AND_FEES_FORM_NAME,
    TAX_AND_FEE_TYPES,
    TAX_AND_FEE_LOGIC_OPTIONS,
} from './constants';
import localeMessages from './messages';

const logicOptions = intl => [
    {
        key: TAX_AND_FEE_LOGIC_OPTIONS.PERCENT,
        value: TAX_AND_FEE_LOGIC_OPTIONS.PERCENT,
        text: intl.formatMessage(localeMessages[TAX_AND_FEE_LOGIC_OPTIONS.PERCENT]),
    }, {
        key: TAX_AND_FEE_LOGIC_OPTIONS.PER_BOOKING,
        value: TAX_AND_FEE_LOGIC_OPTIONS.PER_BOOKING,
        text: intl.formatMessage(localeMessages[TAX_AND_FEE_LOGIC_OPTIONS.PER_BOOKING]),
    }, {
        key: TAX_AND_FEE_LOGIC_OPTIONS.PER_NIGHT,
        value: TAX_AND_FEE_LOGIC_OPTIONS.PER_NIGHT,
        text: intl.formatMessage(localeMessages[TAX_AND_FEE_LOGIC_OPTIONS.PER_NIGHT]),
    }];
const exclusiveOnlyLogicOptions = intl => [
    {
        key: TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON,
        value: TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON,
        text: intl.formatMessage(localeMessages[TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON]),
    }, {
        key: TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON_PER_NIGHT,
        value: TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON_PER_NIGHT,
        text: intl.formatMessage(localeMessages[TAX_AND_FEE_LOGIC_OPTIONS.PER_PERSON_PER_NIGHT]),
    }
];

export default (initialValues, intl) => ({
    formName: EDIT_TAXES_AND_FEES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'type',
            fields: [
                {
                    type: 'select',
                    name: TAXES_AND_FEES_PROPS.TYPE,
                    label: <FormattedMessage {...localeMessages.type} />,
                    initialValue: initialValues.get(TAXES_AND_FEES_PROPS.TYPE),
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.typeTooltip} />,
                    options: [{
                        key: TAX_AND_FEE_TYPES.VAT,
                        value: TAX_AND_FEE_TYPES.VAT,
                        text: intl.formatMessage(localeMessages[TAX_AND_FEE_TYPES.VAT]),
                    }, {
                        key: TAX_AND_FEE_TYPES.TOURIST_TAX,
                        value: TAX_AND_FEE_TYPES.TOURIST_TAX,
                        text: intl.formatMessage(localeMessages[TAX_AND_FEE_TYPES.TOURIST_TAX]),
                    }, {
                        key: TAX_AND_FEE_TYPES.CITY_TAX,
                        value: TAX_AND_FEE_TYPES.CITY_TAX,
                        text: intl.formatMessage(localeMessages[TAX_AND_FEE_TYPES.CITY_TAX]),
                    }, {
                        key: TAX_AND_FEE_TYPES.CLEANING_FEE,
                        value: TAX_AND_FEE_TYPES.CLEANING_FEE,
                        text: intl.formatMessage(localeMessages[TAX_AND_FEE_TYPES.CLEANING_FEE]),
                    }, {
                        key: TAX_AND_FEE_TYPES.TRANSFER_FEE,
                        value: TAX_AND_FEE_TYPES.TRANSFER_FEE,
                        text: intl.formatMessage(localeMessages[TAX_AND_FEE_TYPES.TRANSFER_FEE]),
                    }, {
                        key: TAX_AND_FEE_TYPES.OTHER_FEES,
                        value: TAX_AND_FEE_TYPES.OTHER_FEES,
                        text: intl.formatMessage(localeMessages[TAX_AND_FEE_TYPES.OTHER_FEES]),
                    }]
                },
            ],
        },

        {
            type: 'section',
            name: 'logic',
            fields: [
                {
                    type: 'checkbox',
                    name: TAXES_AND_FEES_PROPS.IS_INCLUSIVE,
                    initialValue: initialValues.get(TAXES_AND_FEES_PROPS.IS_INCLUSIVE),
                    label: <FormattedMessage {...localeMessages.isInclusive} />,
                    help: <FormattedMessage {...localeMessages.isInclusiveTooltip} />,
                },
                {
                    type: 'select',
                    name: TAXES_AND_FEES_PROPS.LOGIC,
                    label: <FormattedMessage {...localeMessages.logic} />,
                    initialValue: initialValues.get(TAXES_AND_FEES_PROPS.LOGIC),
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.logicTooltip} />,
                    options: props => props.values.get(TAXES_AND_FEES_PROPS.IS_INCLUSIVE)
                        ? logicOptions(intl)
                        : logicOptions(intl).concat(exclusiveOnlyLogicOptions(intl)),
                }
            ],
        },

        {
            type: 'section',
            name: 'rate',
            fields: [
                {
                    type: 'number',
                    name: TAXES_AND_FEES_PROPS.RATE,
                    label: <FormattedMessage {...localeMessages.rate} />,
                    initialValue: initialValues.get(TAXES_AND_FEES_PROPS.RATE),
                    validations: ['required', {validator: 'numberGreaterThan', args: [0]}],
                    suffix: "%",
                    showIf: {
                        field: TAXES_AND_FEES_PROPS.LOGIC,
                        value: TAX_AND_FEE_LOGIC_OPTIONS.PERCENT,
                    }
                },
                {
                    type: 'money',
                    name: TAXES_AND_FEES_PROPS.RATE,
                    label: <FormattedMessage {...localeMessages.rate} />,
                    initialValue: initialValues.get(TAXES_AND_FEES_PROPS.RATE),
                    validations: ['required', {validator: 'numberGreaterThan', args: [0]}],
                    showIf: {
                        field: TAXES_AND_FEES_PROPS.LOGIC,
                        valueNot: TAX_AND_FEE_LOGIC_OPTIONS.PERCENT,
                    }
                },
            ],
        },
    ],
})

