import {Iterable} from "immutable";
import React from 'react';
import {
    FormattedMessage,
} from "react-intl";

import localeMessages from './messages';

import {
    GUEST_PROPS
} from "./constants";

export const getGuestName = guest => {
    if (!Iterable.isIterable(guest)) {
        return '';
    }

    const guestFirstName = guest.get(GUEST_PROPS.FIRST_NAME);
    const guestLastName = guest.get(GUEST_PROPS.LAST_NAME);

    if (guestFirstName || guestLastName) {
        return `${guestFirstName || ''} ${guestLastName || ''}`.trim();
    }
    return <FormattedMessage {...localeMessages.reservation} />;
};

export const guestReservationModalId = (reservationId, generatedKey) => `${reservationId}_${generatedKey}`;


