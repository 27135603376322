import { endpoints } from 'api'

import {
    GET_RATES_REQUEST,
    GET_RATES_SUCCESS,
    GET_RATES_ERROR,
    POST_RATE_REQUEST,
    POST_RATE_SUCCESS,
    POST_RATE_ERROR,
    UPDATE_RATE_REQUEST,
    UPDATE_RATE_SUCCESS,
    UPDATE_RATE_ERROR,
    DELETE_RATE_REQUEST,
    DELETE_RATE_SUCCESS,
    DELETE_RATE_ERROR,
    ADD_EDITED_RATE,
    CLEAR_EDITED_RATE,
} from './constants'

export const getRates = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getRates,
    actions: {
        request: GET_RATES_REQUEST,
        success: GET_RATES_SUCCESS,
        error: GET_RATES_ERROR,
    },
});

export const postRate = (rate, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postRate,
    actions: {
        request: POST_RATE_REQUEST,
        success: POST_RATE_SUCCESS,
        error: POST_RATE_ERROR,
        successCallback,
    },
    body: rate,
    formName,
});

export const putRate = (idRate, rate, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putRate,
    actions: {
        request: UPDATE_RATE_REQUEST,
        success: UPDATE_RATE_SUCCESS,
        error: UPDATE_RATE_ERROR,
        successCallback,
    },
    params: { idRate },
    body: rate,
    formName,
});

export const deleteRate = (idRate, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteRate,
    actions: {
        request: DELETE_RATE_REQUEST,
        success: DELETE_RATE_SUCCESS,
        error: DELETE_RATE_ERROR,
        successCallback,
    },
    params: { idRate },
});

export const addEditedRate = editedRate => ({
    type: ADD_EDITED_RATE,
    editedRate,
});

export const clearEditedRate = () => ({
    type: CLEAR_EDITED_RATE,
});

