import { defineMessages } from 'react-intl';

export default defineMessages({
    unsavedChanges: {
        id: 'tabs.unsavedChanges',
        defaultMessage: 'There are unsaved changes, are you sure you want to leave?',
    },
    goBack: {
        id: 'tabs.goBack',
        defaultMessage: 'Go back',
    },
    continue: {
        id: 'tabs.continue',
        defaultMessage: 'Continue without saving',
    },
    delete: {
        id: 'tabs.delete',
        defaultMessage: 'Delete',
    },
})
