import {List, Map} from 'immutable';
import {
    FormattedMessage,
} from 'react-intl';

import localeMessages from './messages';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    BOOKING_PAGE_ROOM_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
    BED_PROPS,
} from './constants';
import React from "react";
import {FILE_INPUT_PROPS} from "eksenia-lib/src/File/constants";

export default (
    initialValues = Map(),
) => ({
    formName: BOOKING_PAGE_ROOM_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SHOW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SHOW),
                    label: <FormattedMessage {...localeMessages.showOnBookingPage} />,
                    help: <FormattedMessage {...localeMessages.showOnBookingPageTooltip} />,
                },
                {
                    type: 'input',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.DISPLAY_NAME,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.DISPLAY_NAME),
                    label: <FormattedMessage {...localeMessages.displayName} />,
                    help: <FormattedMessage {...localeMessages.roomBookingNameTooltip} />,
                },
                {
                    type: 'number',
                    integer: true,
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SIZE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SIZE),
                    label: <FormattedMessage {...localeMessages.size} />,
                },
                {
                    type: 'file',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.MAIN_IMAGE,
                    initialValue: Map({
                        [FILE_INPUT_PROPS.IDS]: List([initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.MAIN_IMAGE)].filter( val => val)),
                        [FILE_INPUT_PROPS.FILES]: List(),
                    }),
                    label: <FormattedMessage {...localeMessages.coverImage} />,
                    accept: 'image/png, image/jpeg',
                    showPreview: true,
                    validateWith: values => {
                        if (!values.get(BOOKING_PAGE_ROOM_FORM_PROPS.SHOW)) {
                            return ['maxFileSize']
                        } else {
                            return ['requiredFile', 'maxFileSize']
                        }
                    },
                    help: <FormattedMessage {...localeMessages.roomCoverImageTooltip} />,
                    previewTitle: <FormattedMessage {...localeMessages.roomCoverImagePreviewTitle} />,
                },
                {
                    type: 'file',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.IMAGES,
                    initialValue: Map({
                        [FILE_INPUT_PROPS.IDS]: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.IMAGES) || List(),
                        [FILE_INPUT_PROPS.FILES]: List(),
                    }),
                    label: <FormattedMessage {...localeMessages.galleryImages} />,
                    accept: 'image/png, image/jpeg',
                    showPreview: true,
                    multiple: true,
                    validations: ['maxFileSize', {validator: 'maximumFiles', args: [20]}],
                    help: <FormattedMessage {...localeMessages.roomGalleryImagesTooltip} />,
                    previewTitle: <FormattedMessage {...localeMessages.roomGalleryImagesPreviewTitle} />,
                },
                {
                    type: 'inputMatrix',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BEDS),
                    headers: [
                        {
                            id: 'label',
                            text: <FormattedMessage {...localeMessages.bedType} />,
                        },
                        {
                            id: BED_PROPS.COUNT,
                            text: <FormattedMessage {...localeMessages.bedTypeCount} />,
                        },
                    ],
                    inputs: {
                        id: (listName, index, value) => value.get('id'),
                        fields: [
                            {
                                type: 'label',
                            },
                            {
                                type: 'number',
                                integer: true,
                                name: BED_PROPS.COUNT,
                            },
                        ],
                    }
                },
            ],
        },
    ],
})
