import { fromJS, Iterable } from 'immutable';

import { reduceWithDefault } from 'utils';
import {
    GET_TAXES_AND_FEES_REQUEST,
    GET_TAXES_AND_FEES_SUCCESS,
    POST_TAX_OR_FEE_SUCCESS,
    DELETE_TAX_OR_FEE_SUCCESS,
    UPDATE_TAX_OR_FEE_SUCCESS,
    TAXES_AND_FEES_PROPS,
    SET_EDITED_TAX_OR_FEE,
    CLEAR_EDITED_TAX_OR_FEE,
} from './constants';

const initialState = fromJS({
    editedTax: false,
    taxesAndFees: [],
    finishedLoading: false,
});
const reducers = {
    [GET_TAXES_AND_FEES_REQUEST]: state =>
        state.merge({
            finishedLoading: false,
        }),

    [GET_TAXES_AND_FEES_SUCCESS]: (state, { data }) =>
        state.merge({
            taxesAndFees: fromJS(data || []),
            finishedLoading: false,
        }),

    [POST_TAX_OR_FEE_SUCCESS]: (state, { data }) =>
        state.merge({
            taxesAndFees: state.get('taxesAndFees').push(fromJS(data)),
            editedTax: fromJS(data),
        }),

    [UPDATE_TAX_OR_FEE_SUCCESS]: (state, { data }) =>
        state.merge({
            taxesAndFees: state.get('taxesAndFees')
                .map(tax =>
                    tax.get(TAXES_AND_FEES_PROPS.ID) === data[TAXES_AND_FEES_PROPS.ID]
                        ? fromJS(data)
                        : tax
                ),
            editedTax: fromJS(data),
        }),

    [DELETE_TAX_OR_FEE_SUCCESS]: (state, { data }) =>
        state.merge({
            taxesAndFees: state.get('taxesAndFees')
                .filter(tax =>
                    tax.get(TAXES_AND_FEES_PROPS.ID) !== data[TAXES_AND_FEES_PROPS.ID]
                ),
        }),

    [SET_EDITED_TAX_OR_FEE]: (state, { editedTaxOrFee }) =>
        state.merge({
            editedTax: Iterable.isIterable(editedTaxOrFee) && editedTaxOrFee,
        }),

    [CLEAR_EDITED_TAX_OR_FEE]: state =>
        state.merge({
            editedTax: initialState.get('editedTaxOrFee'),
        }),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
