export const GET_LOCALE_REQUEST = 'GET_LOCALE_REQUEST';
export const GET_LOCALE_SUCCESS = 'GET_LOCALE_SUCCESS';
export const GET_LOCALE_ERROR = 'GET_LOCALE_ERROR';

export const GET_COUNTY_NAMES_REQUEST = 'GET_COUNTY_NAMES_REQUEST';
export const GET_COUNTY_NAMES_SUCCESS = 'GET_COUNTY_NAMES_SUCCESS';
export const GET_COUNTY_NAMES_ERROR = 'GET_COUNTY_NAMES_ERROR';

export const LANGUAGE_NAMES = {
    ENG: 'en',
    SER: 'sr',
    MNE: 'cnr',
};

export const LANGUAGE_NAME_TEXT = {
    ENG: 'English',
    SER: 'srpski',
    MNE: 'crnogorski',
};

