import {
    FormattedMessage,
} from 'react-intl';
import React from 'react';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    PMT_PAYMENT_PROPS,
    PMT_EDIT_PAYMENT_FORM_NAME,
} from './constants';
import localeMessages from './messages';

export default (paymentData, intl) => ({
    formName: PMT_EDIT_PAYMENT_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'base',
            fields: [
                {
                    type: 'money',
                    name: PMT_PAYMENT_PROPS.AMOUNT,
                    label: <FormattedMessage {...localeMessages.amount} />,
                    initialValue: paymentData[PMT_PAYMENT_PROPS.AMOUNT],
                    validations: ['required'],
                },
                {
                    type: 'date',
                    name: PMT_PAYMENT_PROPS.DATE,
                    initialValue: paymentData[PMT_PAYMENT_PROPS.DATE],
                    label: <FormattedMessage {...localeMessages.date} />,
                },
                {
                    type: 'input',
                    name: PMT_PAYMENT_PROPS.NOTE,
                    label: <FormattedMessage {...localeMessages.note} />,
                    initialValue: paymentData[PMT_PAYMENT_PROPS.NOTE],
                },
                {
                    type: 'input',
                    name: PMT_PAYMENT_PROPS.PAYMENT_TYPE,
                    label: <FormattedMessage {...localeMessages.paymentType} />,
                    initialValue: paymentData[PMT_PAYMENT_PROPS.PAYMENT_TYPE],
                },
            ],
        },
    ],
})

