import {LANGUAGE_NAMES} from "../locale";

export const generateKey = () =>
  'xxxx-xxxx-xxxx-xxxx'.replace(/x/g, c => {
    const r = Math.random() * 16 | 0
    const v = c === 'x'
      ? r
      : ((r & 0x3) | 0x8)

    return v.toString(16)
  })

export const getLocaleString = (language, noDefault) =>
    language === LANGUAGE_NAMES.MNE
        ? LANGUAGE_NAMES.SER
        : language || (!noDefault && LANGUAGE_NAMES.ENG);
