import { defineMessages } from 'react-intl';

export default defineMessages({
    guest: {
        id: 'guests.guest',
        defaultMessage: 'Guest',
    },
    firstName: {
        id: 'guests.firstName',
        defaultMessage: 'First name',
    },
    lastName: {
        id: 'guests.lastName',
        defaultMessage: 'Last name',
    },
    name: {
        id: 'guests.name',
        defaultMessage: 'Name',
    },
    email: {
        id: 'guests.email',
        defaultMessage: 'Email',
    },
    phone: {
        id: 'guests.phone',
        defaultMessage: 'Phone number',
    },
    country: {
        id: 'guests.country',
        defaultMessage: 'Country',
    },
    address: {
        id: 'guests.address',
        defaultMessage: 'Address',
    },
    city: {
        id: 'guests.city',
        defaultMessage: 'City',
    },
    idType: {
        id: 'guests.idType',
        defaultMessage: 'ID type',
    },
    passport: {
        id: 'guests.passport',
        defaultMessage: 'Passport',
    },
    idCard: {
        id: 'guests.idCard',
        defaultMessage: 'ID card',
    },
    driversLicence: {
        id: 'guests.driversLicence',
        defaultMessage: 'Driver\'s licence',
    },
    idNumber: {
        id: 'guests.idNumber',
        defaultMessage: 'ID number',
    },
    notes: {
        id: 'guests.notes',
        defaultMessage: 'Notes',
    },
    edit: {
        id: 'guests.edit',
        defaultMessage: 'Edit',
    },
    searchGuestPlaceholder: {
        id: 'guests.searchGuestPlaceholder',
        defaultMessage: 'Search by name, address or note',
    },
    reservation: {
        id: 'guests.reservation',
        defaultMessage: 'Reservation',
    },
    idTypeTooltip: {
        id: 'guests.idTypeTooltip',
        defaultMessage: 'Type of the identification document provided by the guest.',
    },
    idNumberTooltip: {
        id: 'guests.idNumberTooltip',
        defaultMessage: "Number of the guest's identification document.",
    },
    notesTooltip: {
        id: 'guests.notesTooltip',
        defaultMessage: "Your notes about the guest. For example: Nonsmoker, prefers a quiet room.",
    },
    deleteConfirmation: {
        id: 'guests.deleteConfirmation',
        defaultMessage: 'Are you sure you want to delete this guest?',
    },
    details: {
        id: 'guests.details',
        defaultMessage: 'Guest information',
    },
    reservations: {
        id: 'guests.reservations',
        defaultMessage: 'Reservations',
    },
    guestDeleted: {
        id: 'guests.guestDeleted',
        defaultMessage: "Guest's information has been deleted.",
    },
})
