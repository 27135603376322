import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Iterable } from 'immutable';
import {
    FormattedMessage,
    FormattedDate,
} from 'react-intl';

import Money from 'eksenia-lib/src/Money';
import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal'
import Table from 'eksenia-lib/src/Table';
import Confirm from "eksenia-lib/src/Confirm";
import {
    deletePayment,
    getPayments,
    openEditPaymentModal,
} from './actions';
import {
    PMT_EDIT_PAYMENT_MODAL_NAME,
    PMT_PAYMENT_PROPS,
    PMT_DELETE_PAYMENT_CONFIRMATION_MODAL_NAME,
} from './constants';
import localeMessages from './messages';
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";

const tableHeader = [
    {
        content: localeMessages.amount,
        key: 'amount',
        width: '15%',
    },
    {
        content: localeMessages.date,
        key: 'date',
        width: '15%',
    },
    {
        content: localeMessages.note,
        key: 'note',
        width: '55%',
    },
    {
        content: localeMessages.paymentType,
        key: 'paymentType',
        width: '15%',
    },
];

class PaymentList extends Component {

    deletePaymentId = null;

    constructor() {
        super(...arguments);

        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    }

    componentDidMount() {
        this.props.getPayments(this.props.idReservation);
    }

    handleConfirmationOpen(paymentId) {
        this.deletePaymentId = paymentId;
        this.props.showModal(PMT_DELETE_PAYMENT_CONFIRMATION_MODAL_NAME);
    }

    handleConfirmationClose() {
        this.deletePaymentId = null;
        this.props.hideModal();
    }

    handleConfirmDelete() {
        const {
            deletePayment,
            addNotification,
        } = this.props;

        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage {...localeMessages.paymentRemoved} />,
        });
        deletePayment(this.deletePaymentId);
        this.handleConfirmationClose();
    }

    handleEditPayment(payment) {
        const {
            showModal,
            openEditPaymentModal,
        } = this.props;

        openEditPaymentModal(payment);
        showModal(PMT_EDIT_PAYMENT_MODAL_NAME);
    }

    prepareTableRows() {
        const {
            payments,
        } = this.props;

        return payments.map((payment) => ({
            key: payment.get(PMT_PAYMENT_PROPS.ID),
            onClick: this.handleEditPayment.bind(this, payment),
            cells: [
                {
                    key: 'amount',
                    // eslint-disable-next-line
                    content: <Money value={payment.get(PMT_PAYMENT_PROPS.AMOUNT)} />,
                },
                {
                    key: 'date',
                    content: <FormattedDate value={payment.get(PMT_PAYMENT_PROPS.DATE).toDate()} />,
                },
                {
                    key: 'note',
                    content: payment.get(PMT_PAYMENT_PROPS.NOTE),
                },
                {
                    key: 'paymentType',
                    content: payment.get(PMT_PAYMENT_PROPS.PAYMENT_TYPE),
                },
                // {
                //     key: 'actions',
                //     content: (
                //             <Button
                //                 formatters={[Button.TYPE.LINK, INPUT_FORMATTERS.HEIGHT.S]}
                //                 onClick={this.handleConfirmationOpen.bind(this, payment.get(PMT_PAYMENT_PROPS.ID))}
                //             >
                //                 <FormattedMessage {...localeMessages.delete} />
                //             </Button>
                //     ),
                // },
            ],
        }));
    }


    render() {
        const {
            payments,
        } = this.props;

        if (!Iterable.isIterable(payments)) {
            return false;
        }

        return (
            <div>
                <Table header={tableHeader} rows={this.prepareTableRows()} />

                <Confirm
                    id={PMT_DELETE_PAYMENT_CONFIRMATION_MODAL_NAME}
                    headerText={<FormattedMessage {...localeMessages.deletePaymentConfirmation} />}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleConfirmDelete}
                />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    const paymentsReducer = store.paymentsReducer;

    return {
        payments: paymentsReducer.get('payments'),
    }
};

const mapDispatchToProps = {
    deletePayment,
    getPayments,
    hideModal,
    openEditPaymentModal,
    showModal,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
