import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Iterable, List } from 'immutable';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';

import {
    Form,
} from 'eksenia-lib/src/Form';
import Tabs from 'eksenia-lib/src/Tabs';
import {
    RMS_ROOM_PROPS,
    patchRoom,
} from "../Rooms";
import {BOOKING_PAGE_ROOM_FORM_PROPS} from "./constants";
import basicAmenitiesForm from "./basicAmenitiesForm";
import popularAmenitiesForm from "./popularAmenitiesForm";
import miscellaneousAmenitiesForm from "./miscellaneousAmenitiesForm";
import bathroomAmenitiesForm from "./bathroomAmenitiesForm";
import mediaAmenitiesForm from "./mediaAmenitiesForm";
import foodAndDrinkAmenities from "./foodAndDrinkAmenities";
import securityAndExtrasAmenitiesForm from "./securityAndExtrasAmenitiesForm";
import {
    BED_TYPES,
    BED_PROPS,
    BOOKING_PAGE_ROOM_FORM_NAME,
    POPULAR_AMENITIES_FORM_NAME,
    MISCELLANEOUS_AMENITIES_FORM_NAME,
    BATHROOM_AMENITIES_FORM_NAME,
    MEDIA_AMENITIES_FORM_NAME,
    FOOD_AND_DRINK_AMENITIES_FORM_NAME,
    SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME,
} from './constants';
import localeMessages from './messages';
import {FILE_INPUT_PROPS} from "eksenia-lib/src/File/constants";

const formNames = [
    BOOKING_PAGE_ROOM_FORM_NAME,
    POPULAR_AMENITIES_FORM_NAME,
    MISCELLANEOUS_AMENITIES_FORM_NAME,
    BATHROOM_AMENITIES_FORM_NAME,
    MEDIA_AMENITIES_FORM_NAME,
    FOOD_AND_DRINK_AMENITIES_FORM_NAME,
    SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME,
];

const defaultBedsValue = (amenities, intl) => [{
    id: BED_TYPES.SINGLE,
    label: intl.formatMessage(localeMessages.singleBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.SINGLE),
}, {
    id: BED_TYPES.TWIN,
    label: intl.formatMessage(localeMessages.twinBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.TWIN),
}, {
    id: BED_TYPES.DOUBLE,
    label: intl.formatMessage(localeMessages.doubleBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.DOUBLE),
}, {
    id: BED_TYPES.QUEEN_DOUBLE,
    label: intl.formatMessage(localeMessages.queenSizeBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.QUEEN_DOUBLE),
}, {
    id: BED_TYPES.KING_DOUBLE,
    label: intl.formatMessage(localeMessages.kingSizeBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.KING_DOUBLE),
}, {
    id: BED_TYPES.SOFA,
    label: intl.formatMessage(localeMessages.sofaBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.SOFA),
}, {
    id: BED_TYPES.FOLDABLE,
    label: intl.formatMessage(localeMessages.foldableBed),
    [BED_PROPS.COUNT]: amenities.get(BED_TYPES.FOLDABLE),
}];

export class RoomBookingSettings extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveClick = this.handleSaveClick.bind(this);
    }

    handleSaveClick(formValues, formName) {
        const{
            patchRoom,
            room,
        } = this.props;

        if (formValues.has(BOOKING_PAGE_ROOM_FORM_PROPS.BEDS)) {
            formValues = formValues.merge({
                [BED_TYPES.SINGLE]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '0',
                    BED_PROPS.COUNT
                ])),
                [BED_TYPES.TWIN]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '1',
                    BED_PROPS.COUNT
                ])),
                [BED_TYPES.DOUBLE]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '2',
                    BED_PROPS.COUNT
                ])),
                [BED_TYPES.QUEEN_DOUBLE]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '3',
                    BED_PROPS.COUNT
                ])),
                [BED_TYPES.KING_DOUBLE]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '4',
                    BED_PROPS.COUNT
                ])),
                [BED_TYPES.SOFA]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '5',
                    BED_PROPS.COUNT
                ])),
                [BED_TYPES.FOLDABLE]: parseInt(formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                    '6',
                    BED_PROPS.COUNT
                ])),
            }).delete(BOOKING_PAGE_ROOM_FORM_PROPS.BEDS)
        }

        if (formValues.has(BOOKING_PAGE_ROOM_FORM_PROPS.MAIN_IMAGE)) {
            formValues = formValues.merge({
                [BOOKING_PAGE_ROOM_FORM_PROPS.MAIN_IMAGE]: formValues.getIn([
                    BOOKING_PAGE_ROOM_FORM_PROPS.MAIN_IMAGE,
                    FILE_INPUT_PROPS.IDS,
                    0
                ]),
                [BOOKING_PAGE_ROOM_FORM_PROPS.IMAGES]:
                    (formValues.getIn([
                        BOOKING_PAGE_ROOM_FORM_PROPS.IMAGES,
                        FILE_INPUT_PROPS.IDS
                    ]) || List()).toJS(),
            });
        }

        patchRoom(
            room.get(RMS_ROOM_PROPS.ID),
            {
                "op_list": [{
                    op: 'replace',
                    path: `/${RMS_ROOM_PROPS.AMENITIES}`,
                    value: room.get(RMS_ROOM_PROPS.AMENITIES).merge(formValues),
                }],
            },
            formName);
    }

    render() {
        const {
            room,
            intl,
        } = this.props;

        if (!Iterable.isIterable(room)) {
            return null
        }

        const amenities = room.get(RMS_ROOM_PROPS.AMENITIES);

        const tabs = [{
            defaultActive: true,
            id: 'basics',
            label: <FormattedMessage {...localeMessages.basicAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-basics`}
                    blueprint={basicAmenitiesForm(amenities.set(
                        BOOKING_PAGE_ROOM_FORM_PROPS.BEDS,
                        defaultBedsValue(amenities, intl)
                    ))}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }, {
            id: 'popular',
            label: <FormattedMessage {...localeMessages.popularAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-popular`}
                    blueprint={popularAmenitiesForm(amenities)}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }, {
            id: 'miscellaneous',
            label: <FormattedMessage {...localeMessages.miscellaneousAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-miscellaneous`}
                    blueprint={miscellaneousAmenitiesForm(amenities)}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }, {
            id: 'bathroom',
            label: <FormattedMessage {...localeMessages.bathroomAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-bathroom`}
                    blueprint={bathroomAmenitiesForm(amenities)}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }, {
            id: 'media',
            label: <FormattedMessage {...localeMessages.mediaAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-media`}
                    blueprint={mediaAmenitiesForm(amenities)}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }, {
            id: 'food',
            label: <FormattedMessage {...localeMessages.foodAndDrinksAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-food`}
                    blueprint={foodAndDrinkAmenities(amenities)}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }, {
            id: 'security',
            label: <FormattedMessage {...localeMessages.securityAndExtraAmenities} />,
            content: (
                <Form
                    key={`${room.get(RMS_ROOM_PROPS.ID)}-security`}
                    blueprint={securityAndExtrasAmenitiesForm(amenities)}
                    onSubmit={this.handleSaveClick}
                />
            ),
        }];

        return (
            <Tabs
                groupName="booking-page-room"
                tabs={tabs}
                activeForms={formNames}
            />
        )
    }
}

const mapDispatchToProps = {
    patchRoom,
};

export default injectIntl(connect(undefined, mapDispatchToProps)(RoomBookingSettings))
