import React, {Component} from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Iterable } from 'immutable';
import {FormattedMessage, injectIntl,} from 'react-intl';

import { completePasswordReset } from 'User';
import Input from 'eksenia-lib/src/Input';
import Button from 'eksenia-lib/src/Button';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import localeMessages from './messages';
import ValidationError from "eksenia-lib/src/ValidationError";
import {supportEmail} from "./SupportEmail";
import Icon from "eksenia-lib/src/Icon";
import {LANGUAGE_NAME_TEXT, LANGUAGE_NAMES} from "../locale";

class ResetPassword extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            pwd: '',
            resetFailed: false,
            redirectToDashboard: false,
            submitDisabled: false,
            pwdEmptyError: false,
        };

        this.handlePwdChange = this.handlePwdChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.completePasswordResetInProgress && !this.props.completePasswordResetInProgress) {
            this.setState({
                resetFailed: !this.props.completePasswordResetSuccess,
                submitDisabled: this.props.completePasswordResetSuccess,
                redirectToDashboard: this.props.completePasswordResetSuccess,
            });
        }
    }

    handleSubmit(evt) {
        evt.preventDefault();
        if (this.state.pwd.length === 0) {
            this.setState({
                pwdEmptyError: true,
            });
        } else {
            this.setState({
                submitDisabled: true,
                pwd: '',
            });
            this.props.completePasswordReset(this.props.match.params.token, this.state.pwd);
        }
    }

    handlePwdChange(value) {
        this.setState({
            pwd: value,
            pwdEmptyError: false,
            resetFailed: false,
        });
    }

    handleChangeLanguage(event) {
        localStorage.setItem('language', event.currentTarget.value);
        window.location.reload();
    }

    render() {
        const {
            user,
            intl,
        } = this.props;
        const {
            pwd,
            submitDisabled,
            pwdEmptyError,
            resetFailed,
        } = this.state;

        if (Iterable.isIterable(user) && user.toJS().Email) {
            return (
                <Redirect to="/" />
            );
        }

        let error;
        if (pwdEmptyError) {
            error = <FormattedMessage {...localeMessages.passwordCannotBeEmpty} tagName="div" />
        }
        if (resetFailed) {
            // TODO wrong credentials vs internal error
            error = <FormattedMessage
                {...localeMessages.resetFailed}
                tagName="div"
                values={{
                    email: supportEmail()
                }}
            />
        }

        return (
            <div className="login reset-password">
                <form className="login-content" onSubmit={this.handleSubmit}>
                    <div className="login-form-header">
                        <img
                            src="../eksenia-logo.svg"
                            alt={intl.formatMessage(localeMessages.ekseniaLogo)}
                        />
                    </div>
                    <Input
                        type="password"
                        value={pwd}
                        onChange={this.handlePwdChange}
                        label={<FormattedMessage {...localeMessages.password} />}
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />

                    <Button
                        type="submit"
                        disabled={submitDisabled}
                        formatters={[Button.TYPE.PRIMARY]}
                    >
                        <FormattedMessage {...localeMessages.setPassword} />
                    </Button>

                    <ValidationError
                        error={error}
                        formatters={[INPUT_FORMATTERS.VERTICAL, ValidationError.FORMATTER.BASE_COLOR]}
                        withIcon
                    />
                </form>

                <div className="login-extra-controls">
                    <div className="login-language">
                        <Icon name="globe" type="globe" />
                        <select
                            className="language-select"
                            onChange={this.handleChangeLanguage}
                            defaultValue={intl.locale}
                        >
                            <option
                                key={LANGUAGE_NAMES.ENG}
                                value={LANGUAGE_NAMES.ENG}
                            >
                                {LANGUAGE_NAME_TEXT.ENG}
                            </option>
                            <option
                                key={LANGUAGE_NAMES.SER}
                                value={LANGUAGE_NAMES.SER}
                            >
                                {LANGUAGE_NAME_TEXT.SER}
                            </option>
                            <option
                                key={LANGUAGE_NAMES.MNE}
                                value={LANGUAGE_NAMES.MNE}
                            >
                                {LANGUAGE_NAME_TEXT.MNE}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    const userReducer = store.userReducer;
    return {
        completePasswordResetInProgress: userReducer.get('completePasswordResetInProgress'),
        completePasswordResetSuccess: userReducer.get('completePasswordResetSuccess'),
        user: userReducer.get('user'),
    }
};
const mapDispatchToProps = { completePasswordReset };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))
