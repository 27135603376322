import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    RATE_PROPS,
    RATE_BOOKING_LIMITATIONS_FORM_NAME,
//    DAYS_OF_THE_WEEK,
} from './constants';
import {FormattedMessage} from "react-intl";
import React from "react";
import localeMessages from './messages';

export default (initialValues = {}) => ({
    formName: RATE_BOOKING_LIMITATIONS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    className: 'booking-limitations-form',
    fields: [
        {
            type: 'section',
            name: 'name',
            fields: [
                {
                    type: 'number',
                    name: RATE_PROPS.MINIMUM_STAY,
                    initialValue: initialValues.get(RATE_PROPS.MINIMUM_STAY),
                    label: <FormattedMessage {...localeMessages.minimumStay} />,
                    validations: ['required', {validator: 'numberGreaterThan', args: [0]}],
                },
                {
                    type: 'number',
                    name: RATE_PROPS.MAXIMUM_STAY,
                    initialValue: initialValues.get(RATE_PROPS.MAXIMUM_STAY),
                    label: <FormattedMessage {...localeMessages.maximumStay} />,
                    validations: [
                        'required',
                        'numberNotNegative',
                        (value, values) =>
                            value !== 0 && value < values.get(RATE_PROPS.MINIMUM_STAY)
                                ? <FormattedMessage {...localeMessages.maximumStayValidation} />
                                : false,
                    ],
                },
            ],
        },

        // {
        //     type: 'number',
        //     name: RATE_PROPS.MIN_DAYS_IN_ADVANCE,
        //     initialValue: initialValues.get(RATE_PROPS.MIN_DAYS_IN_ADVANCE),
        //     label: <FormattedMessage {...localeMessages.minimumDaysInAdvance} />,
        //     validations: ['required', 'numberNotNegative'],
        // },
        // {
        //     type: 'number',
        //     name: RATE_PROPS.MAX_DAYS_IN_ADVANCE,
        //     initialValue: initialValues.get(RATE_PROPS.MAX_DAYS_IN_ADVANCE),
        //     label: <FormattedMessage {...localeMessages.maximumDaysInAdvance} />,
        //     validations: [
        //         'required',
        //         'numberNotNegative',
        //         (value, values) => 
        //             value !== 0 && value < values.get(RATE_PROPS.MIN_DAYS_IN_ADVANCE)
        //                 ? <FormattedMessage {...localeMessages.maximumDaysInAdvanceValidation} />
        //                 : false,
        //     ],
        // },
        // {
        //     type: 'inputMatrix',
        //     name: RATE_PROPS.DAYS_LIMITATIONS,
        //     initialValue: initialValues.get(RATE_PROPS.DAYS_LIMITATIONS),
        //     headers: [
        //         {
        //             id: 'label',
        //             text: '',
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.MON,
        //             text: <FormattedMessage {...localeMessages.mondayShort} />,
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.TUE,
        //             text: <FormattedMessage {...localeMessages.tuesdayShort} />,
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.WED,
        //             text: <FormattedMessage {...localeMessages.wednesdayShort} />,
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.THU,
        //             text: <FormattedMessage {...localeMessages.thursdayShort} />,
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.FRI,
        //             text: <FormattedMessage {...localeMessages.fridayShort} />,
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.SAT,
        //             text: <FormattedMessage {...localeMessages.saturdayShort} />,
        //         },
        //         {
        //             id: DAYS_OF_THE_WEEK.SUN,
        //             text: <FormattedMessage {...localeMessages.sundayShort} />,
        //         },
        //     ],
        //     inputs: {
        //         id: (listName, index, value) => value.get('id'),
        //         fields: [
        //             {
        //                 type: 'label',
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.MON,
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.TUE,
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.WED,
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.THU,
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.FRI,
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.SAT,
        //             },
        //             {
        //                 type: 'checkbox',
        //                 name: DAYS_OF_THE_WEEK.SUN,
        //             },
        //         ],
        //     },
        // },
    ],
})

