import React, { Component } from 'react';
import {connect} from "react-redux";
import {IntlProvider} from 'react-intl';
import 'moment/locale/sr';

import Layout from 'Layout'
import {
    getUser,
    impersonateWithToken,
    USR_PROPS,
} from "User";
import {
    getLocale,
    getCountryNames,
    LANGUAGE_NAMES,
} from 'locale';
import {getLocaleString} from "utils";

const supportedLanguages = [
    LANGUAGE_NAMES.SER,
    LANGUAGE_NAMES.MNE,
];

class App extends Component {

    constructor() {
        super(...arguments);

        this.getEssentials = this.getEssentials.bind(this);
        this.state = {
            tempLanguage: LANGUAGE_NAMES.ENG,
        }
    }

    componentDidMount() {
        const {
            impersonateWithToken,
        } = this.props;
        let tempLanguage;

        if (URLSearchParams) {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('impersonation_token');
            tempLanguage = urlParams.get('lang');

            if (token) {
                impersonateWithToken(token)
            } else {
                this.getEssentials()
            }

        } else {
            this.getEssentials()
        }

        if (tempLanguage) {
            this.setTemporaryLanguage(tempLanguage)

        } else {
            const storedLanguage = localStorage.getItem('language');
            const browserLanguage = navigator.language.split("-")[0]
            const cookieLanguage = (document.cookie
                .split('; ')
                .find(row => row.startsWith('language=')) || '')
                .split('=')[1];

            if (supportedLanguages.indexOf(storedLanguage) !== -1) {
                this.setTemporaryLanguage(storedLanguage)

            } else if (cookieLanguage && supportedLanguages.indexOf(cookieLanguage) !== -1) {
                this.setTemporaryLanguage(cookieLanguage)

            } else if (supportedLanguages.indexOf(browserLanguage) !== -1) {
                this.setTemporaryLanguage(browserLanguage)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            getLocale,
            loadingUser,
            user,
            getUser,
            localeMessages,
            loadingLocaleMessages,
            language,
            countryNamesList,
            loadingCountryNames,
            getCountryNames,
        } = this.props;

        if (!user && !loadingUser) {
            getUser();

        } else {
            if (language) {
                localStorage.setItem('language', language);

                if (!prevProps.language || (!localeMessages && !loadingLocaleMessages)) {
                    getLocale(language);
                }

                if (!countryNamesList && !loadingCountryNames) {
                    getCountryNames(language);
                }
            }
        }
    }

    setTemporaryLanguage(language) {
        const {
            getLocale,
        } = this.props;

        this.setState({
            tempLanguage: language,
        });
        getLocale(language);
    }

    getEssentials() {
        const {
            user,
            language,
            getUser,
            getLocale,
            getCountryNames,
        } = this.props;

        if (!user) {
            getUser();

        } else {
            getLocale(language);
            getCountryNames(language);
        }
    }

    render() {
        const {
            loadingUser,
            localeMessages,
            loadingLocaleMessages,
            language,
        } = this.props;

        const {
            tempLanguage,
        } = this.state;

        const updatedLanguage = getLocaleString(language, true)
            || tempLanguage;

        if (loadingUser || loadingLocaleMessages) {
            return null
        }

        return (
            <IntlProvider
                defaultLocale={tempLanguage}
                locale={updatedLanguage}
                key={language}
                messages={localeMessages}
            >
                <Layout />
            </IntlProvider>
        );
    }
}


const mapStateToProps = store => {
    const {
        userReducer,
        localeReducer,
    } = store;

    const user = userReducer.get('user');

    return {
        user,
        loadingUser: userReducer.get('loadingUser'),
        language: user && user.get(USR_PROPS.LANGUAGE),
        localeMessages: localeReducer.get('localeMessages'),
        loadingLocaleMessages: localeReducer.get('loadingLocaleMessages'),
        countryNamesList: localeReducer.get('countryNamesList'),
        loadingCountryNames: localeReducer.get('loadingCountryNames'),
    }
};
const mapDispatchToProps = {
    getUser,
    getLocale,
    getCountryNames,
    impersonateWithToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);