import { endpoints } from 'api'

import {
    RMS_GET_ROOMS_REQUEST,
    RMS_GET_ROOMS_SUCCESS,
    RMS_GET_ROOMS_ERROR,
    GET_UNITS_REQUEST,
    GET_UNITS_SUCCESS,
    GET_UNITS_ERROR,
    RMS_POST_ROOM_REQUEST,
    RMS_POST_ROOM_SUCCESS,
    RMS_POST_ROOM_ERROR,
    RMS_DELETE_ROOM_REQUEST,
    RMS_DELETE_ROOM_SUCCESS,
    RMS_DELETE_ROOM_ERROR,
    RMS_UPDATE_ROOM_REQUEST,
    RMS_UPDATE_ROOM_SUCCESS,
    RMS_UPDATE_ROOM_ERROR,
    RESET_ROOMS_STATE,
    OPEN_EDIT_ROOM,
    CLOSE_EDIT_ROOM,
    RMS_EDIT_ICAL_FORM_NAME,
    RMS_EDIT_ROOM_FORM_NAME,
    RMS_ROOM_PROPS,
    GET_UNITS_MERGE_SUCCESS,
} from './constants'

export const resetRoomsState = () => ({
    type: RESET_ROOMS_STATE,
});

export const getRooms = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getRooms,
    actions: {
        request: RMS_GET_ROOMS_REQUEST,
        success: RMS_GET_ROOMS_SUCCESS,
        error: RMS_GET_ROOMS_ERROR,
        successActions: data => {
            if (Array.isArray(data) && data.length) {
                const idRoomList = data.map(room => room[RMS_ROOM_PROPS.ID]);

                return [getUnits(idRoomList)];
            } else {
                return [];
            }
        }
    },
});

export const postRoom = (room, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postRoom,
    actions: {
        request: RMS_POST_ROOM_REQUEST,
        success: RMS_POST_ROOM_SUCCESS,
        error: RMS_POST_ROOM_ERROR,
        successActions: () => {
            return [getRooms()];
        },
        successCallback,
    },
    body: room,
    formName,
    entityFormNames: [
        RMS_EDIT_ROOM_FORM_NAME,
        RMS_EDIT_ICAL_FORM_NAME,
    ],
});

export const updateRoom = (idRoom, room, formName) => ({
    type: 'API_CALL',
    endpoint: endpoints.putRoom,
    actions: {
        request: RMS_UPDATE_ROOM_REQUEST,
        success: RMS_UPDATE_ROOM_SUCCESS,
        error: RMS_UPDATE_ROOM_ERROR,
    },
    body: room,
    params: {
        idRoom,
    },
    formName,
    entityFormNames: [
        RMS_EDIT_ROOM_FORM_NAME,
        RMS_EDIT_ICAL_FORM_NAME,
    ],
});

export const deleteRoom = (idRoom, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteRoom,
    actions: {
        request: RMS_DELETE_ROOM_REQUEST,
        success: RMS_DELETE_ROOM_SUCCESS,
        error: RMS_DELETE_ROOM_ERROR,
        successCallback,
    },
    params: {
        idRoom,
    },
});

export const getUnits = (rooms, add, withPayload) => ({
    type: 'API_CALL',
    endpoint: endpoints.getUnits,
    actions: {
        request: GET_UNITS_REQUEST,
        success: add
            ? GET_UNITS_MERGE_SUCCESS
            : GET_UNITS_SUCCESS,
        error: GET_UNITS_ERROR,
    },
    queryParams: {
        room_id: rooms,
    },
    payload: {
        rooms: withPayload && rooms
    }
});

export const updateUnit = (unit, idUnit, roomId, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putUnit,
    actions: {
        successActions: () => {
            return [getUnits([roomId], false, true)];
        },
        successCallback,
    },
    formName,
    params: {
        idUnit,
    },
    body: unit,
});

export const postUnit = (unit, roomId, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postUnit,
    actions: {
        successActions: () => {
            return [getUnits([roomId], false, true)];
        },
        successCallback,
    },
    formName,
    body: unit,
});

export const deleteUnit = (idUnit, roomId, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteUnit,
    actions: {
        successActions: () => {
            return [getUnits([roomId], false, true)];
        },
        successCallback,
    },
    params: {
        idUnit,
    },
});

export const openEditRoom = editedRoom => ({
    type: OPEN_EDIT_ROOM,
    editedRoom,
});

export const closeEditRoom = () => ({
    type: CLOSE_EDIT_ROOM,
});

export const patchRoom = (idRoom, patchObj, formName) => ({
    type: 'API_CALL',
    endpoint: endpoints.patchRoom,
    actions: {
        success: RMS_UPDATE_ROOM_SUCCESS,
    },
    params: { idRoom },
    body: patchObj,
    formName,
});
