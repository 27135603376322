import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { composeClassName } from 'utils';

import {
    hideModal,
} from './actions'
import localeMessages from "./messages";
import Button from "../Button";
import Icon from "../Icon";
import {FormattedMessage} from "react-intl";

const SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    BIG: 'big',
};

const TYPE = {
    CONFIRMATION: 'confirmation',
};

export class Modal extends Component {

    static SIZE = SIZE;
    static TYPE = TYPE;

    constructor() {
        super(...arguments);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.modalRoot = document.getElementById('modals');
        this.modalElement = document.createElement('div');
        this.modalContainerElement = React.createRef();
    }

    componentDidMount() {
        const {
            isModalOpen,
        } = this.props;

        if (isModalOpen) {
            this.appendModal();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isModalOpen: wasModalOpen,
        } = prevProps;
        const {
            isModalOpen,
            onHide,
        } = this.props;

        if (!wasModalOpen && isModalOpen) {
            this.appendModal();
        }

        if (wasModalOpen && !isModalOpen) {
            if (this.modalRoot.contains(this.modalElement)) {
                this.modalRoot.removeChild(this.modalElement);
            }
            if (typeof onHide === 'function') {
                onHide();
            }
        }
    }

    componentWillUnmount() {
        const {
            hideModal,
            isModalOpen,
        } = this.props;

        if (isModalOpen) {
            hideModal();
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Escape') {
            event.stopPropagation();

            this.closeModal()
        }
    }

    handleClick(event) {
        // This is here so that closing a modal inside InputMatrix don't trigger the same modal reopening
        // TODO: find a better solution
        event.stopPropagation();
    }

    handleOutsideClick(event) {
        if (event.target === this.modalContainerElement.current) {
            event.stopPropagation();
            this.closeModal()
        }
    }

    appendModal() {
        const {
            onShow,
        } = this.props;

        this.modalRoot.appendChild(this.modalElement);
        if (this.modalContainerElement) {
            this.modalContainerElement.current.focus();
        }
        if (typeof onShow === 'function') {
            onShow();
        }
    }

    closeModal() {
        const {
            onClose,
            hideModal,
        } = this.props;

        if (typeof onClose === 'function') {
            onClose()

        } else {
            hideModal()
        }
    }

    render() {
        const {
            children,
            headerText,
            hideClose,
            formatters = [],
            isModalOpen,
            withDelete,
            onDelete,
            disableDelete,
            deleteDisabledTooltip,
            index,
        } = this.props;

        if (!isModalOpen) {
            return false
        }

        return ReactDOM.createPortal((
            <Fragment>
                <div
                    className="modal-overlay"
                    style={{ zIndex: 20 + (index * 2)}}
                />
                <div
                    className="modal"
                    onKeyDown={this.handleKeyDown}
                    onClick={this.handleOutsideClick}
                    tabIndex="0"
                    ref={this.modalContainerElement}
                    style={{ zIndex: 21 + (index * 2)}}
                >
                    <div
                        className={composeClassName('modal-container', ...formatters)}
                        onClick={this.handleClick}
                    >
                        <div className="modal-content">

                            <div className="modal-header">
                                <div className="modal-header-text">
                                    {headerText}
                                </div>
                                {!hideClose &&
                                <div
                                    className="modal-close"
                                    onClick={this.closeModal}
                                />
                                }
                            </div>

                            <div className="modal-body">
                                {withDelete &&
                                <div
                                    className="modal-delete-container"
                                >
                                    <Button
                                        onClick={onDelete}
                                        formatters={[Button.TYPE.DELETE_LINK]}
                                        aria-labelledby="modal-delete-button"
                                        disabled={disableDelete}
                                    >
                                        <Icon
                                            name="bin"
                                            type="delete"
                                        />
                                        <FormattedMessage {...localeMessages.delete} />
                                    </Button>

                                    {disableDelete && !!deleteDisabledTooltip &&
                                    <div role="tooltip" id="modal-delete-button">
                                        {deleteDisabledTooltip}
                                    </div>
                                    }
                                </div>
                                }
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        ), this.modalElement)
    }
}

const mapStateToProps = (store, { id }) => {
    const modalReducer = store.modalReducer;

    return {
        isModalOpen: modalReducer.includes(id),
        index: modalReducer.indexOf(id),
    }
};

const mapDispatchToProps = {
    hideModal,
};

const ConnectedModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Modal);

ConnectedModal.SIZE = Modal.SIZE;

export default ConnectedModal
