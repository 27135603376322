import { Map } from 'immutable';
import React from "react";
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    MISCELLANEOUS_AMENITIES_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: MISCELLANEOUS_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.WASHING_MACHINE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.WASHING_MACHINE),
                    label: <FormattedMessage {...localeMessages.washingMachine} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SOUNDPROOF,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SOUNDPROOF),
                    label: <FormattedMessage {...localeMessages.soundproof} />,
                },
                {
                    type: 'checkbox',
                    integer: true,
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.CHILDRENS_CRIBS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.CHILDRENS_CRIBS),
                    label: <FormattedMessage {...localeMessages.cribs} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.CLOTHES_RACK,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.CLOTHES_RACK),
                    label: <FormattedMessage {...localeMessages.clothesRack} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.WASHER_DRYER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.WASHER_DRYER),
                    label: <FormattedMessage {...localeMessages.washerDryer} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FOLD_UP_BED,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FOLD_UP_BED),
                    label: <FormattedMessage {...localeMessages.foldUpBed} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TRASH_CANS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TRASH_CANS),
                    label: <FormattedMessage {...localeMessages.trashCans} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FREE_SHEETS_AND_TOWELS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FREE_SHEETS_AND_TOWELS),
                    label: <FormattedMessage {...localeMessages.freeSheetsAndTowels} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.PAID_SHEETS_AND_TOWELS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.PAID_SHEETS_AND_TOWELS),
                    label: <FormattedMessage {...localeMessages.paidSheetsAndTowels} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.WALK_IN_CLOSET,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.WALK_IN_CLOSET),
                    label: <FormattedMessage {...localeMessages.walkInCloset} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FAN,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FAN),
                    label: <FormattedMessage {...localeMessages.fan} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FIREPLACE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FIREPLACE),
                    label: <FormattedMessage {...localeMessages.fireplace} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.HEATING,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.HEATING),
                    label: <FormattedMessage {...localeMessages.heating} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.IRON,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.IRON),
                    label: <FormattedMessage {...localeMessages.iron} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.HOT_TUB,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.HOT_TUB),
                    label: <FormattedMessage {...localeMessages.hotTub} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.PRIVATE_ENTRANCE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.PRIVATE_ENTRANCE),
                    label: <FormattedMessage {...localeMessages.privateEntrance} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SAFE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SAFE),
                    label: <FormattedMessage {...localeMessages.safe} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SITTING_AREA,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SITTING_AREA),
                    label: <FormattedMessage {...localeMessages.sittingArea} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TILE_FLOOR,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TILE_FLOOR),
                    label: <FormattedMessage {...localeMessages.tileFloor} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.HARDWOOD_FLOOR,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.HARDWOOD_FLOOR),
                    label: <FormattedMessage {...localeMessages.hardwoodFloor} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.DESK,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.DESK),
                    label: <FormattedMessage {...localeMessages.desk} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.HYPOALLERGENIC,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.HYPOALLERGENIC),
                    label: <FormattedMessage {...localeMessages.hypoallergenic} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.CLEANING_PRODUCTS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.CLEANING_PRODUCTS),
                    label: <FormattedMessage {...localeMessages.cleaningProducts} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FEATHER_PILLOW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FEATHER_PILLOW),
                    label: <FormattedMessage {...localeMessages.featherPillow} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.NON_FEATHER_PILLOW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.NON_FEATHER_PILLOW),
                    label: <FormattedMessage {...localeMessages.nonFeatherPillow} />,
                },
            ],
        },
    ],
})
