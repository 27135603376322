import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Iterable, Map} from "immutable";
import moment from "moment";
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';

import Modal, {hideModal} from 'eksenia-lib/src/Modal';
import Form from "eksenia-lib/src/Form/Form";
import localeMessages from './messages';

import editPaymentForm from "./editPaymentForm";
import {
    PMT_EDIT_PAYMENT_MODAL_NAME,
    PMT_PAYMENT_PROPS,
} from "./constants";
import {
    closeEditPaymentModal,
    getPayments,
    postPayment,
    updatePayment,
} from "Payments/actions";

const defaultPaymentData = {
    [PMT_PAYMENT_PROPS.AMOUNT]: 0,
    [PMT_PAYMENT_PROPS.DATE]: moment(),
    [PMT_PAYMENT_PROPS.NOTE]: '',
    [PMT_PAYMENT_PROPS.PAYMENT_TYPE]: '',
};

export class EditPayment extends Component {

    constructor() {
        super(...arguments);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSubmitPayment = this.handleSubmitPayment.bind(this);
        this.saveSuccessCallback = this.saveSuccessCallback.bind(this);
    }

    handleCloseModal() {
        const {
            closeEditPaymentModal,
            hideModal,
        } = this.props;

        closeEditPaymentModal();
        hideModal();
    }

    handleSubmitPayment(formValues, formName) {
        const {
            editedPayment,
            idReservation,
            postPayment,
            updatePayment,
        } = this.props;

        const payment = {
            ...formValues.map(value => moment.isMoment(value)
                ? value.format()
                : value
            ).toJS(),
            [PMT_PAYMENT_PROPS.RESERVATION_ID]: idReservation,
        };

        if (Iterable.isIterable(editedPayment)) {
            const idPayment = editedPayment.get(PMT_PAYMENT_PROPS.ID);
            updatePayment(idPayment, payment, formName, this.saveSuccessCallback);

        } else {
            postPayment(payment, formName, this.saveSuccessCallback);
        }
    }

    saveSuccessCallback() {
        this.props.getPayments(this.props.idReservation);
        this.handleCloseModal();
    }

    render() {
        const {
            editedPayment,
            intl,
        } = this.props;

        const defaultFormData = editedPayment
            ? editedPayment.toJS()
            : defaultPaymentData;
        const blueprint = editPaymentForm(defaultFormData, intl);

        return (
            <Modal
                id={PMT_EDIT_PAYMENT_MODAL_NAME}
                headerText={<FormattedMessage {...localeMessages.payment} />}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                withDelete={!!editedPayment}
                onDelete={() => {}}
            >
                <Form
                    blueprint={blueprint}
                    onSubmit={this.handleSubmitPayment}
                />
            </Modal>
        );
    }
}


const mapStateToProps = (store) => {
    const paymentsReducer = store.paymentsReducer;

    return {
        editedPayment: paymentsReducer.get('editedPayment', Map()),
    }
};

const mapDispatchToProps = {
    closeEditPaymentModal,
    getPayments,
    hideModal,
    postPayment,
    updatePayment,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditPayment))
