import Icon from "eksenia-lib/src/Icon";
import React from "react";
import localeMessages from "./messages";
import {FormattedMessage} from "react-intl";
import {BKG_RESERVATION_STATUS_VALUES} from "./constants";

export const ReservationStatus = ({status}) => {

    const statusMessage = localeMessages[status];
    const statusValue = statusMessage
        ? <FormattedMessage key="text" {...statusMessage} />
        : '';
    const statusIcon = status === BKG_RESERVATION_STATUS_VALUES.CONFIRMED
        ? "confirmedReservation"
        : status === BKG_RESERVATION_STATUS_VALUES.CANCELED
            ? "canceledReservation"
            : "notConfirmedReservation";

    return (
        <span>
            <Icon
                name={statusIcon}
                type="reservation-confirmation"
                key="icon"
            />
            {statusValue}
        </span>
    )
}