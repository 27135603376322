import React, { Component } from 'react';
import {
    injectIntl,
} from 'react-intl';

import { composeClassName } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import {
    handleChange,
} from '../Form';
import localeMessages from './messages';
import Icon from "../Icon";
import Tooltip from "../Tooltip";

export class Select extends Component {

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            formatters = [],
            options,
            error,
            noValueText,
            connectTo,
            value,
            intl,
            help,
            disabled,
            submitting,
            ...props
        } = this.props;

        const selectOptions = typeof options === 'function'
            ? options(this.props)
            : options;

        const showNoValue = noValueText && Array.isArray(selectOptions)
            && !selectOptions.find(option => option.value === value);

        if (Array.isArray(selectOptions)
            && !selectOptions.find(option => option.value === value)) {
            selectOptions.unshift({
                value,
                key: value,
                text: intl.formatMessage(localeMessages.notAvailable),

            })
        }

        return (
            <div className={composeClassName('input-container-select', ...formatters)}>
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}

                <div className={composeClassName('select-container', ...formatters)}>
                    <select
                        className="input-select"
                        onChange={handleChange.bind(this, this)}
                        value={value}
                        { ...props }
                        disabled={disabled || submitting}
                    >
                        {Array.isArray(selectOptions) && selectOptions.map(option => (
                            <option
                                key={option.value}
                                value={option.value}
                            >
                                {option.text}
                            </option>
                        ))}
                    </select>
                    <Icon
                        name={"inputArrowDown"}
                        type={"input-dropdown-arrow collapsed"}
                    />
                </div>

                {showNoValue &&
                <div className="select-no-value-warning">
                    {noValueText}
                </div>
                }

                {help && <Tooltip help={help} />}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}

export default injectIntl(Select)
