import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import Modal, {
    hideModal,
    showModal,
} from 'eksenia-lib/src/Modal';
import Button from 'eksenia-lib/src/Button';
import {
    BKG_EDIT_RESERVATION_MODAL_NAME,
} from 'Reservations';

import {
    ADD_RESERVATION_OR_UNAVAILABILITY_MODAL,
    CAL_UNAVAILABILITY_MODAL,
} from './constants';
import {
    setEditedUnavailability,
} from './actions';
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";

export class AddReservationOrUnavailability extends Component {

    render() {
        const {
            showModal,
            hideModal,
            setEditedUnavailability,
        } = this.props;

        return (
            <>
                <Modal
                    id={ADD_RESERVATION_OR_UNAVAILABILITY_MODAL}
                    formatters={[Modal.SIZE.SMALL, Modal.TYPE.CONFIRMATION, "add-reservation-or-unavailability"]}
                    onClose={() => {
                        setEditedUnavailability(Map());
                        hideModal();
                    }}
                >
                    <div>
                        <Button
                            onClick={() => {
                                hideModal();
                                setEditedUnavailability(Map());
                                showModal(BKG_EDIT_RESERVATION_MODAL_NAME);
                            }}
                            formatters={[Button.TYPE.PRIMARY, Button.WIDTH.FULL]}
                        >
                            <FormattedMessage {...localeMessages.addReservation} />
                        </Button>
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                hideModal();
                                showModal(CAL_UNAVAILABILITY_MODAL);
                            }}
                            formatters={[Button.TYPE.TERTIARY, Button.WIDTH.FULL]}
                        >
                            <FormattedMessage {...localeMessages.closeUnit} />
                        </Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (store) => {
    const calendarReducer = store.calendarReducer;

    return {
        editedUnavailability: calendarReducer.get('editedUnavailability', Map()),
    }
};

const mapDispatchToProps = {
    hideModal,
    showModal,
    setEditedUnavailability,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReservationOrUnavailability)
