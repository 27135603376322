import {fromJS, Iterable} from "immutable";
import moment from 'moment';
import {
    PMT_GET_PAYMENTS_SUCCESS,
    PMT_OPEN_EDIT_PAYMENT_MODAL,
    PMT_CLOSE_EDIT_PAYMENT_MODAL,
    PMT_POST_PAYMENT_SUCCESS,
    PMT_PAYMENT_PROPS,
    PMT_DELETE_PAYMENT_SUCCESS,
} from "./constants";
import {reduceWithDefault} from "utils";

const initialState = fromJS({
    editedPayment: false,
    payments: [],
});

function toStorePayment(payment) {
    return payment.merge({
        [PMT_PAYMENT_PROPS.DATE]: moment(payment.get(PMT_PAYMENT_PROPS.DATE))
    });
}

function toStorePayments(payments){
    return payments.map(payment => toStorePayment(payment));
}

const reducers = {
    [PMT_GET_PAYMENTS_SUCCESS]: (state, { data }) =>
        state.merge({
            payments: toStorePayments(fromJS(data || [])),
        }),

    [PMT_POST_PAYMENT_SUCCESS]: (state, { data }) =>
        state.merge({
            payments: state.get('payments').push(toStorePayment(fromJS(data))),
        }),

    [PMT_DELETE_PAYMENT_SUCCESS]: (state, { data }) =>
        state.merge({
            payments: state.get('payments')
                .filter(payment =>
                    payment.get(PMT_PAYMENT_PROPS.ID) !== data[PMT_PAYMENT_PROPS.ID]
                ),
        }),

    [PMT_OPEN_EDIT_PAYMENT_MODAL]: (state, { editedPayment }) =>
        state.merge({
            editedPayment: Iterable.isIterable(editedPayment) && editedPayment,
        }),

    [PMT_CLOSE_EDIT_PAYMENT_MODAL]: (state) =>
        state.merge({
            editedPayment: initialState.get('editedPayment'),
        }),
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
