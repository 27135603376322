export const GET_RATES_REQUEST = 'GET_RATES_REQUEST';
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS';
export const GET_RATES_ERROR = 'GET_RATES_ERROR';

export const POST_RATE_REQUEST = 'POST_RATE_REQUEST';
export const POST_RATE_SUCCESS = 'POST_RATE_SUCCESS';
export const POST_RATE_ERROR = 'POST_RATE_ERROR';

export const UPDATE_RATE_REQUEST = 'UPDATE_RATE_REQUEST';
export const UPDATE_RATE_SUCCESS = 'UPDATE_RATE_SUCCESS';
export const UPDATE_RATE_ERROR = 'UPDATE_RATE_ERROR';

export const DELETE_RATE_REQUEST = 'DELETE_RATE_REQUEST';
export const DELETE_RATE_SUCCESS = 'DELETE_RATE_SUCCESS';
export const DELETE_RATE_ERROR = 'DELETE_RATE_ERROR';

export const ADD_EDITED_RATE = 'ADD_EDITED_RATE';
export const CLEAR_EDITED_RATE = 'CLEAR_EDITED_RATE';

export const EDIT_RATE_FORM_NAME = 'EDIT_RATE_FORM_NAME';
export const RATE_BOOKING_LIMITATIONS_FORM_NAME = 'RATE_BOOKING_LIMITATIONS_FORM_NAME';
export const RATE_DISCOUNTS_FORM_NAME = 'RATE_DISCOUNTS_FORM_NAME';
export const RATE_ROOMS_FORM_NAME = 'RATE_ROOMS_FORM_NAME';
export const EDIT_RATE_MODAL_NAME = 'EDIT_RATE_MODAL_NAME';
export const DELETE_RATE_CONFIRMATION_NAME = 'DELETE_RATE_CONFIRMATION_NAME';
    
export const RATE_PROPS = {
    ID: 'ID',
    NAME: 'Name',
    FIRST_NIGHT: 'FirstNight',
    LAST_NIGHT: 'LastNight',
    PRICE_PER: 'PricePer',
    ROOM_PRICE: 'RoomPrice',
    SINGLE_PRICE: 'SinglePrice',
    DOUBLE_PRICE: 'DoublePrice',
    EXTRA_PERSON: 'ExtraPerson',
    EXTRA_CHILD: 'ExtraChild',
    MINIMUM_STAY: 'MinimumStay',
    MAXIMUM_STAY: 'MaximumStay',
    MIN_DAYS_IN_ADVANCE: 'MinDaysInAdvance',
    MAX_DAYS_IN_ADVANCE: 'MaxDaysInAdvance',
    ALLOW_DAYS: 'DaysApplied',
    ALLOW_CHECK_IN_DAYS: 'CheckInDays',
    ALLOW_CHECK_OUT_DAYS: 'CheckOutDays',
    ROOMS: 'RoomList',
    LONG_STAY_DISCOUNTS: 'LongStayDiscountList',
    DAYS_LIMITATIONS: 'daysLimitations',
    CREATED_AT: 'CreatedAt',
};

export const RATE_LONG_STAY_DISCOUNT_PROPS = {
    ID: 'ID',
    DAYS: 'Days',
    PERCENT: 'Percent',
    PER_NIGHT: 'PerNight',
    ONCE_OFF: 'OnceOff',
    PRICE_CAP: 'PriceCap',
    TEMP_ID: 'id',
};

export const PRICE_PER_OPTIONS = {
    NIGHT: 'Night',
    WEEK: 'Week',
};

export const DAYS_OF_THE_WEEK = {
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat',
    SUN: 'Sun',
};

export const RATE_ROOM_PROPS = {
    USED: 'used',
    MULTIPLIER: 'Multiplier',
    ROOM_ID: 'RoomID',
    ID: 'ID',
};

export const DEFAULT_LONG_STAY_DISCOUNTS = [{
    [RATE_LONG_STAY_DISCOUNT_PROPS.ID]: 'default',
    [RATE_LONG_STAY_DISCOUNT_PROPS.DAYS]: 7,
    [RATE_LONG_STAY_DISCOUNT_PROPS.PERCENT]: 0,
    [RATE_LONG_STAY_DISCOUNT_PROPS.PER_NIGHT]: 0,
    [RATE_LONG_STAY_DISCOUNT_PROPS.ONCE_OFF]: 0,
    [RATE_LONG_STAY_DISCOUNT_PROPS.PRICE_CAP]: 0,
}];
