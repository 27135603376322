import React, { Component } from 'react';

import { composeClassName } from 'utils';
import Tooltip from "../Tooltip";

export default class Widget extends Component {

    render() {
        const {
            children,
            title,
            formatters = [],
            help,
        } = this.props;

        return (
            <div className={composeClassName('widget', ...formatters)}>
                <div className="widget-header">
                    {title}
                    {help && <Tooltip help={help} small />}
                </div>
                <div className="widget-content">
                    {children}
                </div>
            </div>
        );        
    }
}
