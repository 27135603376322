import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Iterable } from 'immutable';
import RCRangeCalendar from 'rc-calendar/lib/RangeCalendar';
import RCDatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import {
    injectIntl,
} from 'react-intl';
import en from 'rc-calendar/lib/locale/en_GB';
import sr from 'rc-calendar/lib/locale/sr_RS';

import {
    handleDateChange,
} from '../Form'
import Input from '../Input'
import localeMessages from './messages';
import {USR_PROPS} from "../User";
import moment from "moment";
import {getLocaleString} from "../utils";
import Icon from "../Icon";

export class RangeDatePicker extends Component {

    prepareDisplayValue(value) {
        const {
            intl,
        } = this.props;

        const from = value[0]
            ? intl.formatDate(value[0].toDate())
            : '';
        const to = value[1]
            ? intl.formatDate(value[1].toDate())
            : '';

        return intl.formatMessage(localeMessages.range, {from, to});
    }

    render() {
        const {
            label,
            formatters = [],
            error,
            value,
            language,
            disabled,
            submitting,
            ...props
        } = this.props;

        const locale = language === 'sr' || language === 'cnr'
            ? sr
            : en;

        const calendar = (
            <RCRangeCalendar
                format={locale === sr ? 'DD.MM.YYYY.' : 'D/M/YYYY'}
                locale={locale}
            />
        );

        const plainValue = Iterable.isIterable(value)
            ? value.toJS()
            : value;

        if (Array.isArray(plainValue)) {
            plainValue.map( val =>
                moment.isMoment(val)
                    ? val.locale(getLocaleString(language))
                    : val
            )
        }

        return (
            <RCDatePicker
                {...props}
                value={plainValue}
                onChange={handleDateChange.bind(this, this)}
                calendar={calendar}
            >
                {({value}) => (
                    <Input
                        value={ this.prepareDisplayValue(plainValue) }
                        formatters={['range-date-picker', ...formatters]}
                        label={label}
                        error={error}
                        icon={
                            <Icon
                                name={"inputArrowDown"}
                                type={"input-dropdown-arrow collapsed"}
                            />
                        }
                        disabled={disabled || submitting}
                    />
                )}
            </RCDatePicker>
        )
    }
}

const mapStateToProps = (store) => {
    const {
        userReducer,
    } = store;

    const user = userReducer.get('user');
    return {
        language: user && user.get(USR_PROPS.LANGUAGE),
    }
};

export default injectIntl(connect(mapStateToProps, undefined)(RangeDatePicker))
