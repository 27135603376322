import React, { Component } from 'react';
import { connect } from 'react-redux';

import { composeClassName } from 'utils';

import { removeNotification } from './actions';
import { NOTIFICATION_TYPE } from './constants';

export class Notification extends Component {

    componentDidMount() {
        const {
            id,
            type,
            removeNotification,
        } = this.props;

        if (type === NOTIFICATION_TYPE.SUCCESS) {
            this.removeTimeout = window.setTimeout(() => removeNotification(id), 5000);
        }
    }

    componentWillUnmount() {
        if (this.removeTimeout) {
            window.clearTimeout(this.removeTimeout);
        }
    }

    render() {
        const {
            id,
            content,
            type,
            removeNotification,
        } = this.props;

        const icon = type === NOTIFICATION_TYPE.ERROR
            ? <i className="error-icon">
                !
            </i>
            : <i className="success-icon" />;

        return (
            <div className={composeClassName('notification', type)} >
                {icon}
                <div className="notification-content">
                    {content}
                </div>

                <div 
                    className="notification-close"
                    onClick={removeNotification.bind(this, id)}
                />
            </div>
        );
    }
};

const mapDispatchToProps = {
    removeNotification,
};

export default connect(
    undefined,
    mapDispatchToProps,
)(Notification);
