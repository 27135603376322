import React from 'react';
import {List} from 'immutable';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    ONBOARDING_TAXES_AND_FEES_FORM_NAME, TAXES_AND_FEES_LIST_INPUT_NAME,
} from './constants';
import OnboardingTaxOrFee from "./OnboardingTaxOrFee";
import {
    TAXES_AND_FEES_PROPS,
    messages as taxesAndFeesMessages,
    EDIT_TAX_OR_FEE_MODAL_NAME,
} from "../TaxesAndFees";
import localeMessages from "./messages";

export default (
    handleTaxOrFeeClick,
    handleAddNewTaxOrFeeClick,
) => ({
    formName: ONBOARDING_TAXES_AND_FEES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    submitButtonText: <FormattedMessage {...localeMessages.done} />,
    fields: [
        {
            type: 'inputMatrix',
            name: TAXES_AND_FEES_LIST_INPUT_NAME,
            initialValue: List(),
            addInputText: <FormattedMessage {...taxesAndFeesMessages.addTaxOrFee} />,
            addInputName: 'add-tax-or-fee-link',
            deleteInputConfirmationText: <FormattedMessage {...taxesAndFeesMessages.deleteTaxOrFee} />,
            tableView: true,
            onAddInput: handleAddNewTaxOrFeeClick,
            renderAddInput: () =>
                <OnboardingTaxOrFee
                    inputId={EDIT_TAX_OR_FEE_MODAL_NAME}
                    formName={ONBOARDING_TAXES_AND_FEES_FORM_NAME}
                    inputName={TAXES_AND_FEES_LIST_INPUT_NAME}
                />
            ,
            headers: [
                {
                    text: taxesAndFeesMessages.type,
                    id: 'type',
                    width: '50%',
                },
                {
                    text: taxesAndFeesMessages.logic,
                    id: 'logic',
                    width: '50%',
                },
            ],
            inputs: {
                id: (listName, index, value) => value.get(TAXES_AND_FEES_PROPS.ID),
                entityId: value => value.get(TAXES_AND_FEES_PROPS.ID),
                onClick: handleTaxOrFeeClick,
                edit: props => {
                    const {
                        inputId,
                    } = props;

                    return (<OnboardingTaxOrFee
                        inputId={inputId}
                        formName={ONBOARDING_TAXES_AND_FEES_FORM_NAME}
                        inputName={TAXES_AND_FEES_LIST_INPUT_NAME}
                    />)
                },
                fields: [
                    {
                        type: 'custom',
                        name: TAXES_AND_FEES_PROPS.TYPE,
                        render: value => value
                    },
                    {
                        type: 'custom',
                        name: TAXES_AND_FEES_PROPS.LOGIC,
                        render: value => value
                    },
                ],
            },
        },
    ],
})

