export const INPUT_FORMATTERS = {
    HEIGHT: {
        S: 'height-s',
        M: 'height-m',
    },
    WIDTH: {
        S: 'width-s',
        M: 'width-m',
        L: 'width-l',
    },
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
    TABLE: 'form-table',
};

