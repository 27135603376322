import {fromJS} from 'immutable';

import {reduceWithDefault} from 'utils';
import {
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
} from './constants';

const initialState = fromJS({
    messages: [],
});

const reducers = {
    [GET_MESSAGES_REQUEST]: (state, { data }) =>
        state.set('messages', initialState.get('messages')),

    [GET_MESSAGES_SUCCESS]: (state, { data }) =>
        state.set('messages', fromJS(data)),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
