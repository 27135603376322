import React, { Component } from 'react';
import {
    FormattedNumber,
} from 'react-intl';

export default class Money extends Component {

    render() {
        const {
            value,
        } = this.props;

        // eslint-disable-next-line
        return <FormattedNumber value={value} style="currency" currency="EUR" />
    }
}
