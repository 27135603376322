import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import { Iterable } from 'immutable';

import {Form} from "eksenia-lib/src/Form";
import Modal, {hideModal} from "eksenia-lib/src/Modal";
import ModalWithForm from "eksenia-lib/src/ModalWithForm";
import localeMessages from "./messages";
import setLanguageForm from "./setLanguageForm";
import { setLanguage } from './actions';
import {SET_LANGUAGE_FORM_NAME, SET_LANGUAGE_MODAL_NAME, USR_PROPS} from "./constants";

export class EditLanguage extends Component {

    constructor() {
        super(...arguments);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(formValues) {
        const {
            setLanguage,
        } = this.props;

        setLanguage(formValues.get(USR_PROPS.LANGUAGE), window.location.reload());
    }

    render() {
        const {
            user,
            hideModal,
        } = this.props;

        const language = Iterable.isIterable(user) && user.get(USR_PROPS.LANGUAGE);

        return (
            <ModalWithForm
                id={SET_LANGUAGE_MODAL_NAME}
                key={SET_LANGUAGE_MODAL_NAME}
                headerText={<FormattedMessage {...localeMessages.chooseLanguage} />}
                formatters={[Modal.SIZE.BIG]}
                onClose={() => hideModal()}
                activeForms={[SET_LANGUAGE_FORM_NAME]}
            >
                <Form
                    key="choose-language"
                    blueprint={setLanguageForm(language)}
                    onSubmit={this.handleSubmit}
                />
            </ModalWithForm>
        );
    }
}

const mapStateToProps = store => {
    const userReducer = store.userReducer;

    return {
        user: userReducer.get('user'),
    }
};

const mapDispatchToProps = {
    hideModal,
    setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLanguage)