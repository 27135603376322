import { defineMessages } from 'react-intl';

export default defineMessages({
    email: {
        id: 'login.email',
        defaultMessage: 'Email',
    },
    password: {
        id: 'login.password',
        defaultMessage: 'Password',
    },
    logIn: {
        id: 'login.logIn',
        defaultMessage: 'Log in',
    },
    emailCannotBeEmpty: {
        id: 'login.emailCannotBeEmpty',
        defaultMessage: 'Email cannot be empty',
    },
    passwordCannotBeEmpty: {
        id: 'login.passwordCannotBeEmpty',
        defaultMessage: 'Password cannot be empty',
    },
    loginFailed: {
        id: 'login.loginFailed',
        defaultMessage: 'Login failed. If you forgot your password you can {reset}.',
    },
    reset: {
        id: 'login.reset',
        defaultMessage: 'reset it',
    },
    ekseniaLogo: {
        id: "login.ekseniaLogo",
        defaultMessage: "Eksenia logo",
    },
    resetPassword: {
        id: 'login.resetPassword',
        defaultMessage: 'Request a new password',
    },
    setPassword: {
        id: 'login.setPassword',
        defaultMessage: 'Set New Password',
    },
    resetFailed: {
        id: 'login.resetFailed',
        defaultMessage: 'Reset failed. Please contact support at {email}.',
    },
    requestFailed: {
        id: 'login.requestFailed',
        defaultMessage: 'Request failed. Please contact support at {email}.',
    },
    requestSuccess: {
        id: 'login.requestSuccess',
        defaultMessage: 'If the email address you provided is registered in Eksenia, you will soon receive an email with the instructions how to reset your password.',
    },
    forHelp: {
        id: 'login.forHelp',
        defaultMessage: 'For help contact {email}.',
    },
    tryAgainTimer: {
        id: 'login.tryAgainTimer',
        defaultMessage: 'You can send a request again in {time} seconds.',
    },
    tryAgainNow: {
        id: 'login.tryAgainNow',
        defaultMessage: 'You can send a request again.',
    },
    tryAgain: {
        id: 'login.tryAgain',
        defaultMessage: 'Request a new email',
    },
    forgotYourPassword: {
        id: 'login.forgotYourPassword',
        defaultMessage: 'Forgot your password?',
    },
})
