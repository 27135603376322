import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import {
    showModal,
} from '../Modal';
import { composeClassName } from '../utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import {
    handleFileUploadChange,
} from '../Form';
import Button from "../Button";
import localeMessages from './messages';
import Tooltip from "../Tooltip";
import PreviewFiles from "./PreviewFiles";
import ProgressBar from "../ProgressBar";
import {FILE_INPUT_ID_PREFIX, FILE_INPUT_PROPS} from "./constants";

export class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleShowPreview = this.handleShowPreview.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
    }

    handleChange(event) {
        const {
            value,
            showPreview,
        } = this.props;

        const files = event.currentTarget.files;
        const newFilesValue = [];
        for (let i = 0; i < files.length; i++) {
            newFilesValue.push(Map({
                [FILE_INPUT_PROPS.FILE]: files[i],
                [FILE_INPUT_PROPS.PATH]: URL.createObjectURL(files[i]),
            }));
        }

        const toDelete = value.get(FILE_INPUT_PROPS.DO_NOT_KEEP) || List();
        const ids = value.get(FILE_INPUT_PROPS.IDS);
        const newValue = Map({
            [FILE_INPUT_PROPS.IDS]: ids,
            [FILE_INPUT_PROPS.FILES]: List(newFilesValue),
            [FILE_INPUT_PROPS.DO_NOT_KEEP]: newFilesValue.length && !toDelete.size
                ? ids.map(id => id)
                : List(),
        });

        handleFileUploadChange(this, newValue, event);
        if (showPreview && newFilesValue.length && value.get(FILE_INPUT_PROPS.IDS).size) {
            this.handleShowPreview();
        }
    }

    handleShowPreview() {
        const {
            showModal,
            value,
            publicFileUrlPrefix,
            name,
        } = this.props;

        if (publicFileUrlPrefix && value) {
            showModal(name)
        }
    }

    handleImageClick(type, checked, id) {
        const {
            name,
            value,
            onChangeFromForm,
            publicFileUrlPrefix,
            multiple,
        } = this.props;

        let doNotKeep = value.get(FILE_INPUT_PROPS.DO_NOT_KEEP) || List();
        let doNotUpload = value.get(FILE_INPUT_PROPS.DO_NOT_UPLOAD) || List();

        if (type === 'old') {
            id = id.split(publicFileUrlPrefix)[1];
            doNotKeep = checked
                ? doNotKeep.filter( val => val !== id)
                : doNotKeep.push(id);

            if (!multiple && checked) {
                doNotUpload = List([value.getIn([FILE_INPUT_PROPS.FILES, 0, FILE_INPUT_PROPS.PATH])]);
            }
        }

        if (type === 'new') {
            doNotUpload = checked
                ? doNotUpload.filter( val => val !== id)
                : doNotUpload.push(id);

            if (!multiple && checked) {
                doNotKeep = List([value.getIn([FILE_INPUT_PROPS.IDS, 0])]);
            }
        }

        const newValue = Map({
            [FILE_INPUT_PROPS.IDS]: value.get(FILE_INPUT_PROPS.IDS),
            [FILE_INPUT_PROPS.FILES]: value.get(FILE_INPUT_PROPS.FILES),
            [FILE_INPUT_PROPS.DO_NOT_KEEP]: doNotKeep,
            [FILE_INPUT_PROPS.DO_NOT_UPLOAD]: doNotUpload,
        });

        onChangeFromForm(name, newValue);
    }

    renderAfterInput() {
        const {
            value,
            showPreview,
            help,
        } = this.props;

        const ids = value.get(FILE_INPUT_PROPS.IDS) || List();
        const files = value.get(FILE_INPUT_PROPS.FILES) || List();
        const doNotKeep = value.get(FILE_INPUT_PROPS.DO_NOT_KEEP) || List();
        const doNotUpload = value.get(FILE_INPUT_PROPS.DO_NOT_UPLOAD) || List();

        const hasFiles = ids.size + files.size > 0;
        const count = ids.size - doNotKeep.size + files.size - doNotUpload.size;

        return (
            <>
                {showPreview && hasFiles &&
                <Button
                    formatters={[Button.TYPE.LINK, Button.SIZE.SMALL]}
                    onClick={this.handleShowPreview}
                >
                    <FormattedMessage {...localeMessages.preview} values={{count}} />
                </Button>
                }

                {help && <Tooltip help={help} />}
            </>
        )
    }

    renderUploadProgress() {
        const {
            totalUploadProgress,
        } = this.props;

        return (
            <div className="upload-progress">
                <ProgressBar progress={totalUploadProgress} formatters={['upload']} />
            </div>
        )
    }

    urlWithPrefix(id) {
        return `${this.props.publicFileUrlPrefix}${id}`;
    }

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            formatters = [],
            error,
            parentValue,
            multiple,
            value,
            defaultValue,
            publicFileUrlPrefix,
            dispatch,
            showModal,
            previewTitle,
            totalUploadProgress,
            showPreview,
            formName,
            validateWith,
            disabled,
            submitting,
            ...props
        } = this.props;

        const ids = value.get(FILE_INPUT_PROPS.IDS) || List();
        const files = value.get(FILE_INPUT_PROPS.FILES) || List();
        const doNotKeep = value.get(FILE_INPUT_PROPS.DO_NOT_KEEP) || List();
        const doNotUpload = value.get(FILE_INPUT_PROPS.DO_NOT_UPLOAD) || List();

        const fileUrls = ids.map(id => ({
            url: this.urlWithPrefix(id),
            doNotKeep: doNotKeep.contains(id),
        }));

        const fileLocations = files.map(fileMap => {
            const path = fileMap.get(FILE_INPUT_PROPS.PATH);
            return {
                path,
                doNotUpload: doNotUpload.contains(path),
            }
        });

        return (
            <div
                className={composeClassName('input-file-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}
                <label
                    className={composeClassName(
                        'input-file',
                        error && 'error',
                        (disabled || submitting) && 'disabled',
                        ...formatters
                    )}
                >
                    {multiple
                        ? <FormattedMessage {...localeMessages.chooseFiles} />
                        : <FormattedMessage {...localeMessages.chooseFile} />
                    }
                    <input
                        type="file"
                        ref={this.inputRef}
                        onChange={this.handleChange}
                        multiple={multiple}
                        id={FILE_INPUT_ID_PREFIX + props.name}
                        { ...props }
                        disabled={disabled || submitting}
                    />
                </label>

                {totalUploadProgress === undefined ? this.renderAfterInput() : this.renderUploadProgress()}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />

                <PreviewFiles
                    id={props.name}
                    fileUrls={fileUrls}
                    fileLocations={fileLocations}
                    headerText={previewTitle}
                    handleImageClick={this.handleImageClick}
                />
            </div>
        )
    }
}

const mapStateToProps = (store, ownProps) => {
    const fileReducer = store.fileReducer;

    return {
        publicFileUrlPrefix: fileReducer.get('publicFileUrlPrefix'),
        totalUploadProgress: fileReducer.getIn(['uploadProgress', ownProps.name, 'total']),
    }
};

const mapDispatchToProps = {
    showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)
