import React, { Component } from 'react';

import { composeClassName } from 'utils';
import { handleManualValueChange } from '../Form';
import InputLabel from '../InputLabel'

export default class CustomFormElement extends Component {

    componentDidUpdate(prevProps) {
        const {
            defaultValue: oldValue,
        } = prevProps;
        const {
            defaultValue,
        } = this.props;

        if (oldValue !== defaultValue) {
            handleManualValueChange(this, defaultValue);
        }
    }

    render() {
        const {
            label,
            formatters = [],
            render,
            ...props
        } = this.props;

        return (
            <div
                className={composeClassName('input-custom-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}
                <div
                    className={composeClassName('input-custom', ...formatters)}
                >
                    {render(props)}
                </div>
            </div>
        )
    }
}
