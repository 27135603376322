import React, { Component } from 'react';

import { composeClassName } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import {
    handleChange,
} from '../Form';
import Tooltip from "../Tooltip";

export default class Input extends Component {

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            formatters = [],
            error,
            parentValue,
            icon,
            help,
            disabled,
            submitting,
            ...props
        } = this.props;

        return (
            <div
                className={composeClassName('input-text-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}
                <input
                    type="text"
                    className={composeClassName('input-text', error && 'error', ...formatters)}
                    onChange={handleChange.bind(this, this)}
                    maxLength={250}
                    { ...props }
                    disabled={disabled || submitting}
                />

                {icon}

                {help && <Tooltip help={help} />}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
