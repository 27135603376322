import { Map } from 'immutable';
import React from "react";
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    MEDIA_AMENITIES_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: MEDIA_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TV,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TV),
                    label: <FormattedMessage {...localeMessages.tv} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.GAME_CONSOLE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.GAME_CONSOLE),
                    label: <FormattedMessage {...localeMessages.gameConsole} />,
                },
                {
                    type: 'checkbox',
                    integer: true,
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.PC,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.PC),
                    label: <FormattedMessage {...localeMessages.pc} />,
                },

                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.LAPTOP,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.LAPTOP),
                    label: <FormattedMessage {...localeMessages.laptop} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FAX,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FAX),
                    label: <FormattedMessage {...localeMessages.fax} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.IPAD,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.IPAD),
                    label: <FormattedMessage {...localeMessages.iPad} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TABLET,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TABLET),
                    label: <FormattedMessage {...localeMessages.tablet} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.CABLE_CHANNELS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.CABLE_CHANNELS),
                    label: <FormattedMessage {...localeMessages.cableChannels} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.DVD_PLAYER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.DVD_PLAYER),
                    label: <FormattedMessage {...localeMessages.dvdPlayer} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BLUE_RAY_PLAYER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BLUE_RAY_PLAYER),
                    label: <FormattedMessage {...localeMessages.blueRayPlayer} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.PAY_PER_VIEW_CHANNELS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.PAY_PER_VIEW_CHANNELS),
                    label: <FormattedMessage {...localeMessages.payPerViewChannels} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.CABLE_SATELLITE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.CABLE_SATELLITE),
                    label: <FormattedMessage {...localeMessages.cableSatellite} />,
                },
            ],
        },
    ],
})
