import {FILE_INPUT_PROPS} from "../File/constants";
import { List } from 'immutable';

const filesCount = value =>
    (value.get(FILE_INPUT_PROPS.IDS) || List()).size
    - (value.get(FILE_INPUT_PROPS.DO_NOT_KEEP) || List()).size
    + (value.get(FILE_INPUT_PROPS.FILES) || List()).size
    - (value.get(FILE_INPUT_PROPS.DO_NOT_UPLOAD) || List()).size

export default {
    required: value =>
        !value && value !== 0
            ? 'required'
            : false,

    numberGreaterThan: (value, limit) =>
        value <= limit
            ? {
                message: 'numberGreaterThan',
                values: {
                    limit,
                }
            }
            : false,

    numberSmallerOrEqualTo: (value, limit) =>
        value > limit
            ? {
                message: 'numberSmallerOrEqualTo',
                values: {
                    limit,
                }
            }
            : false,

    numberNotNegative: (value) =>
        value < 0
            ? 'numberNotNegative'
            : false,

    numberInRangeInclusive: (value, low, high) =>
        value < low || value > high
            ? {
                message: 'minimumFiles',
                values: {
                    low,
                    high,
                }
            }
            : false,

    minimumFiles: (value, limit) =>
        filesCount(value) < limit
            ? {
                message: 'minimumFiles',
                values: {
                    limit,
                }
            }
            : false,

    maximumFiles: (value, limit) =>
        filesCount(value) > limit
            ? {
                message: 'maximumFiles',
                values: {
                    limit,
                }
            }
            : false,

    requiredFile: value =>
        filesCount(value) < 1
            ? {
                message: 'requiredFile',
            }
            : false,

    maxFileSize: (value, limit) =>
        (value.get(FILE_INPUT_PROPS.FILES) || List()).find(file =>
            file.get(FILE_INPUT_PROPS.FILE).size > (limit || 1.5e+7)
        )
            ? {
                message: 'maxFileSize',
                values: {
                    limit: (limit || 1.5e+7) / 1000000
                }
            }
            : false,

}
