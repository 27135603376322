export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';

export const MESSAGE_PROPS = {
    SUBJECT: 'Subject',
    ROOM: 'Room',
    RESERVATION: 'Reservation',
    OVERBOOKING: 'Overbooking',
};

export const MESSAGE_ROOM_PROPS = {
    ID: 'RoomID',
    NAME: 'RoomName',
    UNIT_ID: 'UnitID',
    UNIT_NAME: 'UnitName',
};

export const MESSAGE_RESERVATION_PROPS = {
    ID: 'ID',
    NAME: 'Name',
    CHECK_IN_TIME: 'CheckInTime',
    CHECK_OUT_TIME: 'CheckOutTime',
};
