import Login from './Login';
import ResetPassword from './ResetPassword';
import RequestPasswordReset from "./RequestPasswordReset";
import './Login.scss';

export {
    ResetPassword,
    RequestPasswordReset,
}

export default Login
