import React, { Component } from 'react';
import {
    FormattedMessage,
    FormattedDate,
} from "react-intl";
import './Invoice.scss';
import localeMessages from './messages';
import ReactDOM from "react-dom";
import {PROPERTY_PROPS} from "../Properties";
import {GUEST_PROPS} from "../Guests";
import moment from "moment";

export default class Invoice extends Component {

    render() {
        const {
            guestName,
            invoiceNumber,
            invoiceTable,
            property,
            guest,
            countryNamesMap,
        } = this.props;

        const guestAddress = guest.get(GUEST_PROPS.ADDRESS);
        const guestCity = guest.get(GUEST_PROPS.CITY);
        const guestCountry = countryNamesMap.get(guest.get(GUEST_PROPS.COUNTRY));

        let guestCityLine = '';
        if (guestCity && guestCountry) {
            guestCityLine = <FormattedMessage
                {...localeMessages.cityLine}
                values={{
                    city: guestCity,
                    country: guestCountry,
                }}
            />
        } else if (guestCountry) {
            guestCityLine = guestCountry;
        } else if (guestCity) {
            guestCityLine = guestCity;
        }

        return ReactDOM.createPortal((
            <div className="invoice-print">
                <div className="property-data">
                    <div className="name">
                        {property.get(PROPERTY_PROPS.LEGAL_NAME)}
                    </div>
                    <div>
                        {property.get(PROPERTY_PROPS.LEGAL_ADDRESS)}
                    </div>
                    <FormattedMessage
                        {...localeMessages.cityLine}
                        values={{
                            city: property.get(PROPERTY_PROPS.LEGAL_CITY),
                            country: countryNamesMap.get(property.get(PROPERTY_PROPS.LEGAL_COUNTRY)),
                        }}
                        tagName="div"
                    />
                    <div>
                        {property.get(PROPERTY_PROPS.BUSINESS_EMAIL)}
                    </div>
                </div>

                <div className="guest-data">
                    <div className="name">
                        { guestName }
                    </div>
                    {!!guestAddress &&
                        <div>
                            {guestAddress}
                        </div>
                    }
                    {guestCityLine}
                </div>

                <div className="title">
                    <FormattedMessage {...localeMessages.invoice} tagName="h1" />
                </div>
                <div className="invoice-details">
                    <FormattedMessage {...localeMessages.invoiceNumber} values={{invoiceNumber}} tagName="div" />
                    <FormattedMessage
                        {...localeMessages.invoiceDate}
                        values={{date: <FormattedDate value={moment()}/>}}
                        tagName="div"
                    />
                </div>

                {invoiceTable}

                <div className="greeting-container">
                    <FormattedMessage {...localeMessages.greeting} tagName="div" />
                    <div>{ property.get(PROPERTY_PROPS.NAME) }</div>
                </div>
            </div>
        ), document.getElementById('print'));
    }
}