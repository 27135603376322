import React, {Component} from 'react';

import { composeClassName, generateKey } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import {handleCheckChange,} from '../Form';
import Tooltip from "../Tooltip";

const TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
};


export default class Checkbox extends Component {

    static TYPE = TYPE;

    constructor() {
        super(...arguments);

        this.state = {};
    }

    componentDidMount() {
        if (!this.props.name) {
            this.setState({
                name: generateKey(),
            });
        }
    }

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            value,
            defaultValue,
            formatters = [],
            name,
            error,
            parentValue,
            help,
            formName,
            submitting,
            disabled,
            ...props
        } = this.props;

        const id = name || this.state.name;

        return (
            <div
                className={composeClassName('checkbox-container', ...formatters)}
            >
                {label && (
                    <InputLabel
                        formatters={formatters}
                        className="input-label checkbox-label"
                        htmlFor={id}
                    >
                        {label}
                    </InputLabel>
                )}

                <span className="checkbox-input-container">
                    <input
                        type="checkbox"
                        onChange={handleCheckChange.bind(this, this)}
                        defaultChecked={defaultValue}
                        checked={value}
                        id={id}
                        name={id}
                        {...props}
                        disabled={disabled || submitting}
                    />
                    <label className="checkmark" htmlFor={id}/>
                </span>

                {help && <Tooltip help={help} />}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
