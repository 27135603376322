import React, { Component } from 'react';

import { composeClassName } from 'utils';

export default class InputLabel extends Component {
    render() {
        const {
            formatters = [],
            children,
            ...props
        } = this.props;

        return (
            <label
                className={composeClassName('input-label', ...formatters)}
                {...props}
            >
                {children}
            </label>
        )
    }
}
