export const CAL_GET_UNAVAILABILITIES_SUCCESS = 'CAL_GET_UNAVAILABILITIES_SUCCESS';
export const CAL_POST_UNAVAILABILITY_SUCCESS = 'CAL_POST_UNAVAILABILITY_SUCCESS';
export const CAL_PUT_UNAVAILABILITY_SUCCESS = 'CAL_PUT_UNAVAILABILITY_SUCCESS';
export const CAL_DELETE_UNAVAILABILITY_SUCCESS = 'CAL_DELETE_UNAVAILABILITY_SUCCESS';

export const CAL_SET_EDITED_UNAVAILABILITY = 'CAL_SET_EDITED_UNAVAILABILITY';

export const CAL_UNAVAILABILITY_FORM_NAME = 'CAL_UNAVAILABILITY_FORM_NAME';
export const CAL_FILTER_CALENDAR_FORM_NAME = 'CAL_FILTER_CALENDAR_FORM_NAME';
export const CAL_UNAVAILABILITY_MODAL = 'CAL_UNAVAILABILITY_MODAL';
export const CAL_DELETE_UNAVAILABILITY_MODAL = 'CAL_DELETE_UNAVAILABILITY_MODAL';
export const ADD_RESERVATION_OR_UNAVAILABILITY_MODAL = 'ADD_RESERVATION_OR_UNAVAILABILITY_MODAL';

export const CALENDAR_FILTERING_PROPS = {
    START_DATE: 'startDate',
};

export const UNAVAILABILITY_PROPS = {
    ID: 'ID',
    UNIT_ID: 'UnitID',
    START_TIME: 'StartTime',
    END_TIME: 'EndTime',
};

export const EDITED_UNAVAILABILITY_PROPS = {
    ROOM_ID: 'RoomID',
};

