import { fromJS, List } from 'immutable'

import { reduceWithDefault } from 'utils';

import {
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    REMOVE_NOTIFICATIONS,
} from './constants';

const initialState = List();

const reducers = {
    [ADD_NOTIFICATION]: (state, { notification, id }) =>
        state.push(fromJS({
            id,
            ...notification
        })),

    [REMOVE_NOTIFICATION]: (state, { id }) =>
        state.filter(notification =>
            notification.get('id') !== id),

    [REMOVE_NOTIFICATIONS]: () =>
        initialState,
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
