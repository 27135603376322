import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {fromJS, Iterable, List, Map} from 'immutable';
import ReactResizeDetector from 'react-resize-detector';
import moment from 'moment';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';

import localeMessages from './messages';
import Money from 'eksenia-lib/src/Money';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import Page from 'eksenia-lib/src/Page';
import {
    Form,
    getFormValues,
    updateInputField,
} from 'eksenia-lib/src/Form';
import Button from 'eksenia-lib/src/Button';
import {
    openEditReservationModal,
    RESERVATION_PROPS,
    BKG_RESERVATION_STATUS_VALUES,
    getReservations,
    getReservationGuestName, EditReservation,
} from 'Reservations';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    getRates,
    addEditedRate,
    EditRate,
    RATE_PROPS,
    RATE_ROOM_PROPS,
    EDIT_RATE_MODAL_NAME,
} from 'Rates';

import EditUnavailability from './EditUnavailability';
import calendarFilteringForm from './calendarFilteringForm';
import {
    CALENDAR_FILTERING_PROPS,
    CAL_FILTER_CALENDAR_FORM_NAME,
    UNAVAILABILITY_PROPS,
    CAL_UNAVAILABILITY_MODAL,
    EDITED_UNAVAILABILITY_PROPS,
    ADD_RESERVATION_OR_UNAVAILABILITY_MODAL,
} from './constants';
import {
    setEditedUnavailability,
    getUnavailabilities,
} from './actions';
import {getRoomUnits, UNIT_PROPS} from "../Rooms";
import {transformRateForForm} from "../Rates/utils";
import Icon from "eksenia-lib/src/Icon";
import {composeClassName} from "../utils";
import AddReservationOrUnavailability from "./AddReservationOrUnavailability";
import {collectOverbookings} from "./utils";

const initialValues = {
    [CALENDAR_FILTERING_PROPS.START_DATE]: moment().startOf('day')
};

const activeCellClass = 'active-cell';
const beforeActiveCellClass = 'before-active-cell';
const aboveActiveCellClass = 'above-active-cell';

export class Calendar extends Component {

    constructor() {
        super(...arguments);

        this.setEditedReservation = this.setEditedReservation.bind(this);
        this.nameCell = React.createRef();
        this.state = {
            activeCell: null,
            roomsClosed: Map(),
            editedReservation: null,
        };
    }

    componentDidMount() {
        const {
            getUnavailabilities,
            getRates,
            getReservations,
        } = this.props;

        getUnavailabilities();
        getRates();
        getReservations();
    }

    handleFirstDayChange(newValue) {
        this.props.updateInputField(
            CAL_FILTER_CALENDAR_FORM_NAME,
            CALENDAR_FILTERING_PROPS.START_DATE,
            {
                value: newValue,
            }
        )
    }

    handleRoomNameClick(roomId) {
        const {
            roomsClosed,
        } = this.state;

        const roomOpen = roomsClosed.get(roomId);
        this.setState({
            roomsClosed: roomsClosed.set(roomId, !roomOpen)
        })
    }

    setEditedReservation(reservationId) {
        this.setState({ editedReservation: reservationId })
    }

    collectUnavailableDays(list, startProp, endProp) {
        return list.reduce((acc, cur) => {
            const startDate = cur.get(startProp).clone()
            const endDate = cur.get(endProp).clone()
            const startDateString = startDate.toString()
            const endDateString = endDate.toString()
            let currentDate = startDate.add(1, "days")
            let lastDate = endDate.subtract(1, "days")

            if (acc.startDates[endDateString]) {
                acc[endDateString] = true
            }
            if (acc.endDates[startDateString]) {
                acc[startDateString] = true
            }

            acc.startDates[startDateString] = true
            acc.endDates[endDateString] = true

            while (currentDate.isSameOrBefore(lastDate, 'day')) {
                acc[currentDate.toString()] = true
                currentDate = currentDate.add(1, "days")
            }

            return acc
        }, {
            startDates: {},
            endDates: {},
        })
    }

    calculateButtonOffset(obj, startProp, endProp, startDate, offsetStartDate, lastVisibleDate, columnWidth, isRate) {
        const rightOffset = isRate ? 5 : 35;
        const leftOffset = isRate ? 5 : 25;
        const start = obj.get(startProp);
        const end = obj.get(endProp);
        const offsets = {};

        offsets.left = start.isBefore(startDate, 'day')
            ? 0
            : start.diff(offsetStartDate, 'days') * columnWidth + leftOffset;
        offsets.right = end.isAfter(lastVisibleDate, 'day')
            ? 1
            : -(end.diff(lastVisibleDate, 'days') * columnWidth - rightOffset);
        offsets.start = start;
        offsets.end = end;

        return offsets
    }

    getUnitUnavailabilities(unit, columns, startDate) {
        const {
            unavailabilities,
        } = this.props;

        const unitId = unit.get(UNIT_PROPS.ID);
        const lastVisibleDate = startDate.clone().add(columns.count - 1, 'days');

        return unavailabilities
            .filter(unavailability =>
                unavailability.get(UNAVAILABILITY_PROPS.UNIT_ID) === unitId
            )
            .filterNot(unavailability =>
                unavailability.get(UNAVAILABILITY_PROPS.END_TIME).isBefore(startDate, 'day')
                || unavailability.get(UNAVAILABILITY_PROPS.START_TIME).isAfter(lastVisibleDate, 'day')
            )
    }

    renderUnitUnavailabilities(columns, startDate, unitUnavailabilities, room) {
        const {
            showModal,
            setEditedUnavailability,
        } = this.props;

        const columnWidth = columns.width
        const offsetStartDate = startDate.clone();
        const lastVisibleDate = startDate.clone().add(columns.count - 1, 'days');

        return unitUnavailabilities
            .map(unavailability => {
                const unavailabilityId = unavailability.get(UNAVAILABILITY_PROPS.ID);
                const offsets = this.calculateButtonOffset(
                    unavailability,
                    UNAVAILABILITY_PROPS.START_TIME,
                    UNAVAILABILITY_PROPS.END_TIME,
                    startDate,
                    offsetStartDate,
                    lastVisibleDate,
                    columnWidth
                )

                return (
                    <span
                        className="calendar-button-wrapper calendar-button-wrapper-unavailability"
                        style={{
                            left: offsets.left,
                            right: offsets.right,
                        }}
                        key={unavailabilityId}
                    >
                        <button
                            onClick={() => {
                                setEditedUnavailability(unavailability
                                    .set(EDITED_UNAVAILABILITY_PROPS.ROOM_ID, room.get(RMS_ROOM_PROPS.ID))
                                    .update(UNAVAILABILITY_PROPS.START_TIME, startTime => startTime.clone())
                                    .update(UNAVAILABILITY_PROPS.END_TIME, endTime => endTime.clone()));
                                showModal(CAL_UNAVAILABILITY_MODAL);
                            }}
                            className="calendar-unavailability"
                        >
                            <FormattedMessage {...localeMessages.closed} />
                        </button>
                    </span>
                )
            })
    }

    getUnitReservations(unit, columns, startDate) {
        const {
            reservations,
        } = this.props;

        const unitId = unit.get(UNIT_PROPS.ID);
        const lastVisibleDate = startDate.clone().add(columns.count - 1, 'days');

        return reservations
            .filter(reservation =>
                reservation.get(RESERVATION_PROPS.UNIT_ID) === unitId
                && reservation.get(RESERVATION_PROPS.STATUS) !== BKG_RESERVATION_STATUS_VALUES.CANCELED
            )
            .filterNot(reservation =>
                reservation.get(RESERVATION_PROPS.CHECK_OUT).isBefore(startDate, 'day')
                || reservation.get(RESERVATION_PROPS.CHECK_IN).isAfter(lastVisibleDate, 'day')
            )
    }

    renderUnitReservations(columns, startDate, unitReservations, overbookings) {
        const {
            showModal,
            openEditReservationModal,
            intl,
        } = this.props;

        const columnWidth = columns.width

        const offsetStartDate = startDate.clone();
        const lastVisibleDate = startDate.clone().add(columns.count - 1, 'days');

        return unitReservations
            .map(reservation => {
                const reservationId = reservation.get(RESERVATION_PROPS.ID);
                const offsets = this.calculateButtonOffset(
                    reservation,
                    RESERVATION_PROPS.CHECK_IN,
                    RESERVATION_PROPS.CHECK_OUT,
                    startDate,
                    offsetStartDate,
                    lastVisibleDate,
                    columnWidth
                )

                const guestName = getReservationGuestName(reservation, intl);
                const tooltipClassName =
                    lastVisibleDate.clone().subtract(4, 'days').isSameOrBefore(offsets.end, 'day')
                    ? "right-tooltip"
                    : offsets.end.isSame(startDate, 'day')
                        || offsets.start.clone().add(1, 'days').isSame(offsets.end, 'day')
                        ? "indented-tooltip"
                        : "";
                const buttonWrapperClasses = composeClassName(
                    "calendar-button-wrapper calendar-button-wrapper-reservation",
                    overbookings[reservationId] && "overbooked-reservation",
                    overbookings[reservationId] === 2 && "bottom-overbooked-reservation"
                )

                return (
                    <span
                        className={buttonWrapperClasses}
                        style={{
                            left: offsets.left,
                            right: offsets.right,
                        }}
                        key={reservationId}
                    >
                        <span
                            className="calendar-button-wrapper-inner"
                        >
                            <button
                                onClick={() => {
                                    this.setEditedReservation(reservationId);
                                    openEditReservationModal(reservation);
                                    showModal(reservationId);
                                }}
                                className="calendar-reservation"
                                aria-labelledby={reservationId}
                            >
                                {guestName}
                            </button>
                        </span>

                        <div role="tooltip" id={reservationId} className={tooltipClassName}>
                            {guestName}
                        </div>
                    </span>
                )
            });
    }

    getRoomRates(room, columns, startDate) {
        const {
            rates,
        } = this.props;

        const roomId = room.get(RMS_ROOM_PROPS.ID);
        const lastVisibleDate = startDate.clone().add(columns.count - 1, 'days');

        const roomRates = rates.filter(rate => {
            const rooms = rate.get(RATE_PROPS.ROOMS);
            return Iterable.isIterable(rooms) &&
                rooms.find(rm =>
                    rm.get(RATE_ROOM_PROPS.ROOM_ID) === roomId)
            && !rate.get(RATE_PROPS.LAST_NIGHT).isBefore(startDate, 'day')
            && !rate.get(RATE_PROPS.FIRST_NIGHT).isAfter(lastVisibleDate, 'day')
        }).sort((a, b) => {
            return a.get(RATE_PROPS.CREATED_AT).isBefore(b.get(RATE_PROPS.CREATED_AT))
                ? 1
                : -1;
        });

        const days = {};
        const daysByRates = {};
        let currentDate = startDate.clone().startOf('day');
        while (currentDate.isSameOrBefore(lastVisibleDate)) {
            let currentDateString = currentDate.toString();

            // eslint-disable-next-line
            roomRates.forEach((rate => {
                if (!days[currentDateString]
                    && rate.get(RATE_PROPS.FIRST_NIGHT).isSameOrBefore(currentDate, 'day')
                    && rate.get(RATE_PROPS.LAST_NIGHT).isSameOrAfter(currentDate, 'day')
                ) {
                    days[currentDateString] = 1;
                    const rateId = rate.get(RATE_PROPS.ID);
                    if (daysByRates[rateId] === undefined) {
                        daysByRates[rateId] = {};
                    }
                    daysByRates[rateId][currentDateString] = 1;
                }
            }));

            currentDate = currentDate.add(1, 'days');
        }

        return roomRates.reduce((acc, cur) => {
            const id = cur.get(RATE_PROPS.ID);
            if (!daysByRates[id]) {
                return acc;
            }
            let toReturn = acc;
            let currentDate = cur.get(RATE_PROPS.FIRST_NIGHT).clone();
            let first = currentDate.clone();

            while (currentDate.isSameOrBefore(cur.get(RATE_PROPS.LAST_NIGHT))) {
                if (!daysByRates[id][currentDate.toString()]) {
                    if (!first.isSame(currentDate)) {
                        toReturn = toReturn.push(cur.merge({
                            [RATE_PROPS.FIRST_NIGHT]: first.clone(),
                            [RATE_PROPS.LAST_NIGHT]: currentDate.clone().subtract(1, 'days'),
                        }))
                    }

                    first = currentDate.clone().add(1, 'days');

                } else if (currentDate.isSame(cur.get(RATE_PROPS.LAST_NIGHT), 'day')) {
                    toReturn = toReturn.push(cur.merge({
                        [RATE_PROPS.FIRST_NIGHT]: first.clone(),
                        [RATE_PROPS.LAST_NIGHT]: currentDate.clone(),
                    }))
                }
                currentDate.add(1, 'days');
            }

            return toReturn;
        }, List());
    }

    renderRoomRates(columns, startDate, roomRates, roomId) {
        const {
            rooms,
            addEditedRate,
            showModal,
            intl,
            rates,
        } = this.props;

        const columnWidth = columns.width

        const offsetStartDate = startDate.clone();
        const lastVisibleDate = startDate.clone().add(columns.count - 1, 'days');

        return roomRates
            .map(rate => {
                const rateId = rate.get(RATE_PROPS.ID);
                const fullRate = rates.find(rt => rt.get(RATE_PROPS.ID) === rateId);
                const firstNight = rate.get(RATE_PROPS.FIRST_NIGHT);
                const rateName = rate.get(RATE_PROPS.NAME);
                const price = rate.get(RATE_PROPS.ROOM_PRICE);
                const rateRooms = rate.get(RATE_PROPS.ROOMS);
                const room = rateRooms.find(room => room.get(RATE_ROOM_PROPS.ROOM_ID) === roomId) || Map();
                const multiplier = room.get(RATE_ROOM_PROPS.MULTIPLIER) || 1;
                const offsets = this.calculateButtonOffset(
                    rate,
                    RATE_PROPS.FIRST_NIGHT,
                    RATE_PROPS.LAST_NIGHT,
                    startDate,
                    offsetStartDate,
                    lastVisibleDate,
                    columnWidth,
                    true
                )
                const tooltipClassName = composeClassName(
                    lastVisibleDate.clone().subtract(4, 'days').isSameOrBefore(offsets.end, 'day')
                        ? "right-tooltip"
                        : offsets.end.isSame(startDate, 'day')
                        || offsets.start.clone().add(1, 'days').isSame(offsets.end, 'day')
                        ? "indented-tooltip"
                        : "",
                    (rooms.first().get(RMS_ROOM_PROPS.ID) === roomId) && "bottom-tooltip"
                )

                return (
                    <span
                        style={{
                            left: offsets.left,
                            right: offsets.right,
                        }}
                        key={`${rateId}:${firstNight.toString()}`}
                        className="calendar-button-wrapper calendar-button-wrapper-rate"
                    >
                        <span
                            className="calendar-button-wrapper-inner"
                        >
                            <button
                                onClick={() => {
                                    addEditedRate(transformRateForForm(fullRate, rooms, intl));
                                    showModal(EDIT_RATE_MODAL_NAME);
                                }}
                                className="calendar-rate"
                                aria-labelledby={rateId}
                            >
                                <Money value={price*multiplier} />
                            </button>
                        </span>

                        <div role="tooltip" id={rateId} className={tooltipClassName}>
                            <FormattedMessage
                                {...localeMessages.ratesPrice}
                                values={{
                                    rateName,
                                    // eslint-disable-next-line
                                    fullPrice: <Money
                                        value={price*multiplier}
                                    />,
                                    price: <Money
                                        value={price}
                                    />,
                                    multiplier: multiplier
                                }}
                            />
                        </div>
                    </span>
            )});
    }

    renderHeader() {
        return (
            <div>
                <Button
                    onClick={this.handleFirstDayChange.bind(this, initialValues[CALENDAR_FILTERING_PROPS.START_DATE])}
                    formatters={[INPUT_FORMATTERS.HORIZONTAL, Button.TYPE.SECONDARY]}
                >
                    <FormattedMessage {...localeMessages.today} />
                </Button>
                <Form
                    blueprint={calendarFilteringForm(initialValues)}
                />
            </div>
        );
    }

    renderHeaderCell(startDate, index, width, isEdge) {
        const {
            activeCell,
        } = this.state;

        const date = startDate.clone().add(index, 'days');
        const isActiveDay = activeCell &&
            activeCell.date.isSame(date, 'day');
        const classes = composeClassName(
            'cell',
            'header-cell',
            isActiveDay && aboveActiveCellClass
        )

        const displayDate = date.date();
        const isFirstInMonth = displayDate === 1;

        return (
            <div
                className={classes}
                style={{width}}
                key={date.toString()}
            >
                {isFirstInMonth &&
                    <div className="header-cell-first-in-month" />
                }
                <div className="header-cell-day">
                    {date.format('ddd')}
                </div>
                <div className="header-cell-date">
                    {displayDate}
                </div>
                {(isEdge || isFirstInMonth) &&
                    <div className="header-cell-month">
                        {date.format('MMM')}
                    </div>
                }
            </div>
        )
    }

    renderCalendarHeader(startDate, columns) {
        const headerColumns = [...Array(columns.count).keys()].map(index => {
            return this.renderHeaderCell(
                startDate,
                index,
                columns.width,
                index === 0 || index === columns.count - 1
                );
        });

        return (
            <div className="row calendar-header">
                <div className="cell header-cell name-column-cell">
                    <FormattedMessage {...localeMessages.roomType} />
                </div>

                {headerColumns}

                <Icon
                    name="previousDayArrow"
                    type="date-nav previous"
                    onClick={this.handleFirstDayChange.bind(this, startDate.clone().subtract(1, 'days'))}
                />
                <Icon
                    name="nextDayArrow"
                    type="date-nav next"
                    onClick={this.handleFirstDayChange.bind(this, startDate.clone().add(1, 'days'))}
                />
            </div>
        )
    }

    renderUnitCell(
        unit,
        room,
        startDate,
        index,
        columns,
        isActiveUnit,
        isAboveActiveRoom,
        unitsAfter,
        daysWithReservations,
        daysWithUnavailabilities
    ) {
        const {
            showModal,
            setEditedUnavailability,
            openEditReservationModal,
        } = this.props;
        const {
            activeCell,
        } = this.state;

        const roomId = room.get(RMS_ROOM_PROPS.ID);
        const unitId = unit.get(UNIT_PROPS.ID);
        const date = startDate.clone().add(index, 'days');
        const dateString = date.toString();

        const isActiveDay = activeCell &&
            activeCell.date.isSame(date, 'day');
        const isBeforeActiveDay = activeCell && activeCell.date.isAfter(date, 'day');
        const isActiveCell = isActiveDay && isActiveUnit;
        const isAboveActiveUnit = activeCell &&
            activeCell.unit &&
            unitsAfter.indexOf(activeCell.unit) !== -1;

        const classes = composeClassName(
            'cell',
            'unit-cell',
            isActiveCell && activeCellClass,
            isActiveUnit && isBeforeActiveDay && beforeActiveCellClass,
            isActiveDay && (isAboveActiveRoom || isAboveActiveUnit) && aboveActiveCellClass
        )

        return (
            <div
                className={classes}
                style={{width: columns.width}}
                key={dateString}
                onMouseEnter={() => {
                    if (!daysWithReservations.startDates[dateString] && !daysWithUnavailabilities.startDates[dateString]) {
                        this.setState({
                            activeCell: {
                                date,
                                unit,
                                room
                            }
                        })
                    }
                }}
                onMouseLeave={() =>
                    this.setState({
                        activeCell: null
                    })
                }
                onClick={ () => {
                    if (isActiveCell) {
                        setEditedUnavailability(fromJS({
                            [EDITED_UNAVAILABILITY_PROPS.ROOM_ID]: roomId,
                            [UNAVAILABILITY_PROPS.UNIT_ID]: unitId,
                            [UNAVAILABILITY_PROPS.START_TIME]: date.clone(),
                            [UNAVAILABILITY_PROPS.END_TIME]: date.clone().add(1, 'days'),
                        }));
                        openEditReservationModal(fromJS({
                            [RESERVATION_PROPS.ROOM_ID]: roomId,
                            [RESERVATION_PROPS.UNIT_ID]: unitId,
                            [RESERVATION_PROPS.CHECK_IN]: date.clone(),
                            [RESERVATION_PROPS.CHECK_OUT]: date.clone().add(1, 'days'),
                            [RESERVATION_PROPS.ADULTS]: 1,
                            [RESERVATION_PROPS.CHILDREN]: 0,
                            [RESERVATION_PROPS.INFANTS]: 0,
                            // [RESERVATION_PROPS.PRICE]: rooms.getIn([0, RMS_ROOM_PROPS.MIN_PRICE]),
                            [RESERVATION_PROPS.NOTES]: '',
                            [RESERVATION_PROPS.GUEST_LIST]: [],
                        }));
                        this.setEditedReservation(null);
                        showModal(ADD_RESERVATION_OR_UNAVAILABILITY_MODAL);
                    }
                }}
            >
                {isActiveCell &&
                    <Icon name="calendarPlus" type="calendar-plus" />
                }
            </div>
        )
    }

    renderUnitRow(unit, room, columns, startDate, isAboveActiveRoom, unitsAfter) {
        const {
            activeCell,
        } = this.state;

        const unitReservations = this.getUnitReservations(unit, columns, startDate);
        const daysWithReservations = this.collectUnavailableDays(
            unitReservations,
            RESERVATION_PROPS.CHECK_IN,
            RESERVATION_PROPS.CHECK_OUT
        )
        const unitUnavailabilities = this.getUnitUnavailabilities(unit, columns, startDate);
        const overbookings = collectOverbookings(unitReservations, unitUnavailabilities);
        const daysWithUnavailabilities = this.collectUnavailableDays(
            unitUnavailabilities,
            UNAVAILABILITY_PROPS.START_TIME,
            UNAVAILABILITY_PROPS.END_TIME
        )

        const unitId = unit.get(UNIT_PROPS.ID)
        const unitName = unit.get(UNIT_PROPS.NAME)
        const isActiveUnit = activeCell &&
            activeCell.unit &&
            activeCell.unit.get(UNIT_PROPS.ID) === unit.get(UNIT_PROPS.ID);

        const classes = composeClassName(
            'cell',
            'name-column-cell',
            isActiveUnit && beforeActiveCellClass,
        )

        const cells = [...Array(columns.count).keys()].map(index => {
            return this.renderUnitCell(
                unit,
                room,
                startDate,
                index,
                columns,
                isActiveUnit,
                isAboveActiveRoom,
                unitsAfter,
                daysWithReservations,
                daysWithUnavailabilities
            );
        });

        return (
            <div className="row unit-row" key={unitId} >
                <div className={classes}>
                    {unitName}
                </div>

                <div className="unit-cells-container">
                    {cells}

                    {this.renderUnitUnavailabilities(columns, startDate, unitUnavailabilities, room)}
                    {this.renderUnitReservations(columns, startDate, unitReservations, overbookings)}
                </div>
            </div>
        )
    }

    renderRoomCell(startDate, index, columns, room, isActiveRoom, isAboveActiveRoom, isRoomCellActive, daysWithRates) {
        const {
            showModal,
            addEditedRate,
        } = this.props;
        const {
            activeCell,
        } = this.state;

        const date = startDate.clone().add(index, 'days');
        const dateString = date.toString();

        const isActiveDay = activeCell &&
            activeCell.date.isSame(date, 'day');
        const isBeforeActiveDay = activeCell && activeCell.date.isAfter(date, 'day');
        const isActiveCell = isActiveDay && isActiveRoom;
        const classes = composeClassName(
            'cell',
            'room-cell',
            isActiveCell && activeCellClass,
            isActiveRoom && isBeforeActiveDay && beforeActiveCellClass,
            isActiveDay && (isAboveActiveRoom || isRoomCellActive) && aboveActiveCellClass
        )

        return (
            <div
                className={classes}
                style={{width: columns.width}}
                key={dateString}
                onMouseEnter={() => {
                    if (!daysWithRates.startDates[dateString] &&
                        !daysWithRates.endDates[dateString] &&
                        !daysWithRates[dateString]
                    ) {
                        this.setState({
                            activeCell: {
                                date,
                                room,
                            }
                        })
                    }
                }}
                onMouseLeave={() =>
                    this.setState({
                        activeCell: null
                    })
                }
                onClick={ () => {
                    addEditedRate(fromJS({
                        [RATE_PROPS.FIRST_NIGHT]: date.clone(),
                        [RATE_PROPS.ROOMS]: [{
                            [RATE_ROOM_PROPS.USED]: true,
                            [RATE_ROOM_PROPS.MULTIPLIER]: 1,
                            [RATE_ROOM_PROPS.ROOM_ID]: room.get(RMS_ROOM_PROPS.ID),
                        }],
                    }));
                    showModal(EDIT_RATE_MODAL_NAME);
                }}
            >
                {isActiveCell &&
                    <Icon name="calendarPlus" type="calendar-plus" />
                }
            </div>
        )
    }

    renderRoomRow(room, columns, startDate, roomsAfter) {
        const {
            roomUnits,
        } = this.props;
        const {
            activeCell,
            roomsClosed,
        } = this.state;

        const roomId = room.get(RMS_ROOM_PROPS.ID);
        const isActiveRoom = activeCell &&
            activeCell.room &&
            !activeCell.unit &&
            activeCell.room.get(RMS_ROOM_PROPS.ID) === roomId;
        const isRoomCellActive = activeCell &&
            activeCell.room &&
            activeCell.room.get(RMS_ROOM_PROPS.ID) === roomId &&
            activeCell.unit;
        const isAboveActiveRoom = activeCell &&
            activeCell.room &&
            roomsAfter.indexOf(activeCell.room) !== -1;

        const classes = composeClassName(
            'cell',
            'name-column-cell',
            'room-name-column-cell',
            isActiveRoom && beforeActiveCellClass
        )

        const units = (roomUnits.get(roomId) || List()).sort((a, b) => {
            const aRank = a.get(UNIT_PROPS.RANK);
            const bRank = b.get(UNIT_PROPS.RANK);
            if (aRank < bRank) { return -1; }
            if (aRank > bRank) { return 1; }
            return 0;
        })

        const roomClosed = roomsClosed.get(roomId);
        const roomRates = this.getRoomRates(room, columns, startDate);
        const daysWithRates = this.collectUnavailableDays(roomRates, RATE_PROPS.FIRST_NIGHT, RATE_PROPS.LAST_NIGHT)

        const cells = [...Array(columns.count).keys()].map(index => {
            return this.renderRoomCell(
                startDate,
                index,
                columns,
                room,
                isActiveRoom,
                isAboveActiveRoom,
                isRoomCellActive,
                daysWithRates
            );
        });

        return (
            <Fragment key={room.get(RMS_ROOM_PROPS.ID)}>
                <div className="row room-row">
                    <div className={classes}
                         onClick={this.handleRoomNameClick.bind(this, roomId)}
                    >
                        <Icon
                            name={roomClosed ? "calendarRoomArrowClosed" : "calendarRoomArrowOpen"}
                            type="calendar-room-arrow"
                        />
                        {room.get(RMS_ROOM_PROPS.NAME)}
                    </div>

                    <div className="room-cells-container">
                        {cells}

                        {this.renderRoomRates(columns, startDate, roomRates, roomId)}
                    </div>
                </div>

                {!roomClosed && units.map((unit, index) =>
                    this.renderUnitRow(
                        unit,
                        room,
                        columns,
                        startDate,
                        isAboveActiveRoom,
                        units.slice(index + 1)
                    )
                )}
            </Fragment>
        )
    }

    renderContent(width) {
        const {
            filtersForm,
            rooms,
        } = this.props;

        const filterValues = getFormValues(filtersForm);
        const startDate = moment(filterValues[CALENDAR_FILTERING_PROPS.START_DATE]
            || initialValues[CALENDAR_FILTERING_PROPS.START_DATE]);

        // on full size leave room for the padding, on mobile it's not required
        const horizontalOffset = window.innerWidth > 990 ? 32 : 0;
        // first column width changes depending on the screen size
        const nameColumnWidth = window.innerWidth > 990 ? 172 : 120;

        const containerWidth = isNaN(width)
            ? 0
            : Math.floor(width - horizontalOffset) - nameColumnWidth;
        const columns = [52, 53, 54].reduce((acc, columnWidth) => {
            const modulus = containerWidth % columnWidth

            if (acc.modulus < modulus) {
                return acc;
            } else {
                return {
                    width: columnWidth,
                    count: Math.floor(containerWidth / columnWidth),
                    modulus
                }
            }
        }, {
            width: 0,
            modulus: 1000,
            count: 0,
        });

        return (
            <div className="calendar" ref={this.calendarRef}>
                {this.renderCalendarHeader(startDate, columns)}

                <div className="room-rows-container">
                    <div className="room-rows">
                        {rooms.map((room, index) => {
                            return this.renderRoomRow(room, columns, startDate, rooms.slice(index + 1))
                        })}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Page
                header={this.renderHeader()}
                formatters={["full-width"]}
            >
                <ReactResizeDetector handleWidth>
                    {({width}) => this.renderContent(Math.floor(width))}
                </ReactResizeDetector>

                <EditUnavailability />
                <EditRate />
                {this.state.editedReservation &&
                    <EditReservation id={this.state.editedReservation} />
                }
                <AddReservationOrUnavailability />
            </Page>
        )
    }
}

const mapStateToProps = (store) => {
    const roomsReducer = store.roomsReducer;
    const formReducer = store.formReducer;
    const calendarReducer = store.calendarReducer;
    const ratesReducer = store.ratesReducer;
    const reservationsReducer = store.reservationsReducer;

    return {
        filtersForm: formReducer.get(CAL_FILTER_CALENDAR_FORM_NAME) || Map(),
        unavailabilities: calendarReducer.get('unavailabilities') || List(),
        rooms: roomsReducer.get('rooms', List()),
        roomUnits: getRoomUnits(roomsReducer.get('units'), Map()),
        rates: ratesReducer.get('rates') || List(),
        reservations: reservationsReducer.get('reservations', List()),
    }
};

const mapDispatchToProps = {
    openEditReservationModal,
    updateInputField,
    showModal,
    setEditedUnavailability,
    getUnavailabilities,
    getRates,
    addEditedRate,
    getReservations,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Calendar))
