import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Iterable, Map } from 'immutable';

import { getFormValues } from 'eksenia-lib/src/Form';
import Table from 'eksenia-lib/src/Table';
import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import EditGuest from './EditGuest';
import {
    getGuests,
    clearEditedGuest,
    addEditedGuest,
} from './actions';
import {
    GUEST_PROPS,
    FILTER_GUESTS_FORM_NAME,
    GUESTS_FILTERING_PROPS,
} from './constants';
import {
    getGuestName,
} from "./utils";
import localeMessages from './messages';
import { getReservations } from "../Reservations/actions";

const tableHeader = [
    {
        content: localeMessages.name,
        key: 'name',
        width: '30%',
        sort: true,
    },
    {
        content: localeMessages.country,
        key: 'country',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.city,
        key: 'city',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.email,
        key: 'email',
        width: '30%',
        sort: true,
    },
];

export class GuestList extends Component {

    constructor() {
        super(...arguments);

        this.handleOpenGuestModal = this.handleOpenGuestModal.bind(this);
        this.saveGuestSuccessCallback = this.saveGuestSuccessCallback.bind(this);
        this.state = {
            editedGuestId: undefined
        }
    }

    componentDidMount() {
        this.props.getGuests();
    }

    saveGuestSuccessCallback() {
        const {
            getGuests,
            getReservations,
        } = this.props;

        getGuests();
        getReservations();
    }

    handleOpenGuestModal(guest) {
        const {
            addEditedGuest,
            showModal,
        } = this.props;

        const guestId = guest.get(GUEST_PROPS.ID);
        this.setState({
            editedGuestId: guestId,
        })
        addEditedGuest(guest);
        showModal(guestId);
    }

    prepareTableRows() {
        const {
            guests,
            filtersForm,
            countryNamesMap,
        } = this.props;

        const filterValues = getFormValues(filtersForm);
        const textFilterValue = filterValues[GUESTS_FILTERING_PROPS.TEXT];
        // const periodFilterValue = filterValues[BKG_RESERVATION_FILTERING_PROPS.PERIOD];

        if (!Iterable.isIterable(guests)) {
            return false;
        }

        return guests
            .filter(guest => {
                if (!textFilterValue) {
                   return true;
                }

                const firstName = (guest.get(GUEST_PROPS.FIRST_NAME) || '').toLowerCase();
                const lastName = (guest.get(GUEST_PROPS.LAST_NAME) || '').toLowerCase();
                const country = (guest.get(GUEST_PROPS.COUNTRY) || '').toLowerCase();
                const city = (guest.get(GUEST_PROPS.CITY) || '').toLowerCase();
                const address = (guest.get(GUEST_PROPS.ADDRESS) || '').toLowerCase();
                const note = (guest.get(GUEST_PROPS.NOTES) || '').toLowerCase();


                const textFilterLower = textFilterValue.toLowerCase();
                return !(firstName.indexOf(textFilterLower) === -1
                    && lastName.indexOf(textFilterLower) === -1
                    && country.indexOf(textFilterLower) === -1
                    && city.indexOf(textFilterLower) === -1
                    && address.indexOf(textFilterLower) === -1
                    && note.indexOf(textFilterLower) === -1);

            })
            .map(guest => {
                const id = guest.get(GUEST_PROPS.ID);
                const guestName = getGuestName(guest);
                const countryCode = guest.get(GUEST_PROPS.COUNTRY);
                const country = countryNamesMap.get(countryCode) || countryCode;
                const city = guest.get(GUEST_PROPS.CITY);
                const email = guest.get(GUEST_PROPS.EMAIL);

                return {
                    key: id,
                    onClick: this.handleOpenGuestModal.bind(null, guest),
                    cells: [
                        {
                            content: guestName,
                            value: guestName,
                            key: 'name',
                            className: 'guest-name',
                        },
                        {
                            content: country,
                            value: country,
                            key: 'country',
                            className: 'guest-country',
                        },
                        {
                            content: city,
                            value: city,
                            key: 'city',
                            className: 'guest-city',
                        },
                        {
                            content: email,
                            value: email,
                            key: 'email',
                            className: 'guest-email',
                        },
                    ],
                }
            }).toJS();
    }

    render() {
        const rows = this.prepareTableRows();

        if (!rows) {
            return false;
        }

        return (
            <>
                <Table header={tableHeader} rows={rows} />
                <EditGuest
                    onSuccess={this.saveGuestSuccessCallback}
                    id={this.state.editedGuestId}
                />
            </>
        );
    }
}

const mapStateToProps = (store) => {
    const guestsReducer = store.guestsReducer;
    const formReducer = store.formReducer;
    const localeReducer = store.localeReducer;

    return {
        guests: guestsReducer.get('guests'),
        filtersForm: formReducer.get(FILTER_GUESTS_FORM_NAME) || Map(),
        countryNamesMap: localeReducer.get('countryNamesMap') || Map(),
    }
};

const mapDispatchToProps = {
    clearEditedGuest,
    addEditedGuest,
    showModal,
    hideModal,
    getGuests,
    getReservations,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestList)
