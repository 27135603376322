import {
    FORM_ADD_FORM,
    FORM_REMOVE_FORM,
    FORM_UPDATE_FIELD,
    LEAVE_FORM,
    FORM_SET_INITIAL_VALUES_TO_CURRENT,
    FORM_SET_VALIDATION_ERROR,
    FORM_SET_SUBMITTING,
    FORM_SET_NOT_SUBMITTING,
} from './constants';

export const addForm = (formName, inputFields) => ({
    type: FORM_ADD_FORM,
    formName,
    inputFields,
});

export const removeForm = formName => ({
    type: FORM_REMOVE_FORM,
    formName,
});

export const updateInputField = (formName, inputName, mergeValues) => ({
    type: FORM_UPDATE_FIELD,
    formName,
    inputName,
    mergeValues,
});

export const leaveForm = (formNames, confirmationPrefix, callback, confirmationPrerequisite) => ({
    type: LEAVE_FORM,
    formNames,
    confirmationPrefix,
    callback,
    confirmationPrerequisite,
});

export const setInitialValuesToCurrent = formName => ({
    type: FORM_SET_INITIAL_VALUES_TO_CURRENT,
    formName,
});

export const setValidationError = (formName, errorMessage) => ({
    type: FORM_SET_VALIDATION_ERROR,
    formName,
    errorMessage,
});

export const setFormSubmitting = formName => ({
    type: FORM_SET_SUBMITTING,
    formName,
});

export const setFormNotSubmitting = formName => ({
    type: FORM_SET_NOT_SUBMITTING,
    formName,
});
