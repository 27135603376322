import * as moment from "moment";

export const handleChange = (instance, event) => {
    const {
        onChangeFromForm,
        onChange,
        name,
    } = instance.props;

    const value = event.currentTarget.value;

    if (typeof onChangeFromForm === 'function') {
        onChangeFromForm(name, value);
    }

    if (typeof onChange === 'function') {
        onChange(value, event);
    }
};

export const handleCheckChange = (instance, event) => {
    const {
        onChangeFromForm,
        onChange,
        name,
    } = instance.props;

    const value = event.currentTarget.checked;

    if (typeof onChangeFromForm === 'function') {
        onChangeFromForm(name, value);
    }

    if (typeof onChange === 'function') {
        onChange(value, event);
    }
};

export const handleDateChange = (instance, value, withTime) => {
    if (withTime) {
        return handleManualValueChange(instance, value);
    } else {
        return handleManualValueChange(instance, moment.isMoment(value) ? value.startOf('day') : value);
    }
}

export const handleManualValueChange = (instance, value) => {
    const {
        onChangeFromForm,
        onChange,
        name,
    } = instance.props;

    if (typeof onChangeFromForm === 'function') {
        onChangeFromForm(name, value);
    }

    if (typeof onChange === 'function') {
        onChange(value);
    }
};

export const handleFileUploadChange = (instance, value, event) => {
    const {
        onChangeFromForm,
        onChange,
        name,
    } = instance.props;

    if (typeof onChangeFromForm === 'function') {
        onChangeFromForm(name, value);
    }

    if (typeof onChange === 'function') {
        onChange(value, event);
    }
};

export const handleRadioChange = (instance, value) => {
    const {
        onChangeFromForm,
        onChange,
        name,
    } = instance.props;

    if (typeof onChangeFromForm === 'function') {
        onChangeFromForm(name, value);
    }

    if (typeof onChange === 'function') {
        onChange(value);
    }
};
