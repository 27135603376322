import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
} from 'react-intl';

import Page from 'eksenia-lib/src/Page';
import {PAGE_FORMATTERS} from "eksenia-lib/src/Page/Page";
import Button from 'eksenia-lib/src/Button';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import TaxesAndFeesList from './TaxesAndFeesList';
import EditTaxOrFee from './EditTaxOrFee';
import {
    EDIT_TAX_OR_FEE_MODAL_NAME,
} from './constants';
import localeMessages from './messages';

export class TaxesAndFeesPage extends Component {

    renderHeader() {
        const {
            showModal,
        } = this.props;

        return (
            <Button
                onClick={showModal.bind(this, EDIT_TAX_OR_FEE_MODAL_NAME)}
                name="add-tax-or-fee"
                formatters={[Button.TYPE.PRIMARY]}
            >
                <FormattedMessage {...localeMessages.addTaxOrFee} />
            </Button>
        )
    }

    render() {
        return (
            <Page
                header={this.renderHeader()}
                formatters={[PAGE_FORMATTERS.WITH_HEADER]}
            >
                <TaxesAndFeesList />
                <EditTaxOrFee />
            </Page>
        );
    }
}

const mapDispatchToProps = {
    showModal,
};

export default connect(null, mapDispatchToProps)(TaxesAndFeesPage);
