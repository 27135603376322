import React from 'react';
import {composeClassName} from "../utils";

export const PAGE_FORMATTERS = {
    WITH_HEADER: 'with-header',
}

export default ({ children, header, formatters = [] }) => {
    return (
        <main className={composeClassName("page", ...formatters)}>
            {header && (
                <div className={composeClassName("page-header", ...formatters)}>
                    {header}
                </div>
            )}
            <div className={composeClassName("page-content", ...formatters)}>
                {children}
            </div>
        </main>
    );        
}
