import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fromJS,
    Iterable,
} from 'immutable';
import {
    FormattedMessage,
    injectIntl,
} from "react-intl";

import {
    Form,
} from 'eksenia-lib/src/Form';
import Modal, {
    hideModal, showModal,
} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';

import {
    TAXES_AND_FEES_PROPS,
    EDIT_TAX_OR_FEE_MODAL_NAME,
    EDIT_TAXES_AND_FEES_FORM_NAME,
    DELETE_CHARGE_MODAL,
} from './constants';
import {
    postTaxOrFee,
    updateTaxOrFee,
    clearEditedTaxOrFee,
    deleteTaxOrFee,
} from './actions';
import editTaxOrFeeForm from './editTaxOrFeeForm';
import localeMessages from './messages';
import Confirm from "eksenia-lib/src/Confirm";
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";
import {addNotification} from "eksenia-lib/src/Notifications";

export const defaultTaxOrFeeData = fromJS({
    [TAXES_AND_FEES_PROPS.IS_INCLUSIVE]: false,
    [TAXES_AND_FEES_PROPS.LOGIC]: '',
    [TAXES_AND_FEES_PROPS.TYPE]: '',
    [TAXES_AND_FEES_PROPS.RATE]: '',
});

export class EditTaxOrFee extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleDeleteCharge = this.handleDeleteCharge.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
    }

    handleConfirmationClose() {
        this.props.hideModal(DELETE_CHARGE_MODAL);
    }

    handleDeleteCharge() {
        const {
            deleteTaxOrFee,
            editedTax,
        } = this.props;

        deleteTaxOrFee(editedTax.get(TAXES_AND_FEES_PROPS.ID), this.deleteSuccessCallback);
    }

    deleteSuccessCallback() {
        const {
            addNotification,
            hideModal,
        } = this.props;

        hideModal();
        hideModal();
        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage {...localeMessages.chargeDeleted} />,
        });
    }

    handleSaveClick(formValues, formName) {
        const {
            postTaxOrFee,
            updateTaxOrFee,
            editedTax,
            onSuccess,
        } = this.props;

        const taxOrFee = formValues.toJS();

        if (Iterable.isIterable(editedTax)) {
            updateTaxOrFee(
                editedTax.get(TAXES_AND_FEES_PROPS.ID),
                {
                    ...editedTax.toJS(),
                    ...taxOrFee,
                },
                formName,
                onSuccess || this.handleCloseModal
            );

        } else {
            postTaxOrFee(
                taxOrFee,
                formName,
                onSuccess || this.handleCloseModal
            );
        }
    }

    handleCloseModal() {
        const {
            hideModal,
            clearEditedTaxOrFee,
            onCancel,
        } = this.props;

        if (typeof onCancel === 'function') {
            onCancel()

        } else {
            clearEditedTaxOrFee();
            hideModal();
        }
    }

    render() {
        const {
            editedTax,
            intl,
            id,
            showModal,
        } = this.props;

        const isExistingTaxOrFee = Iterable.isIterable(editedTax);

        const initialValues = isExistingTaxOrFee
            ? editedTax
            : defaultTaxOrFeeData;

        return (
            <ModalWithForm
                id={id || EDIT_TAX_OR_FEE_MODAL_NAME}
                key={id || EDIT_TAX_OR_FEE_MODAL_NAME}
                headerText={<FormattedMessage {...localeMessages.taxOrFee} />}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={[id || EDIT_TAXES_AND_FEES_FORM_NAME]}
                withDelete={isExistingTaxOrFee}
                onDelete={() => showModal(DELETE_CHARGE_MODAL)}
            >
                <Form
                    key="tax-details"
                    blueprint={editTaxOrFeeForm(initialValues, intl)}
                    onSubmit={this.handleSaveClick}
                />
                <Confirm
                    id={DELETE_CHARGE_MODAL}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleDeleteCharge}
                >
                    <FormattedMessage {...localeMessages.confirmDelete} />
                </Confirm>
            </ModalWithForm>
        );
    }
}

const mapStateToProps = (store) => {
    const taxesAndFeesReducer = store.taxesAndFeesReducer;

    return {
        editedTax: taxesAndFeesReducer.get('editedTax'),
    };
};

const mapDispatchToProps = {
    postTaxOrFee,
    updateTaxOrFee,
    clearEditedTaxOrFee,
    hideModal,
    showModal,
    deleteTaxOrFee,
    addNotification,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditTaxOrFee))
