import React, { Component } from 'react';
import {composeClassName} from "../utils";

export default class ProgressBar extends Component {

    render() {
        const {
            progress,
            formatters = [],
        } = this.props;
        return (
            <div className={composeClassName('progress-bar', ...formatters)}>
                <div className="progress-bar-progress" style={{width: `${progress}%`}}/>
            </div>
        )
    }
}
