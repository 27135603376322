import React, { Component } from 'react';
import icons from './icons';
import {composeClassName} from "../utils";

export default class Icon extends Component {
    render() {
        const {
            name,
            type,
            ...props
        } = this.props;

        const classes = composeClassName("icon", type)

        return (
            <i className={classes} {...props} >
                {icons[name]()}
            </i>
        );
    }
}