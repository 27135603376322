import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl,} from "react-intl";
import Button from "eksenia-lib/src/Button";
import Invoice from "./Invoice";
import {connect} from "react-redux";
import {Iterable, List, Map} from "immutable";
import {
    RESERVATION_PROPS,
    PRICE_BREAKDOWN_PROPS,
    preparePriceBreakdown,
    RESERVATION_CHARGE_PROPS, multiPriceBreakdownToPriceBreakdown,
} from "Reservations";
import {getMultiReservationPriceBreakdown} from "./actions"
import {BKG_RESERVATION_STATUS_VALUES} from "./constants"
import {getReservationGuestName} from "./utils"
import {RMS_ROOM_PROPS} from "Rooms";
import Input from "eksenia-lib/src/Input";
import {INPUT_FORMATTERS} from "eksenia-lib/src/formatters";
import localeMessages from './messages';
import Table from "eksenia-lib/src/Table";
import Money from "eksenia-lib/src/Money";
import {PROPERTY_PROPS} from "../Properties";

const reservationTableHeader = [
    {
        content: localeMessages.room,
        key: 'room',
        width: '20%',
    },
    {
        content: localeMessages.checkInDate,
        key: 'checkIn',
        width: '20%',
    },
    {
        content: localeMessages.checkOutDate,
        key: 'checkOut',
        width: '20%',
    },
    {
        content: localeMessages.nights,
        key: 'nights',
        width: '10%',
    },
    {
        content: localeMessages.price,
        key: 'price',
        width: '30%',
    },
];

class InvoicePage extends Component {

    constructor() {
        super(...arguments);

        this.state = {
            invoiceNumber: '',
        };

        this.handleInvoiceNumberChange = this.handleInvoiceNumberChange.bind(this);
    }

    static handlePrintClick() {
        window.print();
    }

    componentDidMount() {
        const {
            editedReservation,
            getMultiReservationPriceBreakdown,
        } = this.props;

        const editedReservationId = editedReservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID);

        if (editedReservationId) {
            getMultiReservationPriceBreakdown(editedReservationId);
        }
    }

    handleInvoiceNumberChange(invoiceNumber) {
        this.setState({ invoiceNumber });
    }

    isDataReady() {
        return !this.props.editedReservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID) ||
            !!this.props.multiReservationPriceBreakdown;

    }

    prepareInvoiceData() {
        const {
            editedReservation,
            rooms,
            reservations: allReservations,
            currentProperty,
            multiReservationPriceBreakdown,
            intl,
        } = this.props;

        const multiReservationID = editedReservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID);
        const groupReservations = allReservations
            .filter(reservation =>
                (multiReservationID !== null
                    || reservation.get(RESERVATION_PROPS.ID) === editedReservation.get(RESERVATION_PROPS.ID))
                && reservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID) === multiReservationID);
        const nonCanceledReservations = groupReservations.filter(reservation =>
            reservation.get(RESERVATION_PROPS.STATUS) !== BKG_RESERVATION_STATUS_VALUES.CANCELED
        )
        const reservations = nonCanceledReservations.map(reservation => {
            const room = rooms.find(
                room => room.get(RMS_ROOM_PROPS.ID) === reservation.get(RESERVATION_PROPS.ROOM_ID));

            return reservation.set('roomName', room.get(RMS_ROOM_PROPS.NAME));
        });
        const priceBreakdown = editedReservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID)
            && Iterable.isIterable(multiReservationPriceBreakdown)
            ? preparePriceBreakdown(multiPriceBreakdownToPriceBreakdown(multiReservationPriceBreakdown))
            : preparePriceBreakdown(editedReservation);

        return {
            reservations,
            priceBreakdown,
            guestName: getReservationGuestName(editedReservation, intl),
            invoiceNumber: this.state.invoiceNumber,
            propertyName: currentProperty.get(PROPERTY_PROPS.LEGAL_NAME),
            canceled: groupReservations.size - nonCanceledReservations.size
        }
    }

    buildPriceBreakdown(editedReservation) {
        if (editedReservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID)) {
            const breakdown =  this.props.multiReservationPriceBreakdown.toJS();
            return {
                cancellationFee: breakdown[PRICE_BREAKDOWN_PROPS.CANCELLATION_FEE],
                totalRoomPrice: breakdown[PRICE_BREAKDOWN_PROPS.TOTAL_ROOM_PRICE],
                totalPrice: breakdown[PRICE_BREAKDOWN_PROPS.TOTAL_PRICE],
            }
        } else {
            return preparePriceBreakdown(editedReservation);
        }
    }

    prepareReservationTableRows(invoiceData) {
        let reservations = invoiceData.reservations.reduce((agg, reservation, index) => {
            const coloredBackground = (index % 2) === 0 ? 'colored-background' : '';

            agg = agg.push(
                {
                    key: reservation.get(RESERVATION_PROPS.ID),
                    formatters: ['passive', 'top-align', 'no-mobile-columns', 'data-row', 'custom-background', coloredBackground],
                    cells: [
                        {
                            content: reservation.get('roomName'),
                            key: 'room',
                        },
                        {
                            content: <FormattedDate value={reservation.get(RESERVATION_PROPS.CHECK_IN)}/>,
                            key: 'checkIn',
                        },
                        {
                            content: <FormattedDate value={reservation.get(RESERVATION_PROPS.CHECK_OUT)}/>,
                            key: 'checkOut',
                        },
                        {
                            content: reservation.get(RESERVATION_PROPS.CHECK_OUT)
                                .diff(reservation.get(RESERVATION_PROPS.CHECK_IN), 'days'),
                            formatters: ['align-right', 'reset-mobile-alignment'],
                            key: 'nights',
                        },
                        {
                            content: reservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID)
                                ? this.prepareReservationRowPriceBreakdown(reservation)
                                : '',
                            formatters: ['align-right', 'squashed', 'no-data-th', 'multiline', 'price-cell'],
                            key: 'price',
                            contentTag: 'div',
                        }
                    ],
                }
            )
            if (reservation.get(RESERVATION_PROPS.MULTI_RESERVATION_ID)) {
                agg = agg.push({
                    key: `${reservation.get(RESERVATION_PROPS.ID)}-price`,
                    formatters: ['passive', 'top-align', 'no-mobile-columns', 'price-breakdown-row', 'custom-background', coloredBackground],
                    cells: [
                        {
                            content: this.prepareReservationRowPriceBreakdown(reservation),
                            formatters: ['align-right', 'no-data-th', 'multiline'],
                            key: 'price',
                            contentTag: 'div',
                        }
                    ],
                })
            }
            return agg
        }, List());

        const canceledCount = invoiceData.canceled;
        if (canceledCount > 0) {
            const coloredBackground = (canceledCount % 2) !== 0 ? 'colored-background' : '';
            reservations = reservations.push({
                key: 'canceled',
                formatters: ['passive', 'top-align', 'total-price-row', 'no-mobile-columns', coloredBackground],
                cells: [
                    {
                        content: <FormattedMessage
                            {...localeMessages.canceledUnits}
                            values={{count: canceledCount}}
                        />,
                        formatters: ['align-right', 'no-data-th'],
                        key: 'canceled',
                        colSpan: 5,
                    }
                ]
            });
        }

        return reservations;
    }

    prepareReservationRowPriceBreakdown(reservation) {
        const priceBreakdown = preparePriceBreakdown(reservation);

        return (
            <>
                {priceBreakdown.map(charge => {
                    const id = charge.get(RESERVATION_CHARGE_PROPS.ID);
                    return (
                        <div
                            key={id}
                            className={id === RESERVATION_PROPS.PRICE_WITH_All_CHARGES
                                ? 'total-price-row price-row'
                                : 'price-row'
                        }
                        >
                            <FormattedMessage
                                {...localeMessages.priceWithLabel}
                                values={{
                                    label: charge.get(RESERVATION_CHARGE_PROPS.TYPE),
                                    price: <Money value={charge.get(RESERVATION_CHARGE_PROPS.TOTAL_PRICE)}/>
                                }}
                            />
                        </div>
                    )
                }
                )}
            </>
        )
    }

    preparePriceTableRows(invoiceData) {
        const priceBreakdown = invoiceData.priceBreakdown;

        return priceBreakdown.map(item => {
            const itemId = item.get(RESERVATION_CHARGE_PROPS.ID);
            const rowFormatters = itemId === RESERVATION_PROPS.PRICE_WITH_All_CHARGES
                || itemId === PRICE_BREAKDOWN_PROPS.TOTAL_PRICE
                ? ['price-row', 'total-price-row', 'passive', 'no-mobile-columns', 'custom-background']
                : ['price-row', 'passive', 'no-mobile-columns', 'custom-background'];

            return {
                key: itemId,
                formatters: rowFormatters,
                cells: [
                    {
                        content: <FormattedMessage
                            {...localeMessages.priceWithLabel}
                            values={{
                                label: item.get(RESERVATION_CHARGE_PROPS.TYPE),
                                price: <Money value={item.get(RESERVATION_CHARGE_PROPS.TOTAL_PRICE)}/>
                            }}
                        />,
                        key: RESERVATION_CHARGE_PROPS.TOTAL_PRICE,
                        formatters: ['align-right'],
                    },
                ]
            }
        });
    }

    renderInvoicePreview(invoiceData) {
        const reservationTableRows = this.prepareReservationTableRows(invoiceData);
        const priceTableRows = this.preparePriceTableRows(invoiceData);

        return (
            <>
                <Table header={reservationTableHeader} rows={reservationTableRows} />
                <Table hideHeader={true} rows={priceTableRows} />
            </>
    );
    }

    render() {
        const {
            editedReservation,
            currentProperty,
            countryNamesMap,
        } = this.props;

        if (!this.isDataReady()) {
            return null;
        }
        const invoiceData = this.prepareInvoiceData();
        const invoiceTable = this.renderInvoicePreview(invoiceData);

        return (
            <div className="invoice">
                { invoiceTable }

                <div className="print-controls-container">
                    <Input
                        value={this.state.invoiceNumber}
                        onChange={this.handleInvoiceNumberChange}
                        label={<FormattedMessage {...localeMessages.invoiceNumberLabel} />}
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />
                    <Button
                        onClick={ InvoicePage.handlePrintClick }
                        formatters={[Button.TYPE.ACTION]}
                    >
                        <FormattedMessage {...localeMessages.print} />
                    </Button>
                </div>

                <Invoice
                    invoiceTable={invoiceTable}
                    property={currentProperty}
                    guest={editedReservation.getIn([RESERVATION_PROPS.GUEST_LIST, 0]) || Map()}
                    countryNamesMap={countryNamesMap}
                    { ...invoiceData }
                />
            </div>
        );
    }
}

const mapStateToProps = (store, { reservationId }) => {
    const reservationsReducer = store.reservationsReducer;
    const roomsReducer = store.roomsReducer;
    const propertiesReducer = store.propertiesReducer;
    const localeReducer = store.localeReducer;

    const editedReservation = reservationsReducer.getIn(['editedReservations', reservationId, 'editedReservation'], Map());

    return {
        editedReservation,
        reservations: reservationsReducer.get('reservations', Map()),
        rooms: roomsReducer.get('rooms', List()),
        multiReservationPriceBreakdown: reservationsReducer.get('multiReservationPriceBreakdown'),
        currentProperty: propertiesReducer.get('currentProperty') || Map(),
        countryNamesMap: localeReducer.get('countryNamesMap') || Map(),
    }
};

const mapDispatchToProps = {
    getMultiReservationPriceBreakdown,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InvoicePage))
