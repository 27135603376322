import {INPUT_FORMATTERS} from 'eksenia-lib/src/formatters';
import {EDIT_RATE_FORM_NAME, RATE_PROPS,} from './constants';
import {FormattedMessage} from "react-intl";
import localeMessages from './messages';
import React from "react";

export default (initialValues = {}) => ({
    formName: EDIT_RATE_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'name',
            fields: [
                {
                    type: 'input',
                    name: RATE_PROPS.NAME,
                    initialValue: initialValues.get(RATE_PROPS.NAME),
                    label: <FormattedMessage {...localeMessages.name} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.nameTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'period',
            fields: [
                {
                    type: 'date',
                    name: RATE_PROPS.FIRST_NIGHT,
                    initialValue: initialValues.get(RATE_PROPS.FIRST_NIGHT),
                    label: <FormattedMessage {...localeMessages.firstNight} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.firstNightTooltip} />,
                },
                {
                    type: 'date',
                    name: RATE_PROPS.LAST_NIGHT,
                    initialValue: initialValues.get(RATE_PROPS.LAST_NIGHT),
                    label: <FormattedMessage {...localeMessages.lastNight} />,
                    validations: ['required',
                        (value, values) => {
                            return !value.isSameOrAfter(values.get(RATE_PROPS.FIRST_NIGHT))
                                ? <FormattedMessage {...localeMessages.rateEndValidation} />
                                : false
                        }
                    ],
                    help: <FormattedMessage {...localeMessages.lastNightTooltip} />,
                },
            ],
        },

        // {
        //     type: 'select',
        //     name: RATE_PROPS.PRICE_PER,
        //     initialValue: initialValues.get(RATE_PROPS.PRICE_PER),
        //     label: 'Price per',
        //     options: [{
        //         key: PRICE_PER_OPTIONS.NIGHT,
        //         value: PRICE_PER_OPTIONS.NIGHT,
        //         text: 'Night',
        //     }, {
        //         key: PRICE_PER_OPTIONS.WEEK,
        //         value: PRICE_PER_OPTIONS.WEEK,
        //         text: 'Week',
        //     }],
        // },

        {
            type: 'section',
            name: 'price',
            fields: [
                {
                    type: 'money',
                    name: RATE_PROPS.ROOM_PRICE,
                    initialValue: initialValues.get(RATE_PROPS.ROOM_PRICE),
                    label: <FormattedMessage {...localeMessages.roomPrice} />,
                    validations: ['required'],
                    help: <FormattedMessage {...localeMessages.roomPriceTooltip} />,
                },
            ],
        },
        // {
        //     type: 'money',
        //     name: RATE_PROPS.SINGLE_PRICE,
        //     initialValue: initialValues.get(RATE_PROPS.SINGLE_PRICE),
        //     label: <FormattedMessage {...localeMessages.singlePrice} />,
        // },
        // {
        //     type: 'money',
        //     name: RATE_PROPS.DOUBLE_PRICE,
        //     initialValue: initialValues.get(RATE_PROPS.DOUBLE_PRICE),
        //     label: <FormattedMessage {...localeMessages.doublePrice} />,
        // },
        // {
        //     type: 'money',
        //     name: RATE_PROPS.EXTRA_PERSON,
        //     initialValue: initialValues.get(RATE_PROPS.EXTRA_PERSON),
        //     label: <FormattedMessage {...localeMessages.extraPerson} />,
        // },
        // {
        //     type: 'money',
        //     name: RATE_PROPS.EXTRA_CHILD,
        //     initialValue: initialValues.get(RATE_PROPS.EXTRA_CHILD),
        //     label: <FormattedMessage {...localeMessages.extraChild} />,
        // },
    ],
})

