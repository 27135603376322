import React from 'react';

import {INPUT_FORMATTERS} from "eksenia-lib/src/formatters";
import {LANGUAGE_NAME_TEXT, LANGUAGE_NAMES} from "../locale";
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";
import {SET_LANGUAGE_FORM_NAME, USR_PROPS} from "./constants";

export default (
    initialValue = LANGUAGE_NAMES.ENG,
) => ({
    formName: SET_LANGUAGE_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'language',
            fields: [
                {
                    type: 'radio',
                    name: USR_PROPS.LANGUAGE,
                    initialValue: initialValue,
                    label: <FormattedMessage {...localeMessages.chooseLanguage} />,
                    options: [
                        {
                            key: LANGUAGE_NAMES.ENG,
                            value: LANGUAGE_NAMES.ENG,
                            text: LANGUAGE_NAME_TEXT.ENG,
                        },
                        {
                            key: LANGUAGE_NAMES.SER,
                            value: LANGUAGE_NAMES.SER,
                            text: LANGUAGE_NAME_TEXT.SER,
                        },
                        {
                            key: LANGUAGE_NAMES.MNE,
                            value: LANGUAGE_NAMES.MNE,
                            text: LANGUAGE_NAME_TEXT.MNE,
                        },
                    ],
                },
            ],
        },
    ],
});
