import {PROPERTY_PROPS} from "./constants";

export const mapPropertyForPatch = formValues => ({
    "op_list": [
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.NAME}`,
            value: formValues.get(PROPERTY_PROPS.NAME),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.LEGAL_NAME}`,
            value: formValues.get(PROPERTY_PROPS.LEGAL_NAME),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.LEGAL_COUNTRY}`,
            value: formValues.get(PROPERTY_PROPS.LEGAL_COUNTRY),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.CURRENCY}`,
            value: formValues.get(PROPERTY_PROPS.CURRENCY),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.TIME_ZONE}`,
            value: formValues.get(PROPERTY_PROPS.TIME_ZONE),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.LEGAL_CITY}`,
            value: formValues.get(PROPERTY_PROPS.LEGAL_CITY),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.LEGAL_ADDRESS}`,
            value: formValues.get(PROPERTY_PROPS.LEGAL_ADDRESS),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.BUSINESS_EMAIL}`,
            value: formValues.get(PROPERTY_PROPS.BUSINESS_EMAIL),
        },
        {
            op: 'replace',
            path: `/${PROPERTY_PROPS.NOTIFICATIONS_EMAIL}`,
            value: formValues.get(PROPERTY_PROPS.NOTIFICATIONS_EMAIL),
        },
    ],
});