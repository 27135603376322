import { Map } from 'immutable';
import React from "react";
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    PROPERTY_AMENITIES_FORM_NAME,
    BOOKING_PAGE_PROPERTY_PROPS,
    BOOKING_PAGE_PROPERTY_TYPES,
    INTERIOR_TYPES,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
    intl,
) => ({
    formName: PROPERTY_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'select',
                    name: BOOKING_PAGE_PROPERTY_PROPS.TYPE,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.TYPE),
                    label: <FormattedMessage {...localeMessages.propertyType} />,
                    help: <FormattedMessage {...localeMessages.propertyTypeTooltip} />,
                    options: [
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.HOTEL,
                            value: BOOKING_PAGE_PROPERTY_TYPES.HOTEL,
                            text: intl.formatMessage(localeMessages.hotel),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.APARTMENT,
                            value: BOOKING_PAGE_PROPERTY_TYPES.APARTMENT,
                            text: intl.formatMessage(localeMessages.apartment),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.GUEST_HOUSE,
                            value: BOOKING_PAGE_PROPERTY_TYPES.GUEST_HOUSE,
                            text: intl.formatMessage(localeMessages.guestHouse),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.BED_AND_BREAKFAST,
                            value: BOOKING_PAGE_PROPERTY_TYPES.BED_AND_BREAKFAST,
                            text: intl.formatMessage(localeMessages.bedAndBreakfast),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.HOMESTAY,
                            value: BOOKING_PAGE_PROPERTY_TYPES.HOMESTAY,
                            text: intl.formatMessage(localeMessages.homestay),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.HOLIDAY_HOME,
                            value: BOOKING_PAGE_PROPERTY_TYPES.HOLIDAY_HOME,
                            text: intl.formatMessage(localeMessages.holidayHome),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.VILLA,
                            value: BOOKING_PAGE_PROPERTY_TYPES.VILLA,
                            text: intl.formatMessage(localeMessages.villa),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.HOSTEL,
                            value: BOOKING_PAGE_PROPERTY_TYPES.HOSTEL,
                            text: intl.formatMessage(localeMessages.hostel),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.MOTEL,
                            value: BOOKING_PAGE_PROPERTY_TYPES.MOTEL,
                            text: intl.formatMessage(localeMessages.motel),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.LODGE,
                            value: BOOKING_PAGE_PROPERTY_TYPES.LODGE,
                            text: intl.formatMessage(localeMessages.lodge),
                        },
                        {
                            key: BOOKING_PAGE_PROPERTY_TYPES.BOAT,
                            value: BOOKING_PAGE_PROPERTY_TYPES.BOAT,
                            text: intl.formatMessage(localeMessages.boat),
                        },
                    ],
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.FREE_PARKING,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.FREE_PARKING),
                    label: <FormattedMessage {...localeMessages.freeParking} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.PAID_PARKING,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.PAID_PARKING),
                    label: <FormattedMessage {...localeMessages.paidParking} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.WIFI_IN_PUBLIC_AREA,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.WIFI_IN_PUBLIC_AREA),
                    label: <FormattedMessage {...localeMessages.wifiInPublicArea} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.WIFI_IN_ROOMS,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.WIFI_IN_ROOMS),
                    label: <FormattedMessage {...localeMessages.wifiInRooms} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.RESTAURANT,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.RESTAURANT),
                    label: <FormattedMessage {...localeMessages.restaurant} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_PROPERTY_PROPS.OFFER_MEALS,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.OFFER_MEALS),
                    label: <FormattedMessage {...localeMessages.offerMeals} />,
                },
                {
                    type: 'select',
                    name: BOOKING_PAGE_PROPERTY_PROPS.INTERIOR_TYPE,
                    initialValue: initialValues.get(BOOKING_PAGE_PROPERTY_PROPS.INTERIOR_TYPE),
                    label: <FormattedMessage {...localeMessages.interiorType} />,
                    options: [
                        {
                            key: INTERIOR_TYPES.MODERN_INTERIOR,
                            value: INTERIOR_TYPES.MODERN_INTERIOR,
                            text: intl.formatMessage(localeMessages.modernInterior),
                        },
                        {
                            key: INTERIOR_TYPES.CLASSICAL_INTERIOR,
                            value: INTERIOR_TYPES.CLASSICAL_INTERIOR,
                            text: intl.formatMessage(localeMessages.classicalInterior),
                        },
                        {
                            key: INTERIOR_TYPES.TRADITIONAL_INTERIOR,
                            value: INTERIOR_TYPES.TRADITIONAL_INTERIOR,
                            text: intl.formatMessage(localeMessages.traditionalInterior),
                        },
                        {
                            key: INTERIOR_TYPES.INDUSTRIAL_INTERIOR,
                            value: INTERIOR_TYPES.INDUSTRIAL_INTERIOR,
                            text: intl.formatMessage(localeMessages.industrialInterior),
                        },
                        {
                            key: INTERIOR_TYPES.RUSTIC_INTERIOR,
                            value: INTERIOR_TYPES.RUSTIC_INTERIOR,
                            text: intl.formatMessage(localeMessages.rusticInterior),
                        },
                        {
                            key: INTERIOR_TYPES.LUXURY_INTERIOR,
                            value: INTERIOR_TYPES.LUXURY_INTERIOR,
                            text: intl.formatMessage(localeMessages.luxuryInterior),
                        },
                        {
                            key: INTERIOR_TYPES.ORIGINAL_INTERIOR,
                            value: INTERIOR_TYPES.ORIGINAL_INTERIOR,
                            text: intl.formatMessage(localeMessages.originalInterior),
                        },
                    ],
                },
            ],
        },
    ],
})
