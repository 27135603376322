import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import moment from 'moment';

import Modal, {
    hideModal,
    showModal,
} from 'eksenia-lib/src/Modal';
import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import Confirm from 'eksenia-lib/src/Confirm';
import { Form } from 'eksenia-lib/src/Form'
import Button from 'eksenia-lib/src/Button';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    SERVER_DATE_FORMAT,
} from 'utils';

import unavailabilityForm from './unavailabilityForm'
import {
    UNAVAILABILITY_PROPS,
    CAL_UNAVAILABILITY_FORM_NAME,
    CAL_UNAVAILABILITY_MODAL,
    CAL_DELETE_UNAVAILABILITY_MODAL,
    EDITED_UNAVAILABILITY_PROPS,
} from './constants';
import {
    postUnavailability,
    deleteUnavailability,
    putUnavailability,
} from './actions';
import {getRoomUnits} from "../Rooms";
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";

export class EditUnavailability extends Component {

    constructor() {
        super(...arguments);

        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmationOpen = this.handleConfirmationOpen.bind(this);
        this.handleSubmitUnavailability = this.handleSubmitUnavailability.bind(this);
        this.handleDeleteUnavailability = this.handleDeleteUnavailability.bind(this);
        this.successCallback = this.successCallback.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
    }

    handleConfirmationOpen() {
        this.props.showModal(CAL_DELETE_UNAVAILABILITY_MODAL);
    }

    handleConfirmationClose() {
        this.props.hideModal(CAL_DELETE_UNAVAILABILITY_MODAL);
    }

    handleDeleteUnavailability() {
        const {
            deleteUnavailability,
            editedUnavailability,
        } = this.props;

        const unavialabilityId = editedUnavailability.get(UNAVAILABILITY_PROPS.ID);

        deleteUnavailability(unavialabilityId, this.deleteSuccessCallback);
    }

    handleSubmitUnavailability(formValues, formName) {
        const {
            postUnavailability,
            editedUnavailability,
            putUnavailability,
        } = this.props;

        const unavialabilityId = editedUnavailability.get(UNAVAILABILITY_PROPS.ID);

        const unavialability = formValues
            .map(value => {
                return moment.isMoment(value)
                    ? value.format(SERVER_DATE_FORMAT)
                    : value;
            })
            .toJS();

        if (unavialabilityId) {
            putUnavailability({
                ...unavialability,
                [UNAVAILABILITY_PROPS.ID]: unavialabilityId,
            }, unavialabilityId, formName, this.successCallback);

        } else {
            postUnavailability(unavialability, formName, this.successCallback);
        }
    }

    successCallback(data) {
        const {
            addNotification,
            hideModal,
            rooms,
        } = this.props;

        const newValue = data.Add || data.Update;

        const {
            [UNAVAILABILITY_PROPS.START_TIME]: startTime,
            [UNAVAILABILITY_PROPS.END_TIME]: endTime,
            [UNAVAILABILITY_PROPS.ROOM_ID]: roomId,
        } = newValue;

        const roomName = (rooms.find(room => room.get(RMS_ROOM_PROPS.ID) === roomId) || Map())
            .get(RMS_ROOM_PROPS.NAME) || '';

        hideModal();
        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage
                {...localeMessages.unitWillBeClosed}
                values={{
                    roomName,
                    startTime,
                    endTime,
                }}
            />,
        });
    }

    deleteSuccessCallback() {
        const {
            addNotification,
            hideModal,
        } = this.props;

        hideModal();
        hideModal();
        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage {...localeMessages.closingRemoved} />,
        });
    }

    render() {
        const {
            editedUnavailability,
            rooms,
            roomUnits,
            showModal,
        } = this.props;

        const initialRoomId = editedUnavailability.get(EDITED_UNAVAILABILITY_PROPS.ROOM_ID);
        const initialUnitId = editedUnavailability.get(UNAVAILABILITY_PROPS.UNIT_ID);

        const startDate = editedUnavailability.get(UNAVAILABILITY_PROPS.START_TIME)
            || moment().startOf('day');

        const endDate = editedUnavailability.get(UNAVAILABILITY_PROPS.END_TIME)
            || moment().startOf('day').add(1, 'days');

        const isExisting = editedUnavailability.get(UNAVAILABILITY_PROPS.ID) !== undefined;

        return (
            <>
                <Modal
                    id={CAL_UNAVAILABILITY_MODAL}
                    headerText={<FormattedMessage {...localeMessages.unavailability} />}
                    formatters={[Modal.SIZE.BIG]}
                    withDelete={isExisting}
                    onDelete={() => showModal(CAL_DELETE_UNAVAILABILITY_MODAL)}
                >
                    <Form
                        blueprint={unavailabilityForm(rooms, roomUnits, initialRoomId, initialUnitId, startDate, endDate)}
                        onSubmit={this.handleSubmitUnavailability}
                    >
                        {isExisting &&
                            <Button
                                onClick={this.handleConfirmationOpen}
                                formatters={[Button.TYPE.LINK]}
                            >
                                <FormattedMessage {...localeMessages.delete} />
                            </Button>
                        }
                    </Form>
                </Modal>

                <Confirm
                    id={CAL_DELETE_UNAVAILABILITY_MODAL}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleDeleteUnavailability}
                >
                    <FormattedMessage {...localeMessages.confirmDelete} />
                </Confirm>
            </>
        )
    }
}

const mapStateToProps = (store) => {
    const calendarReducer = store.calendarReducer;
    const roomsReducer = store.roomsReducer;
    const formReducer = store.formReducer;

    return {
        editedUnavailability: calendarReducer.get('editedUnavailability', Map()),
        rooms: roomsReducer.get('rooms', List()),
        roomUnits: getRoomUnits(roomsReducer.get('units'), Map()),
        form: formReducer.get(CAL_UNAVAILABILITY_FORM_NAME),
    }
};

const mapDispatchToProps = {
    postUnavailability,
    deleteUnavailability,
    putUnavailability,
    hideModal,
    showModal,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUnavailability)
