import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import { Map } from 'immutable';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    BKG_RESERVATION_FILTERING_PROPS,
    BKG_FILTER_RESERVATIONS_FORM_NAME,
} from './constants';
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";
import React from "react";

export default (rooms, initialValues = Map()) => ({
    formName: BKG_FILTER_RESERVATIONS_FORM_NAME,
    formatters: [INPUT_FORMATTERS.HORIZONTAL],
    noSubmitButton: true,
    fields: [
        {
            type: 'date',
            name: BKG_RESERVATION_FILTERING_PROPS.PERIOD,
            initialValue: initialValues.get(BKG_RESERVATION_FILTERING_PROPS.PERIOD),
            help: <FormattedMessage {...localeMessages.dateFilterHelp} />,
        },
        {
            type: 'select',
            name: BKG_RESERVATION_FILTERING_PROPS.ROOMS,
            initialValue: initialValues.get(BKG_RESERVATION_FILTERING_PROPS.ROOMS),
            options: rooms.map(room => ({
                key: room.get(RMS_ROOM_PROPS.ID),
                value: room.get(RMS_ROOM_PROPS.ID),
                text: room.get(RMS_ROOM_PROPS.NAME),
            })).toJS(),
        },
    ],
})

