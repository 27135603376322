import React from 'react';
import { Map, List } from 'immutable';
import {
    FormattedDate,
    FormattedMessage,
} from 'react-intl';

import Money from 'eksenia-lib/src/Money';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    USR_PROPS,
} from 'User';
import {
    RESERVATION_PROPS,
    BKG_EDIT_RESERVATION_FORM_NAME,
    BKG_RESERVATION_STATUS_VALUES, inputsInfluencingPrice,
} from './constants';
import {UNIT_PROPS} from "../Rooms";
import localeMessages from './messages';
import moment from "moment";
import PriceBreakdown from "./PriceBreakdown";
import {isAnyFieldValueDirty} from "eksenia-lib/src/Form";
import {preparePriceBreakdown} from "./utils";
import Button from "eksenia-lib/src/Button";

export default (
    user,
    rooms,
    roomUnits,
    initialValues = Map(),
    isExistingReservation,
    isIcalReservation,
    calculatedPrice,
    intl,
    handleFormValueChange,
    handleFormMount,
    updateInputField,
) => {
    let statusSectionFields = [{
        type: 'select',
        name: RESERVATION_PROPS.STATUS,
        initialValue: isExistingReservation
            ? initialValues.get(RESERVATION_PROPS.STATUS)
            : BKG_RESERVATION_STATUS_VALUES.CONFIRMED,
        label: <FormattedMessage {...localeMessages.status} />,
        options: [{
            key: BKG_RESERVATION_STATUS_VALUES.NOT_CONFIRMED,
            value: BKG_RESERVATION_STATUS_VALUES.NOT_CONFIRMED,
            text: intl.formatMessage(localeMessages[BKG_RESERVATION_STATUS_VALUES.NOT_CONFIRMED]),
        }, {
            key: BKG_RESERVATION_STATUS_VALUES.CONFIRMED,
            value: BKG_RESERVATION_STATUS_VALUES.CONFIRMED,
            text: intl.formatMessage(localeMessages[BKG_RESERVATION_STATUS_VALUES.CONFIRMED]),
        }, {
            key: BKG_RESERVATION_STATUS_VALUES.CANCELED,
            value: BKG_RESERVATION_STATUS_VALUES.CANCELED,
            text: intl.formatMessage(localeMessages[BKG_RESERVATION_STATUS_VALUES.CANCELED]),
        }],
    }];

    if (isExistingReservation) {
        statusSectionFields = statusSectionFields.concat([
            {
                type: 'custom',
                name: RESERVATION_PROPS.RESERVED_AT,
                initialValue: initialValues.get(RESERVATION_PROPS.RESERVED_AT),
                label: <FormattedMessage {...localeMessages.bookingDate} />,
                render: () =>
                    <FormattedDate dateStyle="medium" timeStyle="medium" value={initialValues.get(RESERVATION_PROPS.RESERVED_AT)} />,
                neverDirty: true,
                defaultValue: initialValues.get(RESERVATION_PROPS.RESERVED_AT),
            },
            {
                type: 'custom',
                name: RESERVATION_PROPS.CHANNEL,
                initialValue: initialValues.get(RESERVATION_PROPS.CHANNEL),
                label: <FormattedMessage {...localeMessages.channel} />,
                render: () => {
                    const channel = initialValues.get(RESERVATION_PROPS.CHANNEL);
                    return channel === 'Booking Page'
                        ? <FormattedMessage {...localeMessages.bookingPage} />
                        : channel || user.get(USR_PROPS.EMAIL);
                },
                neverDirty: true,
                defaultValue: initialValues.get(RESERVATION_PROPS.CHANNEL),
            },
        ]);
    }

    return {
        formName: BKG_EDIT_RESERVATION_FORM_NAME,
        formatters: [INPUT_FORMATTERS.VERTICAL],
        onMount: () => {
            handleFormMount()
        },
        onValueChange: handleFormValueChange,
        fields: [
            {
                type: 'section',
                name: 'status',
                fields: statusSectionFields,
            },

            {
                type: 'section',
                name: 'dates',
                fields: [
                    {
                        type: 'date',
                        name: RESERVATION_PROPS.CHECK_IN,
                        initialValue: initialValues.get(RESERVATION_PROPS.CHECK_IN),
                        label: <FormattedMessage {...localeMessages.checkIn} />,
                        disabled: isExistingReservation && initialValues.get(RESERVATION_PROPS.IS_ICAL_EVENT),
                    },
                    {
                        type: 'date',
                        name: RESERVATION_PROPS.CHECK_OUT,
                        initialValue: initialValues.get(RESERVATION_PROPS.CHECK_OUT),
                        label: <FormattedMessage {...localeMessages.checkOut} />,
                        disabled: isExistingReservation && initialValues.get(RESERVATION_PROPS.IS_ICAL_EVENT),
                        validations: [
                            (value, values) => {
                                return !value.isAfter(values.get(RESERVATION_PROPS.CHECK_IN))
                                    ? <FormattedMessage {...localeMessages.checkOutValidation} />
                                    : false
                            }
                        ],
                        connectTo: {
                            [RESERVATION_PROPS.CHECK_IN]: {
                                mapReceivedValue: (checkInValue, value) =>
                                    moment.isMoment(checkInValue) && checkInValue.isSameOrAfter(value)
                                        ? checkInValue.clone().add(1, 'days')
                                        : value
                            }
                        }
                    },
                ],
            },

            {
                type: 'section',
                name: 'unit',
                fields: [
                    {
                        type: 'select',
                        name: RESERVATION_PROPS.ROOM_ID,
                        initialValue: initialValues.get(RESERVATION_PROPS.ROOM_ID),
                        label: <FormattedMessage {...localeMessages.room} />,
                        disabled: isExistingReservation && initialValues.get(RESERVATION_PROPS.IS_ICAL_EVENT),
                        options: rooms.map(room => ({
                            key: room.get(RMS_ROOM_PROPS.ID),
                            value: room.get(RMS_ROOM_PROPS.ID),
                            text: room.get(RMS_ROOM_PROPS.NAME),
                        })).toJS(),
                    },
                    {
                        type: 'select',
                        name: RESERVATION_PROPS.UNIT_ID,
                        initialValue: initialValues.get(RESERVATION_PROPS.UNIT_ID),
                        label: <FormattedMessage {...localeMessages.unit} />,
                        disabled: isExistingReservation && initialValues.get(RESERVATION_PROPS.IS_ICAL_EVENT),
                        noValueText: <FormattedMessage {...localeMessages.unitDoesNotExist} />,
                        options: props => {
                            return (roomUnits.get(props.values.get(RESERVATION_PROPS.ROOM_ID)) || List())
                                .map(unit => ({
                                    key: unit.get(UNIT_PROPS.ID),
                                    value: unit.get(UNIT_PROPS.ID),
                                    text: unit.get(UNIT_PROPS.NAME),
                                })).toJS()
                        },
                        connectTo: {
                            [RESERVATION_PROPS.ROOM_ID]: {
                                mapReceivedValue: roomValue =>
                                    roomUnits.getIn([roomValue, 0, UNIT_PROPS.ID])
                            }
                        }
                    },
                ],
            },

            {
                type: 'section',
                name: 'guests',
                fields: [
                    {
                        type: 'number',
                        name: RESERVATION_PROPS.ADULTS,
                        initialValue: initialValues.get(RESERVATION_PROPS.ADULTS),
                        label: <FormattedMessage {...localeMessages.adults} />,
                        integer: true,
                        validations: ['required', {validator: 'numberGreaterThan', args: [0]}],
                    },
                    {
                        type: 'number',
                        name: RESERVATION_PROPS.CHILDREN,
                        initialValue: initialValues.get(RESERVATION_PROPS.CHILDREN),
                        label: <FormattedMessage {...localeMessages.children} />,
                        integer: true,
                        validations: ['required', 'numberNotNegative'],
                    },
                    {
                        type: 'number',
                        name: RESERVATION_PROPS.INFANTS,
                        initialValue: initialValues.get(RESERVATION_PROPS.INFANTS) || 0,
                        label: <FormattedMessage {...localeMessages.infants} />,
                        integer: true,
                        validations: ['required', 'numberNotNegative'],
                    },
                ],
            },

            {
                type: 'section',
                name: 'price',
                fields: [
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.CALCULATED_PRICE,
                        initialValue: '',
                        label: <FormattedMessage {...localeMessages.calculatedPriceForPeriod} />,
                        render: () => {
                            const calculatedPriceValue = calculatedPrice.get(RESERVATION_PROPS.CALCULATED_PRICE);

                            if (!calculatedPriceValue) {
                                return '';
                            }

                            return <>
                                <Money value={calculatedPriceValue}/>
                                <Button
                                    formatters={[Button.TYPE.PRIMARY_LINK, Button.SIZE.SMALL]}
                                    onClick={() => {
                                        updateInputField(
                                            BKG_EDIT_RESERVATION_FORM_NAME,
                                            RESERVATION_PROPS.PRICE,
                                            { value: calculatedPriceValue }
                                        )
                                    }}
                                >
                                    <FormattedMessage {...localeMessages.useCalculatedPriceAsPrice} />
                                </Button>
                            </>
                        },
                        neverDirty: true,
                        defaultValue: calculatedPrice,
                    },
                    {
                        type: 'money',
                        name: RESERVATION_PROPS.PRICE,
                        initialValue: initialValues.get(RESERVATION_PROPS.PRICE),
                        label: <FormattedMessage {...localeMessages.price} />,
                        validations: ['required', 'numberNotNegative'],
                    },
                ],
            },
            {
                type: 'section',
                name: 'priceBreakdown',
                fields: [
                    {
                        type: 'custom',
                        name: RESERVATION_PROPS.PRICE_BREAKDOWN,
                        render: props => {
                            const values = isAnyFieldValueDirty([
                                [
                                    initialValues.get(RESERVATION_PROPS.PRICE),
                                    props.values.get(RESERVATION_PROPS.PRICE)
                                ],
                                    ...inputsInfluencingPrice.map(prop => [
                                        initialValues.get(prop),
                                        props.values.get(prop)

                                    ])
                            ]) ? calculatedPrice : initialValues;
                            const priceBreakdown = preparePriceBreakdown(values);
                            return <PriceBreakdown priceBreakdown={priceBreakdown} />
                        },
                        formatters: ['price-breakdown'],
                        neverDirty: true,
                    },
                ],
            },
        ],
    }
}

