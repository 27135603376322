import { defineMessages } from 'react-intl';

export default defineMessages({
    legalName: {
        id: 'property.legalName',
        defaultMessage: 'Legal entity name',
    },
    name: {
        id: 'property.name',
        defaultMessage: 'Property common name',
    },
    timeZone: {
        id: 'property.timeZone',
        defaultMessage: 'Time zone',
    },
    currency: {
        id: 'property.currency',
        defaultMessage: 'Currency',
    },
    legalCountry: {
        id: 'property.legalCountry',
        defaultMessage: 'Registration country',
    },
    legalCity: {
        id: 'property.legalCity',
        defaultMessage: 'Registration city',
    },
    legalAddress: {
        id: 'property.legalAddress',
        defaultMessage: 'Registration address',
    },
    businessEmail: {
        id: 'property.businessEmail',
        defaultMessage: 'Business email',
    },
    notificationsEmail: {
        id: 'property.notificationsEmail',
        defaultMessage: 'Notifications email',
    },
    propertyNameTooltip: {
        id: 'property.propertyNameTooltip',
        defaultMessage: 'Enter the name of your property.',
    },
    propertyLegalNameTooltip: {
        id: 'property.propertyLegalNameTooltip',
        defaultMessage: 'Enter the legal entity name of your business. This name will appear on invoices you print.',
    },
    timezoneTooltip: {
        id: 'property.timezoneTooltip',
        defaultMessage: 'Choose the timezone reservations will be displayed in.',
    },
    currencyTooltip: {
        id: 'property.currencyTooltip',
        defaultMessage: "Choose the currency you are setting your prices in.",
    },
    countryTooltip: {
        id: 'property.countryTooltip',
        defaultMessage: "Choose the country your property is registered in. This country will appear on invoices you print.",
    },
    cityTooltip: {
        id: 'property.cityTooltip',
        defaultMessage: "Enter the name of the city, the town, or the village your property is registered in. This place will appear on invoices you print.",
    },
    addressTooltip: {
        id: 'property.addressTooltip',
        defaultMessage: "Enter the address your property is registered at. This address will appear on invoices you print.",
    },
    businessEmailTooltip: {
        id: 'property.businessEmailTooltip',
        defaultMessage: "Enter the email address of the property. This information will not be displayed on the online booking page.",
    },
    notificationsEmailTooltip: {
        id: 'property.notificationsEmailTooltip',
        defaultMessage: "Enter the email address to which Eksenia should send you automatic notifications.",
    },
})
