import React, {Component} from "react";
import { connect } from 'react-redux'
import Dropdown from "eksenia-lib/src/Dropdown";
import {composeClassName} from "../utils";
import Icon from "eksenia-lib/src/Icon";
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";
import {logOut, SET_LANGUAGE_MODAL_NAME} from "User";
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import EditLanguage from "../User/EditLanguage";

class SettingsMenu extends Component {

    settingsMenuItems() {
        const {
            logOut,
            showModal,
        } = this.props;

        return [
            {
                id: 'language',
                text: <FormattedMessage {...localeMessages.language} />,
                onClick: () => showModal(SET_LANGUAGE_MODAL_NAME),
            },
            // {
            //     id: 'profile',
            //     text: 'Profile',
            //     onClick: () => {},
            // },
            // {
            //     id: 'support',
            //     text: 'Support',
            //     onClick: () => {},
            // },
            {
                id: 'logOut',
                text: <FormattedMessage {...localeMessages.logOut} />,
                onClick: logOut,
            },
        ]
    }

    render() {
        return (
            <>
                <Dropdown
                    title={(() => (
                        <button
                            className={composeClassName('header-menu-item')}
                            aria-labelledby="header-settings"
                            type="button"
                        >
                            <Icon
                                name="headerSettings"
                                type="header-menu"
                            />
                            <div role="tooltip" id="header-settings">
                                <FormattedMessage {...localeMessages.settings} />
                            </div>
                        </button>
                    ))}
                    items={this.settingsMenuItems()}
                />

                <EditLanguage />
            </>
        );
    }
}

const mapDispatchToProps = {
    logOut,
    showModal,
};

export default connect(undefined, mapDispatchToProps)(SettingsMenu)
