export const GET_FILE_UPLOAD_URL_SUCCESS = 'GET_FILE_UPLOAD_URL_SUCCESS';
export const GET_FILE_UPLOAD_URL_ERROR = 'GET_FILE_UPLOAD_URL_ERROR';
export const GET_PUBLIC_FILE_URL_PREFIX_SUCCESS = 'GET_PUBLIC_FILE_URL_PREFIX_SUCCESS';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_UPLOAD_FINISHED = 'SET_UPLOAD_FINISHED';

export const FILE_PREVIEW_MODAL_NAME = 'FILE_PREVIEW_MODAL_NAME';

export const FILE_UPLOAD_PROPS = {
    UPLOAD_URL: 'Url',
    FILE_KEY: 'FileKey',
};

export const FILE_INPUT_PROPS = {
    IDS: 'ids',
    FILES: 'files',
    DO_NOT_KEEP: 'doNotKeep',
    DO_NOT_UPLOAD: 'doNotUpload',
    FILE: 'file',
    PATH: 'path',
};

export const FILE_INPUT_ID_PREFIX = 'file-input_';
