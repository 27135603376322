export function composeClassName(...args) {
    return args.reduce((acc, arg) => {
        if (arg && typeof arg === 'string') {
            arg.split(' ').forEach(item => {
                if (item && acc.indexOf(item) === -1) {
                    acc.push(item);
                }
            });
        }
        return acc;
    }, []).join(' ');
}

