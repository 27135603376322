import { defineMessages } from 'react-intl';

export default defineMessages({
    nothingToShow: {
        id: 'table.nothingToShow',
        defaultMessage: 'Nothing to show',
    },
    descending: {
        id: 'table.descending',
        defaultMessage: 'Descending',
    },
    ascending: {
        id: 'table.ascending',
        defaultMessage: 'Ascending',
    },
    resetSort: {
        id: 'table.resetSort',
        defaultMessage: 'Reset',
    },
    sort: {
        id: 'table.sort',
        defaultMessage: 'Order',
    },
    mobileHeader: {
        id: 'table.mobileHeader',
        defaultMessage: '{message}: ',
    },
})
