import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Iterable, List } from 'immutable';

import Money from 'eksenia-lib/src/Money';
import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import Table from 'eksenia-lib/src/Table';
import {
    deleteRoom,
    openEditRoom,
} from './actions';
import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    RMS_EDIT_ROOM_MODAL_NAME,
    RMS_ROOM_PROPS,
} from './constants';
import {
    getRoomUnits,
} from './utils';
import localeMessages from './messages';

const tableHeader = [
    {
        content: localeMessages.name,
        key: 'name',
        width: '55%',
        sort: true,
    },
    {
        content: localeMessages.unitsCount,
        key: 'units',
        width: '15%',
        sort: true,
    },
    {
        content: localeMessages.maximumAdults,
        key: 'maximumAdults',
        width: '15%',
        sort: true,
    },
    {
        content: localeMessages.minimumPrice,
        key: 'minimumPrice',
        width: '15%',
        sort: {
            type: 'number',
        },
    },
];

export class RoomList extends Component {

    handleOpenModal(room) {
        const {
            showModal,
            openEditRoom,
        } = this.props;

        openEditRoom(room);
        showModal(RMS_EDIT_ROOM_MODAL_NAME);
    }

    prepareTableRows() {
        const {
            rooms,
            roomUnits,
        } = this.props;

        return rooms.map((room) => ({
            key: room.get(RMS_ROOM_PROPS.ID),
            onClick: this.handleOpenModal.bind(this, room),
            cells: [
                {
                    key: 'name',
                    content: room.get(RMS_ROOM_PROPS.NAME),
                    value: room.get(RMS_ROOM_PROPS.NAME),
                },
                {
                    key: 'units',
                    content: (roomUnits.get(room.get(RMS_ROOM_PROPS.ID)) || List()).size,
                    value: (roomUnits.get(room.get(RMS_ROOM_PROPS.ID)) || List()).size,
                },
                {
                    key: 'maximumAdults',
                    content: room.get(RMS_ROOM_PROPS.MAX_ADAULTS),
                    value: room.get(RMS_ROOM_PROPS.MAX_ADAULTS),
                },
                {
                    key: 'minimumPrice',
                    // eslint-disable-next-line
                    content: <Money value={room.get(RMS_ROOM_PROPS.MIN_PRICE)} />,
                    value: room.get(RMS_ROOM_PROPS.MIN_PRICE),
                },
                // {
                //     key: 'actions',
                //     formatters: ['align-right'],
                //     content: (
                //             <Button
                //                 formatters={[Button.TYPE.LINK, INPUT_FORMATTERS.HEIGHT.S]}
                //                 onClick={this.handleConfirmationOpen.bind(this, room.get(RMS_ROOM_PROPS.ID), room.get(RMS_ROOM_PROPS.NAME))}
                //                 name={'delete-room'}
                //             >
                //                 <FormattedMessage {...localeMessages.delete} />
                //             </Button>
                //     ),
                // },
            ],
        }));
    }

    render() {
        const {
            rooms,
        } = this.props;

        if (!Iterable.isIterable(rooms)) {
            return false;
        }

        return (
            <Table header={tableHeader} rows={this.prepareTableRows()} />
        );
    }
}

const mapStateToProps = (store) => {
    const roomsReducer = store.roomsReducer;

    return {
        rooms: roomsReducer.get('rooms'),
        roomUnits: getRoomUnits(roomsReducer.get('units')),
    }
};

const mapDispatchToProps = {
    deleteRoom,
    showModal,
    hideModal,
    openEditRoom,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomList)
