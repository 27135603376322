import {fromJS} from 'immutable';

import {reduceWithDefault} from 'utils';
import {
    GET_LOCALE_REQUEST,
    GET_LOCALE_SUCCESS,
    GET_LOCALE_ERROR,
    GET_COUNTY_NAMES_REQUEST,
    GET_COUNTY_NAMES_SUCCESS,
    GET_COUNTY_NAMES_ERROR,
} from './constants';

const initialState = fromJS({
    localeMessages: null,
    loadingLocaleMessages: false,
    countryNamesList: null,
    countryNamesMap: null,
    loadingCountryNames: false,
});

const reducers = {
    [GET_LOCALE_REQUEST]: state =>
        state.set('loadingLocaleMessages', true),

    [GET_LOCALE_SUCCESS]: (state, { data }) =>
        state.merge({
            localeMessages: data,
            loadingLocaleMessages: false,
        }),

    [GET_LOCALE_ERROR]: state =>
        state.set('loadingLocaleMessages', false),

    [GET_COUNTY_NAMES_REQUEST]: state =>
        state.set('loadingCountryNames', true),

    [GET_COUNTY_NAMES_SUCCESS]: (state, { data }) =>
        state.merge({
            countryNamesList: fromJS(data.sort((a, b) => {
                const aName = a.Name.toUpperCase();
                const bName = b.Name.toUpperCase();
                if (aName < bName) {
                    return -1;
                } else if (aName > bName) {
                    return 1;
                } else {
                    return 0;
                }
            })),
            countryNamesMap: fromJS(data.reduce((agg, cur) => {
                agg[cur.Code] = cur.Name;
                return agg
            }, {})),
            loadingCountryNames: false,
        }),

    [GET_COUNTY_NAMES_ERROR]: state =>
        state.set('loadingCountryNames', false),
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
