import { Map, OrderedMap, fromJS } from 'immutable';

import {
    FORM_ADD_FORM,
    FORM_REMOVE_FORM,
    FORM_UPDATE_FIELD,
    FORM_SET_INITIAL_VALUES_TO_CURRENT,
    FORM_SET_VALIDATION_ERROR,
    FORM_SET_SUBMITTING,
    FORM_SET_NOT_SUBMITTING,
} from './constants';

const initialState = Map();
const getFormValidity = form =>
    !form.get('error') && !form.get('inputFields').find(field => field.get('error'));

export default (state = initialState, action) => {
    const {
        type,
        formName,
        inputName,
        inputFields,
        mergeValues,
        errorMessage,
    } = action;

    if (type === FORM_ADD_FORM) {
        return state.set(formName, Map({
            inputFields: OrderedMap(inputFields.map(field => [
                field.inputName,
                fromJS(field)
            ])),
            valid: true,
        }));
    }

    if (type === FORM_REMOVE_FORM) {
        return state.delete(formName);
    }

    if (type === FORM_UPDATE_FIELD) {
        if (state.get(formName)) {
            return state.mergeIn([formName, 'inputFields', inputName], mergeValues)
                .updateIn([formName], form => form.set('valid', getFormValidity(form)));
        }
    }

    if (type === FORM_SET_INITIAL_VALUES_TO_CURRENT) {
        if (state.get(formName)) {
            return state.updateIn([formName, 'inputFields'], inputFields =>
                (inputFields || OrderedMap()).map(field => field.merge({
                    initialValue: field.get('value'),
                    dirty: false,
                })));
        }
    }

    if (type === FORM_SET_VALIDATION_ERROR) {
        if (state.get(formName)) {
            return state.setIn([formName, 'error'], errorMessage)
                .updateIn([formName], form => form.set('valid', getFormValidity(form)));
        }
    }

    if (type === FORM_SET_SUBMITTING) {
        if (state.get(formName)) {
            return state.setIn([formName, 'submitting'], true);
        }
    }

    if (type === FORM_SET_NOT_SUBMITTING) {
        if (state.get(formName)) {
            return state.setIn([formName, 'submitting'], false);
        }
    }

    return state;
}
