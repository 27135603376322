export const BOOKING_PAGE_SETTINGS_FORM_NAME = 'BOOKING_PAGE_SETTINGS_FORM_NAME';
export const PROPERTY_AMENITIES_FORM_NAME = 'PROPERTY_AMENITIES_FORM_NAME';
export const PROPERTY_LOCATION_FORM_NAME = 'PROPERTY_LOCATION_FORM_NAME';
export const PROPERTY_DESCRIPTION_FORM_NAME = 'PROPERTY_DESCRIPTION_FORM_NAME';
export const BOOKING_PAGE_ROOM_FORM_NAME = 'BOOKING_PAGE_ROOM_FORM_NAME';
export const POPULAR_AMENITIES_FORM_NAME = 'POPULAR_AMENITIES_FORM_NAME';
export const MISCELLANEOUS_AMENITIES_FORM_NAME = 'MISCELLANEOUS_AMENITIES_FORM_NAME';
export const BATHROOM_AMENITIES_FORM_NAME = 'BATHROOM_AMENITIES_FORM_NAME';
export const MEDIA_AMENITIES_FORM_NAME = 'MEDIA_AMENITIES_FORM_NAME';
export const FOOD_AND_DRINK_AMENITIES_FORM_NAME = 'FOOD_AND_DRINK_AMENITIES_FORM_NAME';
export const SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME = 'SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME';
export const BOOKING_PAGE_ROOM_SETTINGS_MODAL = 'BOOKING_PAGE_ROOM_SETTINGS_MODAL';

export const BOOKING_PAGE_ROOM_FORM_PROPS = {
    SHOW: 'ShowOnBookingPage',
    DISPLAY_NAME: 'DisplayName',
    SIZE: 'Size',
    BEDS: 'Beds',
    MAIN_IMAGE: 'MainImage',
    IMAGES: 'Images',

    AIR_CONDITIONING: 'AirConditioning',
    KITCHEN: 'Kitchen',
    KITCHENETTE: 'Kitchenette',
    BALCONY: 'Balcony',
    TERRACE: 'Terrace',
    FLAT_SCREEN_TV: 'FlatScreenTv',
    KITCHENWARE: 'Kitchenware',
    POOL: 'Pool',
    SEA_VIEW: 'SeaView',
    LAKE_VIEW: 'LakeView',
    STREET_VIEW: 'StreetView',
    GARDEN_VIEW: 'GardenView',
    POOL_VIEW: 'PoolView',
    RIVER_VIEW: 'RiverView',
    MOUNTAIN_VIEW: 'MountainView',

    WASHING_MACHINE: 'WashingMachine',
    SOUNDPROOF: 'Soundproof',
    CHILDRENS_CRIBS: 'ChildrensCribs',
    CLOTHES_RACK: 'ClothesRack',
    WASHER_DRYER: 'WasherDryer',
    FOLD_UP_BED: 'FoldUpBed',
    TRASH_CANS: 'TrashCans',
    FREE_SHEETS_AND_TOWELS: 'FreeSheetsAndTowels',
    PAID_SHEETS_AND_TOWELS: 'PaidSheetsAndTowels',
    WALK_IN_CLOSET: 'WalkInCloset',
    FAN: 'Fan',
    FIREPLACE: 'Fireplace',
    HEATING: 'Heating',
    IRON: 'Iron',
    HOT_TUB: 'HotTub',
    PRIVATE_ENTRANCE: 'PrivateEntrance',
    SAFE: 'Safe',
    SITTING_AREA: 'SittingArea',
    TILE_FLOOR: 'TileFloor',
    HARDWOOD_FLOOR: 'HardwoodFloor',
    DESK: 'Desk',
    HYPOALLERGENIC: 'Hypoallergenic',
    CLEANING_PRODUCTS: 'CleaningProducts',
    FEATHER_PILLOW: 'FeatherPillow',
    NON_FEATHER_PILLOW: 'NonFeatherPillow',

    PRIVATE_BATHROOM: 'PrivateBathroom',
    SHARED_BATHROOM: 'SharedBathroom',
    TOILET_PAPER: 'ToiletPaper',
    BATHTUB: 'Bathtub',
    BIDET: 'Bidet',
    SHOWER: 'Shower',
    BATHROBE: 'Bathrobe',
    FREE_TOILETRIES: 'FreeToiletries',
    HAIRDRYER: 'Hairdryer',
    SAUNA: 'Sauna',
    SLIPPERS: 'Slippers',

    TV: 'TV',
    GAME_CONSOLE: 'GameConsole',
    PC: 'PC',
    LAPTOP: 'Laptop',
    FAX: 'Fax',
    IPAD: 'IPad',
    TABLET: 'Tablet',
    CABLE_CHANNELS: 'CableChannels',
    DVD_PLAYER: 'DVDPlayer',
    BLUE_RAY_PLAYER: 'BlueRayPlayer',
    PAY_PER_VIEW_CHANNELS: 'PayPerViewChannels',
    CABLE_SATELLITE: 'CableSatellite',

    DINING_AREA: 'DiningArea',
    DINING_TABLE: 'DiningTable',
    BOTTLE_OF_WATER: 'BottleOfWater',
    COOKIES: 'Cookies',
    FRUIT: 'Fruit',
    WINE: 'Wine',
    BARBECUE: 'Barbecue',
    OVEN: 'Oven',
    STOVE: 'Stove',
    TOASTER: 'Toaster',
    DISHWASHER: 'Dishwasher',
    ELECTRIC_KETTLE: 'ElectricKettle',
    OUTDOOR_DINING_AREA: 'OutdoorDiningArea',
    OUTDOOR_FURNITURE: 'OutdoorFurniture',
    MICROWAVE: 'Microwave',
    REFRIGERATOR: 'Refrigerator',
    TEA_COFFEE_MAKER: 'TeaCoffeeMaker',

    KEY_CARD_ACCESS: 'KeyCardAccess',
    SMOKE_ALARM: 'SmokeAlarm',
    FIRE_EXTINGUISHER: 'FireExtinguisher',
    LOCKERS: 'Lockers',
    KEY_ACCESS: 'KeyAccess',
    EXECUTIVE_LOUNGE_ACCESS: 'ExecutiveLoungeAccess',
    ALARM_CLOCK: 'AlarmClock',
    WAKE_UP_SERVICE: 'WakeUpService',
};

export const BED_TYPES = {
    SINGLE: 'SingleBed',
    TWIN: 'TwinBed',
    DOUBLE: 'DoubleBed',
    QUEEN_DOUBLE: 'QueenDoubleBed',
    KING_DOUBLE: 'KingDoubleBed',
    SOFA: 'SofaBed',
    FOLDABLE: 'FoldableBed',
};

export const BED_PROPS = {
    COUNT: 'Count',
};

export const BOOKING_PAGE_PROPERTY_PROPS = {
    DISPLAY_NAME: 'DisplayName',
    ADDRESS: 'Address',
    BOOKING_PAGE_MAIN_IMAGE: 'BookingPageMainImageID',
    BOOKING_PAGE_IMAGES: 'BookingPageImages',
    AVAILABILITY_RULE: 'AvailabilityRule',
    TYPE: 'Type',
    FREE_PARKING: 'FreeParking',
    PAID_PARKING: 'PaidParking',
    SEASIDE: 'Seaside',
    LAKESIDE: 'Lakeside',
    CITY_CENTER: 'CityCenter',
    RIVERSIDE: 'Riverside',
    MOUNTAIN: 'Mountain',
    BEACHFRONT: 'Beachfront',
    URBAN: 'Urban',
    RURAL: 'Rural',
    WIFI_IN_PUBLIC_AREA: 'WiFiInPublicArea',
    WIFI_IN_ROOMS: 'WiFiInRooms',
    RESTAURANT: 'Restaurant',
    OFFER_MEALS: 'OfferMeals',
    PROXIMITY_TO_BEACH: 'ProximityToBeach',
    PROXIMITY_TO_CITY_CENTER: 'ProximityToCityCenter',
    PROXIMITY_TO_SKI_SLOPE: 'ProximityToSkiSlope',
    PROXIMITY_TO_SPORTING_GROUNDS: 'ProximityToSportingGrounds',
    PROXIMITY_TO_RESTAURANT: 'ProximityToRestaurant',
    PROXIMITY_TO_TOURIST_ATTRACTIONS: 'ProximityToTouristAttractions',
    PROXIMITY_TO_SHOPPING: 'ProximityToShopping',
    PROXIMITY_TO_PUBLIC_TRANSPORT: 'ProximityToPublicTransport',
    PROXIMITY_TO_TRAIN_STATION: 'ProximityToTrainStation',
    PROXIMITY_TO_AIRPORT: 'ProximityToAirport',
    INTERIOR_TYPE: 'InteriorType',
    EMAIL: 'Email',
    PHONE_NUMBER: 'PhoneNumber',
    WEBSITE: 'Website',
    DESCRIPTION_EN: 'DescriptionEn',
    DESCRIPTION_SR: 'DescriptionSr',
    DESCRIPTION_CNR: 'DescriptionCnr',
};

export const INTERIOR_TYPES = {
    MODERN_INTERIOR: 'ModernInterior',
    CLASSICAL_INTERIOR: 'ClassicalInterior',
    TRADITIONAL_INTERIOR: 'TraditionalInterior',
    INDUSTRIAL_INTERIOR: 'IndustrialInterior',
    RUSTIC_INTERIOR: 'RusticInterior',
    LUXURY_INTERIOR: 'LuxuryInterior',
    ORIGINAL_INTERIOR: 'OriginalInterior',
};

export const AVAILABILITY_RULE_OPTIONS = {
    SUM_OF_ALL_BOOKINGS: 'SumOfAllBookings',
    ANY_UNIT_AVAILABLE: 'AnyUnitAvailable',
};

export const BOOKING_PAGE_PROPERTY_TYPES = {
    APARTMENT: 'Apartment',
    HOTEL: 'Hotel',
    HOSTEL: 'Hostel',
    MOTEL: 'Motel',
    GUEST_HOUSE: 'GuestHouse',
    HOMESTAY: 'Homestay',
    BED_AND_BREAKFAST: 'BedAndBreakfast',
    HOLIDAY_HOME: 'HolidayHome',
    VILLA: 'Villa',
    BOAT: 'Boat',
    LODGE: 'Lodge',
};
