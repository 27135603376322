import React from 'react';
import {Iterable, List, Map} from 'immutable';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    RESERVATION_PROPS,
    GUEST_LIST_PROPS,
} from './constants';
import {
    EDIT_GUEST_MODAL_NAME,
    GUEST_PROPS,
    messages as guestMessages,
} from '../Guests'
import ReservationGuest from "./ReservationGuest";
import localeMessages from './messages';
import {RMS_ROOM_PROPS} from "../Rooms";
import {reservationGuestsFormName} from "./utils";

export default (
    initialValues = {},
    handleChange,
    handleGuestClick,
    handleAddNewGuestClick,
    rooms,
    countryNamesMap,
    guestKeys,
    id,
) => ({
    formName: reservationGuestsFormName(id),
    formatters: [INPUT_FORMATTERS.TABLE],
    noSubmitButton: true,
    fields: [
        {
            type: 'inputMatrix',
            name: RESERVATION_PROPS.GUEST_LIST,
            initialValue: (initialValues.get(RESERVATION_PROPS.GUEST_LIST) || List()),
            addInputText: <FormattedMessage {...localeMessages.addGuest} />,
            addInputName: 'add-guest-link',
            deleteInputConfirmationText: <FormattedMessage {...localeMessages.deleteGuest} />,
            tableView: true,
            showDelete: true,
            onChange: handleChange,
            onAddInput: handleAddNewGuestClick,
            maxItems: (rooms.find(room => room.get(RMS_ROOM_PROPS.ID) === initialValues.get(RESERVATION_PROPS.ROOM_ID)) || Map())
                    .get(RMS_ROOM_PROPS.MAX_GUESTS) || 1,
            renderAddInput: () =>
                <ReservationGuest
                    inputId={EDIT_GUEST_MODAL_NAME}
                    formName={reservationGuestsFormName(id)}
                    inputName={RESERVATION_PROPS.GUEST_LIST}
                    onChange={handleChange}
                    reservation={initialValues}
                />
            ,
            headers: [
                {
                    id: 'firstName',
                    text: guestMessages.firstName,
                    width: '25%',
                },
                {
                    id: 'lastName',
                    text: guestMessages.lastName,
                    width: '25%',
                },
                {
                    id: 'country',
                    text: guestMessages.country,
                    width: '25%',
                },
                {
                    id: 'city',
                    text: guestMessages.city,
                    width: '25%',
                },
            ],
            inputs: {
                id: (listName, index, value) => value.get(GUEST_LIST_PROPS.ID),
                entityId: value => value.get(GUEST_LIST_PROPS.GUEST_ID),
                onClick: handleGuestClick,
                showDeletedState: value => {
                    return Iterable.isIterable(value) && !!value.get(GUEST_PROPS.DELETED_AT)
                },
                deletedMessage: <FormattedMessage {...localeMessages.deletedGuest} />,
                edit: props => {
                    const {
                        inputId,
                    } = props;

                    const modalId = guestKeys[inputId];

                    return (modalId
                        ? <ReservationGuest
                            inputId={inputId}
                            formName={reservationGuestsFormName(id)}
                            inputName={RESERVATION_PROPS.GUEST_LIST}
                            onChange={handleChange}
                            modalId={modalId}
                        />
                        : false
                    )
                },
                fields: [
                    {
                        type: 'custom',
                        name: GUEST_PROPS.FIRST_NAME,
                        render: value => value
                    },
                    {
                        type: 'custom',
                        name: GUEST_PROPS.LAST_NAME,
                        render: value => value
                    },
                    {
                        type: 'custom',
                        name: GUEST_PROPS.COUNTRY,
                        render: value => countryNamesMap.get(value)
                    },
                    {
                        type: 'custom',
                        name: GUEST_PROPS.CITY,
                        render: value => value
                    },
                ],
            },
        },
    ],
})

