import CalendarPage from './CalendarPage';
import reducer from './reducer';
import './CalendarPage.scss';

export {
    CalendarPage,
    reducer,
};

export default CalendarPage
