import React, { Component } from 'react';
import ProgressBar from "../ProgressBar";

export default class KeyValueIndicator extends Component {

    render() {
        const {
            label,
            value,
            showProgress,
            progress,
        } = this.props;

        return (
            <div className="key-value">
                <div className="key-value-label">
                    {label}
                </div>
                <div className="key-value-value">
                    {value}
                </div>

                {showProgress &&
                    <div className="progress">
                        <ProgressBar progress={progress} />
                    </div>
                }
            </div>
        )
    }
}
