import { defineMessages } from 'react-intl';

export default defineMessages({
    validOnDays: {
        id: 'rates.validOnDays',
        defaultMessage: 'Valid on days',
    },
    allowCheckInOn: {
        id: 'rates.allowCheckInOn',
        defaultMessage: 'Allow check in on',
    },
    allowCheckOutOn: {
        id: 'rates.allowCheckOutOn',
        defaultMessage: 'Allow check out on',
    },
    details: {
        id: 'rates.details',
        defaultMessage: 'Details',
    },
    rooms: {
        id: 'rates.rooms',
        defaultMessage: 'Rooms',
    },
    bookingLimitations: {
        id: 'rates.bookingLimitations',
        defaultMessage: 'Booking limitations',
    },
    discounts: {
        id: 'rates.discounts',
        defaultMessage: 'Discounts',
    },
    editRate: {
        id: 'rates.editRate',
        defaultMessage: 'Edit rate - {rateName}',
    },
    newRate: {
        id: 'rates.newRate',
        defaultMessage: 'New rate',
    },
    name: {
        id: 'rates.name',
        defaultMessage: 'Name',
    },
    firstNight: {
        id: 'rates.firstNight',
        defaultMessage: 'First night',
    },
    lastNight: {
        id: 'rates.lastNight',
        defaultMessage: 'Last night',
    },
    roomPrice: {
        id: 'rates.roomPrice',
        defaultMessage: 'Room price',
    },
    singlePrice: {
        id: 'rates.singlePrice',
        defaultMessage: 'Single price',
    },
    doublePrice: {
        id: 'rates.doublePrice',
        defaultMessage: 'Double price',
    },
    extraPerson: {
        id: 'rates.extraPerson',
        defaultMessage: 'Extra person',
    },
    extraChild: {
        id: 'rates.extraChild',
        defaultMessage: 'Extra child',
    },
    rateEndValidation: {
        id: 'rates.rateEndValidation',
        defaultMessage: 'Rate cannot end before it begins',
    },
    minimumStay: {
        id: 'rates.minimumStay',
        defaultMessage: 'Minimum stay',
    },
    maximumStay: {
        id: 'rates.maximumStay',
        defaultMessage: 'Maximum stay (0 for unlimited)',
    },
    maximumStayValidation: {
        id: 'rates.maximumStayValidation',
        defaultMessage: 'Maximum stay cannot be shorter than minimum stay',
    },
    minimumDaysInAdvance: {
        id: 'rates.minimumDaysInAdvance',
        defaultMessage: 'Minimum days in advance',
    },
    maximumDaysInAdvance: {
        id: 'rates.maximumDaysInAdvance',
        defaultMessage: 'Maximum days in advance (0 for unlimited)',
    },
    maximumDaysInAdvanceValidation: {
        id: 'rates.maximumDaysInAdvanceValidation',
        defaultMessage: 'Maximum days in advance cannot be shorter than minimum days in advance',
    },
    mondayShort: {
        id: 'rates.mondayShort',
        defaultMessage: 'Mon',
    },
    tuesdayShort: {
        id: 'rates.tuesdayShort',
        defaultMessage: 'Tue',
    },
    wednesdayShort: {
        id: 'rates.wednesdayShort',
        defaultMessage: 'Wed',
    },
    thursdayShort: {
        id: 'rates.thursdayShort',
        defaultMessage: 'Thu',
    },
    fridayShort: {
        id: 'rates.fridayShort',
        defaultMessage: 'Fri',
    },
    saturdayShort: {
        id: 'rates.saturdayShort',
        defaultMessage: 'Sat',
    },
    sundayShort: {
        id: 'rates.sundayShort',
        defaultMessage: 'Sun',
    },
    longStayDiscounts: {
        id: 'rates.longStayDiscounts',
        defaultMessage: 'Long stay discounts',
    },
    addDiscount: {
        id: 'rates.addDiscount',
        defaultMessage: 'Add a discount',
    },
    deleteDiscount: {
        id: 'rates.deleteDiscount',
        defaultMessage: 'Delete discount',
    },
    daysBooked: {
        id: 'rates.daysBooked',
        defaultMessage: 'Days booked',
    },
    percent: {
        id: 'rates.percent',
        defaultMessage: 'Percent',
    },
    perNight: {
        id: 'rates.perNight',
        defaultMessage: 'Per night',
    },
    onceOff: {
        id: 'rates.onceOff',
        defaultMessage: 'Once off',
    },
    priceCap: {
        id: 'rates.priceCap',
        defaultMessage: 'Price cap',
    },
    price: {
        id: 'rates.price',
        defaultMessage: 'Price',
    },
    rateHasBeenRemoved: {
        id: 'rates.rateHasBeenRemoved',
        defaultMessage: 'Rate {rateName} has been removed.',
    },
    roomsCount: {
        id: 'rates.roomsCount',
        defaultMessage: '{count, plural, one {room} other {{count} rooms}}',
    },
    edit: {
        id: 'rates.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'rates.delete',
        defaultMessage: 'Delete',
    },
    deleteRateConfirmation: {
        id: 'rates.deleteRateConfirmation',
        defaultMessage: 'Are you sure you want to delete this rate?',
    },
    addRate: {
        id: 'rates.addRate',
        defaultMessage: 'Add rate',
    },
    applyRateTo: {
        id: 'rates.applyRateTo',
        defaultMessage: 'Apply to rooms',
    },
    room: {
        id: 'rates.room',
        defaultMessage: 'Room',
    },
    use: {
        id: 'rates.use',
        defaultMessage: 'Use',
    },
    multiplier: {
        id: 'rates.multiplier',
        defaultMessage: 'Multiplier',
    },
    nameTooltip: {
        id: 'rates.nameTooltip',
        defaultMessage: "Give this rate a name. It's for your reference only, it will not be visible to guests.",
    },
    firstNightTooltip: {
        id: 'rates.firstNightTooltip',
        defaultMessage: 'Choose the date when this rate will start applying. You can set different rates for different seasons by adding multiple rates for each room and selecting the start and the end date when each of the rates apply.',
    },
    lastNightTooltip: {
        id: 'rates.lastNightTooltip',
        defaultMessage: 'Choose the last date this rate will be applied on. You can set different rates for different seasons by adding multiple rates for each room and selecting the start and the end date when each of the rates apply.',
    },
    roomPriceTooltip: {
        id: 'rates.roomPriceTooltip',
        defaultMessage: 'This is the daily rate for the maximum occupancy you have specified.',
    },
    roomsUseTooltip: {
        id: 'rates.roomsUseTooltip',
        defaultMessage: 'Select rooms you want to assign this rate to.',
    },
    multiplierTooltip: {
        id: 'rates.multiplierTooltip',
        defaultMessage: 'Increase or reduce the predefined price. For example, setting this to 1.2 will multiply the price by 1.2.',
    },
})
