import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    CALENDAR_FILTERING_PROPS,
    CAL_FILTER_CALENDAR_FORM_NAME,
} from './constants';

export default (initialValues = {}) => ({
    formName: CAL_FILTER_CALENDAR_FORM_NAME,
    formatters: [INPUT_FORMATTERS.HORIZONTAL],
    noSubmitButton: true,
    fields: [
        {
            type: 'date',
            name: CALENDAR_FILTERING_PROPS.START_DATE,
            initialValue: initialValues[CALENDAR_FILTERING_PROPS.START_DATE],
        },
    ],
})

