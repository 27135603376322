import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    updateInputField,
} from 'eksenia-lib/src/Form';
import {
    clearEditedGuest,
    addEditedGuest,
} from 'Guests';
import {EditReservation} from "../Reservations";

export class GuestReservation extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveReservation = this.handleSaveReservation.bind(this);
        this.handleDeleteReservation = this.handleDeleteReservation.bind(this);
    }

    handleSaveReservation(data) {
        const {
            updateInputField,
            formName,
            inputName,
        } = this.props;

        updateInputField(formName, inputName, {
            value: data,
        });
    }

    handleDeleteReservation() {

    }

    render() {
        const {
            inputId,
            modalId,
        } = this.props;

        return (
            <EditReservation
                onSuccess={this.handleSaveReservation}
                onDelete={this.handleDeleteReservation}
                id={inputId}
                modalId={modalId}
            />
        );
    }
}

const mapStateToProps = (store, ownProps) => {
    const {
        formName,
        inputName,
    } = ownProps;
    const formReducer = store.formReducer;

    return {
        inputValue: formReducer.getIn([formName, 'inputFields', inputName, 'value'])

    };
};

const mapDispatchToProps = {
    clearEditedGuest,
    addEditedGuest,
    showModal,
    hideModal,
    updateInputField,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestReservation);
