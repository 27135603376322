import { defineMessages } from 'react-intl';

export default defineMessages({
    payment: {
        id: 'payments.payment',
        defaultMessage: 'Payment',
    },
    amount: {
        id: 'payments.amount',
        defaultMessage: 'Amount',
    },
    date: {
        id: 'payments.date',
        defaultMessage: 'Date',
    },
    note: {
        id: 'payments.note',
        defaultMessage: 'Note',
    },
    paymentType: {
        id: 'payments.paymentType',
        defaultMessage: 'Payment type',
    },
    paymentRemoved: {
        id: 'payments.paymentRemoved',
        defaultMessage: 'Payment has been removed.',
    },
    edit: {
        id: 'payments.edit',
        defaultMessage: 'Edit',
    },
    delete: {
        id: 'payments.delete',
        defaultMessage: 'Delete',
    },
    deletePaymentConfirmation: {
        id: 'payments.deletePaymentConfirmation',
        defaultMessage: 'Are you sure you want to delete this payment?',
    },
    addPayment: {
        id: 'payments.addPayment',
        defaultMessage: 'Add Payment',
    },
})
