import { combineReducers } from 'redux';

import roomsReducer from './Rooms/reducer';
import reservationsReducer from './Reservations/reducer';
import {
    reducer as userReducer,
} from 'User';
import {
    reducer as paymentsReducer,
} from 'Payments';
import {
    reducer as calendarReducer,
} from 'Calendar';
import {
    reducer as formReducer,
} from 'eksenia-lib/src/Form';
import {
    reducer as modalReducer,
} from 'eksenia-lib/src/Modal';
import {
    reducer as tabsReducer,
} from 'eksenia-lib/src/Tabs';
import {
    reducer as notificationsReducer,
} from 'eksenia-lib/src/Notifications';
import {
    reducer as ratesReducer,
} from 'Rates';
import {
    reducer as guestsReducer,
} from 'Guests';
import {
    reducer as messagesReducer,
} from 'Messages';
import {
    reducer as propertiesReducer,
} from 'Properties';
import {
    reducer as fileReducer,
} from 'eksenia-lib/src/File';
import {
    reducer as localeReducer,
} from 'locale';
import {
    reducer as taxesAndFeesReducer,
} from 'TaxesAndFees';

export default combineReducers({
    userReducer,
    roomsReducer,
    reservationsReducer,
    calendarReducer,
    formReducer,
    modalReducer,
    tabsReducer,
    paymentsReducer,
    ratesReducer,
    guestsReducer,
    notificationsReducer,
    messagesReducer,
    propertiesReducer,
    fileReducer,
    localeReducer,
    taxesAndFeesReducer,
});
