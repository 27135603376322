import {
    USR_GET_USER_SUCCESS,
    USR_GET_USER_ERROR,
    USR_POST_LOG_IN_REQUEST,
    USR_POST_LOG_IN_SUCCESS,
    USR_POST_LOG_IN_ERROR,
    USR_POST_LOG_OUT_SUCCESS,
    USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS,
    USR_POST_COMPLETE_PASSWORD_RESET_REQUEST,
    USR_POST_COMPLETE_PASSWORD_RESET_ERROR,
    USR_POST_LANGUAGE_SUCCESS, USR_GET_USER_REQUEST,
    USR_POST_REQUEST_PASSWORD_RESET_REQUEST,
    USR_POST_REQUEST_PASSWORD_RESET_SUCCESS,
    USR_POST_REQUEST_PASSWORD_RESET_ERROR,
    USR_ALLOW_NEW_PASSWORD_REQUEST,
} from "./constants";
import {fromJS} from "immutable";
import {reduceWithDefault} from "utils";

const initialState = fromJS({
    loadingUser: false,
    requestPasswordResetSuccess: false,
    requestPasswordResetInProgress: false,
});

const reducers = {
    [USR_GET_USER_REQUEST]: (state) =>
        state.merge({
            loadingUser: true,
        }),

    [USR_GET_USER_SUCCESS]: (state, { data }) =>
        state.merge({
            user: fromJS(data),
            loadingUser: false,
        }),

    [USR_GET_USER_ERROR]: (state) =>
        state.merge({
            user: fromJS({}),
            loadingUser: false,
        }),

    [USR_POST_LOG_IN_REQUEST]: (state) =>
        state.merge({
            loginInProgress: true,
        }),

    [USR_POST_LOG_IN_SUCCESS]: (state, { data }) =>
        state.merge({
            user: fromJS(data),
            loginInProgress: false,
        }),

    [USR_POST_LOG_IN_ERROR]: (state) =>
        state.merge({
            user: fromJS({}),
            loginInProgress: false,
        }),

    [USR_POST_LOG_OUT_SUCCESS]: (state) =>
        state.delete('user'),

    [USR_POST_COMPLETE_PASSWORD_RESET_REQUEST]: (state) =>
        state.merge({
            completePasswordResetInProgress: true,
        }),

    [USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS]: (state, { data }) =>
        state.merge({
            user: fromJS(data),
            completePasswordResetSuccess: true,
            completePasswordResetInProgress: false,
        }),

    [USR_POST_COMPLETE_PASSWORD_RESET_ERROR]: (state) =>
        state.merge({
            user: fromJS({}),
            completePasswordResetSuccess: false,
            completePasswordResetInProgress: false,
        }),

    [USR_POST_REQUEST_PASSWORD_RESET_REQUEST]: state =>
        state.merge({
            requestPasswordResetInProgress: true,
        }),

    [USR_POST_REQUEST_PASSWORD_RESET_SUCCESS]: state =>
        state.merge({
            requestPasswordResetSuccess: true,
            requestPasswordResetInProgress: false,
        }),

    [USR_POST_REQUEST_PASSWORD_RESET_ERROR]: state =>
        state.merge({
            requestPasswordResetSuccess: false,
            requestPasswordResetInProgress: false,
        }),

    [USR_POST_LANGUAGE_SUCCESS]: (state, { data }) =>
        state.merge({
            user: fromJS(data),
        }),

    [USR_ALLOW_NEW_PASSWORD_REQUEST]: state =>
        state.merge({
            requestPasswordResetSuccess: false,
        }),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
