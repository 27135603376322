export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const GET_TIMEZONES_SUCCESS = 'GET_TIMEZONES_SUCCESS';

export const PROPERTY_SETTINGS_FORM = 'PROPERTY_SETTINGS_FORM';

export const PROPERTY_PROPS = {
    ID: 'ID',
    BOOKING_PAGE_ACTIVE: 'BookingPageActive',
    COUNTRY: 'Country',
    CITY: 'City',
    AMENITIES: 'Amenities',
    BOOKING_PAGE_URI: 'BookingPageUri',
    NAME: 'Name',
    LEGAL_NAME: 'LegalName',
    LEGAL_COUNTRY: 'LegalCountry',
    LEGAL_CITY: 'LegalCity',
    LEGAL_ADDRESS: 'LegalAddress',
    BUSINESS_EMAIL: 'BusinessEmail',
    NOTIFICATIONS_EMAIL: 'NotificationsEmail',
    TIME_ZONE: 'IANATimeZoneName',
    CURRENCY: 'Currency',
    EMAIL: 'Email',
    PHONE_NUMBER: 'PhoneNumber',
    WEBSITE: 'Website',
};