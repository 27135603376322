import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'form.save',
        defaultMessage: 'Save',
    },
    saving: {
        id: 'form.saving',
        defaultMessage: 'Saving...',
    },
    saved: {
        id: 'form.saved',
        defaultMessage: 'Saved',
    },
})
