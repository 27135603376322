import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Iterable,
    Map,
    fromJS,
} from 'immutable';
import moment from 'moment';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';

import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import {
    Form,
    updateInputField,
} from 'eksenia-lib/src/Form';
import {
    getRooms,
} from 'Rooms';
import Modal, {
    hideModal,
    showModal,
} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';
import Tabs from 'eksenia-lib/src/Tabs';

import {
    RMS_ROOM_PROPS,
} from '../Rooms';
import {
    EDIT_RATE_MODAL_NAME,
    RATE_PROPS,
    PRICE_PER_OPTIONS,
    RATE_LONG_STAY_DISCOUNT_PROPS,
    DAYS_OF_THE_WEEK,
    RATE_ROOM_PROPS,
    DEFAULT_LONG_STAY_DISCOUNTS,
    EDIT_RATE_FORM_NAME,
    RATE_BOOKING_LIMITATIONS_FORM_NAME,
    RATE_DISCOUNTS_FORM_NAME,
    RATE_ROOMS_FORM_NAME, DELETE_RATE_CONFIRMATION_NAME,
} from './constants';
import {
    postRate,
    putRate,
    getRates,
    clearEditedRate,
    addEditedRate,
    deleteRate,
} from './actions';
import {
    mapRateValues, transformRateForForm,
} from './utils';
import editRateForm from './editRateForm';
import rateBookingLimitationsForm from './rateBookingLimitationsForm';
// import rateDiscountsForm from './rateDiscountsForm';
import rateRoomsForm from './rateRoomsForm';
import localeMessages from './messages';
import Confirm from "eksenia-lib/src/Confirm";
import {NOTIFICATION_TYPE} from "eksenia-lib/src/Notifications/constants";

const defaultDaysLimitiationsData = intl => fromJS([{
    id: RATE_PROPS.ALLOW_DAYS,
    label: intl.formatMessage(localeMessages.validOnDays),
    [DAYS_OF_THE_WEEK.MON]: true,
    [DAYS_OF_THE_WEEK.TUE]: true,
    [DAYS_OF_THE_WEEK.WED]: true,
    [DAYS_OF_THE_WEEK.THU]: true,
    [DAYS_OF_THE_WEEK.FRI]: true,
    [DAYS_OF_THE_WEEK.SAT]: true,
    [DAYS_OF_THE_WEEK.SUN]: true,
// }, {
//     id: RATE_PROPS.ALLOW_CHECK_IN_DAYS,
//     label: intl.formatMessage(localeMessages.allowCheckInOn),
//     [DAYS_OF_THE_WEEK.MON]: true,
//     [DAYS_OF_THE_WEEK.TUE]: true,
//     [DAYS_OF_THE_WEEK.WED]: true,
//     [DAYS_OF_THE_WEEK.THU]: true,
//     [DAYS_OF_THE_WEEK.FRI]: true,
//     [DAYS_OF_THE_WEEK.SAT]: true,
//     [DAYS_OF_THE_WEEK.SUN]: true,
// }, {
//     id: RATE_PROPS.ALLOW_CHECK_OUT_DAYS,
//     label: intl.formatMessage(localeMessages.allowCheckOutOn),
//     [DAYS_OF_THE_WEEK.MON]: true,
//     [DAYS_OF_THE_WEEK.TUE]: true,
//     [DAYS_OF_THE_WEEK.WED]: true,
//     [DAYS_OF_THE_WEEK.THU]: true,
//     [DAYS_OF_THE_WEEK.FRI]: true,
//     [DAYS_OF_THE_WEEK.SAT]: true,
//     [DAYS_OF_THE_WEEK.SUN]: true,
}]);

const defaultRateData = (rooms, intl) => fromJS({
    [RATE_PROPS.NAME]: '',
    [RATE_PROPS.FIRST_NIGHT]: undefined,
    [RATE_PROPS.LAST_NIGHT]: undefined,
    [RATE_PROPS.MINIMUM_STAY]: 1,
    [RATE_PROPS.MAXIMUM_STAY]: 0,
    [RATE_PROPS.PRICE_PER]: PRICE_PER_OPTIONS.NIGHT,
    [RATE_PROPS.LONG_STAY_DISCOUNTS]: DEFAULT_LONG_STAY_DISCOUNTS,
    [RATE_PROPS.DAYS_LIMITATIONS]: defaultDaysLimitiationsData(intl),
    [RATE_PROPS.ROOMS]: rooms.map(room => ({
        label: room.get(RMS_ROOM_PROPS.NAME),
        [RATE_ROOM_PROPS.ROOM_ID]: room.get(RMS_ROOM_PROPS.ID),
        [RATE_ROOM_PROPS.USED]: false,
        [RATE_ROOM_PROPS.MULTIPLIER]: 1,
    })),
});

const isValidDiscountValue = value =>
    !isNaN(value) && value > 0;

const mapRateRoomForApi = value =>
    value.filter(room => room.get(RATE_ROOM_PROPS.USED));

const mapLongStayDiscountsForApi = value =>
    value.filter(discount =>
        isValidDiscountValue(discount.get(RATE_LONG_STAY_DISCOUNT_PROPS.DAYS))
        && (
            isValidDiscountValue(discount.get(RATE_LONG_STAY_DISCOUNT_PROPS.PERCENT))
            || isValidDiscountValue(discount.get(RATE_LONG_STAY_DISCOUNT_PROPS.PER_NIGHT))
            || isValidDiscountValue(discount.get(RATE_LONG_STAY_DISCOUNT_PROPS.ONCE_OFF))
            || isValidDiscountValue(discount.get(RATE_LONG_STAY_DISCOUNT_PROPS.PRICE_CAP))
            || isValidDiscountValue(discount.get(RATE_LONG_STAY_DISCOUNT_PROPS.TEMP_ID))
        ));

const formNames = [
    EDIT_RATE_FORM_NAME,
    RATE_BOOKING_LIMITATIONS_FORM_NAME,
    RATE_DISCOUNTS_FORM_NAME,
    RATE_ROOMS_FORM_NAME,
];

export class EditRate extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.saveSuccessCallback = this.saveSuccessCallback.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleConfirmationClose = this.handleConfirmationClose.bind(this);
        this.handleConfirmationOpen = this.handleConfirmationOpen.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.deleteSuccessCallback = this.deleteSuccessCallback.bind(this);
    }

    handleConfirmationOpen() {
        this.props.showModal(DELETE_RATE_CONFIRMATION_NAME);
    }

    handleConfirmationClose() {
        this.props.hideModal();
    }

    handleConfirmDelete() {
        const {
            deleteRate,
            editedRate,
        } = this.props;

        deleteRate(editedRate.get(RATE_PROPS.ID), this.deleteSuccessCallback);
    }

    handleSaveClick(formValues, formName) {
        const {
            postRate,
            putRate,
            editedRate,
            intl,
            updateInputField,
        } = this.props;

        // for a new rate, submit default day limitation values
        if ((!Iterable.isIterable(editedRate) || !editedRate.get(RATE_PROPS.ID))
            && !formValues.has(RATE_PROPS.DAYS_LIMITATIONS)) {
            formValues = formValues.set(RATE_PROPS.DAYS_LIMITATIONS, defaultDaysLimitiationsData(intl));
        }

        const rate = fromJS(formValues)
            .map((value, key) => {
                if (key === RATE_PROPS.ROOMS && (Iterable.isIterable(value))) {
                    value = value
                        .map(room => room.set(RATE_ROOM_PROPS.MULTIPLIER, room.get(RATE_ROOM_PROPS.MULTIPLIER) || 1));
                    updateInputField(formName, RATE_PROPS.ROOMS, {value});
                    value = mapRateRoomForApi(value);
                }

                if (key === RATE_PROPS.LONG_STAY_DISCOUNTS && (Iterable.isIterable(value))) {
                    value = mapLongStayDiscountsForApi(value);
                }

                return moment.isMoment(value)
                    ? value.format('YYYY-MM-DD')
                    : value;
            })
            .reduce((acc, value, key) => {
                if (key === RATE_PROPS.DAYS_LIMITATIONS) {
                    return acc.merge(
                        value.reduce((daysAcc, daysVal) => {
                            return daysAcc.set(daysVal.get('id'),
                                daysVal.filter(val => val && typeof val === 'boolean')
                            );
                        }, Map())
                    );
                }

                return acc.set(key, value);
            }, Map())
            .toJS();

        if (Iterable.isIterable(editedRate) && editedRate.get(RATE_PROPS.ID)) {
            const updatedEditedRate = editedRate.merge({
                [RATE_PROPS.ROOMS]:
                    mapRateRoomForApi(editedRate.get(RATE_PROPS.ROOMS)),
                [RATE_PROPS.LONG_STAY_DISCOUNTS]:
                    mapLongStayDiscountsForApi(editedRate.get(RATE_PROPS.LONG_STAY_DISCOUNTS)),
                [RATE_PROPS.CREATED_AT]: undefined,
            }).map(value =>
                moment.isMoment(value)
                    ? value.format('YYYY-MM-DD')
                    : value
            );

            putRate(
                updatedEditedRate.get(RATE_PROPS.ID),
                {
                    ...updatedEditedRate.toJS(),
                    ...rate,
                },
                formName,
                this.saveSuccessCallback
            );

        } else {
            if (Iterable.isIterable(editedRate)) {
                const rooms = editedRate.get(RATE_PROPS.ROOMS);
                if (Iterable.isIterable(rooms)) {
                    rate[RATE_PROPS.ROOMS] = mapRateRoomForApi(rooms);
                }
            }
            postRate(rate, formName, this.saveSuccessCallback);
        }
    }

    handleCloseModal() {
        const {
            hideModal,
            clearEditedRate,
        } = this.props;

        clearEditedRate();
        hideModal();
    }

    saveSuccessCallback(data) {
        const {
            getRates,
            addEditedRate,
            rooms,
            intl,
        } = this.props;

        addEditedRate(transformRateForForm(mapRateValues(fromJS(data)), rooms, intl));
        getRates();
    }

    deleteSuccessCallback() {
        const {
            addNotification,
            getRates,
            editedRate,
        } = this.props;

        addNotification({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: <FormattedMessage
                {...localeMessages.rateHasBeenRemoved}
                values={{rateName: editedRate.get(RATE_PROPS.NAME)}}
            />,
        });
        this.handleConfirmationClose();
        this.handleCloseModal();
        getRates();
    }

    render() {
        const {
            editedRate,
            rooms,
            intl,
            showModal,
        } = this.props;

        const hasEditedRate = Iterable.isIterable(editedRate);
        const isExistingRate = !!(hasEditedRate && editedRate.get(RATE_PROPS.ID));

        const initialValues = hasEditedRate
            ? editedRate
            : defaultRateData(rooms, intl);

        const tabs = [
            {
                defaultActive: true,
                id: 'details',
                label: <FormattedMessage {...localeMessages.details} />,
                content: (
                    <Form
                        key="details"
                        blueprint={editRateForm(initialValues)}
                        onSubmit={this.handleSaveClick}
                    />
                ),
                withDelete: !!hasEditedRate,
            },
            {
                id: 'rooms',
                label: <FormattedMessage {...localeMessages.rooms} />,
                content: (
                    <Form
                        key="rooms"
                        blueprint={rateRoomsForm(initialValues)}
                        onSubmit={this.handleSaveClick}
                    />
                ),
                enabled: isExistingRate,
                withDelete: !!hasEditedRate,
            },
            {
                id: 'bookingLimitations',
                label: <FormattedMessage {...localeMessages.bookingLimitations} />,
                content: (
                    <Form
                        key="bookingLimitations"
                        blueprint={rateBookingLimitationsForm(initialValues)}
                        onSubmit={this.handleSaveClick}
                    />
                ),
                enabled: isExistingRate,
            },
            // {
            //     id: 'discounts',
            //     label: <FormattedMessage {...localeMessages.discounts} />,
            //     content: (
            //         <Form
            //             key="discounts"
            //             blueprint={rateDiscountsForm(initialValues)}
            //             onSubmit={this.handleSaveClick}
            //         />
            //     ),
            //     enabled: isExistingRate,
            // },
        ];

        const headerText = isExistingRate
            ? <FormattedMessage {...localeMessages.editRate} values={{rateName: editedRate.get(RATE_PROPS.NAME)}} />
            : <FormattedMessage {...localeMessages.newRate} />;

        return (
            <ModalWithForm
                id={EDIT_RATE_MODAL_NAME}
                headerText={headerText}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={formNames}
            >
                <Tabs
                    groupName="edit-rate"
                    tabs={tabs}
                    activeForms={formNames}
                    onDelete={() => showModal(DELETE_RATE_CONFIRMATION_NAME)}
                />

                <Confirm
                    id={DELETE_RATE_CONFIRMATION_NAME}
                    onCancel={this.handleConfirmationClose}
                    onConfirm={this.handleConfirmDelete}
                >
                    <FormattedMessage {...localeMessages.deleteRateConfirmation} />
                </Confirm>
            </ModalWithForm>
        );
    }
}

const mapStateToProps = (store) => {
    const ratesReducer = store.ratesReducer;
    const roomsReducer = store.roomsReducer;

    return {
        editedRate: ratesReducer.get('editedRate'),
        rooms: roomsReducer.get('rooms'),
    };
};

const mapDispatchToProps = {
    postRate,
    putRate,
    getRates,
    getRooms,
    hideModal,
    showModal,
    clearEditedRate,
    deleteRate,
    addEditedRate,
    addNotification,
    updateInputField,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditRate))
