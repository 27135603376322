import { defineMessages } from 'react-intl';

export default defineMessages({
    home: {
        id: "header.home",
        defaultMessage: "Dashboard",
    },
    calendar: {
        id: "header.calendar",
        defaultMessage: "Calendar",
    },
    reservations: {
        id: "header.reservations",
        defaultMessage: "Reservations",
    },
    rooms: {
        id: "header.rooms",
        defaultMessage: "Rooms",
    },
    guests: {
        id: "header.guests",
        defaultMessage: "Guests",
    },
    rates: {
        id: "header.rates",
        defaultMessage: "Rates",
    },
    "booking-page": {
        id: "header.bookingPage",
        defaultMessage: "Booking Page",
    },
    "property-settings": {
        id: "header.propertySettings",
        defaultMessage: "Property Settings",
    },
    notifications: {
        id: "header.notifications",
        defaultMessage: "Notifications",
    },
    search: {
        id: "header.search",
        defaultMessage: "Search",
    },
    help: {
        id: "header.help",
        defaultMessage: "Help",
    },
    settings: {
        id: "header.settings",
        defaultMessage: "Settings",
    },
    logOut: {
        id: 'header.logOut',
        defaultMessage: 'Log Out',
    },
    language: {
        id: 'header.language',
        defaultMessage: 'Language',
    },
    noNotifications: {
        id: 'header.noNotifications',
        defaultMessage: 'There are no new notifications',
    },
    addReservation: {
        id: 'header.addReservation',
        defaultMessage: 'Add reservation',
    },
    "taxes-and-fees": {
        id: 'header.taxesAndFees',
        defaultMessage: 'Taxes and Fees',
    },
});
