import React, { Component } from 'react';

import { composeClassName } from 'utils';
import Cell from './Cell';

export default class Row extends Component {

    render() {
        const {
            cells,
            isHeader,
            formatters = [],
            header,
            ...restProps
        } = this.props;

        const className = isHeader ? 'table-head' : 'table-row';

        return (
            <tr
                className={composeClassName(
                    className,
                    ...formatters,
                    restProps.onClick ? 'clickable' : 'passive'
                )}
                {...restProps}
            >
                {cells.map(cell =>
                    <Cell
                        isHeader={isHeader}
                        columnId={cell.key}
                        header={header}
                        {...cell}
                    />)
                }
            </tr>
        );
    }
}
