import { Iterable } from 'immutable';
import {
    put,
    takeEvery,
    select,
} from 'redux-saga/effects';

import {
    showModal,
} from '../Modal';
import {
    CANCEL_FORM_CONFIRMATION_NAME,
    LEAVE_FORM,
} from './constants';
import {
    isFormDirty,
} from './utils';

const getFormState = state => state.formReducer;

function* doSetActiveTab(action) {
    const {
        formNames,
        confirmationPrefix,
        confirmationPrerequisite,
        callback,
    } = action;

    if (!Array.isArray(formNames)) {
        yield callback();

    } else {
        const formState = yield select(getFormState);

        const dirtyForm = formNames.find(formName => {
            const form = formState.get(formName);

            if (Iterable.isIterable(form)) {
                const dirty = isFormDirty(form);

                if (dirty) {
                    return true;
                }
            }

            return false
        });

        if (!dirtyForm) {
            if (typeof callback === 'function') {
                yield callback();
            }

        } else {
            if (typeof confirmationPrerequisite === 'function') {
                yield confirmationPrerequisite();
            }
            yield put(showModal(`${confirmationPrefix}_${CANCEL_FORM_CONFIRMATION_NAME}`));
        }
    }
}

function* formSaga() {
  yield takeEvery(LEAVE_FORM, doSetActiveTab);
}

export default formSaga
