import { generateKey } from 'utils';

import {
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    REMOVE_NOTIFICATIONS,
} from './constants';

export const addNotification = notification => ({
    type: ADD_NOTIFICATION,
    notification,
    id: generateKey(),
});

export const removeNotification = id => ({
    type: REMOVE_NOTIFICATION,
    id,
});

export const clearNotifications = () => ({
    type: REMOVE_NOTIFICATIONS,
});
