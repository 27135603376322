import { endpoints } from 'api'

import {
    GET_TAXES_AND_FEES_REQUEST,
    GET_TAXES_AND_FEES_SUCCESS,
    GET_TAXES_AND_FEES_ERROR,
    POST_TAX_OR_FEE_REQUEST,
    POST_TAX_OR_FEE_SUCCESS,
    POST_TAX_OR_FEE_ERROR,
    DELETE_TAX_OR_FEE_REQUEST,
    DELETE_TAX_OR_FEE_SUCCESS,
    DELETE_TAX_OR_FEE_ERROR,
    UPDATE_TAX_OR_FEE_REQUEST,
    UPDATE_TAX_OR_FEE_SUCCESS,
    UPDATE_TAX_OR_FEE_ERROR,
    SET_EDITED_TAX_OR_FEE, CLEAR_EDITED_TAX_OR_FEE,
} from './constants';

export const getTaxesAndFees = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getTaxesAndFees,
    actions: {
        request: GET_TAXES_AND_FEES_REQUEST,
        success: GET_TAXES_AND_FEES_SUCCESS,
        error: GET_TAXES_AND_FEES_ERROR,
    },
});

export const postTaxOrFee = (taxOrFee, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postTaxOrFee,
    actions: {
        request: POST_TAX_OR_FEE_REQUEST,
        success: POST_TAX_OR_FEE_SUCCESS,
        error: POST_TAX_OR_FEE_ERROR,
        successCallback,
    },
    body: taxOrFee,
    formName,
});

export const updateTaxOrFee = (idTax, taxOrFee, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putTaxOrFee,
    actions: {
        request: UPDATE_TAX_OR_FEE_REQUEST,
        success: UPDATE_TAX_OR_FEE_SUCCESS,
        error: UPDATE_TAX_OR_FEE_ERROR,
        successCallback,
    },
    body: taxOrFee,
    params: {
        idTax,
    },
    formName,
});

export const deleteTaxOrFee = (idTax, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteTaxOrFee,
    actions: {
        request: DELETE_TAX_OR_FEE_REQUEST,
        success: DELETE_TAX_OR_FEE_SUCCESS,
        error: DELETE_TAX_OR_FEE_ERROR,
        successCallback,
    },
    params: {
        idTax,
    },
});

export const addEditedTaxOrFee = editedTaxOrFee => ({
    type: SET_EDITED_TAX_OR_FEE,
    editedTaxOrFee,
});

export const clearEditedTaxOrFee = () => ({
    type: CLEAR_EDITED_TAX_OR_FEE,
});
