import FileUpload from './FileUpload';
import './FileUpload.scss';
import reducer from './reducer';

export * from './actions';
export * from './utils';
export {
    reducer,
};

export default FileUpload
