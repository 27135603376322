import TaxesAndFees from './TaxesAndFeesPage';
import './TaxesAndFees.scss';
import reducer from './reducer';
import messages from './messages';

export * from './constants';
export * from './actions';

export {
    TaxesAndFees,
    reducer,
    messages,
};

export default TaxesAndFees
