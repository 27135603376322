import React, { Component } from 'react';
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import moment from 'moment';
import {
    FormattedMessage,
    FormattedDate, injectIntl,
} from 'react-intl';

import {
    showModal,
} from 'eksenia-lib/src/Modal';
import Page from 'eksenia-lib/src/Page';
import Widget from 'eksenia-lib/src/Widget';
import Table from 'eksenia-lib/src/Table';
import Checkbox from 'eksenia-lib/src/Checkbox';
import {
    RMS_ROOM_PROPS,
    getRooms,
} from 'Rooms';
import {
    RESERVATION_PROPS,
    BKG_RESERVATION_STATUS_VALUES,
    patchReservation,
    getReservations,
    getLatestReservations,
    getReservationGuestName,
    openEditReservationModal,
    EditReservation,
} from 'Reservations';
import {UNIT_PROPS} from "../Rooms";
import localeMessages from './messages';
import KeyValueIndicator from "eksenia-lib/src/KeyValueIndicator";

const latestReservationsHeader = [
    {
        content: localeMessages.room,
        key: 'room',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.unit,
        key: 'unit',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.guestName,
        key: 'guestName',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.checkIn,
        key: 'checkIn',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.checkOut,
        key: 'checkOut',
        width: '20%',
        sort: true,
    },
];
const arrivalsHeader = [
    {
        content: localeMessages.checkStatus,
        key: 'checkStatus',
        width: '120px',
        help: <FormattedMessage {...localeMessages.arrivalsCheckStatusTooltip} />,
    },
    {
        content: localeMessages.room,
        key: 'room',
        width: '30%',
    },
    {
        content: localeMessages.unit,
        key: 'unit',
        width: '30%',
    },
    {
        content: localeMessages.guestName,
        key: 'guestName',
        width: '40%',
    },
];
const departuresHeader = [
    {
        content: localeMessages.checkStatus,
        key: 'checkStatus',
        width: '120px',
        help: <FormattedMessage {...localeMessages.departureCheckStatusTooltip} />,
    },
    {
        content: localeMessages.room,
        key: 'room',
        width: '30%',
    },
    {
        content: localeMessages.unit,
        key: 'unit',
        width: '30%',
    },
    {
        content: localeMessages.guestName,
        key: 'guestName',
        width: '40%',
    },
];
const currentGuestsHeader = [
    {
        content: localeMessages.room,
        key: 'room',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.unit,
        key: 'unit',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.guestName,
        key: 'guestName',
        width: '40%',
        sort: true,
    },
    {
        content: localeMessages.checkOut,
        key: 'checkOut',
        width: '20%',
        sort: true,
    },
];

export class Dashboard extends Component {

    constructor() {
        super(...arguments);

        this.changeReservationStatusCallback = this.changeReservationStatusCallback.bind(this);
        this.setEditedReservation = this.setEditedReservation.bind(this);
        this.state = {
            editedReservation: null,
        };
    }

    componentDidMount() {
        const {
            getReservations,
            getLatestReservations,
        } = this.props;

        getReservations();
        getLatestReservations();
    }

    handleChangeReservationStatus(reservation, checkStatus, checkOut) {
        this.props.patchReservation(
            reservation.get(RESERVATION_PROPS.ID),
            {
                "op_list": [{
                    op: 'replace',
                    path: `/${checkOut ? RESERVATION_PROPS.CHECKED_OUT : RESERVATION_PROPS.CHECKED_IN}`,
                    value: checkStatus,
                }],
            },
            undefined,
            this.changeReservationStatusCallback,
        )
    }

    handleCheckInChange(reservation, checked) {
        this.handleChangeReservationStatus(reservation, checked);
    }

    handleCheckOutChange(reservation, checked) {
        this.handleChangeReservationStatus(reservation, checked, true);
    }

    setEditedReservation(reservationId) {
        this.setState({ editedReservation: reservationId })
    }

    changeReservationStatusCallback() {
        const {
            getReservations,
        } = this.props;
        
        getReservations();
    }

    renderDailySummary(arrivals, departures) {
        const {
            latestReservations,
        } = this.props;

        const checkedIn = arrivals.filter(reservation =>
            reservation.get(RESERVATION_PROPS.CHECKED_IN)
        ).size;
        const checkedOut = departures.filter(reservation =>
            reservation.get(RESERVATION_PROPS.CHECKED_OUT)
        ).size;

        return (
            <Widget
                title={<FormattedMessage {...localeMessages.dailySummary} />}
                formatters={['secondary-widget', 'daily-summary']}
            >
                <div className="daily-summary-container">
                    <div className="daily-summary-element">
                        <KeyValueIndicator
                            label={<FormattedMessage {...localeMessages.arrivals} />}
                            value={<FormattedMessage
                                {...localeMessages.numberOf}
                                values={{
                                    number: checkedIn,
                                    total: arrivals.size,
                                }}
                            />}
                            showProgress
                            progress={checkedIn === 0 ? 0 : Math.floor(arrivals.size / checkedIn)}
                        />
                    </div>

                    <div className="daily-summary-element">
                        <KeyValueIndicator
                            label={<FormattedMessage {...localeMessages.departures} />}
                            value={<FormattedMessage
                                {...localeMessages.numberOf}
                                values={{
                                    number: checkedOut,
                                    total: departures.size,
                                }}
                            />}
                            showProgress
                            progress={checkedOut === 0 ? 0 : Math.floor(departures.size / checkedOut)}
                        />
                    </div>

                    <div className="daily-summary-element">
                        <KeyValueIndicator
                            label={<FormattedMessage {...localeMessages.newBookings} />}
                            value={latestReservations.size}
                        />
                    </div>
                </div>
            </Widget>
        );
    }

    renderArrivals(arrivals) {
        const {
            openEditReservationModal,
            showModal,
            intl,
        } = this.props;

        const rows = [];
        let date = moment().startOf('day');

        arrivals.forEach(arrival => {
            const reservationId = arrival.get(RESERVATION_PROPS.ID);
            const checkIn = arrival.get(RESERVATION_PROPS.CHECK_IN);

            if (checkIn.isBefore(date, 'date')) {
                rows.push({
                    key: checkIn.toString(),
                    className: 'table-row date-row',
                    cells: [
                        {
                            key: 'date',
                            content: <FormattedDate value={checkIn.toString()} />,
                            value: checkIn,
                            colSpan: 4,
                            className: 'date-cell',
                        }
                    ]
                });
                date = checkIn.clone();
            }

            rows.push({
                key: reservationId,
                cells: [
                    {
                        content: (
                            <Checkbox
                                value={arrival.get(RESERVATION_PROPS.CHECKED_IN)}
                                onChange={this.handleCheckInChange.bind(this, arrival)}
                                formatters={['in-table', Checkbox.TYPE.SECONDARY]}
                            />
                        ),
                        value: arrival.get(RESERVATION_PROPS.CHECKED_IN),
                        key: 'checkStatus',
                        onClick: event => event.stopPropagation(),
                    },
                    {
                        content: arrival.get(RESERVATION_PROPS.ROOM_NAME),
                        value: arrival.get(RESERVATION_PROPS.ROOM_NAME),
                        key: 'room',
                    },
                    {
                        content: arrival.get(RESERVATION_PROPS.UNIT_NAME),
                        value: arrival.get(RESERVATION_PROPS.UNIT_NAME),
                        key: 'unit',
                    },
                    {
                        content: getReservationGuestName(arrival, intl),
                        value: getReservationGuestName(arrival, intl),
                        key: 'guestName',
                    },
                ],
                onClick: () => {
                    openEditReservationModal(arrival);
                    this.setEditedReservation(reservationId);
                    showModal(reservationId);
                }
            })
        });

        return (
            <Widget
                title={<FormattedMessage {...localeMessages.arrivals} />}
                help={<FormattedMessage {...localeMessages.arrivalsTooltip} />}
                formatters={[rows.length === 0 && 'empty']}
            >
                <Table header={arrivalsHeader} rows={rows} />
            </Widget>
        );
    }

    renderDepartures(departures) {
        const {
            openEditReservationModal,
            showModal,
            intl,
        } = this.props;

        const rows = [];
        let date = moment().startOf('day');

        departures.forEach(departure => {
            const reservationId = departure.get(RESERVATION_PROPS.ID);
            const checkOut = departure.get(RESERVATION_PROPS.CHECK_OUT);

            if (checkOut.isBefore(date, 'date')) {
                rows.push({
                    key: checkOut.toString(),
                    className: 'table-row date-row',
                    cells: [
                        {
                            key: 'date',
                            content: <FormattedDate value={checkOut.toString()} />,
                            value: checkOut,
                            colSpan: 4,
                            className: 'date-cell',
                        }
                    ]
                });
                date = checkOut.clone();
            }

            rows.push({
                key: reservationId,
                cells: [
                    {
                        content: (
                            <Checkbox
                                value={departure.get(RESERVATION_PROPS.CHECKED_OUT)}
                                onChange={this.handleCheckOutChange.bind(this, departure)}
                                formatters={['in-table', Checkbox.TYPE.SECONDARY]}
                            />
                        ),
                        value: departure.get(RESERVATION_PROPS.CHECKED_OUT),
                        key: 'checkStatus',
                        onClick: event => event.stopPropagation(),
                    },
                    {
                        content: departure.get(RESERVATION_PROPS.ROOM_NAME),
                        value: departure.get(RESERVATION_PROPS.ROOM_NAME),
                        key: 'room',
                    },
                    {
                        content: departure.get(RESERVATION_PROPS.UNIT_NAME),
                        value: departure.get(RESERVATION_PROPS.UNIT_NAME),
                        key: 'unit',
                    },
                    {
                        content: getReservationGuestName(departure, intl),
                        value: getReservationGuestName(departure, intl),
                        key: 'guestName',
                    },
                ],
                onClick: () => {
                    openEditReservationModal(departure);
                    this.setEditedReservation(reservationId);
                    showModal(reservationId);
                }
            })
        });

        return (
            <Widget
                title={<FormattedMessage {...localeMessages.departures} />}
                help={<FormattedMessage {...localeMessages.departuresTooltip} />}
                formatters={[rows.length === 0 && 'empty']}
            >
                <Table header={departuresHeader} rows={rows} />
            </Widget>
        );
    }

    renderCurrentGuests(currentGuests) {
        const {
            openEditReservationModal,
            showModal,
            intl,
        } = this.props;

        const rows = currentGuests.map(currentGuest => ({
            key: currentGuest.get(RESERVATION_PROPS.ID),
            cells: [
                {
                    content: currentGuest.get(RESERVATION_PROPS.ROOM_NAME),
                    value: currentGuest.get(RESERVATION_PROPS.ROOM_NAME),
                    key: 'room',
                },
                {
                    content: currentGuest.get(RESERVATION_PROPS.UNIT_NAME),
                    value: currentGuest.get(RESERVATION_PROPS.UNIT_NAME),
                    key: 'unit',
                },
                {
                    content: getReservationGuestName(currentGuest, intl),
                    value: getReservationGuestName(currentGuest, intl),
                    key: 'guestName',
                },
                {
                    content: <FormattedDate
                        value={currentGuest.get(RESERVATION_PROPS.CHECK_OUT).toDate()}
                    />,
                    value: currentGuest.get(RESERVATION_PROPS.CHECK_OUT),
                    key: 'checkOut',
                },
            ],
            onClick: () => {
                openEditReservationModal(currentGuest);
                this.setEditedReservation(currentGuest.get(RESERVATION_PROPS.ID));
                showModal(currentGuest.get(RESERVATION_PROPS.ID));
            }
        }));

        return (
            <Widget
                title={<FormattedMessage {...localeMessages.currentGuests} />}
                help={<FormattedMessage {...localeMessages.currentGuestsTooltip} />}
                formatters={['current-guests', 'first-column']}
            >
                <Table header={currentGuestsHeader} rows={rows} />
            </Widget>
        );
    }

    renderLatestReservations() {
        const {
            latestReservations,
            openEditReservationModal,
            showModal,
            intl,
        } = this.props;

        const rows = latestReservations.map(reservation => {
            const reservationId = reservation.get(RESERVATION_PROPS.ID);
            const roomName = this.getReservationRoomName(reservation);
            const unitName = this.getReservationUnitName(reservation);

            return {
                key: reservationId,
                cells: [
                    {
                        content: roomName,
                        value: roomName,
                        key: 'roomName',
                    },
                    {
                        content: unitName,
                        value: unitName,
                        key: 'unitName',
                    },
                    {
                        content: getReservationGuestName(reservation, intl),
                        value: getReservationGuestName(reservation, intl),
                        key: 'guestName',
                    },
                    {
                        content: <FormattedDate
                            value={reservation.get(RESERVATION_PROPS.CHECK_IN).toDate()}
                        />,
                        value: reservation.get(RESERVATION_PROPS.CHECK_IN),
                        key: 'checkIn',
                    },
                    {
                        content: <FormattedDate
                            value={reservation.get(RESERVATION_PROPS.CHECK_OUT).toDate()}
                        />,
                        value: reservation.get(RESERVATION_PROPS.CHECK_OUT),
                        key: 'checkOut',
                    },
                ],
                onClick: () => {
                    openEditReservationModal(reservation);
                    this.setEditedReservation(reservationId);
                    showModal(reservationId);
                }
            }
        });

        return (
            <Widget
                title={<FormattedMessage {...localeMessages.latestBookings} />}
                help={<FormattedMessage {...localeMessages.latestBookingsTooltip} />}
                formatters={['first-column']}
            >
                <Table header={latestReservationsHeader} rows={rows} />
            </Widget>
        );
    }

    prepareReservationList() {
        const {
            reservations,
        } = this.props;

        const now = moment();

        return reservations.reduce((acc, reservation) => {
            const roomName = this.getReservationRoomName(reservation);
            const unitName = this.getReservationUnitName(reservation);
            let updatedReservation = reservation.merge({
                [RESERVATION_PROPS.ROOM_NAME]: roomName,
                [RESERVATION_PROPS.UNIT_NAME]: unitName,
            });
            const status = updatedReservation.get(RESERVATION_PROPS.STATUS);
            const checkOut = updatedReservation.get(RESERVATION_PROPS.CHECK_OUT);
            const checkIn = updatedReservation.get(RESERVATION_PROPS.CHECK_IN);
            const isCheckedIn = updatedReservation.get(RESERVATION_PROPS.CHECKED_IN);
            const isCheckedOut = updatedReservation.get(RESERVATION_PROPS.CHECKED_OUT);

            if (status === BKG_RESERVATION_STATUS_VALUES.CONFIRMED
                && (now.isSame(checkIn, 'day')
                || (now.isAfter(checkIn, 'day') && !isCheckedIn))
            ){
                if (now.isAfter(checkIn, 'day')) {
                    updatedReservation = updatedReservation.set('isLate', true);
                }
                acc = acc.updateIn(['arrivals'], arrivals =>
                    arrivals.push(updatedReservation));
            }

            if (status === BKG_RESERVATION_STATUS_VALUES.CONFIRMED
                && (now.isSame(checkOut, 'day')
                || (now.isAfter(checkOut, 'day') && !isCheckedOut))
            ) {
                acc = acc.updateIn(['departures'], departures =>
                    departures.push(updatedReservation));
            }

            if (status === BKG_RESERVATION_STATUS_VALUES.CONFIRMED
                && now.isSameOrAfter(checkIn, 'day')
                && now.isSameOrBefore(checkOut, 'day')) {
                acc = acc.updateIn(['currentGuests'], currentGuests =>
                    currentGuests.push(updatedReservation));
            }

            return acc;
        }, Map({
            arrivals: List(),
            departures: List(),
            currentGuests: List(),
        })).toObject();
    }

    getReservationRoomName(reservation) {
        const {
            rooms,
        } = this.props;
        const roomId = reservation.get(RESERVATION_PROPS.ROOM_ID);

        return (rooms.find(room => room.get(RMS_ROOM_PROPS.ID) === roomId) || Map())
            .get(RMS_ROOM_PROPS.NAME, '');
    };

    getReservationUnitName(reservation) {
        const {
            units,
        } = this.props;

        return (units.find(unit =>
            unit.get(UNIT_PROPS.ID) === reservation.get(RESERVATION_PROPS.UNIT_ID)) || Map())
            .get(UNIT_PROPS.NAME, '');
    };

    render() {
        const {
            arrivals,
            departures,
            currentGuests,
        } = this.prepareReservationList();

        const sortedArrivals = arrivals.sort((a, b) => {
            if (a.get(RESERVATION_PROPS.CHECK_IN).isBefore(b.get(RESERVATION_PROPS.CHECK_IN), 'date')) {
                return 1;
            } else if (a.get(RESERVATION_PROPS.CHECK_IN).isAfter(b.get(RESERVATION_PROPS.CHECK_IN), 'date')) {
                return -1;
            } else {
                return 0;
            }
        });
        const sortedDepartures = departures.sort((a, b) => {
            if (a.get(RESERVATION_PROPS.CHECK_OUT).isBefore(b.get(RESERVATION_PROPS.CHECK_OUT), 'date')) {
                return 1;
            } else if (a.get(RESERVATION_PROPS.CHECK_OUT).isAfter(b.get(RESERVATION_PROPS.CHECK_OUT), 'date')) {
                return -1;
            } else {
                return 0;
            }
        });

        return (
            <Page
                formatters={["full-width", "full-height", "scrollable"]}
            >
                <div className="dashboard">
                    <div className="dashboard-column">
                        {this.renderCurrentGuests(currentGuests)}
                        {this.renderLatestReservations()}
                    </div>
                    <div className="dashboard-column">
                        {this.renderArrivals(sortedArrivals)}
                        {this.renderDepartures(sortedDepartures)}
                        {this.renderDailySummary(sortedArrivals, sortedDepartures)}
                    </div>
                </div>
                <EditReservation id={this.state.editedReservation} />
            </Page>
        )
    }
}

const mapStateToProps = (store) => {
    const roomsReducer = store.roomsReducer;
    const reservationsReducer = store.reservationsReducer;

    return {
        rooms: roomsReducer.get('rooms') || List(),
        units: roomsReducer.get('units') || List(),
        reservations: reservationsReducer.get('reservations') || List(),
        latestReservations: reservationsReducer.get('latestReservations') || List(),
    }
};

const mapDispatchToProps = {
    patchReservation,
    getRooms,
    getReservations,
    getLatestReservations,
    openEditReservationModal,
    showModal,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
