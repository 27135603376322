import { endpoints } from 'api'

import {
    GET_MULTI_RESERVATION_PRICE_BREAKDOWN_SUCCESS,
    GET_RESERVATIONS_SUCCESS,
    BKG_POST_RESERVATIONS_REQUEST,
    BKG_POST_RESERVATIONS_SUCCESS,
    BKG_POST_RESERVATIONS_ERROR,
    BKG_UPDATE_RESERVATIONS_REQUEST,
    BKG_UPDATE_RESERVATIONS_SUCCESS,
    BKG_UPDATE_RESERVATIONS_ERROR,
    BKG_DELETE_RESERVATIONS_REQUEST,
    BKG_DELETE_RESERVATIONS_SUCCESS,
    BKG_DELETE_RESERVATIONS_ERROR,
    GET_PROVISIONAL_PRICE_REQUEST,
    GET_PROVISIONAL_PRICE_SUCCESS,
    GET_PROVISIONAL_PRICE_ERROR,
    BKG_OPEN_EDIT_RESERVATION_MODAL,
    BKG_CLOSE_EDIT_RESERVATION_MODAL,
    GET_LATEST_RESERVATIONS_SUCCESS,
    PATCH_RESERVATIONS_SUCCESS, BKG_CLEAR_ALL_EDITED_RESERVATIONS,
    SET_RESERVATION_LIST_EDITED_RESERVATION,
    SAVE_NEW_RESERVATION_ID,
    DELETE_NEW_RESERVATION_ID,
} from './constants'

export const getReservations = (successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.getReservations,
    actions: {
        success: GET_RESERVATIONS_SUCCESS,
        successCallback,
    },
});

export const postReservation = (reservation, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postReservation,
    actions: {
        request: BKG_POST_RESERVATIONS_REQUEST,
        success: BKG_POST_RESERVATIONS_SUCCESS,
        error: BKG_POST_RESERVATIONS_ERROR,
        successCallback,
    },
    body: reservation,
    formName,
});

export const putReservation = (idReservation, reservation, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putReservation,
    actions: {
        request: BKG_UPDATE_RESERVATIONS_REQUEST,
        success: BKG_UPDATE_RESERVATIONS_SUCCESS,
        error: BKG_UPDATE_RESERVATIONS_ERROR,
        successCallback,
    },
    params: { idReservation },
    body: reservation,
    formName,
});

export const patchReservation = (idReservation, patchObj, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.patchReservation,
    actions: {
        success: PATCH_RESERVATIONS_SUCCESS,
        successCallback,
    },
    params: { idReservation },
    body: patchObj,
    formName,
});

export const deleteReservation = (idReservation, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteReservation,
    actions: {
        request: BKG_DELETE_RESERVATIONS_REQUEST,
        success: BKG_DELETE_RESERVATIONS_SUCCESS,
        error: BKG_DELETE_RESERVATIONS_ERROR,
        successCallback,
    },
    params: { idReservation },
});

export const openEditReservationModal = editedReservation => ({
    type: BKG_OPEN_EDIT_RESERVATION_MODAL,
    editedReservation,
});

export const closeEditReservationModal = reservationId => ({
    type: BKG_CLOSE_EDIT_RESERVATION_MODAL,
    reservationId,
});

export const closeAllEditedReservations = () => ({
    type: BKG_CLEAR_ALL_EDITED_RESERVATIONS,
});

export const getProvisionalPrice = reservation => ({
    type: 'API_CALL',
    endpoint: endpoints.getProvisionalPrice,
    actions: {
        request: GET_PROVISIONAL_PRICE_REQUEST,
        success: GET_PROVISIONAL_PRICE_SUCCESS,
        error: GET_PROVISIONAL_PRICE_ERROR,
    },
    body: reservation,
});

export const getLatestReservations = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getLatestReservations,
    actions: {
        success: GET_LATEST_RESERVATIONS_SUCCESS,
    },
});

export const getMultiReservationPriceBreakdown = (idMultiReservation) => ({
    type: 'API_CALL',
    endpoint: endpoints.getMultiReservationPriceBreakdown,
    actions: {
        success: GET_MULTI_RESERVATION_PRICE_BREAKDOWN_SUCCESS,
    },
    params: { idMultiReservation },
});

export const setReservationListEditedReservation = reservationId => ({
    type: SET_RESERVATION_LIST_EDITED_RESERVATION,
    reservationId,
});

export const addReservationGuest = (idReservation, guest, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.addReservationGuest,
    actions: {
        success: BKG_UPDATE_RESERVATIONS_SUCCESS,
        successCallback,
    },
    params: { idReservation },
    body: guest,
});

export const saveNewReservationId = reservationId => ({
    type: SAVE_NEW_RESERVATION_ID,
    reservationId,
});

export const deleteNewReservationId = () => ({
    type: DELETE_NEW_RESERVATION_ID,
});
