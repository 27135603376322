import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';

import {
    showModal,
} from 'eksenia-lib/src/Modal';
import {
    updateInputField,
} from 'eksenia-lib/src/Form';
import {
    EditGuest, GUEST_PROPS,
} from 'Guests';
import {
    serverGuestToInput,
} from './utils';
import {GUEST_LIST_PROPS} from "./constants";

export class ReservationGuest extends Component {

    constructor() {
        super(...arguments);

        this.handleSaveGuest = this.handleSaveGuest.bind(this);
        this.handleAddGuestToReservation = this.handleAddGuestToReservation.bind(this);
    }

    handleAddGuestToReservation(data) {
        const {
            updateInputField,
            formName,
            inputName,
        } = this.props;

        if (typeof data === 'object') {
            const updatedInputValue = this.getUpdatedInputValue(data);

            updateInputField(formName, inputName, {
                value: updatedInputValue,
            });
        }
    }

    handleSaveGuest(data) {
        const {
            updateInputField,
            formName,
            inputName,
            onChange,
        } = this.props;

        if (typeof data === 'object') {
            const updatedInputValue = this.getUpdatedInputValue(data);

            if (typeof onChange === 'function') {
                onChange(updatedInputValue);
            }

            updateInputField(formName, inputName, {
                value: updatedInputValue,
            });
        }
    }

    getUpdatedInputValue(data) {
        const {
            inputValue,
        } = this.props;

        const updatedGuestId = data[GUEST_PROPS.ID];
        // remove deleted guest, updated an existing one, or add a new one if it doesn't exist
        return inputValue.find(guest => guest.get(GUEST_LIST_PROPS.GUEST_ID) === updatedGuestId) === undefined
                ? inputValue.push(fromJS(serverGuestToInput(data, updatedGuestId, data[GUEST_LIST_PROPS.RESERVATION_ID])))
                : inputValue.map(guest =>
                    guest.get(GUEST_LIST_PROPS.GUEST_ID) === updatedGuestId
                        ? fromJS(serverGuestToInput(data, guest.get(GUEST_LIST_PROPS.ID), guest.get(GUEST_LIST_PROPS.RESERVATION_ID)))
                        : guest
                );
    }

    render() {
        const {
            inputId,
            modalId,
            reservation,
        } = this.props;

        return (
            <EditGuest
                onSave={this.handleSaveGuest}
                onAddExistingGuestToReservation={this.handleAddGuestToReservation}
                id={inputId}
                modalId={modalId}
                addGuestToReservation
                reservation={reservation}
            />
        );
    }
}

const mapStateToProps = (store, ownProps) => {
    const {
        formName,
        inputName,
    } = ownProps;
    const formReducer = store.formReducer;

    return {
        inputValue: formReducer.getIn([formName, 'inputFields', inputName, 'value'])

    };
};

const mapDispatchToProps = {
    showModal,
    updateInputField,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationGuest);
