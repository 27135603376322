import { fromJS, Iterable, List } from 'immutable';

import { reduceWithDefault } from 'utils';

import {
    mapRatesValues,
} from './utils';
import {
    ADD_EDITED_RATE,
    CLEAR_EDITED_RATE,
    GET_RATES_SUCCESS,
} from './constants';

const initialState = fromJS({
    editedRate: false,
    rates: List(),
});

const reducers = {
    [GET_RATES_SUCCESS]: (state, { data }) =>
        state.merge({
            rates: mapRatesValues(fromJS(data || [])),
        }),

    [ADD_EDITED_RATE]: (state, { editedRate }) =>
        state.merge({
            editedRate: Iterable.isIterable(editedRate)
                ? editedRate
                : initialState.get('editedRate')
            ,
        }),

    [CLEAR_EDITED_RATE]: state =>
        state.merge({
            editedRate: initialState.get('editedRate'),
        }),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);

