import { defineMessages } from 'react-intl';

export default defineMessages({
    showOnBookingPage: {
        id: 'bookingPage.showOnBookingPage',
        defaultMessage: 'Show on booking page',
    },
    displayName: {
        id: 'bookingPage.displayName',
        defaultMessage: 'Display name',
    },
    size: {
        id: 'bookingPage.size',
        defaultMessage: 'Size in m²',
    },
    coverImage: {
        id: 'bookingPage.coverImage',
        defaultMessage: 'Cover image',
    },
    galleryImages: {
        id: 'bookingPage.galleryImages',
        defaultMessage: 'Gallery images',
    },
    bedType: {
        id: 'bookingPage.bedType',
        defaultMessage: 'Bed type',
    },
    bedTypeCount: {
        id: 'bookingPage.bedTypeCount',
        defaultMessage: 'Count',
    },
    privateBathroom: {
        id: 'bookingPage.privateBathroom',
        defaultMessage: 'Private bathroom',
    },
    sharedBathroom: {
        id: 'bookingPage.sharedBathroom',
        defaultMessage: 'Shared bathroom',
    },
    toiletPaper: {
        id: 'bookingPage.toiletPaper',
        defaultMessage: 'Toiled paper',
    },
    bathtub: {
        id: 'bookingPage.bathtub',
        defaultMessage: 'Bathtub',
    },
    bidet: {
        id: 'bookingPage.bidet',
        defaultMessage: 'Bidet',
    },
    shower: {
        id: 'bookingPage.shower',
        defaultMessage: 'Shower',
    },
    bathrobe: {
        id: 'bookingPage.bathrobe',
        defaultMessage: 'Bathrobe',
    },
    freeToiletries: {
        id: 'bookingPage.freeToiletries',
        defaultMessage: 'Free toiletries',
    },
    hairdryer: {
        id: 'bookingPage.hairdryer',
        defaultMessage: 'Hairdryer',
    },
    sauna: {
        id: 'bookingPage.sauna',
        defaultMessage: 'Sauna',
    },
    slippers: {
        id: 'bookingPage.slippers',
        defaultMessage: 'Slippers',
    },
    name: {
        id: 'bookingPage.name',
        defaultMessage: 'Name',
    },
    edit: {
        id: 'bookingPage.edit',
        defaultMessage: 'Edit',
    },
    propertySettings: {
        id: 'bookingPage.propertySettings',
        defaultMessage: 'Property settings',
    },
    propertyDescription: {
        id: 'bookingPage.propertyDescription',
        defaultMessage: 'Property description',
    },
    propertyAmenities: {
        id: 'bookingPage.propertyAmenities',
        defaultMessage: 'Property amenities',
    },
    propertyDescriptionHelp: {
        id: 'bookingPage.propertyDescriptionHelp',
        defaultMessage: "You can add a description of your property to be shown on the booking page. If you don't add a description, we will add it for you." ,
    },
    english: {
        id: 'bookingPage.english',
        defaultMessage: 'English',
    },
    serbian: {
        id: 'bookingPage.serbian',
        defaultMessage: 'Serbian',
    },
    montenegrin: {
        id: 'bookingPage.montenegrin',
        defaultMessage: 'Montenegrin',
    },
    rooms: {
        id: 'bookingPage.rooms',
        defaultMessage: 'Rooms',
    },
    roomSettings: {
        id: 'bookingPage.roomSettings',
        defaultMessage: 'Booking page room settings - {editedRoomName}',
    },
    activate: {
        id: 'bookingPage.activate',
        defaultMessage: 'Activate',
    },
    country: {
        id: 'bookingPage.country',
        defaultMessage: 'Country',
    },
    city: {
        id: 'bookingPage.city',
        defaultMessage: 'City',
    },
    address: {
        id: 'bookingPage.address',
        defaultMessage: 'Address',
    },
    availabilityRule: {
        id: 'bookingPage.availabilityRule',
        defaultMessage: 'Availability rule',
    },
    sumOfAllBookings: {
        id: 'bookingPage.sumOfAllBookings',
        defaultMessage: 'Sum of all bookings',
    },
    anyUnitAvailable: {
        id: 'bookingPage.anyUnitAvailable',
        defaultMessage: 'Any unit must be available',
    },
    diningArea: {
        id: 'bookingPage.diningArea',
        defaultMessage: 'Dining area',
    },
    diningTable: {
        id: 'bookingPage.diningTable',
        defaultMessage: 'Dining table',
    },
    bottleOfWater: {
        id: 'bookingPage.bottleOfWater',
        defaultMessage: 'Bottle of water',
    },
    chocolate: {
        id: 'bookingPage.chocolate',
        defaultMessage: 'Chocolate/cookies',
    },
    fruit: {
        id: 'bookingPage.fruit',
        defaultMessage: 'Fruit',
    },
    wine: {
        id: 'bookingPage.wine',
        defaultMessage: 'Wine/Champagne',
    },
    barbecue: {
        id: 'bookingPage.barbecue',
        defaultMessage: 'Barbecue',
    },
    oven: {
        id: 'bookingPage.oven',
        defaultMessage: 'Oven',
    },
    stove: {
        id: 'bookingPage.stove',
        defaultMessage: 'Stove',
    },
    toaster: {
        id: 'bookingPage.toaster',
        defaultMessage: 'Toaster',
    },
    dishwasher: {
        id: 'bookingPage.dishwasher',
        defaultMessage: 'Dishwasher',
    },
    electricKettle: {
        id: 'bookingPage.electricKettle',
        defaultMessage: 'Electric kettle',
    },
    outdoorDiningArea: {
        id: 'bookingPage.outdoorDiningArea',
        defaultMessage: 'Outdoor dining area',
    },
    outdoorFurniture: {
        id: 'bookingPage.outdoorFurniture',
        defaultMessage: 'Outdoor furniture',
    },
    microwave: {
        id: 'bookingPage.microwave',
        defaultMessage: 'Microwave',
    },
    refrigerator: {
        id: 'bookingPage.refrigerator',
        defaultMessage: 'Refrigerator',
    },
    coffeeMaker: {
        id: 'bookingPage.coffeeMaker',
        defaultMessage: 'Tea/coffee maker',
    },
    tv: {
        id: 'bookingPage.tv',
        defaultMessage: 'TV',
    },
    gameConsole: {
        id: 'bookingPage.gameConsole',
        defaultMessage: 'Game console',
    },
    pc: {
        id: 'bookingPage.pc',
        defaultMessage: 'PC',
    },
    laptop: {
        id: 'bookingPage.laptop',
        defaultMessage: 'Laptop',
    },
    fax: {
        id: 'bookingPage.fax',
        defaultMessage: 'Fax',
    },
    iPad: {
        id: 'bookingPage.iPad',
        defaultMessage: 'iPad',
    },
    tablet: {
        id: 'bookingPage.tablet',
        defaultMessage: 'Tablet',
    },
    cableChannels: {
        id: 'bookingPage.cableChannels',
        defaultMessage: 'Cable channels',
    },
    dvdPlayer: {
        id: 'bookingPage.dvdPlayer',
        defaultMessage: 'DVD player',
    },
    blueRayPlayer: {
        id: 'bookingPage.blueRayPlayer',
        defaultMessage: 'Blue-ray player',
    },
    payPerViewChannels: {
        id: 'bookingPage.payPerViewChannels',
        defaultMessage: 'Pay-per-view channels',
    },
    cableSatellite: {
        id: 'bookingPage.cableSatellite',
        defaultMessage: 'Cable/Satellite',
    },
    washingMachine: {
        id: 'bookingPage.washingMachine',
        defaultMessage: 'Washing machine',
    },
    soundproof: {
        id: 'bookingPage.soundproof',
        defaultMessage: 'Soundproof',
    },
    cribs: {
        id: 'bookingPage.cribs',
        defaultMessage: 'Cribs',
    },
    clothesRack: {
        id: 'bookingPage.clothesRack',
        defaultMessage: 'Clothes rack',
    },
    washerDryer: {
        id: 'bookingPage.washerDryer',
        defaultMessage: 'Washer/Dryer',
    },
    foldUpBed: {
        id: 'bookingPage.foldUpBed',
        defaultMessage: 'Fold-up bed',
    },
    trashCans: {
        id: 'bookingPage.trashCans',
        defaultMessage: 'Trash cans',
    },
    freeSheetsAndTowels: {
        id: 'bookingPage.freeSheetsAndTowels',
        defaultMessage: 'Free sheets and towels',
    },
    paidSheetsAndTowels: {
        id: 'bookingPage.paidSheetsAndTowels',
        defaultMessage: 'Extra charge sheets and towels',
    },
    walkInCloset: {
        id: 'bookingPage.walkInCloset',
        defaultMessage: 'Walk-in closet',
    },
    fan: {
        id: 'bookingPage.fan',
        defaultMessage: 'Fan',
    },
    fireplace: {
        id: 'bookingPage.fireplace',
        defaultMessage: 'Fireplace',
    },
    heating: {
        id: 'bookingPage.heating',
        defaultMessage: 'Heating',
    },
    iron: {
        id: 'bookingPage.iron',
        defaultMessage: 'Iron',
    },
    hotTub: {
        id: 'bookingPage.hotTub',
        defaultMessage: 'Hot tub',
    },
    privateEntrance: {
        id: 'bookingPage.privateEntrance',
        defaultMessage: 'Private entrance',
    },
    safe: {
        id: 'bookingPage.safe',
        defaultMessage: 'Safe',
    },
    sittingArea: {
        id: 'bookingPage.sittingArea',
        defaultMessage: 'Sitting area',
    },
    tileFloor: {
        id: 'bookingPage.tileFloor',
        defaultMessage: 'Tile/marble floor',
    },
    hardwoodFloor: {
        id: 'bookingPage.hardwoodFloor',
        defaultMessage: 'Hardwood/parquet floor',
    },
    desk: {
        id: 'bookingPage.desk',
        defaultMessage: 'Desk',
    },
    hypoallergenic: {
        id: 'bookingPage.hypoallergenic',
        defaultMessage: 'Hypoallergenic',
    },
    cleaningProducts: {
        id: 'bookingPage.cleaningProducts',
        defaultMessage: 'Cleaning products',
    },
    featherPillow: {
        id: 'bookingPage.featherPillow',
        defaultMessage: 'Feather pillow',
    },
    nonFeatherPillow: {
        id: 'bookingPage.nonFeatherPillow',
        defaultMessage: 'Non-feather pillow',
    },
    wifi: {
        id: 'bookingPage.wifi',
        defaultMessage: 'Wi-Fi',
    },
    freeParking: {
        id: 'bookingPage.freeParking',
        defaultMessage: 'Free parking',
    },
    paidParking: {
        id: 'bookingPage.paidParking',
        defaultMessage: 'Paid parking',
    },
    airConditioning: {
        id: 'bookingPage.airConditioning',
        defaultMessage: 'Air conditioning',
    },
    kitchenette: {
        id: 'bookingPage.kitchenette',
        defaultMessage: 'Kitchenette',
    },
    kitchen: {
        id: 'bookingPage.kitchen',
        defaultMessage: 'Kitchen',
    },
    balcony: {
        id: 'bookingPage.balcony',
        defaultMessage: 'Balcony',
    },
    terrace: {
        id: 'bookingPage.terrace',
        defaultMessage: 'Terrace',
    },
    flatScreenTV: {
        id: 'bookingPage.flatScreenTV',
        defaultMessage: 'Flat screen TV',
    },
    kitchenware: {
        id: 'bookingPage.kitchenware',
        defaultMessage: 'Kitchenware',
    },
    pool: {
        id: 'bookingPage.pool',
        defaultMessage: 'Pool',
    },
    seaView: {
        id: 'bookingPage.seaView',
        defaultMessage: 'Sea view',
    },
    lakeView: {
        id: 'bookingPage.lakeView',
        defaultMessage: 'Lake view',
    },
    streetView: {
        id: 'bookingPage.streetView',
        defaultMessage: 'Street view',
    },
    gardenView: {
        id: 'bookingPage.gardenView',
        defaultMessage: 'Garden view',
    },
    poolView: {
        id: 'bookingPage.poolView',
        defaultMessage: 'Pool view',
    },
    riverView: {
        id: 'bookingPage.riverView',
        defaultMessage: 'River view',
    },
    mountainView: {
        id: 'bookingPage.mountainView',
        defaultMessage: 'Mountain view',
    },
    propertyType: {
        id: 'bookingPage.propertyType',
        defaultMessage: 'Property type',
    },
    hotel: {
        id: 'bookingPage.hotel',
        defaultMessage: 'Hotel',
    },
    apartment: {
        id: 'bookingPage.apartment',
        defaultMessage: 'Apartment',
    },
    guestHouse: {
        id: 'bookingPage.guestHouse',
        defaultMessage: 'Guest house',
    },
    bedAndBreakfast: {
        id: 'bookingPage.bedAndBreakfast',
        defaultMessage: 'Bed and breakfast',
    },
    homestay: {
        id: 'bookingPage.homestay',
        defaultMessage: 'Homestay',
    },
    holidayHome: {
        id: 'bookingPage.holidayHome',
        defaultMessage: 'Holiday home',
    },
    villa: {
        id: 'bookingPage.villa',
        defaultMessage: 'Villa',
    },
    hostel: {
        id: 'bookingPage.hostel',
        defaultMessage: 'Hostel',
    },
    motel: {
        id: 'bookingPage.motel',
        defaultMessage: 'Motel',
    },
    lodge: {
        id: 'bookingPage.lodge',
        defaultMessage: 'Lodge',
    },
    boat: {
        id: 'bookingPage.boat',
        defaultMessage: 'Boat',
    },
    wifiInPublicArea: {
        id: 'bookingPage.wifiInPublicArea',
        defaultMessage: 'Wi-Fi in public area',
    },
    wifiInRooms: {
        id: 'bookingPage.wifiInRooms',
        defaultMessage: 'Wi-Fi in rooms',
    },
    bySea: {
        id: 'bookingPage.bySea',
        defaultMessage: 'By a sea',
    },
    byLake: {
        id: 'bookingPage.byLake',
        defaultMessage: 'By a lake',
    },
    byRiver: {
        id: 'bookingPage.byRiver',
        defaultMessage: 'By a river',
    },
    inCityCenter: {
        id: 'bookingPage.inCityCenter',
        defaultMessage: 'In city center',
    },
    onMountain: {
        id: 'bookingPage.onMountain',
        defaultMessage: 'On a mountain',
    },
    urban: {
        id: 'bookingPage.urban',
        defaultMessage: 'In an urban surrounding',
    },
    rural: {
        id: 'bookingPage.rural',
        defaultMessage: 'In an rural surrounding',
    },
    beachfront: {
        id: 'bookingPage.beachfront',
        defaultMessage: 'Beachfront',
    },
    singleBed: {
        id: 'bookingPage.singleBed',
        defaultMessage: 'Single bed',
    },
    twinBed: {
        id: 'bookingPage.twinBed',
        defaultMessage: 'Twin bed',
    },
    doubleBed: {
        id: 'bookingPage.doubleBed',
        defaultMessage: 'Double bed',
    },
    queenSizeBed: {
        id: 'bookingPage.queenSizeBed',
        defaultMessage: 'Queen size bed',
    },
    kingSizeBed: {
        id: 'bookingPage.kingSizeBed',
        defaultMessage: 'King size bed',
    },
    sofaBed: {
        id: 'bookingPage.sofaBed',
        defaultMessage: 'Sofa bed',
    },
    foldableBed: {
        id: 'bookingPage.foldableBed',
        defaultMessage: 'Foldable bed',
    },
    basicAmenities: {
        id: 'bookingPage.basicAmenities',
        defaultMessage: 'Basics',
    },
    popularAmenities: {
        id: 'bookingPage.popularAmenities',
        defaultMessage: 'Popular',
    },
    miscellaneousAmenities: {
        id: 'bookingPage.miscellaneousAmenities',
        defaultMessage: 'Miscellaneous',
    },
    bathroomAmenities: {
        id: 'bookingPage.bathroomAmenities',
        defaultMessage: 'Bathroom',
    },
    mediaAmenities: {
        id: 'bookingPage.mediaAmenities',
        defaultMessage: 'media',
    },
    foodAndDrinksAmenities: {
        id: 'bookingPage.foodAndDrinksAmenities',
        defaultMessage: 'Food and Drinks',
    },
    securityAndExtraAmenities: {
        id: 'bookingPage.securityAndExtraAmenities',
        defaultMessage: 'Security and Extras',
    },
    keyCardAccess: {
        id: 'bookingPage.keyCardAccess',
        defaultMessage: 'Key card access',
    },
    keyAccess: {
        id: 'bookingPage.keyAccess',
        defaultMessage: 'Key access',
    },
    smokeAlarm: {
        id: 'bookingPage.smokeAlarm',
        defaultMessage: 'Smoke alarm',
    },
    fireExtinguisher: {
        id: 'bookingPage.fireExtinguisher',
        defaultMessage: 'Fire extinguisher',
    },
    lockers: {
        id: 'bookingPage.lockers',
        defaultMessage: 'Lockers',
    },
    executiveLoungeAccess: {
        id: 'bookingPage.executiveLoungeAccess',
        defaultMessage: 'Executive lounge Access',
    },
    alarmClock: {
        id: 'bookingPage.alarmClock',
        defaultMessage: 'Alarm clock',
    },
    wakeUpService: {
        id: 'bookingPage.wakeUpService',
        defaultMessage: 'Wake-up service',
    },
    restaurant: {
        id: 'bookingPage.restaurant',
        defaultMessage: 'Includes a restaurant',
    },
    offerMeals: {
        id: 'bookingPage.offerMeals',
        defaultMessage: 'Offers home cooked meals',
    },
    interiorType: {
        id: 'bookingPage.interiorType',
        defaultMessage: 'Interior style',
    },
    modernInterior: {
        id: 'bookingPage.modernInterior',
        defaultMessage: 'Modern (contemporary)',
    },
    classicalInterior: {
        id: 'bookingPage.ClassicalInterior',
        defaultMessage: 'Classical',
    },
    traditionalInterior: {
        id: 'bookingPage.TraditionalInterior',
        defaultMessage: 'Traditional (authentic)',
    },
    industrialInterior: {
        id: 'bookingPage.IndustrialInterior',
        defaultMessage: 'Industrial',
    },
    rusticInterior: {
        id: 'bookingPage.RusticInterior',
        defaultMessage: 'Rustic',
    },
    luxuryInterior: {
        id: 'bookingPage.LuxuryInterior',
        defaultMessage: 'Luxury',
    },
    originalInterior: {
        id: 'bookingPage.OriginalInterior',
        defaultMessage: 'Original',
    },
    proximityToBeach: {
        id: 'bookingPage.proximityToBeach',
        defaultMessage: 'Proximity to a beach',
    },
    proximityToCityCenter: {
        id: 'bookingPage.proximityToCityCenter',
        defaultMessage: 'Proximity to a city center',
    },
    proximityToSkiSlope: {
        id: 'bookingPage.proximityToSkiSlope',
        defaultMessage: 'Proximity to a ski slope',
    },
    proximityToSportingGrounds: {
        id: 'bookingPage.proximityToSportingGrounds',
        defaultMessage: 'Proximity to sporting grounds',
    },
    proximityToRestaurant: {
        id: 'bookingPage.proximityToRestaurant',
        defaultMessage: 'Proximity to a closest restaurant',
    },
    proximityToTouristAttractions: {
        id: 'bookingPage.proximityToTouristAttractions',
        defaultMessage: 'Proximity to a closest tourist attraction',
    },
    proximityToShopping: {
        id: 'bookingPage.proximityToShopping',
        defaultMessage: 'Proximity to closest shopping options',
    },
    proximityToPublicTransport: {
        id: 'bookingPage.proximityToPublicTransport',
        defaultMessage: 'Proximity to public transportation',
    },
    proximityToTrainStation: {
        id: 'bookingPage.proximityToTrainStation',
        defaultMessage: 'Proximity to a train station',
    },
    proximityToAirport: {
        id: 'bookingPage.proximityToAirport',
        defaultMessage: 'Proximity to an airport',
    },
    propertyLocation: {
        id: 'bookingPage.propertyLocation',
        defaultMessage: 'Property location',
    },
    inMeters: {
        id: 'bookingPage.inMeters',
        defaultMessage: 'km',
    },
    copyBookingPageLocation: {
        id: 'bookingPage.copyBookingPageLocation',
        defaultMessage: 'Copy location',
    },
    goToBookingPage: {
        id: 'bookingPage.goToBookingPage',
        defaultMessage: 'Open booking page',
    },
    activateTooltip: {
        id: 'bookingPage.activateTooltip',
        defaultMessage: 'Check to activate the booking page for the property. Uncheck to deactivate it.',
    },
    displayNameTooltip: {
        id: 'bookingPage.displayNameTooltip',
        defaultMessage: 'Enter the name of the property that will be displayed on the booking page.',
    },
    coverImageTooltip: {
        id: 'bookingPage.coverImageTooltip',
        defaultMessage: 'Select a photo for the cover of the booking page.',
    },
    galleryImagesTooltip: {
        id: 'bookingPage.galleryImagesTooltip',
        defaultMessage: 'Select multiple photos to be displayed in the property gallery on the booking page.',
    },
    sumOfAllBookingsTooltip: {
        id: 'bookingPage.sumOfAllBookingsTooltip',
        defaultMessage: ' - The quantity of rooms available is equal to the total number of this room type minus the total number of bookings for this room type.',
    },
    anyUnitAvailableTooltip: {
        id: 'bookingPage.anyUnitAvailableTooltip',
        defaultMessage: ' - Inventory is limited to the total number of units available.',
    },
    propertyTypeTooltip: {
        id: 'bookingPage.propertyTypeTooltip',
        defaultMessage: 'Select the type of accommodation.',
    },
    showOnBookingPageTooltip: {
        id: 'bookingPage.showOnBookingPageTooltip',
        defaultMessage: 'Check this if you want the room to be shown on the booking page.',
    },
    roomBookingNameTooltip: {
        id: 'bookingPage.roomBookingNameTooltip',
        defaultMessage: 'Enter the name for this room that you wish to be displayed on the booking page.',
    },
    roomCoverImageTooltip: {
        id: 'bookingPage.roomCoverImageTooltip',
        defaultMessage: 'Select the main photo for the room.',
    },
    roomGalleryImagesTooltip: {
        id: 'bookingPage.roomGalleryImagesTooltip',
        defaultMessage: 'Select all the photos of the room that you wish to display in the gallery on the booking page.',
    },
    email: {
        id: 'bookingPage.email',
        defaultMessage: 'Email',
    },
    emailTooltip: {
        id: 'bookingPage.emailTooltip',
        defaultMessage: 'The email address will not be displayed on the booking page, it will be included in the booking confirmation email sent to guests after booking on booking page.',
    },
    phone: {
        id: 'bookingPage.phone',
        defaultMessage: 'Phone number',
    },
    phoneTooltip: {
        id: 'bookingPage.phoneTooltip',
        defaultMessage: 'The phone number will not be displayed on the booking page, it will be included in the booking confirmation email sent to guests after booking on booking page.',
    },
    website: {
        id: 'bookingPage.website',
        defaultMessage: 'Website',
    },
    websiteTooltip: {
        id: 'bookingPage.websiteTooltip',
        defaultMessage: 'The website address will not be displayed on the booking page, it will be included in the booking confirmation email sent to guests after booking on booking page.',
    },
    coverImagePreviewTitle: {
        id: 'bookingPage.coverImagePreviewTitle',
        defaultMessage: 'Booking page cover image',
    },
    galleryImagesPreviewTitle: {
        id: 'bookingPage.galleryImagesPreviewTitle',
        defaultMessage: 'Booking page gallery images',
    },
    roomCoverImagePreviewTitle: {
        id: 'bookingPage.roomCoverImagePreviewTitle',
        defaultMessage: 'Room cover image',
    },
    roomGalleryImagesPreviewTitle: {
        id: 'bookingPage.roomGalleryImagesPreviewTitle',
        defaultMessage: 'Room gallery images',
    },
})
