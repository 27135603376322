import React from "react";
import {FormattedMessage} from "react-intl";
import {INPUT_FORMATTERS} from 'eksenia-lib/src/formatters';
import localeMessages from './messages';
import {EDIT_GUEST_FORM_NAME, GUEST_PROPS, ID_TYPES,} from './constants';
import SearchGuestResultLine from "./SearchGuestResultLine";

export default (
    initialValues = {},
    countryOptions,
    intl,
    addGuestToReservation,
    search,
    searchResults,
    handleChooseGuest,
) => ({
    formName: EDIT_GUEST_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'name',
            fields: [
                addGuestToReservation ? {
                    type: 'searchInput',
                    name: GUEST_PROPS.FIRST_NAME,
                    initialValue: initialValues[GUEST_PROPS.FIRST_NAME],
                    label: <FormattedMessage {...localeMessages.firstName} />,
                    search: search,
                    searchResults: searchResults,
                    searchResultLineKey: guest => guest.get(GUEST_PROPS.ID),
                    renderSearchResult: SearchGuestResultLine,
                    onLineClick: handleChooseGuest,
                } : {
                    type: 'input',
                    name: GUEST_PROPS.FIRST_NAME,
                    initialValue: initialValues[GUEST_PROPS.FIRST_NAME],
                    label: <FormattedMessage {...localeMessages.firstName} />,
                },
                {
                    type: 'input',
                    name: GUEST_PROPS.LAST_NAME,
                    initialValue: initialValues[GUEST_PROPS.LAST_NAME],
                    label: <FormattedMessage {...localeMessages.lastName} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'contact',
            fields: [
                {
                    type: 'input',
                    name: GUEST_PROPS.EMAIL,
                    initialValue: initialValues[GUEST_PROPS.EMAIL],
                    label: <FormattedMessage {...localeMessages.email} />,
                },
                {
                    type: 'input',
                    name: GUEST_PROPS.PHONE,
                    initialValue: initialValues[GUEST_PROPS.PHONE],
                    label: <FormattedMessage {...localeMessages.phone} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'address',
            fields: [
                {
                    type: 'select',
                    name: GUEST_PROPS.COUNTRY,
                    initialValue: initialValues[GUEST_PROPS.COUNTRY],
                    label: <FormattedMessage {...localeMessages.country} />,
                    options: countryOptions,
                },
                {
                    type: 'input',
                    name: GUEST_PROPS.ADDRESS,
                    initialValue: initialValues[GUEST_PROPS.ADDRESS],
                    label: <FormattedMessage {...localeMessages.address} />,
                },
                {
                    type: 'input',
                    name: GUEST_PROPS.CITY,
                    initialValue: initialValues[GUEST_PROPS.CITY],
                    label: <FormattedMessage {...localeMessages.city} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'id',
            fields: [
                {
                    type: 'select',
                    name: GUEST_PROPS.ID_TYPE,
                    initialValue: initialValues[GUEST_PROPS.ID_TYPE],
                    label: <FormattedMessage {...localeMessages.idType} />,
                    options: [{
                        key: ID_TYPES.PASSPORT,
                        value: ID_TYPES.PASSPORT,
                        text: intl.formatMessage(localeMessages.passport),
                    }, {
                        key: ID_TYPES.ID_CARD,
                        value: ID_TYPES.ID_CARD,
                        text: intl.formatMessage(localeMessages.idCard),
                    }, {
                        key: ID_TYPES.DRIVERS_LICENCE,
                        value: ID_TYPES.DRIVERS_LICENCE,
                        text: intl.formatMessage(localeMessages.driversLicence),
                    }],
                    help: <FormattedMessage {...localeMessages.idTypeTooltip} />,
                },
                {
                    type: 'input',
                    name: GUEST_PROPS.ID_NUMBER,
                    initialValue: initialValues[GUEST_PROPS.ID_NUMBER],
                    label: <FormattedMessage {...localeMessages.idNumber} />,
                    help: <FormattedMessage {...localeMessages.idNumberTooltip} />,
                },
            ],
        },

        {
            type: 'section',
            name: 'notes',
            fields: [
                {
                    type: 'textArea',
                    name: GUEST_PROPS.NOTES,
                    initialValue: initialValues[GUEST_PROPS.NOTES],
                    label: <FormattedMessage {...localeMessages.notes} />,
                    help: <FormattedMessage {...localeMessages.notesTooltip} />,
                    rows: 4,
                },
            ],
        },

    ],
})

