import React, {Component} from 'react';

import { composeClassName } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import Tooltip from "../Tooltip";

export default class SearchInput extends Component {

    constructor() {
        super(...arguments);

        this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.blurTimeout = null;
        this.searchTimeout = null;
        this.searchValue = '';

        this.state = {
            inputInFocus: false,
        };
    }

    componentWillUnmount() {
        if (this.searchTimeout) {
            window.clearTimeout(this.searchTimeout);
        }
        if (this.blurTimeout) {
            window.clearTimeout(this.blurTimeout);
        }
    }

    handleSearchValueChange(event) {
        const {
            onChangeFromForm,
            onChange,
            name,
            search,
        } = this.props;

        const newValue = event.currentTarget.value;
        this.searchValue = newValue;

        if (this.searchTimeout) {
            window.clearTimeout(this.searchTimeout);
        }
        if (typeof search === 'function') {
            this.searchTimeout = window.setTimeout(() => {
                search(newValue);
            }, 1000);
        }

        if (typeof onChangeFromForm === 'function') {
            onChangeFromForm(name, newValue);
        }

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    handleBlur() {
        this.blurTimeout = setTimeout(
            () => this.setState({inputInFocus: false}),
            100
        )
    }

    renderSearchResults() {
        const {
            searchResults,
            renderSearchResult,
            searchResultLineKey,
            onLineClick,
        } = this.props;

        if (!searchResults || !this.state.inputInFocus) {
            return false
        }

        return <>
            {searchResults.map(resultLine =>
                <div
                    className="search-input-result-line"
                    key={searchResultLineKey(resultLine)}
                    onClick={() => {onLineClick(resultLine)}}
                >
                    {renderSearchResult(resultLine)}
                </div>
            )}
        </>
    }

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            formatters = [],
            error,
            parentValue,
            icon,
            help,
            disabled,
            submitting,
            search,
            searchResults,
            renderSearchResult,
            searchResultLineKey,
            onLineClick,
            ...props
        } = this.props;

        return (
            <div
                className={composeClassName('search-input-text-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}
                <input
                    type="text"
                    className={composeClassName('input-text', error && 'error', ...formatters)}
                    onChange={this.handleSearchValueChange}
                    maxLength={250}
                    { ...props }
                    disabled={disabled || submitting}
                    onFocus={() => this.setState({inputInFocus: true})}
                    onBlur={this.handleBlur}
                />

                {icon}

                {help && <Tooltip help={help} />}

                <div className="search-input-results">
                    {this.renderSearchResults()}
                </div>

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
