import React, { Component } from 'react';

import { composeClassName } from 'utils';

export default class Dropdown extends Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.state = {
            open: false,
            value: null,
        }
    }

    componentDidMount() {
        const {
            keepValue,
            initialValue,
        } = this.props;

        if (keepValue) {
           this.setState({
               value: initialValue,
           })
        }

        window.addEventListener('click', this.closeDropdown);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.closeDropdown);
    }

    handleClick(event) {
        event.stopPropagation();
        this.setState({
            open: !this.state.open,
        });
    }

    handleItemClick(item) {
        const {
            keepValue,
        } = this.props;

        const {
            id,
            onClick,
        } = item;

        const newState = {
            open: false,
        };

        if (keepValue) {
            newState.value = id;
        }
        this.setState(newState);

        if (typeof onClick === 'function') {
            onClick()
        }
    }

    closeDropdown(event) {
        if (this.state.open) {
            this.setState({
                open: false,
            })
        }
    }

    render() {
        const {
            items,
            title,
        } = this.props;

        const {
            open,
            value,
        } = this.state;

        const Title = title;

        return (
            <div
                className="dropdown"
                onClick={this.handleClick}
            >
                <Title
                    className="dropdown-title"
                />

                {open &&
                    <div className="dropdown-items-container">
                        {items.map(item =>
                            item.content
                                ? item.content()
                                : (
                                    <div
                                        key={item.id}
                                        onClick={this.handleItemClick.bind(this, item)}
                                        className={composeClassName(
                                            'dropdown-item',
                                            value === item.id && 'active',
                                            item.onClick === null && 'no-click'
                                        )}
                                    >
                                        {item.text}
                                    </div>
                                )
                        )}
                    </div>
                }
            </div>
        )
    }
}
