import React, {Component} from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import {FormattedMessage, injectIntl,} from 'react-intl';

import { logIn } from 'User';
import Input from 'eksenia-lib/src/Input';
import Button from 'eksenia-lib/src/Button';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import localeMessages from './messages';
import ValidationError from "eksenia-lib/src/ValidationError";
import Icon from "eksenia-lib/src/Icon";
import {LANGUAGE_NAME_TEXT, LANGUAGE_NAMES} from "../locale";

class Login extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            email: '',
            pwd: '',
            loginFailed: false,
            submitDisabled: false,
            emailEmptyError: false,
            pwdEmptyError: false,
            language: '',
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePwdChange = this.handlePwdChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.loginInProgress && !this.props.loginInProgress) {
            const user = this.props.user.toJS();
            this.setState({
                loginFailed: !user.Email,
                submitDisabled: false,
            });
        }
    }

    handleSubmit(evt) {
        evt.preventDefault();
        if (this.state.email.length === 0 || this.state.pwd.length === 0) {
            this.setState({
                emailEmptyError: this.state.email.length === 0,
                pwdEmptyError: this.state.pwd.length === 0,
            });
        } else {
            this.setState({
                submitDisabled: true,
                pwd: '',
            });
            this.props.logIn(this.state.email, this.state.pwd);
        }
    }

    handleEmailChange(value) {
        this.setState({
            email: value,
            emailEmptyError: false,
            loginFailed: false,
        });
    };

    handlePwdChange(value) {
        this.setState({
            pwd: value,
            pwdEmptyError: false,
            loginFailed: false,
        });
    }

    handleChangeLanguage(event) {
        localStorage.setItem('language', event.currentTarget.value);
        window.location.reload();
    }

    render() {
        const {
            intl,
            user,
            location
        } = this.props;

        const {
            email,
            pwd,
            submitDisabled,
            emailEmptyError,
            pwdEmptyError,
            loginFailed,
        } = this.state;

        if (user && user.toJS().Email) {
            return (
                <Redirect to={{
                    pathname: '/',
                    state: { from: location }
                }}/>
            );
        }

        let error;
        if (emailEmptyError) {
            error = <FormattedMessage
                {...localeMessages.emailCannotBeEmpty}
                tagName="div"
            />
        }
        if (pwdEmptyError) {
            error = <FormattedMessage {...localeMessages.passwordCannotBeEmpty} tagName="div" />
        }
        if (loginFailed) {
            // TODO wrong credential vs internal error
            error = <FormattedMessage
                {...localeMessages.loginFailed}
                tagName="div"
                values={{
                    reset: <a
                        href="/reset_password"
                    >
                        <FormattedMessage {...localeMessages.reset} />
                    </a>
                }}
            />
        }

        return (
            <div className="login">
                <form className="login-content" onSubmit={this.handleSubmit}>
                    <div className="login-form-header">
                        <img
                            src="eksenia-logo.svg"
                            alt={intl.formatMessage(localeMessages.ekseniaLogo)}
                        />
                    </div>
                    <Input
                        label={<FormattedMessage {...localeMessages.email} />}
                        value={email}
                        onChange={this.handleEmailChange}
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />

                    <Input
                        type="password"
                        value={pwd}
                        onChange={this.handlePwdChange}
                        label={<FormattedMessage {...localeMessages.password} />}
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />
                    <div className="forgot-password">
                        <a href="/reset_password">
                            <FormattedMessage {...localeMessages.forgotYourPassword} />
                        </a>
                    </div>

                    <Button
                        type="submit"
                        disabled={submitDisabled}
                        formatters={[Button.TYPE.PRIMARY]}
                    >
                        <FormattedMessage {...localeMessages.logIn} />
                    </Button>

                    <ValidationError
                        error={error}
                        formatters={[INPUT_FORMATTERS.VERTICAL, ValidationError.FORMATTER.BASE_COLOR]}
                        withIcon
                    />
                </form>

                <div className="login-extra-controls">
                    <div className="login-language">
                        <Icon name="globe" type="globe" />
                        <select
                            className="language-select"
                            onChange={this.handleChangeLanguage}
                            defaultValue={intl.locale}
                        >
                            <option
                                key={LANGUAGE_NAMES.ENG}
                                value={LANGUAGE_NAMES.ENG}
                            >
                                {LANGUAGE_NAME_TEXT.ENG}
                            </option>
                            <option
                                key={LANGUAGE_NAMES.SER}
                                value={LANGUAGE_NAMES.SER}
                            >
                                {LANGUAGE_NAME_TEXT.SER}
                            </option>
                            <option
                                key={LANGUAGE_NAMES.MNE}
                                value={LANGUAGE_NAMES.MNE}
                            >
                                {LANGUAGE_NAME_TEXT.MNE}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    const userReducer = store.userReducer;
    return {
        user: userReducer.get('user'),
        loginInProgress: userReducer.get('loginInProgress'),
    }
};
const mapDispatchToProps = { logIn };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login))
