import React, {Component, createRef} from 'react';

import Row from './Row';
import { Iterable } from 'immutable';
import {composeClassName, generateKey} from "../utils";
import {FormattedMessage} from "react-intl";
import messages from './messages';
import {SORTING_OPTIONS} from "./constants";
import moment from "moment";

export default class Table extends Component {

    constructor() {
        super(...arguments);

        this.id = generateKey();

        this.state = {
            isScrolling: false,
            sortDropDownOpen: false,
            sortSelected: false,
        };

        this.scrollObserverRef = createRef();
        this.containerRef = createRef();

        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                this.setState({isScrolling: false})
            } else {
                this.setState({isScrolling: true})
            }
        }, {
            root: this.containerRef.current,
        });

        this.onSortClick = this.onSortClick.bind(this);
        this.onSortOptionClick = this.onSortOptionClick.bind(this);
    }

    componentDidMount() {
        this.observer.observe(this.scrollObserverRef.current);
    }

    componentWillUnmount() {
        this.observer.unobserve(this.scrollObserverRef.current);
    }

    onSortClick(column) {
        this.setState({
            sortDropDownOpen: this.state.sortDropDownOpen === column
                ? false
                : column,
        })
    }

    onSortOptionClick(column, type) {
        this.setState({
            sortSelected: type === SORTING_OPTIONS.NONE
                ? false
                : {
                    column,
                    type
                },
        })
    }

    sortRows() {
        const {
            rows,
            header,
        } = this.props;
        const {
            sortSelected,
        } = this.state;

        if (!sortSelected || !(rows.length || rows.size)) {
            return rows;
        }

        const columnIndex = header.findIndex(element => element.key === sortSelected.column);
        const sortOrder = sortSelected.type;

        // eslint-disable-next-line
        return Iterable.isIterable(rows)
            ? rows.sort((rowA, rowB) => {
                const a = rowA.cells[columnIndex].value;
                const b = rowB.cells[columnIndex].value;

                return this.comparator(a, b, sortOrder, header[columnIndex].sort);
            })
            : [...rows].sort((rowA, rowB) => {
                const a = rowA.cells[columnIndex].value;
                const b = rowB.cells[columnIndex].value;

                return this.comparator(a, b, sortOrder, header[columnIndex].sort);
            });
    }

    comparator(a, b, sortOrder, sort) {
        if (typeof sort.ascending === 'function' && typeof sort.descending === 'function') {
            if (sortOrder === SORTING_OPTIONS.ASC) {
                return sort.ascending(a, b);
            } else {
                return sort.descending(a, b);
            }
        }

        if (sort.type === 'number') {
            if (sortOrder === SORTING_OPTIONS.ASC) {
                return parseFloat(a) - parseFloat(b);
            } else {
                return parseFloat(b) - parseFloat(a);
            }
        }

        if (moment.isMoment(a) && moment.isMoment(b)) {
            if (sortOrder === SORTING_OPTIONS.ASC) {
                return b.isBefore(a) ? 1 : -1;
            } else {
                return b.isAfter(a) ? 1 : -1;
            }
        }

        if (typeof a === 'number' && typeof b === 'number') {
            if (sortOrder === SORTING_OPTIONS.ASC) {
                return a - b;
            } else {
                return b - a;
            }
        }

        if (typeof a === 'string' && typeof b === 'string') {
            if (sortOrder === SORTING_OPTIONS.ASC) {
                return a.toUpperCase() > b.toUpperCase() ? 1 : -1;
            } else {
                return a.toUpperCase() < b.toUpperCase() ? 1 : -1;
            }
        }

        if (typeof a === 'boolean' && typeof b === 'boolean') {
            if (sortOrder === SORTING_OPTIONS.ASC) {
                return a ? 1 : -1;
            } else {
                return b ? 1 : -1;
            }
        }
    }

    render() {
        const {
            rows,
            header,
            hideHeader,
        } = this.props;

        const {
            isScrolling,
            sortSelected,
        } = this.state;

        const isEmpty = rows.length === 0 || rows.size === 0;
        const containerClasses = composeClassName("table-container", isEmpty && "empty");
        const headerClasses = composeClassName("table-head", isScrolling && "scrolling");
        const tableClasses = composeClassName("table-with-sort", hideHeader && "headless-table")
        const updatedHeader = header && !hideHeader
            ? header.map(cell => {
                if (cell.sort) {
                    cell.sortProps = {
                        tableId: this.id,
                        showDropdown: this.state.sortDropDownOpen === cell.key,
                        callbacks: cell.sort,
                        dropdownHandler: this.onSortClick,
                        sortHandler: this.onSortOptionClick,
                        sortSelected: sortSelected,
                    }
                }
                return cell
            })
            : false;

        return (
            <div className={tableClasses}>
                <div className="sort-container" id={this.id}/>
                <div className={containerClasses} ref={this.containerRef}>
                    <div className="scroll-observer" ref={this.scrollObserverRef}/>
                    <table className="table">
                        {updatedHeader && (
                            <thead className={headerClasses}>
                                <Row cells={updatedHeader} isHeader/>
                            </thead>
                        )}

                        <tbody>
                            {this.sortRows(rows).map(row => <Row {...row} header={header} />)}
                        </tbody>
                    </table>

                    {isEmpty &&
                    <div className="empty-table">
                        <FormattedMessage {...messages.nothingToShow} />
                    </div>
                    }
                </div>
            </div>
        );
    }
}
