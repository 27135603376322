export const PMT_GET_PAYMENTS_REQUEST = 'PMT_GET_PAYMENTS_REQUEST';
export const PMT_GET_PAYMENTS_SUCCESS = 'PMT_GET_PAYMENTS_SUCCESS';
export const PMT_GET_PAYMENTS_ERROR = 'PMT_GET_PAYMENTS_ERROR';
export const PMT_POST_PAYMENT_REQUEST = 'PMT_POST_PAYMENT_REQUEST';
export const PMT_POST_PAYMENT_SUCCESS = 'PMT_POST_PAYMENT_SUCCESS';
export const PMT_POST_PAYMENT_ERROR = 'PMT_POST_PAYMENT_ERROR';
export const PMT_UPDATE_PAYMENT_REQUEST = 'PMT_UPDATE_PAYMENT_REQUEST';
export const PMT_UPDATE_PAYMENT_SUCCESS = 'PMT_UPDATE_PAYMENT_SUCCESS';
export const PMT_UPDATE_PAYMENT_ERROR = 'PMT_UPDATE_PAYMENT_ERROR';
export const PMT_DELETE_PAYMENT_REQUEST = 'PMT_DELETE_PAYMENT_REQUEST';
export const PMT_DELETE_PAYMENT_SUCCESS = 'PMT_DELETE_PAYMENT_SUCCESS';
export const PMT_DELETE_PAYMENT_ERROR = 'PMT_DELETE_PAYMENT_ERROR';

export const PMT_EDIT_PAYMENT_MODAL_NAME = 'PMT_EDIT_PAYMENT_MODAL_NAME';
export const PMT_DELETE_PAYMENT_CONFIRMATION_MODAL_NAME = 'PMT_DELETE_PAYMENT_CONFIRMATION_MODAL_NAME';

export const PMT_EDIT_PAYMENT_FORM_NAME = 'PMT_EDIT_PAYMENT_FORM_NAME';

export const PMT_OPEN_EDIT_PAYMENT_MODAL = 'PMT_OPEN_EDIT_PAYMENT_MODAL';
export const PMT_CLOSE_EDIT_PAYMENT_MODAL = 'PMT_CLOSE_EDIT_PAYMENT_MODAL';

export const PMT_PAYMENT_PROPS = {
    ID: 'ID',
    AMOUNT: 'Amount',
    DATE: 'Date',
    NOTE: 'Note',
    RESERVATION_ID: 'ReservationId',
    PAYMENT_TYPE: 'PaymentType',
};
