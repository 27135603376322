import React from 'react';
import {
    fromJS,
    is,
    Iterable,
} from 'immutable';
import moment from 'moment';

import {
    inputTypes,
    numberInputs,
} from './constants';
import {
    getFieldValue,
    getFieldError,
    getAllFormFieldsFromStore, isFieldValueDirty,
} from './utils';
import validators from './validators';
import ReactDOM from "react-dom";

const getValues = (form, blueprint) => {
    const blueprintFields = getBlueprintFields(blueprint);

    return form.get('inputFields').map(field => {
        const value = field.get('value');
        const inputName = field.get('inputName');
        const fieldInBlueprint = blueprintFields.find(bpField =>
            bpField.name === inputName) || {};

        const {
            formatValue,
            type,
        } = fieldInBlueprint;

        if (typeof formatValue === 'function') {
            return formatValue(value);
        } else if (numberInputs.indexOf(type) !== -1) {
            return parseFloat(value);
        }

        return value;
    });
}

export const getBlueprintFields = (blueprint) =>
    blueprint.fields.reduce((acc, cur) => {
        if (typeof cur === 'boolean') {
            return acc;
        }
        if (cur.type === 'section') {
            return [...acc, ...cur.fields];
        }
        return [...acc, cur];
    }, []);

export default {
    handleSubmit: function(event) {
        const {
            onSubmit,
            form,
            blueprint,
            setValidationError,
        } = this.props;

        if (typeof onSubmit !== 'function') {
            return
        }

        const {
            formName,
        } = blueprint;

        event.preventDefault();
        event.stopPropagation();

        setValidationError(formName);

        const values = getValues(form, blueprint);
        const error = this.validateForm(values);
        if (error) {
            this.errorScrollTimeout = window.setTimeout(() => {
                const formElement = ReactDOM.findDOMNode(this.formRef.current);
                const errorElements = formElement.getElementsByClassName('error');
                if (errorElements.length > 0) {
                    formElement.getElementsByClassName('form-input-fields')[0]
                        .scrollTo(0, errorElements[0].offsetTop - 30);
                }
            }, 100);
            return;
        }

        const fileUploadFields = getBlueprintFields(blueprint)
            .filter(field => field.type === 'file');

        if (fileUploadFields.length) {
            return this.handleUploadFiles(fileUploadFields, values, 0);
        }

        this.submit(values)
    },

    handleUploadFiles: function(fileUploadFields, values, index, previousFieldValue) {
        const {
            uploadFiles,
            blueprint,
        } = this.props;

        const {
            formName,
        } = blueprint;

        if (index > 0) {
            values = values.set(fileUploadFields[index - 1].name, previousFieldValue)
        }

        if (fileUploadFields.length === index) {
            return this.submit(values);
        }

        const fieldName = fileUploadFields[index].name;
        uploadFiles(
            values.get(fieldName),
            formName,
            fieldName,
            this.handleUploadFiles.bind(this, fileUploadFields, values, index + 1),
            // we support only public files at the moment!!!
            true,
            index + 1 !== fileUploadFields.length
        )
    },

    handleInputChange: function(inputName, value, dontUpdate, newState = {}) {
        const {
            updateInputField,
            blueprint,
            showIf,
            form,
        } = this.props;
        const visible = this.shouldShowField(showIf);

        this.updateConnectedInputs(inputName, value, dontUpdate);

        const updatedField = {
            value,
            visible,
            error: false,
            dirty: newState.dirty !== undefined
                ? newState.dirty
                : this.isFieldDirty(inputName, value),
        };

        updateInputField(blueprint.formName, inputName, updatedField);

        if (typeof blueprint.onValueChange === 'function') {
            const values = getValues(form, blueprint);

            blueprint.onValueChange(inputName, value, values);
        }
    },

    handleConfirmationClose: function() {
        this.props.hideModal();
    },

    submit: function(values) {
        const {
            onSubmit,
            blueprint,
        } = this.props;

        const {
            formName,
        } = blueprint;

        if (typeof blueprint.onSubmit === 'function') {
            return blueprint.onSubmit(onSubmit, values);
        }

        onSubmit(values, formName);
    },

    isFieldDirty: function(inputName, value) {
        const {
            form,
            blueprint,
        } = this.props;
        const blueprintFields = getBlueprintFields(blueprint);

        const neverDirty = (blueprintFields.find(field =>
            field.name === inputName) || {}).neverDirty;

        if (neverDirty) {
            return false;
        }

        const initialValue = form.getIn(['inputFields', inputName, 'initialValue']);

        return isFieldValueDirty(initialValue, value);
    },

    validateForm: function(values) {
        return values.reduce((acc, val, key) => {
            const error = this.validateField(key, val, values);

            return acc ? acc : !!error;
        }, false);
    },

    validateField: function(inputName, value, values) {
        const {
            blueprint,
            updateInputField,
        } = this.props;

        const blueprintFields = getBlueprintFields(blueprint);
        const inputField = (blueprintFields.find(field =>
            field.name === inputName) || {});
        let validations = inputField.validations;
        const validateIf = inputField.validateIf;
        const validateWith = inputField.validateWith;

        if (typeof validateIf === 'function' && !validateIf(values)) {
            updateInputField(blueprint.formName, inputName, { error: false });

            return false;
        }

        if (typeof validateWith === 'function') {
            validations = validateWith(values);
        }

        if (Array.isArray(validations)) {
            const error = validations.reduce((acc, cur) => {
                if (typeof acc === 'string' || typeof acc === 'object') {
                    return acc;
                }

                if (typeof cur === 'function') {
                    return cur(value, values) || false;
                }

                if (typeof cur === 'string' && typeof validators[cur] === 'function') {
                    return validators[cur](value) || false;
                }

                if (typeof cur === 'object' && typeof validators[cur.validator] === 'function') {
                    return validators[cur.validator](value, ...cur.args) || false;
                }

                return acc;
            }, false);

            updateInputField(blueprint.formName, inputName, { error });

            return error;
        }
    },

    updateConnectedInputs: function(inputName, value, dontUpdate = []) {
        const {
            form,
            blueprint,
        } = this.props;

        const blueprintFields = getBlueprintFields(blueprint);
        const connectTo = blueprintFields.filter(field =>
            field.connectTo
            && dontUpdate.indexOf(field.name) === -1
            && field.connectTo[inputName]
        );

        if (!Array.isArray(connectTo)) {
            return
        }

        dontUpdate = dontUpdate.concat(connectTo.map(field => field.name));

        connectTo.forEach(field => {
            const {
                name,
                connectTo,
            } = field;
            const fieldsFromStore = getAllFormFieldsFromStore(form);
            const connectedFieldValue = getFieldValue(form, name);
            const newValue = connectTo[inputName]
                .mapReceivedValue(value, connectedFieldValue, fieldsFromStore);

            let newState;
            if (typeof connectTo[inputName].updateState === 'function') {
                newState = connectTo[inputName]
                    .updateState(value, connectedFieldValue, fieldsFromStore);
            }

            this.handleInputChange(name, newValue, dontUpdate, newState);

        });
    },

    fieldBlueprintToStore: function(fields) {
        return fields.map(field => {
            const isNumber = numberInputs.indexOf(field.type) !== -1;
            const initialValue = field.initialValue;
            const value = isNumber
                ? typeof initialValue === 'number' && !isNaN(initialValue)
                    ? initialValue.toString()
                    : ''
                : initialValue;

            return {
                inputName: field.name,
                initialValue: value,
                value,
                visible: this.shouldShowField.call(this, field.showIf, true),
                error: false,
                dirty: false,
            }
        });
    },

    shouldShowField: function(showIf, initial) {
        const {
            form,
            blueprint,
        } = this.props;

        const blueprintFields = getBlueprintFields(blueprint);

        if (!showIf) {
            return true;
        }

        if (Array.isArray(showIf)) {
            return showIf.map(condition => this.shouldShowField.call(this,condition, initial));
        }

        const {
            field: fieldName,
            value,
            valueNot,
        } = showIf;

        if (value !== undefined) {
            return initial
                ? blueprintFields.find(field => field.name === fieldName).initialValue === value
                : form.getIn(['inputFields', fieldName, 'value']) === value;

        } else if (valueNot !== undefined) {
            return initial
                ? blueprintFields.find(field => field.name === fieldName).initialValue !== valueNot
                : form.getIn(['inputFields', fieldName, 'value']) !== valueNot;
        }
    },

    renderSection: function(section, name) {
        return (
            <div className="form-section" key={name}>
                {section.map(field =>
                    this.renderInputField(field)
                )}
            </div>
        )
    },

    renderInputField: function(field) {
        if (!field) {
            return false;
        }

        const {
            blueprint,
            form,
        } = this.props;

        const {
            formatters,
        } = blueprint;

        const {
            type,
            name,
            initialValue,
            showIf,
            validateIf,
            fields,
            ...props
        } = field;

        if (type === 'section') {
            return this.renderSection(fields, name);
        }

        if (this.shouldShowField(showIf) === false) {
            return false;
        }

        const Field = inputTypes[type];

        const storeValue = getFieldValue(form, name);
        const value = storeValue === undefined
            ? initialValue
            : storeValue;

        return (
            <Field
                key={name}
                // defaultValue={initialValue}
                onChangeFromForm={this.handleInputChange}
                name={name}
                formatters={formatters}
                {...props}
                // value={getFieldValue(form, name)}
                value={value}
                error={getFieldError(form, name)}
                values={getValues(form, blueprint)}
                submitting={form.get('submitting')}
            />
        )
    },
}
