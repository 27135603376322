import { defineMessages } from 'react-intl';

export default defineMessages({
    daysInterval: {
        id: 'calendar.daysInterval',
        defaultMessage: '{count, plural, one {{count} day} other {{count} days}}',
    },
    unitClosed: {
        id: 'calendar.unitClosed',
        defaultMessage: 'Closed',
    },
    unitClosedWithUnitName: {
        id: 'calendar.unitClosedWithUnitName',
        defaultMessage: '{unitName} closed',
    },
    rates: {
        id: 'calendar.rates',
        defaultMessage: 'Rates',
    },
    ratesPrice: {
        id: 'calendar.ratesPrice',
        defaultMessage: '{rateName} - {fullPrice} {multiplier, plural, one {} other {({price} * {multiplier})}}',
    },
    closed: {
        id: 'calendar.closed',
        defaultMessage: 'Closed',
    },
    today: {
        id: 'calendar.today',
        defaultMessage: 'Today',
    },
    openClose: {
        id: 'calendar.openClose',
        defaultMessage: 'Open/closed',
    },
    reservations: {
        id: 'calendar.reservations',
        defaultMessage: 'Reservations',
    },
    room: {
        id: 'calendar.room',
        defaultMessage: 'Room',
    },
    unit: {
        id: 'calendar.unit',
        defaultMessage: 'Unit',
    },
    unavailabilityStart: {
        id: 'calendar.unavailabilityStart',
        defaultMessage: 'Unavailability start',
    },
    unavailabilityEnd: {
        id: 'calendar.unavailabilityEnd',
        defaultMessage: 'Unavailability end',
    },
    unavailability: {
        id: 'calendar.unavailability',
        defaultMessage: 'Unavailability',
    },
    mustOpenAfterClosing: {
        id: 'calendar.mustOpenAfterClosing',
        defaultMessage: 'The unit must open after it was closed',
    },
    unitWillBeClosed: {
        id: 'calendar.unitWillBeClosed',
        defaultMessage: 'Unit {roomName} will be closed between {startTime} and {endTime}',
    },
    closingRemoved: {
        id: 'calendar.closingRemoved',
        defaultMessage: 'Closing has been removed.',
    },
    delete: {
        id: 'calendar.delete',
        defaultMessage: 'Delete',
    },
    confirmDelete: {
        id: 'calendar.confirmDelete',
        defaultMessage: 'Are you sure you want to delete this unavailability?',
    },
    roomType: {
        id: 'calendar.roomType',
        defaultMessage: 'Room type',
    },
    addReservation: {
        id: 'calendar.addReservation',
        defaultMessage: 'Add reservation',
    },
    closeUnit: {
        id: 'calendar.closeUnit',
        defaultMessage: 'Close unit',
    },
})
