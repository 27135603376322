import {
    MODAL_SHOW,
    MODAL_HIDE, MODALS_HIDE,
} from './constants'

export const showModal = (modalId) => {
    return {
        type: MODAL_SHOW,
        modalId,
    }
};

export const hideModal = () => {
    return {
        type: MODAL_HIDE
    }
};

export const hideAllModals = () => {
    return {
        type: MODALS_HIDE
    }
}