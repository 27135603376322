import { List } from 'immutable'

import { reduceWithDefault } from 'utils';

import {
    MODAL_SHOW,
    MODAL_HIDE,
    MODALS_HIDE,
} from './constants'

const initialState = List();

const reducers = {
    [MODAL_SHOW]: (state, { modalId }) =>
        state.push(modalId),

    [MODAL_HIDE]: state =>
        state.pop(),

    [MODALS_HIDE]: () => initialState,
};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
