import { endpoints } from 'api'

import {
    GET_GUESTS_REQUEST,
    GET_GUESTS_SUCCESS,
    GET_GUESTS_ERROR,
    POST_GUEST_REQUEST,
    POST_GUEST_SUCCESS,
    POST_GUEST_ERROR,
    UPDATE_GUEST_REQUEST,
    UPDATE_GUEST_SUCCESS,
    UPDATE_GUEST_ERROR,
    GET_GUEST_REQUEST,
    GET_GUEST_SUCCESS,
    GET_GUEST_ERROR,
    GET_GUEST_RESERVATIONS_REQUEST,
    GET_GUEST_RESERVATIONS_SUCCESS,
    GET_GUEST_RESERVATIONS_ERROR,
    ADD_EDITED_GUEST,
    CLEAR_EDITED_GUEST,
    CLEAR_GUEST_RESERVATIONS,
    GUEST_PROPS,
    DELETE_GUEST_ERROR,
    DELETE_GUEST_SUCCESS,
    DELETE_GUEST_REQUEST,
    CLEAR_ALL_EDITED_GUESTS,
    SEARCH_GUESTS_ERROR,
    SEARCH_GUESTS_SUCCESS,
    SEARCH_GUESTS_REQUEST,
    CLEAR_SEARCH_GUESTS_RESULT,
} from './constants'

export const getGuests = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getGuests,
    actions: {
        request: GET_GUESTS_REQUEST,
        success: GET_GUESTS_SUCCESS,
        error: GET_GUESTS_ERROR,
    },
});

export const postGuest = (guest, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postGuest,
    actions: {
        request: POST_GUEST_REQUEST,
        success: POST_GUEST_SUCCESS,
        error: POST_GUEST_ERROR,
        successCallback,
    },
    body: guest,
    formName,
    payload: {
        guestId: guest[GUEST_PROPS.ID]
    }
});

export const putGuest = (idGuest, guest, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putGuest,
    actions: {
        request: UPDATE_GUEST_REQUEST,
        success: UPDATE_GUEST_SUCCESS,
        error: UPDATE_GUEST_ERROR,
        successCallback,
    },
    params: { idGuest },
    body: guest,
    formName,
    payload: {
        guestId: idGuest
    }
});

export const getGuest = (idGuest, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.getGuest,
    actions: {
        request: GET_GUEST_REQUEST,
        success: GET_GUEST_SUCCESS,
        error: GET_GUEST_ERROR,
        successCallback,
    },
    params: { idGuest },
});

export const addEditedGuest = editedGuest => ({
    type: ADD_EDITED_GUEST,
    editedGuest,
});

export const clearEditedGuest = guestId => ({
    type: CLEAR_EDITED_GUEST,
    guestId
});

export const clearAllEditedGuests = () => ({
    type: CLEAR_ALL_EDITED_GUESTS,
});

export const getGuestReservations = idGuest => ({
    type: 'API_CALL',
    endpoint: endpoints.getGuestReservations,
    actions: {
        request: GET_GUEST_RESERVATIONS_REQUEST,
        success: GET_GUEST_RESERVATIONS_SUCCESS,
        error: GET_GUEST_RESERVATIONS_ERROR,
    },
    params: { idGuest },
});

export const clearGuestReservations = guestId => ({
    type: CLEAR_GUEST_RESERVATIONS,
    guestId,
});

export const deleteGuest = (idGuest, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteGuest,
    actions: {
        request: DELETE_GUEST_REQUEST,
        success: DELETE_GUEST_SUCCESS,
        error: DELETE_GUEST_ERROR,
        successCallback,
    },
    params: { idGuest },
});

export const searchGuests = (excludeIds, query) => ({
    type: 'API_CALL',
    endpoint: endpoints.searchGuests,
    actions: {
        request: SEARCH_GUESTS_REQUEST,
        success: SEARCH_GUESTS_SUCCESS,
        error: SEARCH_GUESTS_ERROR,
    },
    params: { query },
    queryParams: { excludeIds }
});

export const clearSearchGuestsResult = () => ({
    type: CLEAR_SEARCH_GUESTS_RESULT,
})