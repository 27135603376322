import React, { Component } from 'react';
import { connect } from 'react-redux';

import {FILE_PREVIEW_MODAL_NAME} from "./constants";
import Modal, {hideModal} from "../Modal";
import Checkbox from "../Checkbox";

export class PreviewFiles extends Component {
    constructor(props) {
        super(props);

        this.handleHideModal = this.handleHideModal.bind(this);
    }

    handleHideModal() {
        this.props.hideModal();
    }

    render() {
        const {
            id,
            headerText,
            fileUrls,
            fileLocations,
            handleImageClick,
        } = this.props;

        return (
            <Modal
                id={id || FILE_PREVIEW_MODAL_NAME}
                key={id || FILE_PREVIEW_MODAL_NAME}
                headerText={headerText}
                formatters={[Modal.SIZE.MEDIUM]}
                onClose={this.handleHideModal}
            >
                <div className="files-preview-container">
                    <div className="files-preview-content-container">
                        {fileLocations.map(({ path, doNotUpload }) =>
                            <div
                                key={path}
                                className="file-input-image-preview"
                                onClick={() => handleImageClick('new', doNotUpload, path)}
                            >
                                <img
                                    src={path}
                                    className={doNotUpload ? 'to-delete' : ''}
                                    alt=""
                                />
                                <div className="file-controls">
                                    <Checkbox
                                        name={path}
                                        value={!doNotUpload}
                                        onClick={event => event.stopPropagation()}
                                    />
                                </div>
                            </div>
                        )}

                        {fileUrls.map(({ url, doNotKeep }) =>
                            <div
                                key={url}
                                className="file-input-image-preview"
                                onClick={() => handleImageClick('old', doNotKeep, url)}
                            >
                                <img
                                    src={url}
                                    className={doNotKeep ? 'to-delete' : ''}
                                    alt=""
                                />
                                <div className="file-controls">
                                    <Checkbox
                                        name={url}
                                        value={!doNotKeep}
                                        onClick={event => event.stopPropagation()}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    hideModal,
}

export default connect(undefined, mapDispatchToProps)(PreviewFiles)