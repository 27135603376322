import React, { Component } from 'react';
import { connect } from 'react-redux';

import Money from 'eksenia-lib/src/Money';
import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import Table from 'eksenia-lib/src/Table';
import {
    deleteTaxOrFee,
    addEditedTaxOrFee,
} from './actions';
import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    EDIT_TAX_OR_FEE_MODAL_NAME, TAX_AND_FEE_LOGIC_OPTIONS,
    TAXES_AND_FEES_PROPS,
} from './constants';
import localeMessages from './messages';
import {FormattedMessage, FormattedNumber} from "react-intl";

const tableHeader = [
    {
        content: localeMessages.type,
        key: 'type',
        width: '25%',
        sort: true,
    },
    {
        content: localeMessages.isInclusive,
        key: 'isInclusive',
        width: '25%',
        sort: true,
    },
    {
        content: localeMessages.logic,
        key: 'logic',
        width: '25%',
        sort: true,
    },
    {
        content: localeMessages.rate,
        key: 'rate',
        width: '25%',
        sort: {
            type: 'number',
        },
    },
];

export class RoomList extends Component {

    handleOpenModal(taxOrFee) {
        const {
            showModal,
            addEditedTaxOrFee,
        } = this.props;

        addEditedTaxOrFee(taxOrFee);
        showModal(EDIT_TAX_OR_FEE_MODAL_NAME);
    }

    prepareTableRows() {
        const {
            taxesAndFees,
        } = this.props;

        return taxesAndFees.map((taxOrFee) => ({
            key: taxOrFee.get(TAXES_AND_FEES_PROPS.ID),
            onClick: this.handleOpenModal.bind(this, taxOrFee),
            cells: [
                {
                    key: 'type',
                    content: <FormattedMessage {...localeMessages[taxOrFee.get(TAXES_AND_FEES_PROPS.TYPE)]} />,
                    value: taxOrFee.get(TAXES_AND_FEES_PROPS.TYPE),
                },
                {
                    key: 'isInclusive',
                    content: taxOrFee.get(TAXES_AND_FEES_PROPS.IS_INCLUSIVE)
                        ? <FormattedMessage {...localeMessages.inclusive} />
                        : <FormattedMessage {...localeMessages.exclusive} />,
                    value: taxOrFee.get(TAXES_AND_FEES_PROPS.IS_INCLUSIVE),
                },
                {
                    key: 'logic',
                    content: <FormattedMessage {...localeMessages[taxOrFee.get(TAXES_AND_FEES_PROPS.LOGIC)]} />,
                    value: taxOrFee.get(TAXES_AND_FEES_PROPS.LOGIC),
                },
                {
                    key: 'rate',
                    // eslint-disable-next-line
                    content: taxOrFee.get(TAXES_AND_FEES_PROPS.LOGIC) === TAX_AND_FEE_LOGIC_OPTIONS.PERCENT
                        ? <FormattedNumber
                            value={taxOrFee.get(TAXES_AND_FEES_PROPS.RATE) / 100}
                            // eslint-disable-next-line
                            style="percent"
                            maximumFractionDigits={2}
                        />
                        : <Money value={taxOrFee.get(TAXES_AND_FEES_PROPS.RATE)} />,
                    value: taxOrFee.get(TAXES_AND_FEES_PROPS.RATE),
                },
            ],
        }));
    }

    render() {
        const rows = this.prepareTableRows();

        if (!rows) {
            return false;
        }

        return (
            <Table header={tableHeader} rows={rows} />
        );
    }
}

const mapStateToProps = (store) => {
    const taxesAndFeesReducer = store.taxesAndFeesReducer;

    return {
        taxesAndFees: taxesAndFeesReducer.get('taxesAndFees'),
    }
};

const mapDispatchToProps = {
    deleteTaxOrFee,
    addEditedTaxOrFee,
    showModal,
    hideModal,
    addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomList)
