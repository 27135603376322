import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// import { leaveForm } from '../Form/actions';
import Confirm from '../Confirm';
import Modal, {
    hideModal,
} from '../Modal';
import localeMessages from './messages';
import {
    leaveForm,
    CANCEL_FORM_CONFIRMATION_NAME,
} from '../Form';

export class ModalWithForm extends Component {

    constructor() {
        super(...arguments);

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleClose() {
        const {
            activeForms,
            leaveForm,
            onClose,
            id,
        } = this.props;

        if (Array.isArray(activeForms) && typeof leaveForm === 'function') {
            leaveForm(activeForms, id, onClose);
        } else {
            onClose();
        }
    }

    handleConfirm() {
        const {
            hideModal,
            onClose,
        } = this.props;

        hideModal();
        onClose();
    }

    render() {
        const {
            id,
            hideModal,
            onDelete,
        } = this.props;

        return (
            <>
                <Modal
                    {...this.props}
                    onClose={this.handleClose}
                    onDelete={onDelete}
                />

                <Confirm
                    id={`${id}_${CANCEL_FORM_CONFIRMATION_NAME}`}
                    onCancel={hideModal}
                    onCancelText={<FormattedMessage {...localeMessages.cancel} />}
                    onConfirm={this.handleConfirm}
                    onConfirmText={<FormattedMessage {...localeMessages.confirm} />}
                >
                    <FormattedMessage {...localeMessages.unsavedChanges} />
                </Confirm>
            </>
        );
    }
}

const mapDispatchToProps = {
    hideModal,
    leaveForm,
};

export default connect(
    undefined,
    mapDispatchToProps,
)(ModalWithForm)
