import React, { Component } from 'react';

import { composeClassName } from 'utils'
import Tooltip from "../Tooltip";
import Sort from "./Sort";
import messages from "./messages";
import {injectIntl} from "react-intl";

class Cell extends Component {

    render() {
        const {
            content,
            isHeader,
            className,
            formatters = [],
            width = '',
            help,
            sortProps,
            columnId,
            sort,
            header,
            intl,
            render,
            itemId,
            contentTag = 'span',
            ...restProps
        } = this.props;

        const Tag = isHeader
            ? 'th'
            : 'td';

        const ContentTag = contentTag;

        const classes = composeClassName(
            'table-cell',
            className,
            ...formatters
        );

        const contentClasses = composeClassName(
            isHeader && sort && 'th-content',
        )

        const headerValue = !isHeader && ((header || []).find(element => element.key === columnId) || {}).content;

        return (
            <Tag
                className={classes}
                style={{width}}
                data-th={headerValue && intl.formatMessage(messages.mobileHeader, {
                    message: intl.formatMessage(headerValue)
                })}
                {...restProps}
            >
                <ContentTag className={contentClasses}>
                    {typeof render === 'function'
                        ? render(content, itemId)
                        : isHeader && !!content
                            ? intl.formatMessage(content)
                            : content
                    }
                </ContentTag>
                {help && <Tooltip help={help} small />}
                {sortProps && <Sort {...sortProps} columnId={columnId} />}
            </Tag>
        );
    }
}

export default injectIntl(Cell)
