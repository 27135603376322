import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Icon from "../Icon";
import SortDropdown from "./SortDropdown";

export default class Sort extends Component {

    constructor() {
        super(...arguments);

        this.containerRef = React.createRef();
    }

    render() {
        const {
            showDropdown,
            tableId,
            dropdownHandler,
            columnId,
            callbacks,
            sortHandler,
            sortSelected,
        } = this.props;

        const columnSelected = sortSelected && sortSelected.column === columnId;
        const typeSelected = columnSelected && sortSelected.type;

        return (
            <div className="sort" ref={this.containerRef} >
                <Icon
                    name={columnSelected ? 'sortActive' : 'sortPassive'}
                    type="sort-passive"
                    onClick={() => dropdownHandler(columnId)}
                />

                {showDropdown &&
                    ReactDOM.createPortal(
                        <SortDropdown
                            container={this.containerRef}
                            callbacks={callbacks}
                            closeHandler={dropdownHandler.bind(this, columnId)}
                            showDropdown={showDropdown}
                            sortHandler={sortHandler.bind(this, columnId)}
                            sortSelected={typeSelected}
                        />,
                        document.getElementById(tableId)
                    )
                }
            </div>
        )
    }
}
