import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Iterable,
    List,
    Map,
} from 'immutable';
import {
    FormattedMessage,
    FormattedDate,
    injectIntl,
} from 'react-intl';

import Money from 'eksenia-lib/src/Money';
import {
    addNotification,
} from 'eksenia-lib/src/Notifications';
import Table from 'eksenia-lib/src/Table';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    showModal,
    hideModal,
} from 'eksenia-lib/src/Modal';
import {
    deleteRate,
    addEditedRate,
    getRates,
} from './actions';
import {
    RATE_PROPS,
    EDIT_RATE_MODAL_NAME,
    RATE_ROOM_PROPS,
} from './constants';
import localeMessages from './messages';
import {transformRateForForm} from "./utils";

const tableHeader = [
    {
        content: localeMessages.name,
        key: 'name',
        width: '30%',
        sort: true,
    },
    {
        content: localeMessages.firstNight,
        key: 'firstNight',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.lastNight,
        key: 'lastNight',
        width: '20%',
        sort: true,
    },
    {
        content: localeMessages.price,
        key: 'price',
        width: '10%',
        sort: {
            type: 'number',
        },
    },
    {
        content: localeMessages.rooms,
        key: 'rooms',
        width: '20%',
        sort: true,
    },
];

const getRoomName = (roomId, rooms) =>
    (rooms.find(room => room.get(RMS_ROOM_PROPS.ID) === roomId) || Map())
        .get(RMS_ROOM_PROPS.NAME, '');

export class RateList extends Component {

    constructor() {
        super(...arguments);

        this.handleEditRate = this.handleEditRate.bind(this);
    }

    componentDidMount() {
        this.props.getRates();
    }

    handleEditRate(rate) {
        const {
            showModal,
            addEditedRate,
            rooms,
            intl,
        } = this.props;

        addEditedRate(transformRateForForm(rate, rooms, intl));
        showModal(EDIT_RATE_MODAL_NAME)
    }

    prepareTableRows() {
        const {
            rates,
            rooms,
        } = this.props;

        if (!Iterable.isIterable(rates)) {
            return false;
        }

        return rates.map(rate => {
            const rateRooms = rate.get(RATE_PROPS.ROOMS);
            const rateRoomsCount = rateRooms && rateRooms.size;
            const roomsContent = rateRooms
                ? rateRoomsCount === 1
                    ? getRoomName(rateRooms.getIn([0, RATE_ROOM_PROPS.ROOM_ID]), rooms)
                    : <FormattedMessage {...localeMessages.roomsCount} values={{count: rateRoomsCount}} />
                : '';

            const id = rate.get(RATE_PROPS.ID);

            return {
                key: id,
                onClick: this.handleEditRate.bind(null, rate),
                cells: [
                    {
                        content: rate.get(RATE_PROPS.NAME),
                        value: rate.get(RATE_PROPS.NAME),
                        key: 'name',
                    },
                    {
                        content: <FormattedDate value={rate.get(RATE_PROPS.FIRST_NIGHT).toDate()} />,
                        value: rate.get(RATE_PROPS.FIRST_NIGHT),
                        key: 'firstNight',
                    },
                    {
                        content: <FormattedDate value={rate.get(RATE_PROPS.LAST_NIGHT).toDate()} />,
                        value: rate.get(RATE_PROPS.LAST_NIGHT),
                        key: 'lastNight',
                    },
                    {
                        // eslint-disable-next-line
                        content: <Money value={rate.get(RATE_PROPS.ROOM_PRICE)} />,
                        value: rate.get(RATE_PROPS.ROOM_PRICE),
                        key: 'price',
                    },
                    {
                        content: roomsContent,
                        value: rateRoomsCount,
                        key: 'rooms',
                    },
                    // {
                    //     formatters: ['align-right'],
                    //     content: (
                    //             <Button
                    //                 formatters={[Button.TYPE.LINK, INPUT_FORMATTERS.HEIGHT.S]}
                    //                 onClick={this.handleConfirmationOpen.bind(this, id, name)}
                    //                 key="delete"
                    //             >
                    //                 <FormattedMessage {...localeMessages.delete} />
                    //             </Button>
                    //     ),
                    //     key: 'actions',
                    // },
                ],
            };
        }).toJS();
    }

    render() {
        const rows = this.prepareTableRows();

        if (!rows) {
            return false;
        }

        return (
            <Table header={tableHeader} rows={rows} />
        );
    }
}

const mapStateToProps = store => {
    const ratesReducer = store.ratesReducer;
    const roomsReducer = store.roomsReducer;

    return {
        rates: ratesReducer.get('rates') || List(),
        rooms: roomsReducer.get('rooms'),
    }
};

const mapDispatchToProps = {
    deleteRate,
    showModal,
    hideModal,
    addEditedRate,
    getRates,
    addNotification,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RateList))
