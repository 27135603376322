import { endpoints } from 'api'
import {
    CAL_GET_UNAVAILABILITIES_SUCCESS,
    CAL_POST_UNAVAILABILITY_SUCCESS,
    CAL_SET_EDITED_UNAVAILABILITY,
    CAL_PUT_UNAVAILABILITY_SUCCESS,
    CAL_DELETE_UNAVAILABILITY_SUCCESS,
} from './constants'

export const getUnavailabilities = () => ({
    type: 'API_CALL',
    actions: {
        success: CAL_GET_UNAVAILABILITIES_SUCCESS,
    },
    endpoint: endpoints.getUnavailabilities,
});

export const postUnavailability = (unavailability, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postUnavailability,
    actions: {
        success: CAL_POST_UNAVAILABILITY_SUCCESS,
        successCallback,
    },
    body: unavailability,
    formName,
});

export const deleteUnavailability = (idUnavailability, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.deleteUnavailability,
    actions: {
        success: CAL_DELETE_UNAVAILABILITY_SUCCESS,
        successCallback,
    },
    params: {
        idUnavailability,
    },
});

export const putUnavailability = (unavailability, idUnavailability, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.putUnavailability,
    actions: {
        success: CAL_PUT_UNAVAILABILITY_SUCCESS,
        successCallback,
    },
    params: {
        idUnavailability,
    },
    body: unavailability,
    formName,
});

export const setEditedUnavailability = editedUnavailability => ({
    type: CAL_SET_EDITED_UNAVAILABILITY,
    editedUnavailability,
});
