export default {
    getUser: {
        endpoint: 'user',
        method: 'GET',
    },
    logIn: {
        endpoint: 'log_in',
        method: 'POST',
    },
    logOut: {
        endpoint: 'log_out',
        method: 'POST',
    },
    completePasswordReset: {
        endpoint: 'complete_password_reset',
        method: 'POST',
    },
    getRooms: {
        endpoint: 'rooms',
        method: 'GET',
    },
    postRoom: {
        endpoint: 'room',
        method: 'POST',
    },
    getRoom: {
        endpoint: 'room/:idRoom',
        method: 'GET',
    },
    putRoom: {
        endpoint: 'room/:idRoom',
        method: 'PUT',
    },
    deleteRoom: {
        endpoint: 'room/:idRoom',
        method: 'DELETE',
    },
    patchRoom: {
        endpoint: 'room/:idRoom',
        method: 'PATCH',
    },
    getReservations: {
        endpoint: 'reservations',
        method: 'GET',
    },
    postReservation: {
        endpoint: 'reservation',
        method: 'POST',
    },
    putReservation: {
        endpoint: 'reservation/:idReservation',
        method: 'PUT',
    },
    deleteReservation: {
        endpoint: 'reservation/:idReservation',
        method: 'DELETE',
    },
    patchReservation: {
        endpoint: 'reservation/:idReservation',
        method: 'PATCH',
    },
    getLatestReservations: {
        endpoint: 'latest-reservations',
        method: 'GET',
    },
    getMultiReservationPriceBreakdown: {
        endpoint: 'multi_reservation/:idMultiReservation/price_breakdown',
        method: 'GET',
    },
    getPayments: {
        endpoint: 'reservation/:idReservation/payments',
        method: 'GET',
    },
    postPayment: {
        endpoint: 'payment',
        method: 'POST',
    },
    putPayment: {
        endpoint: 'payment/:idPayment',
        method: 'PUT',
    },
    deletePayment: {
        endpoint: 'payment/:idPayment',
        method: 'DELETE',
    },
    getUnavailabilities: {
        endpoint: 'unavailabilities',
        method: 'GET',
    },
    postUnavailability: {
        endpoint: 'unavailability',
        method: 'POST',
    },
    putUnavailability: {
        endpoint: 'unavailability/:idUnavailability',
        method: 'PUT',
    },
    deleteUnavailability: {
        endpoint: 'unavailability/:idUnavailability',
        method: 'DELETE',
    },
    getRates: {
        endpoint: 'rates',
        method: 'GET',
    },
    postRate: {
        endpoint: 'rate',
        method: 'POST',
    },
    putRate: {
        endpoint: 'rate/:idRate',
        method: 'PUT',
    },
    deleteRate: {
        endpoint: 'rate/:idRate',
        method: 'DELETE',
    },
    getGuests: {
        endpoint: 'guests',
        method: 'GET',
    },
    postGuest: {
        endpoint: 'guest',
        method: 'POST',
    },
    putGuest: {
        endpoint: 'guest/:idGuest',
        method: 'PUT',
    },
    getGuest: {
        endpoint: 'guest/:idGuest',
        method: 'GET',
    },
    deleteGuest: {
        endpoint: 'guest/:idGuest',
        method: 'DELETE',
    },
    getProvisionalPrice: {
        endpoint: 'reservation/provisional-price',
        method: 'POST',
    },
    getMessages: {
        endpoint: 'messages',
        method: 'GET',
    },
    getUnits: {
        endpoint: 'units?room_id',
        method: 'GET',
    },
    postUnit: {
        endpoint: 'unit',
        method: 'POST',
    },
    putUnit: {
        endpoint: 'unit/:idUnit',
        method: 'PUT',
    },
    patchUnit: {
        endpoint: 'unit/:idUnit',
        method: 'PATCH',
    },
    deleteUnit: {
        endpoint: 'unit/:idUnit',
        method: 'DELETE',
    },
    getProperties: {
        endpoint: 'properties',
        method: 'GET',
    },
    patchProperty: {
        endpoint: 'property/:idProperty',
        method: 'PATCH',
    },
    getLocale: {
        endpoint: 'locale/:file',
        method: 'GET',
    },
    postLanguage: {
        endpoint: 'user-language',
        method: 'POST',
    },
    getCountryNamesLocalisation: {
        endpoint: 'localisation/country-names/:language',
        method: 'GET',
    },
    getTimeZones: {
        endpoint: 'codelist/timezone',
        method: 'GET',
    },
    impersonateWithToken: {
        endpoint: 'impersonate_with_token',
        method: 'POST',
    },
    requestPasswordReset: {
        endpoint: 'initialize_password_reset',
        method: 'POST',
    },
    getTaxesAndFees: {
        endpoint: 'additional_charges',
        method: 'GET',
    },
    postTaxOrFee: {
        endpoint: 'additional_charge',
        method: 'POST',
    },
    putTaxOrFee: {
        endpoint: 'additional_charge/:idTax',
        method: 'PUT',
    },
    deleteTaxOrFee: {
        endpoint: 'additional_charge/:idTax',
        method: 'DELETE',
    },
    getGuestReservations: {
        endpoint: 'guest/:idGuest/reservations',
        method: 'GET',
    },
    searchGuests: {
        endpoint: 'search-guests/:query?excludeIds',
        method: 'GET',
    },
    addReservationGuest: {
        endpoint: 'reservation/:idReservation/add-guest',
        method: 'POST',
    },
}
