import {
    Iterable,
    List,
    fromJS,
    Map,
} from 'immutable';
import moment from 'moment';

import {
    RMS_ROOM_PROPS,
} from '../Rooms'
import {
    RATE_PROPS,
    RATE_ROOM_PROPS,
    DAYS_OF_THE_WEEK,
    DEFAULT_LONG_STAY_DISCOUNTS,
} from './constants';
import localeMessages from './messages';

const dateValues = [
    RATE_PROPS.FIRST_NIGHT,
    RATE_PROPS.LAST_NIGHT,
    RATE_PROPS.CREATED_AT,
];

export const mapRateValues = rate =>
        rate.map((value, key) =>
            dateValues.indexOf(key) !== -1
                ? value = moment(value)
                : value
        );

export const mapRatesValues = rates =>
    rates.map(mapRateValues);

const getRoomValues = (rate, roomId) => {
    const room = (rate.get(RATE_PROPS.ROOMS) || List())
        .find(room =>
            room.get(RATE_ROOM_PROPS.ROOM_ID) === roomId);

    return Iterable.isIterable(room)
        ? {
            [RATE_ROOM_PROPS.USED]: true,
            [RATE_ROOM_PROPS.MULTIPLIER]: room.get(RATE_ROOM_PROPS.MULTIPLIER),
        }
        : {
            [RATE_ROOM_PROPS.USED]: false,
            [RATE_ROOM_PROPS.MULTIPLIER]: 1,
        }
};

export const transformRateForForm = (rate, rooms, intl) =>
    rate.merge({
        [RATE_PROPS.ROOMS]: (rooms || List())
            .map(room => {
                const roomId = room.get(RMS_ROOM_PROPS.ID);
                const rateRooms = rate.get(RATE_PROPS.ROOMS) || List();
                const rateRoom = rateRooms.find(rateRoom => rateRoom.get(RATE_ROOM_PROPS.ROOM_ID) === roomId) || Map();

                return fromJS({
                    label: room.get(RMS_ROOM_PROPS.NAME),
                    [RATE_ROOM_PROPS.ROOM_ID]: roomId,
                    [RATE_ROOM_PROPS.ID]: rateRoom.get(RATE_ROOM_PROPS.ID),
                    ...getRoomValues(rate, roomId),
                });
            }),
        [RATE_PROPS.LONG_STAY_DISCOUNTS]: (() => {
            const discounts = rate.get(RATE_PROPS.LONG_STAY_DISCOUNTS) || List();

            return discounts.size
                ? discounts
                : fromJS(DEFAULT_LONG_STAY_DISCOUNTS);
        })(),
        [RATE_PROPS.DAYS_LIMITATIONS]: [{
            id: RATE_PROPS.ALLOW_DAYS,
            label: intl.formatMessage(localeMessages.validOnDays),
            [DAYS_OF_THE_WEEK.MON]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.MON]),
            [DAYS_OF_THE_WEEK.TUE]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.TUE]),
            [DAYS_OF_THE_WEEK.WED]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.WED]),
            [DAYS_OF_THE_WEEK.THU]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.THU]),
            [DAYS_OF_THE_WEEK.FRI]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.FRI]),
            [DAYS_OF_THE_WEEK.SAT]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.SAT]),
            [DAYS_OF_THE_WEEK.SUN]:
                rate.getIn([RATE_PROPS.ALLOW_DAYS, DAYS_OF_THE_WEEK.SUN]),
        // }, {
        //     id: RATE_PROPS.ALLOW_CHECK_IN_DAYS,
        //     label: intl.formatMessage(localeMessages.allowCheckInOn),
        //     [DAYS_OF_THE_WEEK.MON]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.MON]),
        //     [DAYS_OF_THE_WEEK.TUE]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.TUE]),
        //     [DAYS_OF_THE_WEEK.WED]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.WED]),
        //     [DAYS_OF_THE_WEEK.THU]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.THU]),
        //     [DAYS_OF_THE_WEEK.FRI]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.FRI]),
        //     [DAYS_OF_THE_WEEK.SAT]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.SAT]),
        //     [DAYS_OF_THE_WEEK.SUN]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_IN_DAYS, DAYS_OF_THE_WEEK.SUN]),
        // }, {
        //     id: RATE_PROPS.ALLOW_CHECK_OUT_DAYS,
        //     label: intl.formatMessage(localeMessages.allowCheckOutOn),
        //     [DAYS_OF_THE_WEEK.MON]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.MON]),
        //     [DAYS_OF_THE_WEEK.TUE]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.TUE]),
        //     [DAYS_OF_THE_WEEK.WED]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.WED]),
        //     [DAYS_OF_THE_WEEK.THU]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.THU]),
        //     [DAYS_OF_THE_WEEK.FRI]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.FRI]),
        //     [DAYS_OF_THE_WEEK.SAT]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.SAT]),
        //     [DAYS_OF_THE_WEEK.SUN]:
        //         rate.getIn([RATE_PROPS.ALLOW_CHECK_OUT_DAYS, DAYS_OF_THE_WEEK.SUN]),
        }],
    });

