import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseFile: {
        id: 'fileUpload.chooseFile',
        defaultMessage: 'Choose a file',
    },
    chooseFiles: {
        id: 'fileUpload.chooseFiles',
        defaultMessage: 'Choose files',
    },
    preview: {
        id: 'fileUpload.preview',
        defaultMessage: 'Preview ({count})',
    },
    serverError: {
        id: 'fileUpload.serverError',
        defaultMessage: 'There was a problem with the server, please try again later.',
    },
})
