import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import Notification from './Notification';

export class Notifications extends Component {

    render() {
        const {
            notifications,
        } = this.props;

        return ReactDOM.createPortal((
            <div className="notifications">
                {notifications.map(notification => 
                    <Notification
                        key={notification.get('id')}
                        {...notification.toJS()}
                    />
                )}
            </div>
        ), document.getElementById('notifications'))
    }
};

const mapStateToProps = store => ({
    notifications: store.notificationsReducer,
});

export default connect(
    mapStateToProps,
    undefined,
)(Notifications)
