import React, { Component } from 'react';
import localeMessages from "./messages";
import {FormattedMessage} from "react-intl";
import { SORTING_OPTIONS } from "./constants";
import Icon from "../Icon";

export default class SortDropdown extends Component {

    constructor() {
        super(...arguments);

        this.hideDropdown = this.hideDropdown.bind(this);
    }

    componentDidMount() {
        setTimeout(
            () => window.addEventListener('click', this.hideDropdown), 50
        )
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideDropdown)
    }

    hideDropdown() {
        const {
            closeHandler,
            showDropdown,
        } = this.props;
        if (showDropdown) {
            closeHandler()
        }
    }

    chooseSorting(type) {
        const {
            sortHandler,
        } = this.props;

        sortHandler(type)
    }

    render() {
        const {
            callbacks,
            container,
            showDropdown,
            sortSelected,
        } = this.props;

        if (!showDropdown) {
            return false
        }

        let positionLeft = container.current.offsetLeft + container.current.offsetParent.offsetLeft;
        let positionRight = 'unset';
        if (container.current.offsetParent.offsetParent.offsetWidth < positionLeft + 150) {
            positionLeft = 'unset';
            positionRight = 16;
        }

        return (
            <div className="sort-dropdown" style={{
                left: positionLeft,
                right: positionRight,
            }}>
                <div className="sort-title">
                    <FormattedMessage {...localeMessages.sort} />
                </div>
                <div className="sort-option" onClick={() => {
                    this.chooseSorting(SORTING_OPTIONS.ASC);
                    if (callbacks.externalAscending) {
                        callbacks.externalAscending();
                    }
                }}>
                    <Icon
                        name={sortSelected === SORTING_OPTIONS.ASC ? 'radioOn' : 'radioOff'}
                        type="radio-control"
                    />
                    <div className="radio-label">
                        <FormattedMessage {...localeMessages.ascending} />
                    </div>
                </div>
                <div className="sort-option" onClick={() => {
                    this.chooseSorting(SORTING_OPTIONS.DESC);
                    if (callbacks.externalDescending) {
                        callbacks.externalDescending();
                    }
                }}>
                    <Icon
                        name={sortSelected === SORTING_OPTIONS.DESC ? 'radioOn' : 'radioOff'}
                        type="radio-control"
                    />
                    <div className="radio-label">
                        <FormattedMessage {...localeMessages.descending} />
                    </div>
                </div>
                <div className="sort-option" onClick={() => {
                    this.chooseSorting(SORTING_OPTIONS.NONE);
                    if (callbacks.externalReset) {
                        callbacks.externalReset();
                    }
                }}>
                    <div className="close-control" />
                    <div className="radio-label">
                        <FormattedMessage {...localeMessages.resetSort} />
                    </div>
                </div>
            </div>
        )
    }
}
