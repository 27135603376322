import Rates from './RatePage';
import EditRate from './EditRate';
import './Rates.scss';
import reducer from './reducer';

export * from './constants';
export * from './actions';

export {
    Rates,
    EditRate,
    reducer,
};

export default Rates
