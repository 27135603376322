import { Map } from 'immutable';
import React from "react";
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    POPULAR_AMENITIES_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: POPULAR_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.AIR_CONDITIONING,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.AIR_CONDITIONING),
                    label: <FormattedMessage {...localeMessages.airConditioning} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.KITCHENETTE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.KITCHENETTE),
                    label: <FormattedMessage {...localeMessages.kitchenette} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.KITCHEN,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.KITCHEN),
                    label: <FormattedMessage {...localeMessages.kitchen} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.BALCONY,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.BALCONY),
                    label: <FormattedMessage {...localeMessages.balcony} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.TERRACE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.TERRACE),
                    label: <FormattedMessage {...localeMessages.terrace} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FLAT_SCREEN_TV,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FLAT_SCREEN_TV),
                    label: <FormattedMessage {...localeMessages.flatScreenTV} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.KITCHENWARE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.KITCHENWARE),
                    label: <FormattedMessage {...localeMessages.kitchenware} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.POOL,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.POOL),
                    label: <FormattedMessage {...localeMessages.pool} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SEA_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SEA_VIEW),
                    label: <FormattedMessage {...localeMessages.seaView} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.LAKE_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.LAKE_VIEW),
                    label: <FormattedMessage {...localeMessages.lakeView} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.STREET_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.STREET_VIEW),
                    label: <FormattedMessage {...localeMessages.streetView} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.GARDEN_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.GARDEN_VIEW),
                    label: <FormattedMessage {...localeMessages.gardenView} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.POOL_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.POOL_VIEW),
                    label: <FormattedMessage {...localeMessages.poolView} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.RIVER_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.RIVER_VIEW),
                    label: <FormattedMessage {...localeMessages.riverView} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.MOUNTAIN_VIEW,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.MOUNTAIN_VIEW),
                    label: <FormattedMessage {...localeMessages.mountainView} />,
                },
            ],
        },
    ],
})
