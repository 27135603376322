import { defineMessages } from 'react-intl';

export default defineMessages({
    messageSubjectOverbooking: {
        id: 'notifications.messageSubjectOverbooking',
        defaultMessage: 'Overbooking',
    },
    overbookingNotification: {
        id: 'notifications.overbookingNotification',
        defaultMessage: 'Unit {unitName} in room {roomName} is overbooked from {startDate} until {endDate}',
    },
    overbookingNotificationSingleDay: {
        id: 'notifications.overbookingNotificationSingleDay',
        defaultMessage: 'Unit {unitName} in room {roomName} is overbooked on {startDate}',
    },
})
