import { Map } from 'immutable';
import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME,
    BOOKING_PAGE_ROOM_FORM_PROPS,
} from './constants';
import localeMessages from './messages';

export default (
    initialValues = Map(),
) => ({
    formName: SECURITY_AND_EXTRAS_AMENITIES_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'all',
            fields: [
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.KEY_CARD_ACCESS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.KEY_CARD_ACCESS),
                    label: <FormattedMessage {...localeMessages.keyCardAccess} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.KEY_ACCESS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.KEY_ACCESS),
                    label: <FormattedMessage {...localeMessages.keyAccess} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.SMOKE_ALARM,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.SMOKE_ALARM),
                    label: <FormattedMessage {...localeMessages.smokeAlarm} />,
                },
                {
                    type: 'checkbox',
                    integer: true,
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.FIRE_EXTINGUISHER,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.FIRE_EXTINGUISHER),
                    label: <FormattedMessage {...localeMessages.fireExtinguisher} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.LOCKERS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.LOCKERS),
                    label: <FormattedMessage {...localeMessages.lockers} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.EXECUTIVE_LOUNGE_ACCESS,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.EXECUTIVE_LOUNGE_ACCESS),
                    label: <FormattedMessage {...localeMessages.executiveLoungeAccess} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.ALARM_CLOCK,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.ALARM_CLOCK),
                    label: <FormattedMessage {...localeMessages.alarmClock} />,
                },
                {
                    type: 'checkbox',
                    name: BOOKING_PAGE_ROOM_FORM_PROPS.WAKE_UP_SERVICE,
                    initialValue: initialValues.get(BOOKING_PAGE_ROOM_FORM_PROPS.WAKE_UP_SERVICE),
                    label: <FormattedMessage {...localeMessages.wakeUpService} />,
                },
            ],
        },
    ],
})
