import React, { Component } from 'react';

import { composeClassName } from 'utils';
import InputLabel from '../InputLabel'
import ValidationError from '../ValidationError'
import Tooltip from "../Tooltip";

export default class MoneyInput extends Component {

    constructor() {
        super(...arguments);


        this.state = { value: '' };

        this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleValueChange(event) {
        const {
            onChangeFromForm,
            onChange,
            name,
        } = this.props;

        let newValue = event.currentTarget.value;

        if (typeof newValue === 'string' && newValue !== '') {
            newValue = newValue.split('').reduce((acc, char) => {
                const isAllowed = char.match(/\d|\./);

                // if character is not a digit or a dot
                // or if it is a dot but there's already a dot in the accumulator
                if (!isAllowed || (char === '.' && acc.indexOf('.') !== -1)) {
                    return acc;
                }

                return acc.concat(char);
            }, '').substring(0, 14);
        }

        const fractionalPart = newValue.split(".")[1];
        if (fractionalPart && fractionalPart.length > 2) {
            newValue = this.state.value;
        }

        if (this.state.value !== newValue) {
            if (typeof onChangeFromForm === 'function') {
                onChangeFromForm(name, newValue);
            }

            if (typeof onChange === 'function') {
                onChange(newValue);
            }

            this.setState({value: newValue});
        }
    }

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            formatters = [],
            error,
            validations,
            value,
            help,
            disabled,
            submitting,
            ...props
        } = this.props;

        const complexValue = typeof value === 'object' && value.hasOwnProperty('amount');
        const currency = complexValue
            ? value.currency
            : 'EUR';
        const inputValue = complexValue
            ? value.amount
            : value;

        return (
            <div
                className={composeClassName('input-money-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}

                <input
                    type="text"
                    className={composeClassName('input-money', error && 'error', ...formatters)}
                    onChange={this.handleValueChange}
                    value={inputValue}
                    maxLength={10}
                    { ...props }
                    disabled={disabled || submitting}
                />
                <span className={composeClassName('input-money-currency', ...formatters)}>
                    {currency}
                </span>

                {help && <Tooltip help={help} />}

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
