import {
    UNAVAILABILITY_PROPS,
} from "./constants";
import moment from "moment";
import {RESERVATION_PROPS} from "../Reservations";

const dateValues = [
    UNAVAILABILITY_PROPS.START_TIME,
    UNAVAILABILITY_PROPS.END_TIME,
];

export const mapUnavailabilityValues = unavailatility =>
    unavailatility.map((value, key) =>
        dateValues.indexOf(key) !== -1
            ? moment(value)
            : value
    );

export const mapUnavailabilitiesValues = rates =>
    rates.map(mapUnavailabilityValues);

// TODO: handle multiple reservations overbooking, at the moment only two are found
export const collectOverbookings = (reservations) => {
    const setReservationDuplicates = (acc, currentId, date, duplicateType) => {
        const dateDuplicates = acc[duplicateType][date.toString()];
        if (dateDuplicates) {
            dateDuplicates.forEach(reservation => {
                if (!acc.duplicates[reservation] && !acc.duplicates[currentId]) {
                    acc.duplicates[reservation] = 1;
                    acc.duplicates[currentId] = 2;
                } else if (!acc.duplicates[reservation]) {
                    acc.duplicates[reservation] = acc.duplicates[currentId] === 1 ? 2 : 1;
                } else if (!acc.duplicates[currentId]) {
                    acc.duplicates[currentId] = acc.duplicates[reservation] === 1 ? 2 : 1;
                }
            });
        }
    }
    const addReservationToDate = (acc, currentId, date, duplicateType) => {
        const currentDateString = date.toString();
        if (!acc[duplicateType][currentDateString]) {
            acc[duplicateType][currentDateString] = [];
        }
        acc[duplicateType][currentDateString].push(currentId);
    }

    return reservations.reduce((acc, cur) => {
        const reservationId = cur.get(RESERVATION_PROPS.ID);
        const checkIn = cur.get(RESERVATION_PROPS.CHECK_IN);
        const checkOut = cur.get(RESERVATION_PROPS.CHECK_OUT);

        setReservationDuplicates(acc, reservationId, checkIn, 'dates');
        setReservationDuplicates(acc, reservationId, checkIn, 'startDates');
        addReservationToDate(acc, reservationId, checkIn, 'startDates');

        const currentDate = checkIn.clone().add(1, 'days');
        while (currentDate.isBefore(checkOut, 'day')) {
            setReservationDuplicates(acc, reservationId, currentDate, 'startDates');
            setReservationDuplicates(acc, reservationId, currentDate, 'dates');
            addReservationToDate(acc, reservationId, currentDate, 'dates');

            currentDate.add(1, 'days');
        }

        return acc;
    }, {
        dates: {},
        startDates: {},
        duplicates: {},
    }).duplicates;
}

