import {endpoints} from 'api';
import {
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_ERROR,
} from './constants';

export const getMessages = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getMessages,
    actions: {
        request: GET_MESSAGES_REQUEST,
        success: GET_MESSAGES_SUCCESS,
        error: GET_MESSAGES_ERROR,
    },
    noErrorNotification: true,
});

