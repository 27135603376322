export const USR_GET_USER_REQUEST = 'USR_GET_USER_REQUEST';
export const USR_GET_USER_SUCCESS = 'USR_GET_USER_SUCCESS';
export const USR_GET_USER_ERROR = 'USR_GET_USER_ERROR';
export const USR_POST_LOG_IN_REQUEST = 'USR_POST_LOG_IN_REQUEST';
export const USR_POST_LOG_IN_SUCCESS = 'USR_POST_LOG_IN_SUCCESS';
export const USR_POST_LOG_IN_ERROR = 'USR_POST_LOG_IN_ERROR';
export const USR_POST_LOG_OUT_REQUEST = 'USR_POST_LOG_OUT_REQUEST';
export const USR_POST_LOG_OUT_SUCCESS = 'USR_POST_LOG_OUT_SUCCESS';
export const USR_POST_LOG_OUT_ERROR = 'USR_POST_LOG_OUT_ERROR';
export const USR_POST_COMPLETE_PASSWORD_RESET_REQUEST = 'USR_POST_COMPLETE_PASSWORD_RESET_REQUEST';
export const USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS = 'USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS';
export const USR_POST_COMPLETE_PASSWORD_RESET_ERROR = 'USR_POST_COMPLETE_PASSWORD_RESET_ERROR';
export const USR_POST_REQUEST_PASSWORD_RESET_REQUEST = 'USR_POST_REQUEST_PASSWORD_RESET_REQUEST';
export const USR_POST_REQUEST_PASSWORD_RESET_SUCCESS = 'USR_POST_REQUEST_PASSWORD_RESET_SUCCESS';
export const USR_POST_REQUEST_PASSWORD_RESET_ERROR = 'USR_POST_REQUEST_PASSWORD_RESET_ERROR';
export const USR_ALLOW_NEW_PASSWORD_REQUEST = 'USR_ALLOW_NEW_PASSWORD_REQUEST';

export const USR_POST_LANGUAGE_REQUEST = 'USR_POST_LANGUAGE_REQUEST';
export const USR_POST_LANGUAGE_SUCCESS = 'USR_POST_LANGUAGE_SUCCESS';
export const USR_POST_LANGUAGE_ERROR = 'USR_POST_LANGUAGE_ERROR';

export const SET_LANGUAGE_FORM_NAME = 'SET_LANGUAGE_FORM_NAME';
export const SET_LANGUAGE_MODAL_NAME = 'SET_LANGUAGE_MODAL_NAME';

export const USR_PROPS = {
    EMAIL: 'Email',
    LANGUAGE: 'Language',
};