import React from 'react';
import {GUEST_PROPS} from "./constants";
import {getGuestName} from "./utils";

export default (guest) => {
    const country = guest.get(GUEST_PROPS.COUNTRY);
    const city = guest.get(GUEST_PROPS.CITY);
    const email = guest.get(GUEST_PROPS.EMAIL);

    const secondLine = [country, city, email].filter(value => value).join(', ');

    return (
        <div className="guest-search-result-line">
            <div>
                {getGuestName(guest)}
            </div>
            <div className="guest-search-result-second-line">
                {secondLine}
            </div>
        </div>
    );
}
