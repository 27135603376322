import { defineMessages } from 'react-intl';

export default defineMessages({
    unsavedChanges: {
        id: 'modalWithForm.unsavedChanges',
        defaultMessage: 'There are unsaved changes, are you sure you want to leave?',
    },
    cancel: {
        id: 'modalWithForm.cancel',
        defaultMessage: 'Go back',
    },
    confirm: {
        id: 'modalWithForm.confirm',
        defaultMessage: 'Close without saving',
    },
})
