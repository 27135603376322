import Tabs from './Tabs'
import reducer from './reducer'
import './Tabs.scss'

export {
    Tabs,
    reducer,
}

export default Tabs
