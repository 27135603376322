const baseUrl = '/api/v1/';

export default (endpointObj, params, body, queryParams) => {
    const {
        endpoint,
        directEndpoint,
        method,
    } = endpointObj;

    let updatedEndpoint = '';
    if (!directEndpoint) {
        const splitEndpoint = endpoint.split('?');
        let queryParamValues = '';
        if (splitEndpoint[1]) {
            queryParamValues = splitEndpoint[1].split('&')
                .filter(param => queryParams[param])
                .map(param => `${param}=${queryParams[param]}`)
        }

        updatedEndpoint = splitEndpoint[0]
            .split('/').map(part =>
                part.startsWith(':')
                    ? params[part.slice(1)]
                    : part
            ).join('/').concat(
                queryParamValues
                    ? `?${queryParamValues}`
                    : ''
            );
    }

    const url = directEndpoint || baseUrl + updatedEndpoint;

    const headers = new Headers();
    headers.set('Content-Type', 'application/json');

    const init = {
        method,
        headers,
        body: body && JSON.stringify(body),
    };

    const request = new Request(url, init);

    return fetch(request)
        .then(response => {
            try {
                const contentType = response.headers.get('content-type');
                return (typeof contentType !== 'string' || contentType.indexOf('application/json') === -1)
                    ?  {
                        status: response.status,
                        ok: response.ok
                    }
                    : response.json().then(data => ({
                        status: response.status,
                        ok: response.ok,
                        data,
                    }));

            } catch (error) {
                return response.text().then(() => ({
                    status: response.status,
                    ok: response.ok,
                }));
            }
    })
}

