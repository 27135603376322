import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    UNAVAILABILITY_PROPS,
    CAL_UNAVAILABILITY_FORM_NAME, EDITED_UNAVAILABILITY_PROPS,
} from './constants'
import {UNIT_PROPS} from "../Rooms";
import {FormattedMessage} from "react-intl";
import localeMessages from "./messages";
import React from "react";

export default (rooms, roomUnits, initialRoomId, initialUnitId, startDate, endDate) => {
    return {
        formName: CAL_UNAVAILABILITY_FORM_NAME,
        formatters: [INPUT_FORMATTERS.VERTICAL],
        className: 'edit-unavailability-form',
        fields: [
            {
                type: 'section',
                name: 'rooms',
                fields: [
                    {
                        type: 'select',
                        name: EDITED_UNAVAILABILITY_PROPS.ROOM_ID,
                        label: <FormattedMessage {...localeMessages.room} />,
                        initialValue: initialRoomId,
                        options: rooms.map(room => ({
                            key: room.get(RMS_ROOM_PROPS.ID),
                            value: room.get(RMS_ROOM_PROPS.ID),
                            text: room.get(RMS_ROOM_PROPS.NAME),
                        })).toJS(),
                    },
                    {
                        type: 'select',
                        name: UNAVAILABILITY_PROPS.UNIT_ID,
                        label: <FormattedMessage {...localeMessages.unit} />,
                        initialValue: initialUnitId,
                        options: props =>
                            roomUnits.get(props.values.get(EDITED_UNAVAILABILITY_PROPS.ROOM_ID)).map(unit => ({
                                key: unit.get(UNIT_PROPS.ID),
                                value: unit.get(UNIT_PROPS.ID),
                                text: unit.get(UNIT_PROPS.NAME),
                            })).toJS(),
                        connectTo: {
                            [EDITED_UNAVAILABILITY_PROPS.ROOM_ID]: {
                                mapReceivedValue: roomValue =>
                                    roomUnits.getIn([roomValue, 0, UNIT_PROPS.ID])
                            }
                        }
                    },
                    {
                        type: 'date',
                        name: UNAVAILABILITY_PROPS.START_TIME,
                        label: <FormattedMessage {...localeMessages.unavailabilityStart} />,
                        initialValue: startDate,
                    },
                    {
                        type: 'date',
                        name: UNAVAILABILITY_PROPS.END_TIME,
                        label: <FormattedMessage {...localeMessages.unavailabilityEnd} />,
                        initialValue: endDate,
                        validations: [
                            (value, values) => {
                                return !value.isAfter(values.get(UNAVAILABILITY_PROPS.START_TIME))
                                    ? <FormattedMessage {...localeMessages.mustOpenAfterClosing} />
                                    : false
                            }
                        ],
                    },
                ],
            },
        ],
    }
}

