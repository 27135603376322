import { Map, Iterable, fromJS, is } from 'immutable';
import moment from "moment";

export const getFormValues = form => {
    const inputFields = form.get('inputFields') || Map();

    return inputFields
        .filter(field => field.get('visible'))
        .map(field => field.get('value'))
        .toJS();
};

export const getAllFormValues = form => {
    const inputFields = form.get('inputFields') || Map();

    return inputFields
        .map(field => field.get('value'))
        .toJS();
};

export const getDirtyFormValues = form => {
    const inputFields = form.get('inputFields') || Map();

    return inputFields
        .filter(field => field.get('value').is(field.get('initialValue')))
        .map(field => field.get('value'))
        .toJS();
};

export const getFieldValue = (form, inputName) =>
    form.getIn(['inputFields', inputName, 'value']);

export const getFieldError = (form, inputName) =>
    form.getIn(['inputFields', inputName, 'error']) || false;

export const getVisibleFieldValue = (form, inputName) =>
    form.getIn(['inputFields', inputName, 'visible'])
        ? getFieldValue(form, inputName)
        : undefined;

export const isFormDirty = form =>
    form.get('inputFields', Map()).reduce((acc, cur) => {
        return cur.get('dirty') || acc
    }, false);

export const getAllFormFieldsFromStore = form =>
    form.get('inputFields') || Map();

export const isFieldValueDirty = (initialValue, value) => {
    if (Iterable.isIterable(initialValue)) {
        return !is(initialValue, value);

    } else if (moment.isMoment(initialValue)) {
        return !initialValue.isSame(value);

    } else if (typeof initialValue === 'object') {
        return !is(fromJS(initialValue), fromJS(value));

    } else {
        return initialValue !== value
            // when we don't have initial value and it's set to null on the server
            && !(initialValue === undefined && value === null)
    }
}

export const isAnyFieldValueDirty = values =>
    values.map(value => isFieldValueDirty(value[0], value[1])).indexOf(true) !== -1
