import React, { Component } from 'react';
import { fromJS, Map, List } from 'immutable';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';

import Page from 'eksenia-lib/src/Page';
import Form from 'eksenia-lib/src/Form/Form'
import Button from 'eksenia-lib/src/Button';
import EmptyState from 'eksenia-lib/src/EmptyState';
import {
    RMS_ROOM_PROPS,
} from 'Rooms';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import ReservationList from './ReservationList';
import reservationFilteringForm from './reservationFilteringForm';
import {
    BKG_RESERVATION_FILTERING_ALL_VALUE,
    BKG_RESERVATION_FILTERING_PROPS,
    BKG_EDIT_RESERVATION_MODAL_NAME,
} from './constants';
import localeMessages from './messages';
import {PAGE_FORMATTERS} from "eksenia-lib/src/Page/Page";

const initialValues = fromJS({
    [BKG_RESERVATION_FILTERING_PROPS.PERIOD]: moment(),
    [BKG_RESERVATION_FILTERING_PROPS.ROOMS]: BKG_RESERVATION_FILTERING_ALL_VALUE,
});

export class ReservationsPage extends Component {

    renderHeader() {
        const {
            rooms,
            showModal,
            intl,
        } = this.props;

        const roomsWithAll = rooms.unshift(Map({
            [RMS_ROOM_PROPS.ID]: BKG_RESERVATION_FILTERING_ALL_VALUE,
            [RMS_ROOM_PROPS.NAME]: intl.formatMessage(localeMessages.allRooms),
        }));

        return (
            <div>
                <Form
                    blueprint={reservationFilteringForm(roomsWithAll, initialValues)}
                />
                <Button
                    onClick={showModal.bind(this, BKG_EDIT_RESERVATION_MODAL_NAME)}
                    formatters={[Button.TYPE.PRIMARY]}
                >
                    <FormattedMessage {...localeMessages.addReservation} />
                </Button>
            </div>
        );
    }

    render() {
        const {
            rooms,
            finishedLoading,
        } = this.props;

        if (finishedLoading && rooms.size === 0) {
            return (
                <Page>
                    <EmptyState
                        formatters={['page']}
                        text={<FormattedMessage {...localeMessages.pleaseCreateRooms} />}
                    />
                </Page>
            )
        }

        return (
            <Page
                header={this.renderHeader()}
                formatters={[PAGE_FORMATTERS.WITH_HEADER]}
            >
                <ReservationList />
            </Page>
        );
    }
}

const mapStateToProps = (store) => {
    const roomsReducer = store.roomsReducer;

    return {
        rooms: roomsReducer.get('rooms', List()),
        finishedLoading: roomsReducer.get('finishedLoading', false),
    }
};

const mapDispatchToProps = {
    showModal,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReservationsPage))
