import React, { Component } from 'react';

import {
    inputTypes,
} from '../Form';
import methods from '../Form/methods';
import InputLabel from '../InputLabel';
import ValidationError from '../ValidationError';
import { composeClassName } from 'utils';

export default class InputGroup extends Component {

    constructor() {
        super(...arguments);

        this.handleInputChange = methods.handleInputChange.bind(this);
        this.handleSubmit = methods.handleSubmit.bind(this);
        this.renderInputField = methods.renderInputField.bind(this);
        this.shouldShowField = methods.shouldShowField.bind(this);
        this.fieldBlueprintToStore = methods.fieldBlueprintToStore.bind(this);
        this.updateConnectedInputs = methods.updateConnectedInputs.bind(this);
    }

    handleChange(id, value) {
        const {
            onChangeFromForm,
            onChange,
            name,
            mapValueChange,
            value: oldValue,
        } = this.props;

        if (typeof mapValueChange === 'function') {
            return mapValueChange(id, value);
        }

        const newValue = {
            ...oldValue,
            [id]: value,
        };

        if (typeof onChangeFromForm === 'function') {
            onChangeFromForm(name, newValue);
        }

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    render() {
        const {
            label,
            onChange,
            onChangeFromForm,
            connectTo,
            value = {},
            initialValue = {},
            showIf,
            field,
            group,
            formatters = [],
            error,
            // eslint-disable-next-line
            ...restProps
        } = this.props;

        return (
            <div
                className={composeClassName('input-group-container', ...formatters)}
            >
                {label && (
                    <InputLabel
                        className={composeClassName('input-group-label', ...formatters)}
                    >
                        {label}
                    </InputLabel>
                )}

                <div
                    className={composeClassName('input-group', ...formatters)}
                >
                    {group.map(field => {
                        const {
                            type,
                            name,
                            ...restProps
                        } = field;

                        const Field = inputTypes[type];


                        return (
                            <Field
                                onChange={this.handleChange.bind(this, name)}
                                key={name}
                                defaultValue={initialValue[name]}
                                value={value[name]}
                                formatters={formatters}
                                name={name}
                                {...restProps}
                            />
                        )
                    })}
                </div>

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}
