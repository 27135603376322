import { endpoints } from '../api'

import {
    GET_FILE_UPLOAD_URL_SUCCESS,
    GET_FILE_UPLOAD_URL_ERROR,
    GET_PUBLIC_FILE_URL_PREFIX_SUCCESS,
    SET_UPLOAD_PROGRESS,
    SET_UPLOAD_FINISHED,
} from './constants';

export const uploadFiles = (inputValue, formName, inputName, successCallback, isPublic, doNotSetFormNotSubmitting) => ({
    type: 'FILE_UPLOAD',
    actions: {
        success: GET_FILE_UPLOAD_URL_SUCCESS,
        error: GET_FILE_UPLOAD_URL_ERROR,
        successCallback,
    },
    inputValue,
    formName,
    inputName,
    isPublic,
    doNotSetFormNotSubmitting,
});

export const getPublicFileUrlPrefix = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getPublicFileUrlPrefix,
    actions: {
        success: GET_PUBLIC_FILE_URL_PREFIX_SUCCESS,
    },
});

export const setUploadProgress = (name, total) => ({
    type: SET_UPLOAD_PROGRESS,
    name,
    total,
});

export const setUploadFinished = name => ({
    type: SET_UPLOAD_FINISHED,
    name,
});
