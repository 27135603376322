export const GET_GUESTS_REQUEST = 'GET_GUESTS_REQUEST';
export const GET_GUESTS_SUCCESS = 'GET_GUESTS_SUCCESS';
export const GET_GUESTS_ERROR = 'GET_GUESTS_ERROR';

export const POST_GUEST_REQUEST = 'POST_GUEST_REQUEST';
export const POST_GUEST_SUCCESS = 'POST_GUEST_SUCCESS';
export const POST_GUEST_ERROR = 'POST_GUEST_ERROR';

export const UPDATE_GUEST_REQUEST = 'UPDATE_GUEST_REQUEST';
export const UPDATE_GUEST_SUCCESS = 'UPDATE_GUEST_SUCCESS';
export const UPDATE_GUEST_ERROR = 'UPDATE_GUEST_ERROR';

export const GET_GUEST_REQUEST = 'GET_GUEST_REQUEST';
export const GET_GUEST_SUCCESS = 'GET_GUEST_SUCCESS';
export const GET_GUEST_ERROR = 'GET_GUEST_ERROR';

export const GET_GUEST_RESERVATIONS_REQUEST = 'GET_GUEST_RESERVATIONS_REQUEST';
export const GET_GUEST_RESERVATIONS_SUCCESS = 'GET_GUEST_RESERVATIONS_SUCCESS';
export const GET_GUEST_RESERVATIONS_ERROR = 'GET_GUEST_RESERVATIONS_ERROR';

export const DELETE_GUEST_REQUEST = 'DELETE_GUEST_REQUEST';
export const DELETE_GUEST_SUCCESS = 'DELETE_GUEST_SUCCESS';
export const DELETE_GUEST_ERROR = 'DELETE_GUEST_ERROR';

export const SEARCH_GUESTS_REQUEST = 'SEARCH_GUESTS_REQUEST';
export const SEARCH_GUESTS_SUCCESS = 'SEARCH_GUESTS_SUCCESS';
export const SEARCH_GUESTS_ERROR = 'SEARCH_GUESTS_ERROR';

export const CLEAR_SEARCH_GUESTS_RESULT = 'CLEAR_SEARCH_GUESTS_RESULT';

export const CLEAR_GUEST_RESERVATIONS = 'CLEAR_GUEST_RESERVATIONS';

export const ADD_EDITED_GUEST = 'ADD_EDITED_GUEST';
export const CLEAR_EDITED_GUEST = 'CLEAR_EDITED_GUEST';
export const CLEAR_ALL_EDITED_GUESTS = 'CLEAR_ALL_EDITED_GUESTS';

export const EDIT_GUEST_MODAL_NAME = 'EDIT_GUEST_MODAL_NAME';
export const EDIT_GUEST_FORM_NAME = 'EDIT_GUEST_FORM_NAME';
export const GUEST_RESERVATIONS_FORM_NAME = 'GUEST_RESERVATIONS_FORM_NAME';

export const FILTER_GUESTS_FORM_NAME = 'FILTER_GUESTS_FORM_NAME';
export const DELETE_GUEST_CONFIRMATION_NAME = 'DELETE_GUEST_CONFIRMATION_NAME';

export const GUEST_PROPS = {
    ID: 'ID',
    FIRST_NAME: 'FirstName',
    LAST_NAME: 'LastName',
    EMAIL: 'Email',
    PHONE: 'Phone',
    COUNTRY: 'Country',
    ADDRESS: 'Address',
    CITY: 'City',
    ID_TYPE: 'IDType',
    ID_NUMBER: 'IDNumber',
    NOTES: 'Notes',
    RESERVATIONS: 'reservations',
    DELETED_AT: 'DeletedAt',
};

export const ID_TYPES = {
    PASSPORT: 'Passport',
    ID_CARD: 'IdCard',
    DRIVERS_LICENCE: 'DriversLicence',
};

export const GUESTS_FILTERING_PROPS = {
    TEXT: 'TEXT',
};

export const GUEST_RESERVATIONS_PROPS = {
    GUEST_ID: 'GuestID',
    RESERVATION_LIST: 'ReservationList',
}
