import { call, put, takeEvery, all } from 'redux-saga/effects';
import React from 'react';
import {
    FormattedMessage,
} from "react-intl";

import localeMessages from './messages';
import apiCall from "./apiCall";
import {
    setInitialValuesToCurrent,
    setValidationError,
    setFormSubmitting,
    setFormNotSubmitting,
} from '../Form';
import {
    addNotification,
} from '../Notifications';
import {NOTIFICATION_TYPE} from "../Notifications/constants";

function* updateList(action) {
    const {
        actions: {
            success,
            error,
        } = {},
        toPost,
        toUpdate,
        toDelete,
        postEndpoint,
        updateEndpoint,
        deleteEndpoint,
        formName,
        payload,
    } = action;

    if (typeof formName === 'string') {
        yield put(setFormSubmitting(formName));
    }

    const data = yield all(toPost.map(it =>
        call(apiCall, postEndpoint, {}, it, {})
    ).concat(toUpdate.map(it =>
        call(apiCall, updateEndpoint, {[it.param.name]: it.param.value}, it, {})
    )).concat(toDelete.map(it =>
        call(apiCall, deleteEndpoint, {[it.param.name]: it.param.value}, it, {})
    )));

    const toYield = [];

    data.forEach(response => {
        if (!response.ok && response.status < 500) {
            const resData = response.data;
            const errorMessage = resData && resData.ErrorMessage;
            toYield.push({
                type: error || '',
                errors: resData ? resData.Errors : undefined,
                message: errorMessage || undefined,
                status: response.status,
                payload,
            });

            if (typeof formName === 'string' && errorMessage) {
                toYield.push(setValidationError(formName, errorMessage));
            }

        }
    });

    if (data.every(response => response.ok && response.status < 500)) {
        toYield.push({
            type: success || '',
            data: {
                data: data.map(item => item.data),
                toDelete,
                toUpdate,
                toPost,
            },
            payload,
        });
        toYield.push(setInitialValuesToCurrent(formName));
    } else if (data.some(response => !response.ok && response.status >= 500)) {
        toYield.push(addNotification({
            type: NOTIFICATION_TYPE.ERROR,
            content: <FormattedMessage {...localeMessages.serverError} />,
        }));
        toYield.push({
            type: error || '',
            error,
            payload,
        });
        toYield.push(setValidationError(formName, ''));
    }

    if (typeof formName === 'string') {
        toYield.push(setFormNotSubmitting(formName));
    }

    if (toYield.length) {
        yield all(toYield.map(act => put(act)));
    }
}

function* updateListSaga() {
    yield takeEvery('UPDATE_LIST', updateList);
}

export default updateListSaga
