import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {FormattedMessage, injectIntl,} from 'react-intl';

import { composeClassName } from 'utils';
import localeMessages from './messages';
import Icon from "eksenia-lib/src/Icon";

const breakpoint = 990;

export class VerticalNavigation extends Component {

    constructor() {
        super(...arguments);

        this.onCollapseClick = this.onCollapseClick.bind(this);
        this.onMobileMenuToggle = this.onMobileMenuToggle.bind(this);
        this.onPropertyClick = this.onPropertyClick.bind(this);
        this.closeMobileMenu = this.closeMobileMenu.bind(this);

        this.state = {
            propertyGroupOpen: false,
            collapsed: false,
            showMobileMenu: false,
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.closeMobileMenu);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.closeMobileMenu);
    }

    closeMobileMenu() {
        if (this.props.width < breakpoint && this.state.showMobileMenu) {
            this.setState({
                showMobileMenu: false,
            });
        }
    }

    onMobileMenuToggle() {
        setTimeout(() => {
            this.setState({
                showMobileMenu: !this.state.showMobileMenu,
            });
        }, 10)
    }

    onCollapseClick() {
        this.setState({
            collapsed: !this.state.collapsed,
            propertyGroupOpen: !this.state.collapsed ? false : this.state.propertyGroupOpen,
        });
    }

    onPropertyClick(event) {
        event.stopPropagation();
        this.setState({
            propertyGroupOpen: !this.state.propertyGroupOpen,
        });
    }

    renderHamburger() {
        const {
            intl,
        } = this.props;
        const {
            showMobileMenu,
        } = this.state;

        return (
            <div className="vertical-navigation">
                <div className="vertical-navigation-content">
                    <div className="main-menu">
                        <img
                            src={'eksenia-logo-icon.svg'}
                            alt={intl.formatMessage(localeMessages.mainMenu)}
                            onClick={this.onMobileMenuToggle}
                        />

                        <div className="navigation">
                            {showMobileMenu && this.renderMenu()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    propertyGroup() {
        const {
            location,
        } = this.props;
        const {
            propertyGroupOpen,
            collapsed,
        } = this.state;

        const propertyClassName = (!propertyGroupOpen && [
            '/rooms',
            '/booking-page',
            '/property-settings',
        ].indexOf(location.pathname) !== -1)
            ? 'nav-item dropdown-nav-item active'
            : 'nav-item dropdown-nav-item';

        return (
            <>
                <button
                    className={propertyClassName}
                    onClick={this.onPropertyClick}
                    aria-labelledby="navigation-property-submenu"
                    type="button"
                >
                    <Icon name="property" type="vertical-nav" />
                    <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                        <FormattedMessage {...localeMessages.property} />
                    </span>
                    <Icon
                        name={propertyGroupOpen ? "sideArrowActive" : "sideArrowPassive"}
                        type={collapsed ? 'dropdown-arrow collapsed' : 'dropdown-arrow'}
                    />
                    {collapsed &&
                        <div role="tooltip" id="navigation-property-submenu">
                            <FormattedMessage {...localeMessages.propertySubmenu} />
                        </div>
                    }
                </button>

                {propertyGroupOpen &&
                    <>
                        <Link
                            to='/rooms'
                            className={composeClassName('nav-item sub-nav-item', location.pathname === '/rooms' ? 'active' : '')}
                        >
                            <FormattedMessage {...localeMessages.rooms} />
                        </Link>
                        <Link
                            to='/taxes-and-fees'
                            className={composeClassName('nav-item sub-nav-item', location.pathname === '/taxes-and-fees' ? 'active' : '')}
                        >
                            <FormattedMessage {...localeMessages.taxesAndFees} />
                        </Link>
                        <Link
                            to='/booking-page'
                            className={composeClassName('nav-item sub-nav-item', location.pathname === '/booking-page' ? 'active' : '')}
                        >
                            <FormattedMessage {...localeMessages.bookingPage} />
                        </Link>
                        <Link
                            to='/property-settings'
                            className={composeClassName('nav-item sub-nav-item', location.pathname === '/property-settings' ? 'active' : '')}
                        >
                            <FormattedMessage {...localeMessages.settings} />
                        </Link>
                    </>
                }
            </>
        )
    }

    renderMenu() {
        const {
            location,
        } = this.props;
        const {
            collapsed,
        } = this.state;

        return (
            <nav>
                <Link
                    to='/'
                    className={composeClassName('nav-item', location.pathname === '/' ? 'active' : '')}
                    aria-labelledby="navigation-dashboard"
                >
                    <Icon name="dashboard" type="vertical-nav" />
                    <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                <FormattedMessage {...localeMessages.dashboard} />
                            </span>
                    {collapsed &&
                    <div role="tooltip" id="navigation-dashboard">
                        <FormattedMessage {...localeMessages.dashboard} />
                    </div>
                    }
                </Link>
                <Link
                    to='/calendar'
                    className={composeClassName('nav-item', location.pathname === '/calendar' ? 'active' : '')}
                    aria-labelledby="navigation-calendar"
                >
                    <Icon name="calendar" type="vertical-nav" />
                    <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                <FormattedMessage {...localeMessages.calendar} />
                            </span>
                    {collapsed &&
                    <div role="tooltip" id="navigation-calendar}">
                        <FormattedMessage {...localeMessages.calendar} />
                    </div>
                    }
                </Link>
                <Link
                    to='/reservations'
                    className={composeClassName('nav-item', location.pathname === '/reservations' ? 'active' : '')}
                    aria-labelledby="navigation-reservations"
                >
                    <Icon name="reservations" type="vertical-nav" />
                    <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                <FormattedMessage {...localeMessages.reservations} />
                            </span>
                    {collapsed &&
                    <div role="tooltip" id="navigation-reservations}">
                        <FormattedMessage {...localeMessages.reservations} />
                    </div>
                    }
                </Link>
                <Link
                    to='/rates'
                    className={composeClassName('nav-item', location.pathname === '/rates' ? 'active' : '')}
                    aria-labelledby="navigation-rates"
                >
                    <Icon name="rates" type="vertical-nav" />
                    <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                <FormattedMessage {...localeMessages.rates} />
                            </span>
                    {collapsed &&
                    <div role="tooltip" id="navigation-rates}">
                        <FormattedMessage {...localeMessages.rates} />
                    </div>
                    }
                </Link>
                <Link
                    to='/guests'
                    className={composeClassName('nav-item', location.pathname === '/guests' ? 'active' : '')}
                    aria-labelledby="navigation-guests"
                >
                    <Icon name="guests" type="vertical-nav" />
                    <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                <FormattedMessage {...localeMessages.guests} />
                            </span>
                    {collapsed &&
                    <div role="tooltip" id="navigation-guests}">
                        <FormattedMessage {...localeMessages.guests} />
                    </div>
                    }
                </Link>

                {this.propertyGroup()}
            </nav>
        );
    }

    render() {
        const {
            intl,
            width,
        } = this.props;
        const {
            collapsed,
        } = this.state;

        if (width < breakpoint) {
            return this.renderHamburger();
        }

        return (
            <div className={composeClassName(
                'vertical-navigation',
                collapsed ? 'collapsed-navigation' : 'open-navigation')
            } >
                <div className="vertical-navigation-content">
                    <div className="logo">
                        <img
                            src={collapsed ? 'eksenia-logo-icon.svg' : 'eksenia-logo.svg'}
                            alt={intl.formatMessage(localeMessages.ekseniaLogo)}
                        />
                    </div>

                    <div
                        className="collapse-control"
                        onClick={this.onCollapseClick}
                    >
                        <Icon
                            name={collapsed ? "expandPassive" : "collapsePassive"}
                            type="collapse"
                        />
                    </div>

                    {this.renderMenu()}
                </div>
            </div>
        );
    }
}

export default injectIntl(VerticalNavigation)