import React from "react";
import moment from 'moment';
import {Iterable, List, Map} from 'immutable';
import {FormattedMessage} from "react-intl";

import {
    GUEST_PROPS,
} from 'Guests';
import {
    RESERVATION_PROPS,
    GUEST_LIST_PROPS,
    RESERVATION_CHARGE_PROPS,
    PRICE_BREAKDOWN_PROPS,
    RESERVATION_GUESTS_FORM_NAME,
} from './constants';
import chargesMessages from "../TaxesAndFees/messages";
import localeMessages from "./messages";

export const prepareDataForApi = values =>
    values
        .filter((value, key) => key !== RESERVATION_PROPS.PRICE_BREAKDOWN)
        .map((value, key) =>
        moment.isMoment(value)
            ? value.format('YYYY-MM-DD')
            : prepareReservationGuests(value, key));

export const prepareReservationGuests = (value, key) => {
    if (key !== RESERVATION_PROPS.GUEST_LIST) {
        return value;

    } else if (Iterable.isIterable(value)) {
        return value.map((guest, idx) => inputToServerReservationGuest(guest, idx));

    } else {
        return value;
    }
};

export const inputToServerReservationGuest = (guest, idx) => ({
    ...guest.toJS(),
    [GUEST_LIST_PROPS.GUEST_RANK]: idx,
    [GUEST_LIST_PROPS.ID]: guest.get(GUEST_LIST_PROPS.RESERVATION_ID)
        ? guest.get(GUEST_LIST_PROPS.ID)
        : undefined,
});

export const serverGuestToInput = (guest, inputId, reservationId) => ({
    ...guest,
    [GUEST_LIST_PROPS.GUEST_ID]: guest[GUEST_PROPS.ID],
    [GUEST_LIST_PROPS.ID]: inputId,
    [GUEST_LIST_PROPS.RESERVATION_ID]: reservationId,
});

export const getReservationGuestName = (reservation, intl) => {
    if (!Iterable.isIterable(reservation)) {
        return '';
    }

    const guestFirstName = reservation.getIn([RESERVATION_PROPS.GUEST_LIST, 0, GUEST_PROPS.FIRST_NAME]);
    const guestLastName = reservation.getIn([RESERVATION_PROPS.GUEST_LIST, 0, GUEST_PROPS.LAST_NAME]);
    const guestNameFromICal = reservation.get(RESERVATION_PROPS.NAME);

    if (guestFirstName || guestLastName) {
        return `${guestFirstName || ''} ${guestLastName || ''}`.trim();

    }

    if (guestNameFromICal) {
        return guestNameFromICal;
    }

    return intl.formatMessage(localeMessages.placeholderGuestName);
};

export const mapChargeToPriceBreakdownItem = charge => {
    return Map({
        [RESERVATION_CHARGE_PROPS.ID]: charge.get(RESERVATION_CHARGE_PROPS.NAME_FROM_OTA)
        || charge.get(RESERVATION_CHARGE_PROPS.TYPE) + charge.get(RESERVATION_CHARGE_PROPS.TOTAL_PRICE),
        [RESERVATION_CHARGE_PROPS.TYPE]: charge.get(RESERVATION_CHARGE_PROPS.NAME_FROM_OTA)
        || <FormattedMessage {...chargesMessages[charge.get(RESERVATION_CHARGE_PROPS.TYPE)]} />,
        [RESERVATION_CHARGE_PROPS.TOTAL_PRICE]: charge.get(RESERVATION_CHARGE_PROPS.TOTAL_PRICE),
    });
}

export const preparePriceBreakdown = charges => {
    const chargeList = charges.get(RESERVATION_PROPS.CHARGE_LIST) || List();

    const inclusiveChargeList = chargeList.filter(charge =>
        charge.get(RESERVATION_CHARGE_PROPS.IS_INCLUSIVE)
    ).map(charge => mapChargeToPriceBreakdownItem(charge));

    const exclusiveChargeList = chargeList.filter(charge =>
        !charge.get(RESERVATION_CHARGE_PROPS.IS_INCLUSIVE)
    ).map(charge => mapChargeToPriceBreakdownItem(charge));

    const priceWithoutInclusiveCharges = Map({
        [RESERVATION_CHARGE_PROPS.ID]: RESERVATION_PROPS.PRICE_WITHOUT_INCLUSIVE_CHARGES,
        [RESERVATION_CHARGE_PROPS.TYPE]: <FormattedMessage {...localeMessages.priceWithoutInclusiveCharges} />,
        [RESERVATION_CHARGE_PROPS.TOTAL_PRICE]: charges.get(RESERVATION_PROPS.PRICE_WITHOUT_INCLUSIVE_CHARGES) || 0,
    });
    const price = Map({
        [RESERVATION_CHARGE_PROPS.ID]: RESERVATION_PROPS.PRICE,
        [RESERVATION_CHARGE_PROPS.TYPE]: <FormattedMessage {...localeMessages.price} />,
        [RESERVATION_CHARGE_PROPS.TOTAL_PRICE]: charges.get(RESERVATION_PROPS.PRICE) || 0,
    });
    const priceWithAllCharges = Map({
        [RESERVATION_CHARGE_PROPS.ID]: RESERVATION_PROPS.PRICE_WITH_All_CHARGES,
        [RESERVATION_CHARGE_PROPS.TYPE]: <FormattedMessage {...localeMessages.priceWithAllCharges} />,
        [RESERVATION_CHARGE_PROPS.TOTAL_PRICE]: charges.get(RESERVATION_PROPS.PRICE_WITH_All_CHARGES) || 0,
    });

    let completeCharges = List();
    if (inclusiveChargeList.size > 0) {
        completeCharges = completeCharges.push(priceWithoutInclusiveCharges, ...inclusiveChargeList);
    }
    completeCharges = completeCharges.push(price)
    if (exclusiveChargeList.size > 0) {
        completeCharges = completeCharges.push(...exclusiveChargeList, priceWithAllCharges);
    }
    const cancellationFee = charges.get(PRICE_BREAKDOWN_PROPS.CANCELLATION_FEE);
    if (cancellationFee) {
        completeCharges = completeCharges.concat(List([
            Map({
                [RESERVATION_CHARGE_PROPS.ID]: PRICE_BREAKDOWN_PROPS.CANCELLATION_FEE,
                [RESERVATION_CHARGE_PROPS.TYPE]: <FormattedMessage {...localeMessages.cancellationFee} />,
                [RESERVATION_CHARGE_PROPS.TOTAL_PRICE]: cancellationFee,
            }),
            Map({
                [RESERVATION_CHARGE_PROPS.ID]: PRICE_BREAKDOWN_PROPS.TOTAL_PRICE,
                [RESERVATION_CHARGE_PROPS.TYPE]: <FormattedMessage {...localeMessages.totalDue} />,
                [RESERVATION_CHARGE_PROPS.TOTAL_PRICE]: charges.get(PRICE_BREAKDOWN_PROPS.TOTAL_PRICE),
            })
        ]));
    }
    return completeCharges;
}

export const multiPriceBreakdownToPriceBreakdown = charges =>
    Map({
        [PRICE_BREAKDOWN_PROPS.CANCELLATION_FEE]:
            charges.get(PRICE_BREAKDOWN_PROPS.CANCELLATION_FEE),
        [PRICE_BREAKDOWN_PROPS.TOTAL_PRICE]:
            charges.get(PRICE_BREAKDOWN_PROPS.TOTAL_PRICE),
        [RESERVATION_PROPS.PRICE]:
            charges.get(PRICE_BREAKDOWN_PROPS.TOTAL_ROOM_PRICE),
        [RESERVATION_PROPS.PRICE_WITHOUT_INCLUSIVE_CHARGES]:
            charges.get(PRICE_BREAKDOWN_PROPS.TOTAL_ROOM_PRICE_WITHOUT_INCLUSIVE_CHARGES),
        [RESERVATION_PROPS.PRICE_WITH_All_CHARGES]:
            charges.get(PRICE_BREAKDOWN_PROPS.TOTAL_ROOM_PRICE_WITH_ALL_CHARGES),
        [RESERVATION_PROPS.CHARGE_LIST]:
            charges.get(RESERVATION_PROPS.CHARGE_LIST),
    })

export const mapReservationsValues = reservations =>
    (reservations || List()).map(mapReservationValues);

export const mapReservationValues = reservation =>
    reservation.merge({
        [RESERVATION_PROPS.CHECK_IN]: moment(reservation.get(RESERVATION_PROPS.CHECK_IN)),
        [RESERVATION_PROPS.CHECK_OUT]: moment(reservation.get(RESERVATION_PROPS.CHECK_OUT)),
    });

export const reservationGuestModalId = (guestId, generatedKey) => `${guestId}_${generatedKey}`;

const reservationFormName = (formId, defaultFormName) =>
    formId
        ? `${formId}_${defaultFormName}`
        : defaultFormName;

export const reservationGuestsFormName = formId =>
    reservationFormName(formId, RESERVATION_GUESTS_FORM_NAME);
