import { defineMessages } from 'react-intl';

export default defineMessages({
    unitLimitReached: {
        id: 'validation.unitLimitReached',
        defaultMessage: 'You have reached your limit of units.',
    },
    unitUnavailable: {
        id: 'validation.unitUnavailable',
        defaultMessage: 'This unit is unavailable in this period.',
    },
    checkInNotAllowedOnThisWeekDay: {
        id: 'validation.checkInNotAllowedOnThisWeekDay',
        defaultMessage: 'Checkin is not allowed on this day.',
    },
    checkOutNotAllowedOnThisWeekDay: {
        id: 'validation.checkOutNotAllowedOnThisWeekDay',
        defaultMessage: 'Check out is not allowed on this day.',
    },
    required: {
        id: 'validation.required',
        defaultMessage: 'Required',
    },
    numberGreaterThan: {
        id: 'validation.numberGreaterThan',
        defaultMessage: 'Value must be greater than {limit}',
    },
    numberSmallerOrEqualTo: {
        id: 'validation.numberSmallerOrEqualTo',
        defaultMessage: 'Value cannot be greater than {limit}',
    },
    numberNotNegative: {
        id: 'validation.numberNotNegative',
        defaultMessage: 'Value cannot be negative number',
    },
    numberInRangeInclusive: {
        id: 'validation.numberInRangeInclusive',
        defaultMessage: 'Value must be in range between {low} and {high}',
    },
    minimumFiles: {
        id: 'validation.minimumFiles',
        defaultMessage: 'Minimum {limit} files required',
    },
    maximumFiles: {
        id: 'validation.maximumFiles',
        defaultMessage: 'Maximum of {limit} files allowed',
    },
    requiredFile: {
        id: 'validation.requiredFile',
        defaultMessage: 'File is required',
    },
    maxFileSize: {
        id: 'validation.maxFileSize',
        defaultMessage: 'Maximum file size allowed is {limit}Mb',
    },
    uploadFailed: {
        id: 'validation.uploadFailed',
        defaultMessage: 'Upload failed. The internet connection might be slow. Try uploading files one by one.',
    },
    uploadFailedSingleFile: {
        id: 'validation.uploadFailedSingleFile',
        defaultMessage: 'Upload failed. The internet connection might be slow. Please try again later.',
    },
})
