import React, { Component } from 'react';
import moment from 'moment';
import {
    FormattedMessage,
    FormattedDate,
} from "react-intl";

import {
    MESSAGE_PROPS,
    MESSAGE_ROOM_PROPS,
    MESSAGE_RESERVATION_PROPS,
} from './constants';
import localeMessages from './messages';

export default class Message extends Component {

    render() {
        const {
            message,
        } = this.props;

        const reservationStart = moment(message.getIn([MESSAGE_PROPS.RESERVATION, MESSAGE_RESERVATION_PROPS.CHECK_IN_TIME]));
        const reservationEnd = moment(message.getIn([MESSAGE_PROPS.RESERVATION, MESSAGE_RESERVATION_PROPS.CHECK_OUT_TIME]));
        const newReservationStart = moment(message.getIn([MESSAGE_PROPS.OVERBOOKING, MESSAGE_RESERVATION_PROPS.CHECK_IN_TIME]));
        const newReservationEnd = moment(message.getIn([MESSAGE_PROPS.OVERBOOKING, MESSAGE_RESERVATION_PROPS.CHECK_OUT_TIME]));

        const overbookingStart = newReservationStart.isAfter(reservationStart)
            ? newReservationStart
            : reservationStart;

        const overbookingEnd = moment(newReservationEnd.isBefore(reservationEnd)
            ? newReservationEnd
            : reservationEnd).subtract(1, 'day');

        const overbookingMessage = overbookingStart.isSame(overbookingEnd, 'day')
            ? localeMessages.overbookingNotificationSingleDay
            : localeMessages.overbookingNotification;

        const roomID = message.getIn([MESSAGE_PROPS.ROOM, MESSAGE_ROOM_PROPS.ID]);
        const reservationID = message.getIn([MESSAGE_PROPS.RESERVATION, MESSAGE_RESERVATION_PROPS.ID]);
        const newReservationID = message.getIn([MESSAGE_PROPS.OVERBOOKING, MESSAGE_RESERVATION_PROPS.ID]);
        const key = `${roomID}_${reservationID}_${newReservationID}`;

        return (
            <div className="message" key={key}>
                <div className="message-subject" key="subject">
                    <FormattedMessage {...localeMessages.messageSubjectOverbooking} />
                </div>
                <div className="message-content" key="content">
                    <FormattedMessage {...overbookingMessage} values={{
                        unitName: <span className="message-emphasized">{message.getIn([MESSAGE_PROPS.ROOM, MESSAGE_ROOM_PROPS.UNIT_NAME])}</span>,
                        roomName: <span className="message-emphasized">{message.getIn([MESSAGE_PROPS.ROOM, MESSAGE_ROOM_PROPS.NAME])}</span>,
                        startDate: <span className="message-emphasized">
                            <FormattedDate value={overbookingStart.toDate()} />
                        </span>,
                        endDate: <span className="message-emphasized">
                            <FormattedDate value={overbookingEnd.toDate()} />
                        </span>,
                    }} />
                </div>
            </div>
        );
    }
}
