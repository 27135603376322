import React, {Component} from 'react';
import {Iterable} from 'immutable';
import {connect} from 'react-redux';

import {inputTypes,} from '../Form';
import Confirm from '../Confirm';
import {hideModal, showModal} from '../Modal';
import ValidationError from '../ValidationError';
import InputLabel from '../InputLabel';
import Button from '../Button';
import {composeClassName} from '../utils';
import Table from "../Table";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const confirmModalName = name => `${name}_INPUT_MATRIX_DELETE_CONFIRMATION_NAME`;

export class InputMatrix extends Component {

    constructor() {
        super(...arguments);

        this.handleAddInput = this.handleAddInput.bind(this);
        this.deleteInput = this.deleteInput.bind(this);
        this.deleteInputCloseConfirmation = this.deleteInputCloseConfirmation.bind(this);
    }

    deleteInputId = null;

    handleAddInput() {
        const {
            value,
            onAddInput,
            createEmptyRow,
        } = this.props;

        if (typeof onAddInput === 'function') {
            onAddInput();
        } else {
            const newValue = value.push(createEmptyRow(value));

            this.updateValue(newValue);
        }
    }

    handleDeleteInput(id, event) {
        const {
            deleteInputConfirmationText,
            showModal,
            name,
        } = this.props;

        event.stopPropagation();

        this.deleteInputId = id;

        if (deleteInputConfirmationText) {
            showModal(confirmModalName(name));

        } else {
            this.deleteInput();
        }
    }

    deleteInput() {
        const {
            value,
            name,
            inputs,
        } = this.props;

        const {
            id: getFieldId,
        } = inputs;

        const newValue = value.filter((field, i) =>
            this.deleteInputId !== getFieldId(name, i, field)
        );

        this.updateValue(newValue);
        this.deleteInputId = null
    }

    deleteInputCloseConfirmation() {
        this.deleteInput();
        this.props.hideModal();
    }

    handleChange(id, name, value) {
        const {
            value: oldValue,
            inputs,
            name: fieldName,
        } = this.props;

        const {
            id: getFieldId,
        } = inputs;

        const newValue = oldValue.map((item, i) =>
            getFieldId(fieldName, i, item) === id
                ? item.set(name, value)
                : item
        );

        this.updateValue(newValue);
    }

    updateValue(newValue) {
        const {
            onChangeFromForm,
            onChange,
            name,
        } = this.props;

        if (typeof onChangeFromForm === 'function') {
            onChangeFromForm(name, newValue);
        }

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    prepareTableRows() {
        const {
            defaultValue,
            inputs,
            value,
            name,
            formatters = [],
            showDelete,
        } = this.props;

        const {
            id: getFieldId,
            entityId: getEntityId,
            edit: renderEdit,
            onClick,
            fields,
            showDeletedState,
            deletedMessage,
        } = inputs;

        return (value || defaultValue).map((item, i) => {
            const itemId = getFieldId(name, i, item);
            const entityId = getEntityId(item);

            const deletedEntity = typeof showDeletedState === 'function' && showDeletedState(item);
            const cells = deletedEntity
                ? [{
                    content: deletedMessage,
                    key: 'deleted',
                    itemId,
                    colSpan: fields.length
                }]
                : fields.map((cell, iter) => {
                    const {
                        name,
                        initialValue: fieldDefaultValue,
                        render,
                        formatters: cellFormatters = [],
                        ...restCellProps
                    } = cell;
                    let fieldValue = item.get(name);

                    if (!fieldValue && typeof fieldDefaultValue !== 'undefined') {
                        fieldValue = fieldDefaultValue;
                    }
                    if (typeof render === 'function') {
                        fieldValue = render(fieldValue);
                    }

                    if (iter === 0) {
                        fieldValue = <>
                            {fieldValue}
                            {typeof renderEdit === 'function' && renderEdit({
                                inputId: entityId,
                                item,
                            })}
                        </>
                    }

                    const cellId = `${itemId}_${name}`;

                    return (
                        {
                            content: fieldValue,
                            key: cellId,
                            className: composeClassName(...formatters, ...cellFormatters),
                            itemId,
                            ...restCellProps
                        }
                    );
                });

            if (showDelete) {
                cells.push({
                    content:
                        <Button
                            onClick={event => this.handleDeleteInput(itemId, event)}
                            formatters={[Button.TYPE.DELETE_LINK]}
                        >
                            <Icon
                                name="bin"
                                type="delete"
                            />
                        </Button>
                    ,
                    key: 'delete',
                    className: composeClassName(...formatters, 'delete-cell'),
                });
            }

            return {
                key: itemId,
                onClick: typeof onClick === 'function' && !deletedEntity
                    ? onClick.bind(null, entityId, item)
                    : undefined,
                cells: cells,
            }
        });
    }

    renderTableView() {
        const {
            formatters = [],
            headers,
            showDelete,
            hideHeader,
        } = this.props;

        const tableHeader = headers.map(head => ({
            key: head.id,
            content: head.text,
            width: head.width,
        }));

        if (showDelete) {
            tableHeader.push({
                key: 'delete',
                content: '',
                width: '30px',
            });
        }

        const rows = this.prepareTableRows();

        return (
            <div
                className={composeClassName('input-matrix-container', ...formatters)}
            >
                <Table
                    header={tableHeader}
                    hideHeader={hideHeader}
                    rows={rows}
                />
            </div>
        );
    }

    renderMatrixView() {
        const {
            defaultValue,
            value,
            inputs,
            name,
            deleteInputText,
            formatters = [],
            headers,
            atLeastOne,
        } = this.props;

        const {
            id: getFieldId,
            fields,
            ...inputProps
        } = inputs;

        return (
            <table
                className={composeClassName('input-matrix', ...formatters)}
            >
                {Array.isArray(headers) && !!(value || defaultValue).size && (
                    <thead>
                    <tr>
                        {headers.map(header =>
                            <th key={header.id}>
                                {header.text}
                                {header.help && <Tooltip help={header.help} />}
                            </th>
                        )}
                        <td/>
                    </tr>
                    </thead>
                )}

                <tbody>
                {(value || defaultValue).map((item, i, iter) => {
                    const itemId = getFieldId(name, i, item);

                    return (
                        <tr
                            key={itemId}
                            className={composeClassName('input-matrix-row', ...formatters)}
                        >
                            {fields.map((cell) => {
                                const {
                                    type,
                                    name,
                                    initialValue: fieldDefaultValue,
                                    ...restProps
                                } = cell;

                                if (type === 'label') {
                                    return (
                                        <td key="label" className='input-matrix-label-cell'>
                                            <span>{item.get('label')}</span>
                                        </td>
                                    );
                                }

                                const cellId = `${itemId}_${name}`;
                                const Field = inputTypes[type];
                                let fieldValue = item.get(name);
                                if (!fieldValue && typeof fieldDefaultValue !== 'undefined') {
                                    fieldValue = fieldDefaultValue;
                                }

                                return (
                                    <td key={cellId}>
                                        <Field
                                            key={cellId}
                                            onChange={this.handleChange.bind(this, itemId, name)}
                                            value={fieldValue}
                                            parentValue={item}
                                            {...inputProps}
                                            {...restProps}
                                        />
                                    </td>
                                );
                            })}

                            {deleteInputText && !(atLeastOne && iter.size === 1) &&
                                <td key="delete">
                                    <Button
                                        onClick={event => this.handleDeleteInput(itemId, event)}
                                        formatters={[Button.TYPE.LINK]}
                                    >
                                        {deleteInputText}
                                    </Button>
                                </td>
                            }
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }

    render() {
        const {
            label,
            defaultValue,
            value,
            addInputText,
            formatters = [],
            error,
            deleteInputConfirmationText,
            hideModal,
            tableView,
            renderAddInput,
            maxItems,
            name,
        } = this.props;

        if (!Iterable.isIterable(value) && !Iterable.isIterable(defaultValue)) {
            return false
        }

        const content = tableView
            ? this.renderTableView()
            : this.renderMatrixView();

        const showAddItem = maxItems
            ? maxItems > (value || defaultValue).size
            : true;

        return (
            <div
                className={composeClassName('input-matrix-container', ...formatters)}
            >
                {label && (
                    <InputLabel formatters={formatters}>
                        {label}
                    </InputLabel>
                )}

                {content}

                {addInputText && showAddItem &&
                    <div
                        className={composeClassName('input-matrix-add-button', ...formatters)}
                    >
                        <Button
                            formatters={[Button.TYPE.PRIMARY_LINK]}
                            onClick={this.handleAddInput}
                        >
                            <Icon
                                name="plus"
                                type="plus"
                            />
                            {addInputText}
                        </Button>
                        {typeof renderAddInput === 'function' && renderAddInput(value)}
                    </div>
                }

                <Confirm
                    id={confirmModalName(name)}
                    onCancel={hideModal}
                    onConfirm={this.deleteInputCloseConfirmation}
                >
                    {deleteInputConfirmationText}
                </Confirm>

                <ValidationError
                    error={error}
                    formatters={formatters}
                />
            </div>
        )
    }
}

const mapDispatchToProps = {
    hideModal,
    showModal,
};

export default connect(undefined, mapDispatchToProps)(InputMatrix)
