import {RMS_ROOM_PROPS, UNIT_PROPS} from "./constants";
import {Iterable, List, Map} from "immutable";
import React from "react";

export const getRoomUnits = units =>
    units.reduce((acc, unit) => {
        const roomID = unit.get(UNIT_PROPS.ROOM_ID);

        return acc.get(roomID)
            ? acc.updateIn([roomID], room => room.push(unit))
            : acc.set(roomID, List([unit]));
    }, Map());

export const getUnitName = (rooms, units, unitId) => {
    let unitName = '';
    const unit = units.find(unit => unit.get(UNIT_PROPS.ID) === unitId);
    if (Iterable.isIterable(unit)) {
        const room = rooms.find(room => room.get(RMS_ROOM_PROPS.ID) === unit.get(UNIT_PROPS.ROOM_ID));
        if (Iterable.isIterable(room)) {
            unitName = `${room.get(RMS_ROOM_PROPS.NAME)} - ${unit.get(UNIT_PROPS.NAME)}`
        }
    }

    const nonExistingUnit = <span className="reservation-list-non-existing-unit">
                    n/a
                </span>;

    return unitName || nonExistingUnit
}